import dayjs from 'dayjs';

import { useAccountMonthlyCredits } from '@/features/common/account';

export type FreePlanCardViewModel = {
  credits: {
    used: number;
    total: number;
    refreshDate: string;
  };
  maxUsers: number;
};

export function useFreePlanCardViewModel(): FreePlanCardViewModel {
  const accountMonthlyCredits = useAccountMonthlyCredits();
  return {
    maxUsers: 1,
    credits: {
      used: accountMonthlyCredits.used,
      total: accountMonthlyCredits.limit,
      refreshDate: dayjs(accountMonthlyCredits.expirationDate * 1000).format('lll'),
    },
  };
}
