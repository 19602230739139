import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PromotionExpiredError, PromotionNotFoundError } from '@/features/common/billing';

/**
 * Three different states of the promotion code input:
 *
 * Initial: The user has not yet interacted with the promotion code input.
 * Entering: The user is entering a promotion code.
 * Applied: The user has applied a promotion code.
 */
export enum PromotionCodeViewModelKind {
  Initial = 'Initial',
  Entering = 'Entering',
  Applied = 'Applied',
}

// Error codes that are used to display error messages to the user. Values of enum are i18n keys.
export enum PromotionUIError {
  Required = 'required',
  Expired = 'expired',
  NotFound = 'doesNotExist',
  Unknown = 'unknown',
}

export type InitialViewModel = {
  kind: PromotionCodeViewModelKind.Initial;
  onEnter(): void;
};

export type EnteringViewModel = {
  kind: PromotionCodeViewModelKind.Entering;
  code: string;
  error?: PromotionUIError;
  onChange(code: string): void;
  onApply(): Promise<void>;
};

export type AppliedViewModel = {
  kind: PromotionCodeViewModelKind.Applied;
  appliedPromo: string;
  onRemove(): void;
};

export type PromotionCodeViewModel =
  | InitialViewModel
  | EnteringViewModel
  | AppliedViewModel;

type UsePromotionCodeViewModel = (params: {
  promotionCode?: string;
  onApply: (coupon: string) => Promise<void>;
  onRemove: () => void;
}) => PromotionCodeViewModel;

type WithoutHandlers<T extends object> = {
  [K in keyof T as T[K] extends (...args: any) => any ? never : K]: T[K];
};

export const usePromotionCodeViewModel: UsePromotionCodeViewModel = (props) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'promotionCode' });

  const [state, setState] = useState<WithoutHandlers<PromotionCodeViewModel>>({
    kind: PromotionCodeViewModelKind.Initial,
  });

  const setError = (error: PromotionUIError) => {
    setState((prevState) => {
      if (prevState.kind !== PromotionCodeViewModelKind.Entering) {
        return prevState;
      }

      return {
        ...prevState,
        error,
      };
    });
  };

  useEffect(() => {
    if (props.promotionCode) {
      setState({
        kind: PromotionCodeViewModelKind.Applied,
        appliedPromo: props.promotionCode,
      });
    } else {
      setState({
        kind: PromotionCodeViewModelKind.Initial,
      });
    }
  }, [props.promotionCode, t]);

  const handleEnter = () => {
    setState({
      kind: PromotionCodeViewModelKind.Entering,
      code: '',
    });
  };

  const handleChange = (code: string) => {
    setState((prevState) => {
      if (prevState.kind !== PromotionCodeViewModelKind.Entering) {
        return prevState;
      }

      return {
        ...prevState,
        error: undefined,
        code,
      };
    });
  };

  const handleApply = async (): Promise<void> => {
    if (state.kind !== PromotionCodeViewModelKind.Entering) {
      return;
    }

    if (!state.code) {
      return setError(PromotionUIError.Required);
    }

    try {
      await props.onApply(state.code);
    } catch (e) {
      if (e instanceof PromotionExpiredError) {
        return setError(PromotionUIError.Expired);
      }

      if (e instanceof PromotionNotFoundError) {
        return setError(PromotionUIError.NotFound);
      }

      return setError(PromotionUIError.Unknown);
    }
  };

  return {
    ...state,
    onEnter: handleEnter,
    onChange: handleChange,
    onApply: handleApply,
    onRemove: props.onRemove,
  };
};
