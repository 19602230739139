import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { flatten, pipe, uniq, values } from 'ramda';
import { firstValueFrom } from 'rxjs';

import { ROUTES } from '@/router/routes';

import { ActionDisableGuard } from '@/features/common/account';
import { useContactUseCase } from '@/features/common/contact';
import { useTagSelectOptions } from '@/features/common/tag';

import { CustomSelect, SearchInput } from '@/components';

import { useSearch } from '@/hooks';

import { ContactsContext } from '../../context';

import styles from './styles.module.scss';

export const TagApplyAction: FC = () => {
  const { selectedContacts, contactEntitiesMapById } = useContext(ContactsContext);
  const [contactDataToApply, setContactDataToApply] = useState<Record<string, string[]>>(
    {},
  );
  const [tagsToUncheck, setTagsToUncheck] = useState(getTagsToUncheck);

  const { value: search, handleSearchChange, handleSearchClear } = useSearch();
  const { t } = useTranslation('contacts');
  const { filteredOptions } = useTagSelectOptions(search);
  const contactUseCase = useContactUseCase();

  function getTagsToUncheck(): string[] {
    const arr: string[] = [];
    selectedContacts.forEach((contactId) => {
      const contact = contactEntitiesMapById[contactId];
      arr.push(...(contact?.tags || []));
    });

    return uniq(arr);
  }

  const handleCheckboxChange = (option, checked): void => {
    const dataToApply: Record<string, string[]> = { ...contactDataToApply };
    selectedContacts.forEach((contactId) => {
      const contact = contactEntitiesMapById[contactId];
      if (!dataToApply[contactId]) dataToApply[contactId] = [...contact.tags];

      if (tagsToUncheck.includes(option.value) || !checked) {
        dataToApply[contactId] = dataToApply[contactId].filter(
          (item) => item !== option.value,
        );
        return;
      }
      dataToApply[contactId] = uniq([...(dataToApply[contactId] || []), option.value]);
    });

    const toUpdate = uniq(
      Object.values(tagsToUncheck)
        .flat()
        .filter((item) => item !== option.value),
    );

    setTagsToUncheck(toUpdate);
    setContactDataToApply(dataToApply);
  };

  const onResetComponentState = (): void => {
    setTagsToUncheck(getTagsToUncheck());
    setContactDataToApply({});
  };

  const handleApply = async (): Promise<void> => {
    const d = Math.floor(Date.now() / 1000);
    const patch = selectedContacts.map((contactId) => {
      const contact = contactEntitiesMapById[contactId];

      return {
        ...contact,
        tags: contactDataToApply[contactId] || contact?.tags,
        updatedAt: d,
      };
    });

    await firstValueFrom(contactUseCase.upsertPatch(patch));
  };

  const selectValue = pipe(values, flatten)(contactDataToApply);
  const applyIsDisable = !Object.keys(contactDataToApply).length;

  const handlePopoverCloseAnimationEnd = (): void => {
    if (selectValue.length) onResetComponentState();
  };

  const navigate = useNavigate();
  const handleClick = (): void => {
    navigate(ROUTES.SETTINGS.TAG_MANAGEMENT);
  };

  useEffect(() => {
    onResetComponentState();
  }, [JSON.stringify(selectedContacts), JSON.stringify(contactEntitiesMapById)]);

  return (
    <Box className={styles.tagApply}>
      <CustomSelect
        multiple
        options={filteredOptions}
        value={selectValue}
        disabled={!selectedContacts.length}
        popoverProps={{
          transformOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
          TransitionProps: {
            onExited: handlePopoverCloseAnimationEnd,
          },
        }}
        renderAnchor={({ setAnchorElement }): ReactElement => {
          return (
            <ActionDisableGuard>
              <Button
                variant="outlined"
                color="info"
                disabled={!selectedContacts.length}
                onClick={(e): void => {
                  setAnchorElement(e.currentTarget);
                }}
              >
                {t('subhead.tagsApply.button')}
              </Button>
            </ActionDisableGuard>
          );
        }}
        renderHeader={(): ReactElement => {
          return (
            <SearchInput
              value={search}
              placeholder={t('subhead.tagsApply.searchField.placeholder')}
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              variant="filled"
            />
          );
        }}
        renderOption={({ option }): ReactElement => {
          const isIndeterminate = tagsToUncheck.includes(option.value);
          const isSelected = selectValue.includes(option.value);
          return (
            <CustomSelect.TagOption
              key={option.value}
              option={option}
              selected={isSelected}
              checkBoxProps={{
                indeterminate: isIndeterminate,
                onChange: (e, checked): void => {
                  handleCheckboxChange(option, checked);
                },
              }}
            />
          );
        }}
        renderActions={({ onClose }): ReactElement => {
          return (
            <Box className={styles.tagApplyActions}>
              <Button onClick={handleClick}>{t('subhead.tagsApply.tagsManage')}</Button>
              <Button
                onClick={(): void => {
                  handleApply();
                  onClose();
                }}
                disabled={applyIsDisable}
              >
                {t('subhead.tagsApply.apply')}
              </Button>
            </Box>
          );
        }}
      />
    </Box>
  );
};
