import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { ErrorPage404, ErrorPage500 } from '@/router/error';
import { ROUTES } from '@/router/routes';

import { IntegrationErrorDialog } from '@/features/integrations';

import { SplashScreen } from '@/components/SplashScreen';

import { SubHeadIntegration, TabName } from './components/Subhead';
import { FieldMappingsTab } from './components/Tabs/FieldMappingTab';
import { SettingsTab } from './components/Tabs/SettingsTab';
import useIntegrationViewModel from './IntegrationViewModel';

export const Integration: FC = () => {
  const {
    integration,
    handleTabChange,
    tab,
    integrationMapping,
    isLoading,
    hasError,
    errorType,
    providerName,
    navigateToIntegrations,
    isPlanLowerThenGrowth,
  } = useIntegrationViewModel();

  if (isPlanLowerThenGrowth) {
    return <Navigate to={ROUTES.INTEGRATIONS} />;
  }

  if (hasError) {
    if (errorType === 'NotFound') {
      return <ErrorPage404 />;
    }

    if (errorType === 'SalesforceApiDisabled' && integration) {
      return (
        <IntegrationErrorDialog
          isOpen
          onClose={navigateToIntegrations}
          variant="SalesforceApiDisabled"
          integrationId={integration.provider}
        />
      );
    }

    return <ErrorPage500 />;
  }

  if (isLoading || !integration || !integrationMapping) {
    return <SplashScreen />;
  }

  return (
    <>
      <SubHeadIntegration
        providerName={providerName}
        handleTabChange={handleTabChange}
        currentTab={tab}
      />
      {tab === TabName.Settings && (
        <SettingsTab integration={integration} providerName={providerName} />
      )}
      {tab === TabName.FieldMapping && (
        <FieldMappingsTab
          integration={integration}
          integrationMapping={integrationMapping}
          providerName={providerName}
        />
      )}
    </>
  );
};
