import { ChangeEvent, MutableRefObject, useContext, useRef } from 'react';

import { useCurrentPlanType } from '@/features/common/billing';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { EnrichmentUploadingContext } from '../EnrichmentUploadingContext';

import { useEnrichmentAccess } from './useEnrichmentAccess';

export const useUploadEnrichmentFile = (): {
  inputRef: MutableRefObject<HTMLInputElement | null>;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onClick(): void;
} => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { onUploadingModalOpen, upload, toView } = useContext(EnrichmentUploadingContext);
  const { ensureEnrichmentAccess } = useEnrichmentAccess();
  const { trackEvent } = useAnalytics();
  const { data: planType } = useCurrentPlanType();

  const onChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!event.target.files?.length) return;

    try {
      const newFile = event.target.files[0];
      upload(newFile);
      toView('StartViewDefault');
      onUploadingModalOpen();
    } catch (error) {
    } finally {
      event.target.value = '';
    }
  };

  const onClick = (): void => {
    trackEvent(ANALYTICS_EVENTS.ENRICHMENT_UPLOAD_BTN_CLICK, {
      plan: planType,
    });
    if (ensureEnrichmentAccess()) inputRef.current?.click();
  };

  return {
    inputRef,
    onChange,
    onClick,
  };
};
