import { FC, Fragment } from 'react';

import { PlanType } from '@/features/common/billing';

import { PlanCardType } from './components/PaidPlanCard/types';
import { FreePlanCard, PaidPlanCard } from './components';
import { usePlanCardViewModel } from './PlanCardViewModel';

export const PlanCard: FC = () => {
  const { currentPlan, futurePlan, showNewPlanCard } = usePlanCardViewModel();

  if (!currentPlan) {
    return null;
  }

  if (currentPlan.type === PlanType.Free) {
    return <FreePlanCard />;
  }

  return (
    <Fragment>
      <PaidPlanCard
        type={showNewPlanCard ? PlanCardType.Current : undefined}
        paidPlanType={currentPlan.type}
        billingCycle={currentPlan.billingCycle}
      />
      {showNewPlanCard && futurePlan && futurePlan.type !== PlanType.Free ? (
        <PaidPlanCard
          type={PlanCardType.New}
          paidPlanType={futurePlan.type}
          billingCycle={futurePlan.billingCycle}
        />
      ) : null}
    </Fragment>
  );
};
