import { useEffect, useState } from 'react';
import { isMobile as isMobileDetector } from 'react-device-detect';

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(isMobileDetector);

  useEffect(() => {
    const handleWindowSizeChange = (): void => {
      setIsMobile(isMobileDetector || window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleWindowSizeChange);

    return (): void => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isMobile;
};
