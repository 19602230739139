import { CardBrand, IPaymentMethodEntity } from '@/features/common/billing';

type UsePaymentMethod = (params: {
  paymentMethod: Nullable<IPaymentMethodEntity>;
}) => Nullable<{
  last4: string;
  brand: CardBrand;
  expDate: string;
  isExpired: boolean;
}>;

export const usePaymentMethod: UsePaymentMethod = ({ paymentMethod }) => {
  if (!paymentMethod?.card) {
    return null;
  }

  const { card } = paymentMethod;

  const now = new Date();

  return {
    last4: card.last4,
    brand: card.brand,
    expDate: `${card.expMonth}/${card.expYear}`,
    isExpired: card.expYear < now.getFullYear() && card.expMonth < now.getMonth() + 1,
  };
};
