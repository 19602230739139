import { createContext, FC, PropsWithChildren } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { ACCOUNT_TYPES } from '@/ioc/types';

import { useObservableResult } from '@/utils/rx';

import { ACCESS_PERMISSIONS, IAccountPermissionsUseCase } from '../../domain';

export type AccountPermissionsContextType = {
  uuid: string;
  isOwnerOrAdmin: boolean;
  permissions: Record<ACCESS_PERMISSIONS, boolean>;
  freezedToUpdate: boolean;
};

const defaultContextData = {
  uuid: '',
  isOwnerOrAdmin: false,
  permissions: {
    [ACCESS_PERMISSIONS.CAN_ACCESS_ALL_CONTACTS]: false,
    [ACCESS_PERMISSIONS.CAN_ACCESS_BILLING]: false,
    [ACCESS_PERMISSIONS.CAN_CHANGE_CONTACTS_OWNER]: false,
    [ACCESS_PERMISSIONS.CAN_EDIT_DELETE_CONTACT_LIST]: false,
    [ACCESS_PERMISSIONS.CAN_MANAGE_ALL_TAGS]: false,
    [ACCESS_PERMISSIONS.CAN_MANAGE_TEAM_MEMBERS]: false,
    [ACCESS_PERMISSIONS.CAN_MANAGE_INTEGRATIONS]: false,
    [ACCESS_PERMISSIONS.CAN_USE_EXTENSION]: false,
    [ACCESS_PERMISSIONS.CAN_VIEW_PROSPECT_TASK_PROGRESS]: false,
    [ACCESS_PERMISSIONS.CAN_ACCESS_REFFERAL]: false,
  },
  freezedToUpdate: false,
};

export const AccountPermissionsContext =
  createContext<AccountPermissionsContextType>(defaultContextData);

export const AccountPermissionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const accountPermissionsUseCase = useInjection<IAccountPermissionsUseCase>(
    ACCOUNT_TYPES.AccountPermissionsUseCase,
  );

  const { data } = useObservableResult(
    () => accountPermissionsUseCase.getAccountPermissions(),
    { deps: [] },
  );

  if (!data) {
    return null;
  }

  return (
    <AccountPermissionsContext.Provider value={data}>
      {children}
    </AccountPermissionsContext.Provider>
  );
};
