import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, IconButton, Menu } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { clsx } from 'clsx';

import { ActionDisableGuard } from '@/features/common/account';
import { ContactExportProgress } from '@/features/common/contact';

import { MenuItem } from '@/components';
import { MoreHorizIcon } from '@/components/Icons';

import ExcelIcon from '@/assets/icons/excel.svg?react';

import { useActionsCellViewModel } from './useActionsCellViewModel';

import styles from './styles.module.scss';

export const ActionsCell: FC<GridRenderCellParams> = (props) => {
  const { t } = useTranslation('lists');
  const {
    menuOpen,
    anchorEl,
    onCloseMenu,
    onOpenMenu,
    onStartRenameList,
    onOpenDeleteModal,
    hasPermission,
    exportIntegrationOptions,
    exportProgress,
    exportDisabled,
    onManageIntegrations,
    onRetry,
    onExportToCsv,
    onExportToIntegration,
  } = useActionsCellViewModel(props.row);

  const showAdditionalActions = props.row.specialType !== 'all_contacts' && hasPermission;

  return (
    <Box className={styles.cell}>
      <IconButton size="small" focusRipple onClick={onOpenMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        open={menuOpen}
        onClose={onCloseMenu}
        anchorEl={anchorEl}
        classes={{ list: styles.menuList }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          disabled={exportDisabled}
          className={styles.menuListItem}
          onClick={onExportToCsv}
        >
          <Box className={styles.menuListItemIcon}>
            <ExcelIcon />
          </Box>
          <Box component="span">{t('table.rows.actions.csvExport')}</Box>
        </MenuItem>
        {!!exportIntegrationOptions.length &&
          exportIntegrationOptions.map((option) => {
            return (
              <ActionDisableGuard>
                <MenuItem
                  className={styles.menuListItem}
                  key={option.value}
                  disabled={exportDisabled}
                  onClick={(): void => {
                    onExportToIntegration(option);
                  }}
                >
                  {!!option.icon && (
                    <Box className={styles.menuListItemIcon}>
                      <img src={option.icon} alt={option.label} />
                    </Box>
                  )}
                  <Box component="span">{option.label}</Box>
                </MenuItem>
              </ActionDisableGuard>
            );
          })}
        {showAdditionalActions && <Divider className={styles.divider} />}
        {showAdditionalActions && (
          <ActionDisableGuard>
            <MenuItem
              onClick={onStartRenameList}
              className={styles.menuListItem}
              key="rename"
            >
              {t('table.rows.actions.renameList')}
            </MenuItem>
          </ActionDisableGuard>
        )}

        {showAdditionalActions && (
          <ActionDisableGuard>
            <MenuItem
              className={clsx(styles.menuListItem, styles.delete)}
              onClick={onOpenDeleteModal}
              key="delete"
            >
              {t('table.rows.actions.deleteList')}
            </MenuItem>
          </ActionDisableGuard>
        )}
        <Divider className={styles.divider} />
        <Box component="li">
          <Button onClick={onManageIntegrations}>
            {t('table.rows.actions.manageIntegrations')}
          </Button>
        </Box>
      </Menu>
      <ContactExportProgress
        open={exportProgress.open}
        status={exportProgress.status}
        anchorEl={anchorEl}
        onRetry={onRetry}
        onTransitionExited={(): void => {
          exportProgress.setConfig((prev) => ({
            ...prev,
            status: 'pending',
            provider: null,
          }));
        }}
        onClose={(): void => {
          if (exportProgress.status === 'pending') return;

          exportProgress.setConfig((prev) => ({ ...prev, open: false }));
          onCloseMenu();
        }}
        {...exportProgress.getMetadata({ selectedCount: props.row.contactsAmount })}
      />
    </Box>
  );
};
