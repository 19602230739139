import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, OutlinedInput, Typography } from '@mui/material';

import { IBillingDetailsEntity, PaymentFormField } from '@/features/common/billing';

import { CountrySelect, Modal } from '@/components';
import { CancelOutlinedIcon } from '@/components/Icons';

import {
  EditBillingDetailsFormValues,
  useEditBillingDetailsViewModel,
} from './useEditBillingDetailsViewModel';

import styles from './styles.module.scss';

type EditBillingDetailsDialogProps = {
  isOpened: boolean;
  billingDetails: IBillingDetailsEntity;
  onSubmit: (data: IBillingDetailsEntity) => Promise<void>;
  onClose: () => void;
};

export const EditBillingDetailsDialog: FC<EditBillingDetailsDialogProps> = (props) => {
  const { t: tField } = useTranslation('billing', { keyPrefix: 'fields' });
  const { t: tDialog } = useTranslation('settings', {
    keyPrefix: 'subscriptions.editBillingDetails',
  });

  const { form, validations, onSubmit, isProcessing } =
    useEditBillingDetailsViewModel(props);

  const translateField = (key: string): { label: string; placeholder: string } => {
    return {
      label: tField(`${key}.label`),
      placeholder: tField(`${key}.placeholder`),
    };
  };

  const bindFormFieldProps = (
    key: keyof EditBillingDetailsFormValues,
  ): {
    error: boolean;
    errorText?: string;
    labelText: string;
    name: string;
    disabled: boolean;
  } => {
    return {
      name: key,
      labelText: translateField(key).label,
      error: Boolean(form.formState.errors[key]),
      errorText: form.formState.errors[key]?.message,
      disabled: isProcessing,
    };
  };

  return (
    <Modal open={props.isOpened} onClose={props.onClose} containerClass={styles.modal}>
      <Fragment>
        <CancelOutlinedIcon className={styles.closeIcon} onClick={props.onClose} />
        <Typography variant="h1">{tDialog('title')}</Typography>
        <form
          noValidate
          className={styles.form}
          id="edit-billing-details"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <PaymentFormField {...bindFormFieldProps('name')} required>
            <OutlinedInput
              placeholder={translateField('fullName').placeholder}
              {...form.register('name', validations.name)}
            />
          </PaymentFormField>
          <PaymentFormField {...bindFormFieldProps('country')} required>
            <CountrySelect
              value={form.watch('country')}
              onChange={(country): void => {
                form.setValue('country', country);
              }}
            />
          </PaymentFormField>
          <PaymentFormField {...bindFormFieldProps('address')} required>
            <OutlinedInput
              placeholder={translateField('address').placeholder}
              {...form.register('address')}
            />
          </PaymentFormField>
          <PaymentFormField {...bindFormFieldProps('state')}>
            <OutlinedInput
              placeholder={translateField('state').placeholder}
              {...form.register('state', validations.state)}
            />
          </PaymentFormField>
          <PaymentFormField {...bindFormFieldProps('city')} required>
            <OutlinedInput
              placeholder={translateField('city').placeholder}
              {...form.register('city', validations.city)}
            />
          </PaymentFormField>
          <PaymentFormField {...bindFormFieldProps('postalCode')} required>
            <OutlinedInput
              placeholder={translateField('postalCode').placeholder}
              {...form.register('postalCode', validations.postalCode)}
            />
          </PaymentFormField>
          <PaymentFormField {...bindFormFieldProps('phone')} required>
            <OutlinedInput
              placeholder={translateField('phone').placeholder}
              {...form.register('phone', validations.phone)}
            />
          </PaymentFormField>
          <PaymentFormField {...bindFormFieldProps('company')}>
            <OutlinedInput
              placeholder={translateField('company').placeholder}
              {...form.register('company', validations.company)}
            />
          </PaymentFormField>
          <PaymentFormField {...bindFormFieldProps('email')} required>
            <OutlinedInput
              placeholder={translateField('email').placeholder}
              {...form.register('email', validations.email)}
            />
          </PaymentFormField>
          <PaymentFormField {...bindFormFieldProps('vatId')}>
            <OutlinedInput
              placeholder={translateField('vatId').placeholder}
              {...form.register('vatId', validations.vatId)}
            />
          </PaymentFormField>
        </form>
        <Box className={styles.footer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="edit-billing-details"
            className={styles.submit}
            disabled={isProcessing}
          >
            {tDialog('submit')}
          </Button>
        </Box>
      </Fragment>
    </Modal>
  );
};
