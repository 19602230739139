import { useEffect, useState } from 'react';

import {
  ACCESS_PERMISSIONS,
  useAccount,
  usePermissions,
} from '@/features/common/account';

type UseTeamMemberSelectResult = {
  value: string;
  setValue: (value: string) => void;
  resetValue: () => void;
};

export const useTeamMemberSelect = (defaultValue = ''): UseTeamMemberSelectResult => {
  const { account } = useAccount();
  const { hasPermission } = usePermissions();
  const canAccessAllContacts = hasPermission(ACCESS_PERMISSIONS.CAN_ACCESS_ALL_CONTACTS);

  const getDefaultValue = (): string => {
    if (account && !canAccessAllContacts) return account.uuid;
    return defaultValue ?? '';
  };

  const [value, setValue] = useState<string>(getDefaultValue);

  const resetValue = (): void => {
    setValue(getDefaultValue());
  };

  useEffect(() => {
    if (!account) return;

    if (!canAccessAllContacts) {
      setValue(account.uuid);
    } else {
      setValue('');
    }
  }, [canAccessAllContacts, account?.uuid]);

  return {
    value,
    setValue,
    resetValue,
  };
};
