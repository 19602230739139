import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IWorkspaceApiService, IWorkspaceDC } from '@/features/common/workspace';
import { ISwitchWorkspaceReq } from '@/features/common/workspace/data/dataContracts/SwitchWorkspaceReqDC';
import { IHttpClient } from '@/features/system/network';

const workspaceEndpoints = {
  workspaces: 'workspaces',
  switch: 'workspaces/switch',
};

@injectable()
export default class WorkspaceApiService implements IWorkspaceApiService {
  @inject(NETWORK_TYPES.HttpClient) private httpClient: IHttpClient;

  fetchWorkspace(): Observable<IWorkspaceDC[]> {
    return this.httpClient
      .get(workspaceEndpoints.workspaces)
      .pipe(map((response: AxiosResponse<IWorkspaceDC[]>) => response.data));
  }

  updateWorkspace(account: Partial<IWorkspaceDC>): Observable<IWorkspaceDC> {
    return this.httpClient
      .patch(workspaceEndpoints.workspaces, account)
      .pipe(map((response: AxiosResponse<IWorkspaceDC>) => response.data));
  }

  switchWorkspace(req: ISwitchWorkspaceReq): Observable<boolean> {
    return this.httpClient
      .post(workspaceEndpoints.switch, req)
      .pipe(map((response: AxiosResponse<boolean>) => response.data));
  }
}
