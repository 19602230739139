import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { ContentBox } from '@/features/referral/ui/components/ContentBox';

import { TableEmptyState } from './components';
import { useInviteeTableSectionViewModel } from './useInviteeTableSectionViewModel';

import styles from './styles.module.scss';

export const InviteeTableSection: React.FC = () => {
  const { t } = useTranslation('referral', { keyPrefix: 'inviteesSection' });
  const { columns, rows, credits } = useInviteeTableSectionViewModel();

  return (
    <ContentBox className={styles.wrapper}>
      <Typography variant={'h1'}>{t('title')}</Typography>
      <Typography variant={'h3'}>{t('subtitle', { credits })}</Typography>
      <DataGridPro
        autoHeight
        rows={rows}
        columns={columns}
        variant="basic"
        disableColumnReorder
        hideFooter
        slots={{
          noRowsOverlay: TableEmptyState,
        }}
        classes={{
          root: styles.tableRoot,
          virtualScrollerContent: !rows.length ? styles.virtualScrollerContent : '',
        }}
        getRowId={(row): string => row.uuid}
      />
    </ContentBox>
  );
};
