import { inject, injectable } from 'inversify';
import { first, map, Observable, of, switchMap, throwError } from 'rxjs';

import { CONTACT_LIST_TYPES } from '@/ioc/types';

import {
  IContactListDao,
  IContactListDC,
  IContactListEntity,
  IContactListRepository,
} from '@/features/common/contactList';

import {
  ICreateContactListUseCaseDto,
  IGetContactListByQueryUseCaseDto,
  IUpdateContactListUseCaseDto,
} from '../domain/abstractions/dto';
import { ContactNameAlreadyExistError } from '../domain/errors';

import { mapContactListDcToEntity } from './mappers';

@injectable()
export default class ContactListRepository implements IContactListRepository {
  @inject(CONTACT_LIST_TYPES.ContactListDao)
  private contactListDao: IContactListDao;

  getDefaultContactList(): Observable<IContactListEntity> {
    return this.contactListDao
      .getDefaultContactList()
      .pipe(map(mapContactListDcToEntity));
  }

  getContactListByQuery(
    dto: IGetContactListByQueryUseCaseDto,
  ): Observable<IContactListEntity[]> {
    return this.contactListDao
      .getContactListByQuery({
        nameReg: dto.nameReg,
        created_by: dto.createdBy,
        sortSchema: dto.sortSchema,
      })
      .pipe(map((list) => list.map(mapContactListDcToEntity)));
  }

  createContactList(dto: ICreateContactListUseCaseDto): Observable<IContactListEntity> {
    return this.checkContactListExistence(dto).pipe(
      switchMap(() =>
        this.contactListDao.upsert({
          name: dto.name,
          created_by: dto.createdBy,
          contacts_amount: 0,
        }),
      ),
      map(mapContactListDcToEntity),
    );
  }

  updateContactList(dto: IUpdateContactListUseCaseDto): Observable<IContactListEntity> {
    return this.checkContactListExistence(dto).pipe(
      switchMap(() => this.contactListDao.updateOne(dto.uuid, { name: dto.name })),
      map(mapContactListDcToEntity),
    );
  }

  deleteContactList(uuid: string): Observable<boolean> {
    return this.contactListDao.removeOne(uuid).pipe(map((response) => !!response));
  }

  getContactListById(id: string): Observable<IContactListEntity | null> {
    return this.contactListDao
      .findById(id)
      .pipe(map((response) => response && mapContactListDcToEntity(response)));
  }

  private checkContactListExistence(
    dto: ICreateContactListUseCaseDto | IUpdateContactListUseCaseDto,
  ): Observable<IContactListDC | null> {
    return this.contactListDao.findOne({ selector: { name: dto.name } }).pipe(
      first(),
      switchMap((contactList) => {
        const isDuplicate =
          // @ts-ignore
          contactList && (dto?.uuid ? dto.uuid !== contactList.uuid : true);

        if (isDuplicate) {
          return throwError(() => new ContactNameAlreadyExistError());
        }

        return of(contactList);
      }),
    );
  }
}
