import { inject, injectable } from 'inversify';
import { Observable, share, switchMap, tap } from 'rxjs';

import { AUTH_TYPES, SYNC_TYPES } from '@/ioc/types';

import { IBaseSyncRepository } from '@/features/system/sync';

import { IAuthRepository } from '../data';

import { IAuthWatcherUseCase } from './abstractions';
import { AuthStatus } from './types';

@injectable()
export class AuthWatcherUseCase implements IAuthWatcherUseCase {
  @inject(SYNC_TYPES.BaseSyncRepository)
  private baseSyncRepository: IBaseSyncRepository;

  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  start = (): Observable<AuthStatus> => {
    return this.baseSyncRepository.getDeleteAccountEvents().pipe(
      tap(() => {
        this.authRepository.signOut();
      }),
      switchMap(() => this.authRepository.getAuthStatus()),
      share(),
    );
  };
}
