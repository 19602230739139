import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { firstValueFrom } from 'rxjs';

import { ROUTES } from '@/router/routes';

import {
  BillingCycle,
  getSubscriptionPlanFrom,
  PlanType,
  useBillingUseCase,
} from '@/features/common/billing';
import { useWorkspaceSubscription } from '@/features/common/workspace';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { useChangeBillingCycleState } from './hooks';

export type BillingCycleDialogViewModel = {
  isOpened: boolean;
  isProcessing: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  onOpen: (params: { planType: PlanType; billingCycle: BillingCycle }) => void;
  text: string;
  changeDate: string;
};

export function useBillingCycleDialogViewModel(): BillingCycleDialogViewModel {
  const {
    t,
    i18n: { language },
  } = useTranslation('plans');
  const navigate = useNavigate();

  const { trackEvent } = useAnalytics();

  const snackbar = useSnackbar();

  const [state, handlers] = useChangeBillingCycleState();

  const billingUseCase = useBillingUseCase();

  const logger = useAppLogger();

  const { data: subscription } = useWorkspaceSubscription();

  const changeDate = subscription?.expirationDate
    ? dayjs(dayjs.unix(subscription?.expirationDate), {
        locale: language,
      }).format('MMMM D, YYYY')
    : '';

  const planName = state.isOpened
    ? t(`plans.${state.subscription.planType}.longName`)
    : '';

  const billingCycle = state.isOpened
    ? t(`billingCycleDialog.${state.subscription.billingCycle}`)
    : '';

  const text = `${planName} (${billingCycle})`;

  return {
    isOpened: state.isOpened,
    isProcessing: state.isOpened && state.isPorcessing,
    onClose: (): void => {
      handlers.close();
    },
    onConfirm: async (): Promise<void> => {
      try {
        if (!state.isOpened) {
          return;
        }

        handlers.startProcessing();

        await firstValueFrom(
          billingUseCase.updateSubscription({
            plan: getSubscriptionPlanFrom(state.subscription),
          }),
        );

        snackbar.enqueueSnackbar(t('billingCycleDialog.success'), {
          variant: 'success',
          description: t('billingCycleDialog.successDescription'),
          autoHideDuration: 5000,
        });

        handlers.processedWithSuccess();

        trackEvent(ANALYTICS_EVENTS.CHANGE_SUBSCRIPTION, {
          type: `Change to ${state.subscription.billingCycle.toLowerCase()}`,
        });

        navigate(ROUTES.SETTINGS.SUBSCRIPTION);
      } catch (e) {
        logger.error(e);

        snackbar.enqueueSnackbar(t('billingCycleDialog.error'), {
          variant: 'error',
          description: t('billingCycleDialog.errorDescription'),
          autoHideDuration: 5000,
        });
        handlers.processedWithError();
      }
    },
    onOpen: (subscription): void => {
      handlers.open(subscription);
    },
    text,
    changeDate,
  };
}
