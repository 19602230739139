import { FC, ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { CustomSelect } from '@/components';

import { ContactsContext } from '../../context';

import styles from './styles.module.scss';

type Option = { label: string; value: string };

export const FilterByExport: FC = () => {
  const { t } = useTranslation('contacts');
  const { filterByExport, setFilterByExport } = useContext(ContactsContext);
  const handleChange = (value: string): void => {
    setFilterByExport(value);
  };
  const options: Option[] = [
    {
      value: '',
      label: t('subhead.exportFilter.options.allStatuses'),
    },
    { value: '1', label: t('subhead.exportFilter.options.exported') },
    { value: '0', label: t('subhead.exportFilter.options.notExported') },
  ];

  return (
    <Box className={styles.Wrapper}>
      <CustomSelect
        value={filterByExport}
        name="exportFilter"
        options={options}
        onChange={handleChange}
        renderOption={({ option, selected }): ReactElement => {
          return (
            <CustomSelect.DefaultOption
              key={option.value}
              option={option}
              selected={selected}
            />
          );
        }}
      />
    </Box>
  );
};
