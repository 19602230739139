import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BillingCycle, PlanType } from '@/features/common/billing';
import { billingConfig } from '@/features/common/billing/config';
import {
  BillingConfigPlanDiscount,
  NotLegacyPlanType,
} from '@/features/common/billing/domain';

import { PlanAction, PlanMetadata } from '../types';

function getPlanName(planType: PlanType): string {
  return `plans.${planType}.name`;
}

function getPlanDescription(planType: PlanType): string {
  return `plans.${planType}.description`;
}

function getPlanFeatures(planType: PlanType, maxItems: number): PlanMetadata['features'] {
  return {
    title: `plans.${planType}.features.title`,
    items: Array.from(
      { length: maxItems },
      (_, index) => `plans.${planType}.features.items.${index + 1}`,
    ),
  };
}

function getPlanPrices(
  planType: PlanType,
  discounts?: BillingConfigPlanDiscount,
): PlanMetadata['prices'] {
  const basePrice = billingConfig.basePlanPrices[planType];

  return {
    [BillingCycle.Monthly]: calcPrice(basePrice, discounts?.[BillingCycle.Monthly]),
    [BillingCycle.Yearly]: calcPrice(basePrice, discounts?.[BillingCycle.Yearly]),
  };
}

function getCrossedOutPrices(
  planType: PlanType,
  discounts?: BillingConfigPlanDiscount,
): PlanMetadata['crossedOutPrice'] {
  const basePrice = billingConfig.basePlanPrices[planType];

  return {
    [BillingCycle.Monthly]: calcPrice(basePrice, discounts?.[BillingCycle.Monthly]),
    [BillingCycle.Yearly]: calcPrice(basePrice, discounts?.[BillingCycle.Monthly]),
  };
}
/*
 *  Price is calculated discount-by-discount.
 *  Every next discount applies to price with previous discount.
 */
const calcPrice = (
  basePrice: number,
  discounts: BillingConfigPlanDiscount[BillingCycle],
): number => {
  return Array.isArray(discounts)
    ? Math.round(
        discounts.reduce((acc, current) => {
          return (acc = acc * (1 - current.rate));
        }, basePrice),
      )
    : basePrice;
};

const PLAN_METADATA_BY_TYPE: Record<NotLegacyPlanType, PlanMetadata> = {
  [PlanType.Free]: {
    name: getPlanName(PlanType.Free),
    description: getPlanDescription(PlanType.Free),
    prices: getPlanPrices(PlanType.Free),
    credits: {
      [BillingCycle.Monthly]: 10,
      [BillingCycle.Yearly]: 120,
    },
    crossedOutPrice: null,
    isCurrent: false,
    isMostPopular: false,
    customBadgeText: null,
    features: getPlanFeatures(PlanType.Free, 4),
    actions: [],
    creditCardRequired: false,
  },
  [PlanType.Pro]: {
    name: getPlanName(PlanType.Pro),
    description: getPlanDescription(PlanType.Pro),
    prices: getPlanPrices(PlanType.Pro, billingConfig.discounts[PlanType.Pro]),
    crossedOutPrice: getCrossedOutPrices(
      PlanType.Pro,
      billingConfig.discounts[PlanType.Pro],
    ),
    credits: {
      [BillingCycle.Monthly]: 90,
      [BillingCycle.Yearly]: 1080,
    },
    isCurrent: false,
    isMostPopular: false,
    customBadgeText: null,
    features: getPlanFeatures(PlanType.Pro, 4),
    actions: [],
    creditCardRequired: true,
  },
  [PlanType.Unlimited]: {
    name: getPlanName(PlanType.Unlimited),
    description: getPlanDescription(PlanType.Unlimited),
    prices: getPlanPrices(
      PlanType.Unlimited,
      billingConfig.discounts[PlanType.Unlimited],
    ),
    crossedOutPrice: getCrossedOutPrices(
      PlanType.Unlimited,
      billingConfig.discounts[PlanType.Unlimited],
    ),
    credits: {
      [BillingCycle.Monthly]: 'Unlimited',
      [BillingCycle.Yearly]: 'Unlimited',
    },
    isCurrent: false,
    isMostPopular: true,
    customBadgeText: null,
    features: getPlanFeatures(PlanType.Unlimited, 3),
    actions: [],
    creditCardRequired: true,
  },
  [PlanType.Expand]: {
    name: getPlanName(PlanType.Expand),
    description: getPlanDescription(PlanType.Expand),
    prices: getPlanPrices(PlanType.Expand, billingConfig.discounts[PlanType.Expand]),
    crossedOutPrice: getCrossedOutPrices(
      PlanType.Expand,
      billingConfig.discounts[PlanType.Expand],
    ),
    credits: {
      [BillingCycle.Monthly]: 'Unlimited',
      [BillingCycle.Yearly]: 'Unlimited',
    },
    isCurrent: false,
    isMostPopular: false,
    customBadgeText: null,
    features: getPlanFeatures(PlanType.Expand, 6),
    actions: [{ type: PlanAction.TalkToSales }],
    creditCardRequired: true,
  },
};

export function usePlansMetadata(params: {
  currentPlanType?: PlanType | null;
}): Record<PlanType, PlanMetadata> {
  const {
    t,
    i18n: { language },
  } = useTranslation('plans');

  return useMemo(() => {
    return Object.entries(PLAN_METADATA_BY_TYPE).reduce(
      (acc, [planType, _planMetadata]) => {
        const planMetadata: PlanMetadata = structuredClone(_planMetadata);
        planMetadata.name = t(planMetadata.name);
        planMetadata.isCurrent = planType === params.currentPlanType;
        planMetadata.description = t(planMetadata.description, { escapeValue: true });
        planMetadata.features.title = t(planMetadata.features.title);
        planMetadata.features.items = planMetadata.features.items.map((item) => (
          <Trans i18nKey={item as string} components={{ strong: <strong /> }} t={t} />
        ));

        acc[planType] = planMetadata;

        return acc;
      },
      {} as Record<PlanType, PlanMetadata>,
    );
  }, [language, params.currentPlanType]);
}
