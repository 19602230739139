import { useObservableResult } from '@/utils/rx';

import { OnboardingStep } from '../../domain/types';

import { useOnboardingUseCase } from './useOnboardingUseCase';

export const useOnboardingStepState = (
  step: OnboardingStep,
): {
  isCompleted: boolean;
} => {
  const onboardingUseCase = useOnboardingUseCase();
  const { data: isCompleted } = useObservableResult(
    () => onboardingUseCase.isStepCompleted(step),
    {
      deps: [step],
      defaultData: false,
    },
  );

  return {
    isCompleted,
  };
};
