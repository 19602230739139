import { Dispatch, FC, MouseEvent, ReactElement, SetStateAction } from 'react';
import { Box, BoxProps } from '@mui/material';
import { clsx } from 'clsx';

import { ArrowDownIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export type CustomSelectAnchorProp = BoxProps & {
  anchorValue: string;
  placeholder?: string;
  isOpen?: boolean;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  size?: 'small' | 'medium' | 'large';
  setAnchorElement: Dispatch<SetStateAction<HTMLElement | null>>;
  renderAnchorValue?: (value: string) => ReactElement | string;
};

export const CustomSelectAnchor: FC<CustomSelectAnchorProp> = ({
  anchorValue,
  placeholder = '',
  isOpen,
  setAnchorElement,
  size = 'small',
  error,
  disabled,
  className,
  renderAnchorValue,
  ...props
}) => {
  const handleClick = (e: MouseEvent<HTMLElement>): void => {
    if (disabled) return;
    if (isOpen) {
      setAnchorElement(null);
    } else {
      setAnchorElement(e.currentTarget);
    }
  };
  return (
    <Box
      {...props}
      className={clsx(
        styles.anchor,
        {
          customSelectSizeSmall: size === 'small',
          customSelectSizeMedium: size === 'medium',
          customSelectSizeLarge: size === 'large',
          [styles.anchorOpened]: isOpen,
          [styles.anchorDisabled]: disabled,
          [styles.anchorError]: error,
        },
        className,
      )}
      onClick={handleClick}
    >
      {renderAnchorValue ? (
        renderAnchorValue(anchorValue)
      ) : (
        <Box component="span" className={styles.anchorText}>
          {anchorValue || placeholder}
        </Box>
      )}
      <Box className={styles.anchorIcon}>
        <ArrowDownIcon />
      </Box>
    </Box>
  );
};
