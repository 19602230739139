import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, OutlinedInput, Typography } from '@mui/material';
import { clsx } from 'clsx';
import { useSnackbar } from 'notistack';

import { ActionDisableGuard } from '@/features/common/account';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { Modal } from '@/components';

import { IIntegrationEntityUI } from '../../entities';
import { useIntegrationsUseCase } from '../../hooks';

import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  integrations: IIntegrationEntityUI[];
  integration: Nullable<IIntegrationEntityUI>;
  onIntegrationChange: (integration: IIntegrationEntityUI) => void;
  onClose(): void;
};

export const IntegrationRequestDialog: FC<Props> = ({
  isOpen,
  integrations,
  integration,
  onIntegrationChange,
  onClose,
}) => {
  const { t } = useTranslation(['integrations', 'common']);
  const analytics = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const integrationsUseCase = useIntegrationsUseCase();

  const [message, setMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!integration || !isOpen) return;

    if (integration.id === 'custom') {
      analytics.trackEvent(ANALYTICS_EVENTS.INTEGRATIONS_CONNECT_CLICK, {
        type: 'Custom',
      });
    } else {
      analytics.trackEvent(ANALYTICS_EVENTS.INTEGRATIONS_CONTACT_SALES_CLICK, {
        type: t(integration.name),
      });
    }
  }, [integration?.id, isOpen]);

  const handleRequest = async (): Promise<void> => {
    try {
      setIsProcessing(true);

      if (!integration) throw new Error('Integration is not selected');

      const title = integration.name;

      await integrationsUseCase.sendRequest({
        title: t(title),
        message,
      });
      onClose();

      analytics.trackEvent(ANALYTICS_EVENTS.INTEGRATIONS_REQUEST_SENT, {
        name: t(title),
      });
      enqueueSnackbar({
        variant: 'success',
        message: t('requestSentToastTitle'),
        description: t('requestSentToastSubtitle'),
        autoHideDuration: 5000,
      });
    } catch {
      enqueueSnackbar({
        variant: 'error',
        message: t('errors.errorOccurred', {
          ns: 'common',
        }),
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal open={isOpen} containerClass={styles.dialogPaper} onClose={onClose}>
      <>
        <Typography className={styles.dialogTitle}>{t('requestDialog.title')}</Typography>
        <Typography className={styles.dialogSubtitle}>
          {t('requestDialog.subtitle')}
        </Typography>
        <Grid className={styles.dialogIntegrationsList}>
          {integrations.map((_integration) => {
            return (
              <button
                key={_integration.id}
                onClick={(): void => onIntegrationChange(_integration)}
                className={clsx(styles.dialogIntegrationButton, {
                  [styles.selected]: _integration.id === integration?.id,
                })}
              >
                {t(_integration.name)}
              </button>
            );
          })}
        </Grid>
        <Typography className={styles.messageTitle}>
          {t('requestDialog.messageTitle')}{' '}
          <span>{t('requestDialog.messageTitleMandatority')}</span>
        </Typography>
        <OutlinedInput
          multiline
          fullWidth
          minRows={1}
          maxRows={4}
          placeholder={t('requestDialog.messagePlaceholder')}
          value={message}
          onChange={(e): void => setMessage(e.target.value)}
        />
        <div className={styles.dialogButtons}>
          <Button color="info" onClick={onClose}>
            {t('requestDialog.cancelIntegrationButtonText')}
          </Button>
          <ActionDisableGuard>
            <Button
              onClick={handleRequest}
              color="primary"
              variant="contained"
              disabled={isProcessing}
            >
              {t('requestDialog.requestIntegrationButtonText')}
            </Button>
          </ActionDisableGuard>
        </div>
      </>
    </Modal>
  );
};
