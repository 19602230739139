import { inject, injectable } from 'inversify';
import { RxReplicationWriteToMasterRow } from 'rxdb/dist/types/types';
import { firstValueFrom, map } from 'rxjs';

import { CONTACT_LIST_TYPES, SYNC_TYPES } from '@/ioc/types';

import { CollectionName, ContactListDocument } from '@/features/system/db';
import { ReplicationService } from '@/features/system/replication';
import { mapBaseSyncEntity } from '@/features/system/replication/data/mappers';
import { IBaseSyncRepository } from '@/features/system/sync';

import { IContactListServicesApi } from '../network/ContactListApiSevices';

@injectable()
export class ContactListReplicationService extends ReplicationService<ContactListDocument> {
  @inject(CONTACT_LIST_TYPES.ContactListApiService)
  private apiService: IContactListServicesApi;

  @inject(SYNC_TYPES.BaseSyncRepository)
  private syncRepository: IBaseSyncRepository;

  constructor() {
    super({
      collectionName: CollectionName.ContactList,
      pullStreamFactory: () =>
        this.syncRepository
          .getContactListEvents()
          .pipe(map(mapBaseSyncEntity<ContactListDocument>)),
      pushHandlers: {
        create: (docs) => this.syncCreate(docs),
        update: (docs) => this.syncCreate(docs),
        delete: (docs) => this.syncDelete(docs),
      },
    });
  }

  private async syncCreate(
    docsToCreate: RxReplicationWriteToMasterRow<ContactListDocument>[],
  ): Promise<ContactListDocument[]> {
    return firstValueFrom(
      this.apiService
        .createContactLists(docsToCreate.map((doc) => doc.newDocumentState))
        .pipe(
          map((contactLists) => {
            return contactLists.map((contactList) => ({
              ...contactList,
              _deleted: false,
            }));
          }),
        ),
    );
  }

  private syncDelete(
    docsToDelete: RxReplicationWriteToMasterRow<ContactListDocument>[],
  ): Promise<boolean> {
    return firstValueFrom(
      this.apiService.deleteContactLists(
        docsToDelete.map((doc) => doc.newDocumentState.uuid),
      ),
    );
  }
}
