import { FC, ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { equals } from 'ramda';

import { ROUTES } from '@/router/routes';

import { useTagSelectOptions } from '@/features/common/tag';

import { CustomSelect, SearchInput } from '@/components';

import { useOptimisticState, useSearch } from '@/hooks';

import { ContactsContext } from '../../context';

import styles from './styles.module.scss';

export const FilterByTagAction: FC = () => {
  const { filterByTags, setFilterByTags } = useContext(ContactsContext);
  const { value: search, handleSearchChange, handleSearchClear } = useSearch();
  const { filteredOptions, optionsMapByValue } = useTagSelectOptions(search);
  const [value, setValue] = useOptimisticState(filterByTags);
  const { t } = useTranslation('contacts');
  const navigate = useNavigate();
  const valuesIsMatched = equals(value.sort(), filterByTags.sort());

  const handleChange = (values: string[]): void => {
    setValue(values);
  };

  const handlePopoverCloseAnimationEnd = (): void => {
    if (!valuesIsMatched) {
      setValue(filterByTags);
    }
  };

  useEffect(() => {
    setFilterByTags(
      filterByTags.filter((filterByTagsItem) => optionsMapByValue[filterByTagsItem]),
    );
  }, [JSON.stringify(Object.keys(optionsMapByValue))]);

  return (
    <Box className={styles.Wrapper}>
      <CustomSelect
        multiple
        value={value}
        name="tagsFilter"
        options={filteredOptions}
        onChange={handleChange}
        listProps={{
          className: styles.List,
        }}
        popoverProps={{
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          TransitionProps: {
            onExited: handlePopoverCloseAnimationEnd,
          },
        }}
        defaultAnchorProps={{
          renderAnchorValue: (): ReactElement => {
            const selectedCount = filterByTags.length;
            return (
              <Box className={styles.Anchor}>
                {Boolean(selectedCount) && (
                  <Box component={'span'} className={styles.AnchorCounter}>
                    {selectedCount}
                  </Box>
                )}
                <Box component="span" className={styles.AnchorText}>
                  {t('subhead.tagsFilter.selectField.value')}
                </Box>
              </Box>
            );
          },
        }}
        renderOption={({ option, selected }): ReactElement => {
          return (
            <CustomSelect.TagOption
              key={option.value}
              option={option}
              selected={selected}
            />
          );
        }}
        renderHeader={(): ReactElement => {
          return (
            <SearchInput
              value={search}
              placeholder={t('subhead.tagsFilter.searchField.placeholder')}
              variant="filled"
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              autoFocus
            />
          );
        }}
        renderActions={({ onClose }): ReactElement => {
          return (
            <Box className={styles.Actions}>
              <Button
                onClick={(): void => {
                  navigate(ROUTES.SETTINGS.TAG_MANAGEMENT);
                }}
              >
                {t('subhead.tagsFilter.tagsManage')}
              </Button>
              <Button
                disabled={valuesIsMatched}
                onClick={(): void => {
                  setFilterByTags(value);
                  onClose();
                }}
              >
                {t('subhead.tagsFilter.apply')}
              </Button>
            </Box>
          );
        }}
      ></CustomSelect>
    </Box>
  );
};
