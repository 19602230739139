import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { Modal } from '@/components';

import { BillingCycleDialogViewModel } from './BillingCycleDialogViewModel';

import styles from './styles.module.scss';

export const BillingCycleDialog: FC<BillingCycleDialogViewModel> = (props) => {
  const { t } = useTranslation('plans', { keyPrefix: 'billingCycleDialog' });

  return (
    <Modal open={props.isOpened} onClose={props.onClose} containerClass={styles.modal}>
      <div className={styles.container}>
        <Typography variant="h1">{t('title')}</Typography>
        <Typography variant="subtitle1">
          {t('subtitle', { date: props.changeDate })}
        </Typography>
        <div className={styles.body}>
          <Typography variant="h2">{props.text}</Typography>
        </div>
        <div className={styles.footer}>
          <Button
            variant="text"
            color="info"
            size="small"
            onClick={props.onClose}
            disabled={props.isProcessing}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={(): void => {
              void props.onConfirm();
            }}
            disabled={props.isProcessing}
          >
            {t('confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
