import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { IWorkspaceSubscriptionEntity } from '@/features/common/workspace';

import { formatCurrency } from '@/utils/formatCurrency';

import { PaymentInfoType } from '../types';

type PaymentInfo =
  | {
      type: PaymentInfoType.Renews;
      date: string;
      amount: string;
    }
  | {
      type: PaymentInfoType.Expires;
      date: string;
    }
  | {
      type: PaymentInfoType.Suspended;
    }
  | {
      type: PaymentInfoType.LastPaymentFailed;
    }
  | {
      type: PaymentInfoType.Gifted;
    };

export function usePaymentInfo(params: {
  subscription: Nullable<IWorkspaceSubscriptionEntity>;
}): Nullable<PaymentInfo> {
  const {
    i18n: { language },
  } = useTranslation();

  const { subscription } = params;

  if (!subscription) {
    return null;
  }

  if (subscription.isActive && !subscription.isCanceled && subscription.recurringPrice) {
    return {
      type: PaymentInfoType.Renews,
      date: dayjs(subscription.expirationDate * 1000).format('ll'),
      amount: formatCurrency({
        amount: Number.parseFloat(subscription.recurringPrice),
        currency: 'USD',
        currencyDisplay: 'symbol',
        locale: language,
      }),
    };
  }

  if (subscription.isActive && subscription.isCanceled) {
    return {
      type: PaymentInfoType.Expires,
      date: dayjs(subscription.expirationDate * 1000).format('ll'),
    };
  }

  if (subscription.isGracePeriod) {
    return {
      type: PaymentInfoType.LastPaymentFailed,
    };
  }

  if (subscription.purchaseType === 'gift') {
    return {
      type: PaymentInfoType.Gifted,
    };
  }

  if (!subscription.isActive) {
    return {
      type: PaymentInfoType.Suspended,
    };
  }

  return null;
}
