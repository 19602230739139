import * as React from "react";
const SvgEditTagIcon = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 115.5, height: 111, viewBox: "0 0 115.5 111", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", { id: "k28ghjxima", x: 0, y: 60, width: 115, height: 43, filterUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("feOffset", null), /* @__PURE__ */ React.createElement("feGaussianBlur", { stdDeviation: 3, result: "blur" }), /* @__PURE__ */ React.createElement("feFlood", { floodOpacity: 0.161 }), /* @__PURE__ */ React.createElement("feComposite", { operator: "in", in2: "blur" }), /* @__PURE__ */ React.createElement("feComposite", { in: "SourceGraphic" }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 16299", transform: "translate(-663 -323)" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 16299" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 10612", d: "M55.5 0A55.5 55.5 0 1 1 0 55.5 55.5 55.5 0 0 1 55.5 0z", transform: "translate(664.5 323)", style: {
  fill: "#e7f0ff"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  filter: "url(#k28ghjxima)"
} }, /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 17823", width: 97, height: 25, rx: 5, transform: "translate(672 392)", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("rect", { "data-name": "Rectangle 17824", width: 43, height: 8, rx: 3, transform: "translate(678 401)", style: {
  fill: "#f5f5fa"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31909", d: "m332.2 91.919-17.988-17.987 8.041-8.041a5.944 5.944 0 0 1 8.382 0l9.6 9.6a5.944 5.944 0 0 1 0 8.382z", transform: "translate(436.532 264.838)", style: {
  fill: "#ff0335"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 17822", d: "m764.717 360.77-17.987-17.987 4.919-4.918 17.987 17.986z" }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31911", d: "m128.61 128.037-34.676 34.675-9.57 27.557 27.557-9.57 34.679-34.676z", transform: "translate(618.636 214.231)", style: {
  fill: "#ffa345"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31913", d: "m84.364 322.527 27.557-9.57-17.987-17.987z", transform: "translate(618.636 81.973)", style: {
  fill: "#ffc473"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 31915", d: "m96.452 377.332-7.89-7.89-4.2 12.088z", transform: "translate(618.636 22.971)", style: {
  fill: "#57555c"
} })));
export default SvgEditTagIcon;
