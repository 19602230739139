import { useState } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { useBillingUseCase } from '@/features/common/billing';

import { formatCurrency } from '@/utils/formatCurrency';
import { useObservableResult } from '@/utils/rx';

dayjs.extend(localizedFormat);

type BillingHistory = {
  data: {
    date: string;
    amount: string;
    type: string;
    transactionId: string;
    url: string;
  }[];
  isLoading: boolean;
  isVisible: boolean;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
    changePage: (page: number) => void;
  };
};

export function useBillingHistoryViewModel(): BillingHistory {
  const billingUseCase = useBillingUseCase();

  const { data: invoices, isLoading } = useObservableResult(
    () => billingUseCase.getInvoices(),
    {
      deps: [],
      cacheKey: 'invoices',
      defaultData: [],
    },
  );

  const pageSize = 10;
  const [page, setPage] = useState(0);

  return {
    data: invoices.slice(page * pageSize, (page + 1) * pageSize).map((invoice) => ({
      transactionId: invoice.transactionId,
      url: invoice.url,
      date: dayjs(invoice.date).format('L'),
      type: invoice.description ?? '',
      amount: formatCurrency({ amount: invoice.amount, currency: invoice.currency }),
    })),
    isVisible: invoices.length > 0,
    isLoading,
    pagination: {
      page,
      pageSize,
      total: invoices.length,
      changePage: (page: number) => setPage(page),
    },
  };
}
