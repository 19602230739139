import { useEffect, useState } from 'react';

import { useOnlineTrackerUseCase } from '../../hooks';

type UseOnlineTrackerViewModel = () => {
  isOpen: boolean;
  handleClose: () => void;
};

export const useOnlineTrackerViewModel: UseOnlineTrackerViewModel = () => {
  const onlineTrackerUseCase = useOnlineTrackerUseCase();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const sub = onlineTrackerUseCase.getIsOnline().subscribe((isOnline) => {
      setIsOpen(!isOnline);
    });

    return (): void => {
      sub.unsubscribe();
    };
  }, []);

  const handleClose = (): void => {
    setIsOpen(false);
  };

  return {
    isOpen,
    handleClose,
  };
};
