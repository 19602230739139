import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { PlanType } from '@/features/common/billing';

import { CardInfoHint, CreditsUsed, PlanCardLayout, UpgradeAction } from '..';

import { useFreePlanCardViewModel } from './FreePlanCardViewModel';

export const FreePlanCard: FC = () => {
  const { t } = useTranslation('settings', { keyPrefix: 'subscriptions.planCard' });

  const { credits, maxUsers } = useFreePlanCardViewModel();

  return (
    <PlanCardLayout
      body={
        <Fragment>
          <Typography variant="h1">{t(`${PlanType.Free}.title`)}</Typography>
          <Typography variant="subtitle1">
            {t('creditsLimit', { count: credits.total })}
          </Typography>
          <Typography variant="subtitle1">
            {t('usersLimit', { count: maxUsers })}
          </Typography>
          <CardInfoHint>
            {t('creditsRefresh', { date: credits.refreshDate })}
          </CardInfoHint>
        </Fragment>
      }
      bodyActions={<UpgradeAction />}
      footer={<CreditsUsed used={credits.used} total={credits.total} />}
    />
  );
};
