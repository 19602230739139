import { inject, injectable } from 'inversify';
import { combineLatest, distinctUntilChanged, map, Observable } from 'rxjs';

import { ACCOUNT_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { IAccountCreditsUseCase } from '@/features/common/account';
import { ISubscriptionUseCase } from '@/features/common/workspace';

import { IFairUsageUseCase } from './abstractions/IFareUsageUseCase';

@injectable()
export class FairUsageUseCase implements IFairUsageUseCase {
  @inject(ACCOUNT_TYPES.AccountCreditsUseCase)
  private accountCreditsUseCase: IAccountCreditsUseCase;

  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private subscriptionUseCase: ISubscriptionUseCase;

  getIsFairUsageLimitReached(): Observable<boolean> {
    return combineLatest({
      isDailyLimitReached: this.isDailyLimitReached(),
      isMonthlyLimitReached: this.isMonthlyLimitReached(),
      isFreePlan: this.subscriptionUseCase.getIsFreePlan(),
    }).pipe(
      map(({ isDailyLimitReached, isMonthlyLimitReached, isFreePlan }) => {
        if (isFreePlan) {
          return false;
        }

        return isDailyLimitReached || isMonthlyLimitReached;
      }),
      distinctUntilChanged(),
    );
  }

  private isMonthlyLimitReached(): Observable<boolean> {
    return combineLatest({
      used: this.accountCreditsUseCase.getMonthlyUsedCredits(),
      limit: this.accountCreditsUseCase.getMonthlyCreditsLimit(),
    }).pipe(
      map(({ used, limit }) => {
        return used >= limit;
      }),
    );
  }

  private isDailyLimitReached(): Observable<boolean> {
    return combineLatest({
      used: this.accountCreditsUseCase.getDailyUsedCredits(),
      limit: this.accountCreditsUseCase.getDailyCreditsLimit(),
    }).pipe(
      map(({ used, limit }) => {
        return used >= limit;
      }),
    );
  }
}
