import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { unix } from 'dayjs';

import { ReferralStatus } from '@/features/referral/domain/entities';

import { theme } from '@/theme';

export const TableCellEmail: React.FC<{ value: string }> = ({ value }) => {
  return (
    <Typography variant="h3" fontWeight={500}>
      {value}
    </Typography>
  );
};

export const TableCellLastActivity: React.FC<{ value: string }> = ({ value }) => {
  const valueFormatted = value ? unix(parseInt(value)).format('DD MMMM YYYY') : '';

  return (
    <Typography variant="subtitle1" color="info">
      {valueFormatted}
    </Typography>
  );
};

export const TableCellStatus: React.FC<{ status: ReferralStatus }> = ({ status }) => {
  const { t } = useTranslation('referral', {
    keyPrefix: 'inviteesSection.table.statusType',
  });

  const statusColorMap = {
    [ReferralStatus.InvitationSent]: theme.palette.info.main,
    [ReferralStatus.WaitingForInstall]: '#f79a70',
    [ReferralStatus.Credited]: theme.palette.success.main,
    [ReferralStatus.Expired]: theme.palette.error.main,
  };

  return (
    <Typography variant={'subtitle3'} color={statusColorMap[status]}>
      {t(status)}
    </Typography>
  );
};
