import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Slider, Stack, styled, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { ActionDisableGuard, UserRole } from '@/features/common/account';
import { PlanType } from '@/features/common/billing';

import styles from '@/components/header/components/UserMenu/styles.module.scss';
import { FlashIcon } from '@/components/Icons';

import { useUserMenuHandlers } from '../../hooks';

type UserMenuBillingSectionProps = {
  planType: PlanType;
  creditsUsed: number;
  creditsLimit: number;
  role: UserRole;
};

export const UserMenuCreditsBar = styled(Slider)`
  width: 260px;
  height: 6px;
  border-radius: 2px;
  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
  padding: 0;

  .MuiSlider-rail {
    opacity: 1;
    color: ${({ theme }): string => theme.palette.info.disabled};
  }

  .MuiSlider-track {
    color: ${({ theme }): string => theme.palette.secondary.main};
  }

  .MuiSlider-thumb {
    display: none;
  }
`;

export const UserMenuBillingSection: FC<UserMenuBillingSectionProps> = ({
  planType,
  creditsUsed,
  creditsLimit,
  role,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onClose } = useUserMenuHandlers();

  const handleClickUpgradeNow = (): void => {
    navigate(ROUTES.BILLING.PLANS);
    onClose();
  };

  if (planType === PlanType.Free) {
    return (
      <>
        <Stack mb={0.75}>
          <Grid justifyContent="space-between" alignItems="center" mb={0.75}>
            <Typography variant="subtitle2">{t('header.creditsUsage')}</Typography>
            <Typography variant="subtitle2">
              {creditsUsed}/{creditsLimit}
            </Typography>
          </Grid>
          <UserMenuCreditsBar
            defaultValue={creditsUsed}
            value={creditsUsed}
            min={0}
            max={creditsLimit}
            disabled
          />
          <ActionDisableGuard>
            <Button
              startIcon={<FlashIcon style={{ fontSize: '12px' }} />}
              variant="outlined"
              size="small"
              className={styles.unlimitedCreditsButton}
              onClick={handleClickUpgradeNow}
            >
              <Typography variant="subtitle2" fontWeight={600}>
                {t('header.upgradeNow')}
              </Typography>
            </Button>
          </ActionDisableGuard>
        </Stack>

        <Box className={styles.divider} />
      </>
    );
  }

  return (
    <>
      <Grid justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">{t('header.creditsUsage')}</Typography>
        <Typography variant="subtitle2">
          {role === UserRole.Manager
            ? t('header.userMenuNoCredits')
            : `${creditsUsed}/${t('header.unlimited')}`}
        </Typography>
      </Grid>

      <Box className={styles.divider} />
    </>
  );
};
