import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './translations/i18n';

import { container } from '@/ioc/container';
import { IocProvider } from '@/ioc/ioc.react';

import { AccountPermissionsProvider } from '@/features/common/account';
import { Auth } from '@/features/common/auth';
import { StripeProvider } from '@/features/common/billing';
import { BillingOverlay } from '@/features/common/billing';
import { AuthExtensionSync } from '@/features/extensionAuth/ui/components/AuthExtensionSync';
import { Analytics } from '@/features/system/analytics';
import { ContextualOnboarding } from '@/features/system/contextualOnboarding';
import { AuthIdentityCookieSync } from '@/features/system/cookies';
import { CustomerIO } from '@/features/system/CustomerIO';
import { DatabaseInitializer } from '@/features/system/db';
import { GeoSync } from '@/features/system/geoSync';
import { GlobalErrors } from '@/features/system/globalError';
import { Hotjar } from '@/features/system/Hotjar';
import { LeadsTracker } from '@/features/system/leadsTracking';
import { OnlineTracker } from '@/features/system/OnlineTracker';
import { Replication } from '@/features/system/replication';
import { SentryTracker, withSentryProfiler } from '@/features/system/sentry';
import { ServerTimeSync } from '@/features/system/serverTime';
import { BaseSync } from '@/features/system/sync';

import { ModalProvider } from '@/components';
import { UserMenuContextProvider } from '@/components/header/contexts';

import { ObservableResultCacheProvider } from '@/utils/rx';

import { AppRouter } from './router/AppRouter';

import './styles/index.scss';

import { ThemeProvider } from '@/theme';

const App: FC = () => {
  useEffect(() => {
    const nativeSplashScreen = document.getElementById('splash-screen');
    nativeSplashScreen?.parentNode?.removeChild(nativeSplashScreen);
  }, []);

  return (
    <BrowserRouter>
      <IocProvider container={container}>
        <ThemeProvider>
          <ModalProvider>
            <StripeProvider>
              <UserMenuContextProvider>
                <ObservableResultCacheProvider>
                  <LeadsTracker />
                  <SentryTracker />
                  <ServerTimeSync />
                  <GeoSync />
                  <AuthIdentityCookieSync />
                  <AuthExtensionSync />
                  <GlobalErrors />
                  <OnlineTracker />
                  <ContextualOnboarding />
                  <Analytics />
                  <CustomerIO />
                  <Hotjar />
                  <Auth>
                    <DatabaseInitializer>
                      <Replication />
                      <BaseSync>
                        <AccountPermissionsProvider>
                          <BillingOverlay>
                            <AppRouter />
                          </BillingOverlay>
                        </AccountPermissionsProvider>
                      </BaseSync>
                    </DatabaseInitializer>
                  </Auth>
                </ObservableResultCacheProvider>
              </UserMenuContextProvider>
            </StripeProvider>
          </ModalProvider>
        </ThemeProvider>
      </IocProvider>
    </BrowserRouter>
  );
};

export default withSentryProfiler(App);
