import { inject, injectable } from 'inversify';
import { first, firstValueFrom, map, Observable, switchMap } from 'rxjs';

import { CONTACT_TYPES, INTEGRATION_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { type IIntegrationRepository } from '@/features/integration';

import { getPlanTypeFromSubscription, PlanType } from '../../billing';
import { IWorkspaceRepository } from '../../workspace';

import { IContactRepository, IContactUseCase } from './abstractions';
import { IContactEntity } from './entities';
import { ContactExportNotAllowedError } from './errors';
import { ContactGetByFiltersResult, UpdateContactDto } from './types';

type ProviderData =
  | { provider: string; ids: string[] }
  | { provider: string; searchParams: URLSearchParams };

@injectable()
export class ContactUseCase implements IContactUseCase {
  @inject(CONTACT_TYPES.ContactRepository)
  private readonly contactRepository: IContactRepository;

  @inject(INTEGRATION_TYPES.IntegrationRepository)
  private integrationRepository: IIntegrationRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  private async exportGuardByPlanType<R>(fn: () => Promise<R>): Promise<R> {
    const subscription = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspaceSubscription(),
    );
    const planType = getPlanTypeFromSubscription(subscription);

    if (planType === PlanType.Free) {
      throw new ContactExportNotAllowedError();
    }
    return fn();
  }

  public getByFilters(params: {
    queryString: string;
    autoUpdate?: boolean;
  }): Observable<ContactGetByFiltersResult> {
    return this.contactRepository.getByFilters(params);
  }

  public moveToList(listId: string, ids: string[]): Observable<IContactEntity[]> {
    return this.contactRepository.moveToList(listId, ids);
  }

  public updateById(id: string, dto: UpdateContactDto): Observable<boolean> {
    return this.contactRepository.updateById(id, dto);
  }

  public upsertPatch(patch: IContactEntity[]): Observable<boolean> {
    return this.contactRepository.upsertPatch(patch);
  }

  public exportToCsvAll(): Promise<{ linkToDownload: string }> {
    return this.exportGuardByPlanType(() => {
      return this.contactRepository.exportToCsvAll();
    });
  }

  public exportToCsvByFilters(
    queryParams: URLSearchParams,
  ): Promise<{ linkToDownload: string }> {
    return this.exportGuardByPlanType(() => {
      return this.contactRepository.exportToCsvByFilters(queryParams);
    });
  }

  public exportToCsvByIds(ids: string[]): Promise<{ linkToDownload: string }> {
    return this.exportGuardByPlanType(() => {
      return this.contactRepository.exportToCsvByIds(ids);
    });
  }

  public wrongInfoReport(dto: {
    value: string;
    contact_uuid: string;
    entity_type: string;
  }): Promise<boolean> {
    return this.contactRepository.wrongInfoReport(dto);
  }

  public exportToIntegration(data: ProviderData): Promise<void> {
    return this.exportGuardByPlanType(() => {
      if ('ids' in data) {
        return this.integrationRepository.exportByIds(data.provider, data.ids);
      } else {
        const searchParams = new URLSearchParams(data.searchParams);

        return firstValueFrom(
          this.contactRepository.getByQuery(searchParams).pipe(
            first(),
            map((contacts) => {
              return contacts.map((entity) => entity.uuid);
            }),
            switchMap((ids) =>
              this.integrationRepository.exportByIds(data.provider, ids),
            ),
          ),
        );
      }
    });
  }
}
