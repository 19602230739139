import { PlanType } from '../types';

export function isPlanTypeLegacy(plan: PlanType): boolean {
  switch (plan) {
    case PlanType.ProLegacyV0:
    case PlanType.ProLegacyV1:
    case PlanType.AdvancedLegacy:
    case PlanType.Ultimate:
    case PlanType.Growth:
    case PlanType.Scale:
      return true;
    default:
      return false;
  }
}
