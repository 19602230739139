import { useMemo } from 'react';

import { useGetFreeCreditsUseCase } from '@/features/getFreeCredits';

import { useObservableResult } from '@/utils/rx';

type Props = {
  isFederatedIdentityProvider: boolean;
};

export const useIsFederatedIdentityProvider = (): Props => {
  const useCase = useGetFreeCreditsUseCase();

  const federatedIdentityProvider$ = useMemo(
    () => useCase.getFederatedIdentityProvider(),
    [],
  );
  const { data } = useObservableResult(federatedIdentityProvider$);

  return {
    isFederatedIdentityProvider: !!data,
  };
};
