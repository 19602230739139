import { injectable } from 'inversify';

import { IWorkspaceDC } from '@/features/common/workspace';
import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

export type IWorkspaceDao = IDbCollection<IWorkspaceDC, 'uuid'>;

@injectable()
export default class WorkspaceDao
  extends DbCollection<IWorkspaceDC, 'uuid'>
  implements IWorkspaceDao
{
  constructor() {
    super({ collectionName: CollectionName.Workspace, idKey: 'uuid' });
  }
}
