import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { BILLING_TYPES, ENRICHMENT_TYPES } from '@/ioc/types';

import {
  IBillingUseCase,
  isPlanTypeHigherOrEqualThen,
  PlanType,
} from '@/features/common/billing';

import { IEnrichmentRepository, IEnrichmentUseCase } from './abstraction';
import { IEnrichmentEntity } from './entities';
import { EnrichmentSortSchema } from './types';

@injectable()
export class EnrichmentUseCase implements IEnrichmentUseCase {
  @inject(ENRICHMENT_TYPES.EnrichmentRepository)
  private readonly enrichmentRepository: IEnrichmentRepository;

  @inject(BILLING_TYPES.BillingUseCase)
  private readonly billingUseCase: IBillingUseCase;

  public getAllByParams(
    params: {
      name?: string;
      createdBy?: string;
    },
    sortSchema?: EnrichmentSortSchema,
  ): Observable<IEnrichmentEntity[]> {
    return this.enrichmentRepository.getAllByParams(params, sortSchema);
  }

  public download(uuid: string): Promise<string> {
    return this.enrichmentRepository.downloadReport(uuid);
  }

  public ensureAccess(): Observable<{ hasAccess: boolean }> {
    return this.billingUseCase.getPlanType().pipe(
      map((planType) => {
        return {
          hasAccess: isPlanTypeHigherOrEqualThen(planType, PlanType.Pro),
        };
      }),
    );
  }

  public contactAdmin(): Promise<boolean> {
    return this.enrichmentRepository.contactAdmin();
  }
}
