import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { WORKSPACE_TYPES } from '@/ioc/types';

import { SubscriptionPlan } from '..';

import { ISubscriptionUseCase, IWorkspaceRepository } from './abstractions';
import { ICreditsEntity, IWorkspaceSubscriptionEntity } from './entities';
import { CreditType } from './types';

@injectable()
export class SubscriptionUseCase implements ISubscriptionUseCase {
  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workpsaceRepository: IWorkspaceRepository;

  getSubscription(): Observable<IWorkspaceSubscriptionEntity> {
    return this.workpsaceRepository.getCurrentWorkspaceSubscription();
  }

  getSubscriptionPlan(): Observable<SubscriptionPlan> {
    return this.getSubscription().pipe(map((subscription) => subscription.plan));
  }

  getIsFreePlan(): Observable<boolean> {
    return this.getSubscriptionPlan().pipe(
      map((plan) => {
        return (
          plan === SubscriptionPlan.FreeMonthly ||
          plan === SubscriptionPlan.FreePlusMonthly
        );
      }),
    );
  }

  getSubscriptionCreditsInfo(): Observable<ICreditsEntity> {
    return this.getSubscription().pipe(
      map((subscription) => {
        const creditsInfo = subscription.credits.find(
          (credit) => credit.creditType === CreditType.Full,
        );

        if (!creditsInfo) {
          throw new Error('Contact credits not found');
        }

        return creditsInfo;
      }),
    );
  }
}
