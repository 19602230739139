import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { BillingCycle, PlanType } from '@/features/common/billing';

import { CancelSubscriptionDialog } from '../CancelSubscriptionDialog';
import {
  CancelSubscriptionAction,
  CancelSubscriptionCard,
  CardErrorHint,
  CardInfoHint,
  ChangeCardAction,
  CreditCardDetails,
  KeepPlanAction,
  PlanCardLayout,
  PlanCardNextPayment,
  ResubscribeAction,
  UpgradeAction,
} from '..';

import { usePaidPlanCardViewModel } from './PaidPlanCardViewModel';
import { PaymentInfoType, PlanCardType } from './types';

type PaidPlanCardProps = {
  paidPlanType:
    | PlanType.ProLegacyV0
    | PlanType.ProLegacyV1
    | PlanType.AdvancedLegacy
    | PlanType.Ultimate
    | PlanType.Unlimited
    | PlanType.Pro
    | PlanType.Growth
    | PlanType.Scale
    | PlanType.Expand;
  billingCycle: BillingCycle;
  type?: PlanCardType;
};

export const PaidPlanCard: FC<PaidPlanCardProps> = ({
  paidPlanType,
  billingCycle,
  type,
}) => {
  const { t } = useTranslation('settings', { keyPrefix: 'subscriptions.planCard' });
  const {
    paymentMethod,
    paymentInfo,
    cancelSubscriptionDialog,
    cancelSubscriptionWarning,
    teamMembersCount,
    resubscribeAction,
    upgradeAction,
    cancelSubscriptionAction,
    keepPlanAction,
    changeCardAction,
    showSubtitle,
  } = usePaidPlanCardViewModel({ type, paidPlanType });

  const isNewPlan = type === PlanCardType.New;
  const isCurrentPlan = type === PlanCardType.Current;
  const isFooterVisible = paymentMethod && type !== PlanCardType.Current;

  return (
    <Fragment>
      <PlanCardLayout
        title={isNewPlan ? t('titleNew') : t('title')}
        body={
          <Fragment>
            <Typography variant="h1">
              {t(`${paidPlanType}${billingCycle}.title`)}
            </Typography>
            {showSubtitle ? (
              <Typography variant="subtitle1">
                {t(`${paidPlanType}${billingCycle}.subtitle`)}
              </Typography>
            ) : null}
            <Typography variant="subtitle1">
              {t('usersCount', { count: teamMembersCount })}
            </Typography>
            {paymentInfo?.type === PaymentInfoType.Gifted ? (
              <CardInfoHint>{t('gifted')}</CardInfoHint>
            ) : null}
            {paymentInfo?.type === PaymentInfoType.Suspended ? (
              <CardErrorHint>{t('accountSuspended')}</CardErrorHint>
            ) : null}
            {paymentInfo?.type === PaymentInfoType.LastPaymentFailed ? (
              <CardErrorHint>{t('lastPaymentFailed')}</CardErrorHint>
            ) : null}
            {paymentInfo?.type === PaymentInfoType.Renews && !isCurrentPlan ? (
              <PlanCardNextPayment />
            ) : null}
            {paymentInfo?.type === PaymentInfoType.Expires ? (
              <CardErrorHint>{t('expired', paymentInfo)}</CardErrorHint>
            ) : null}
          </Fragment>
        }
        bodyActions={
          <Fragment>
            {resubscribeAction.isVissible ? (
              <ResubscribeAction
                onClick={resubscribeAction.onResubscribe}
                isLoading={resubscribeAction.isProcessing}
              />
            ) : null}
            {upgradeAction.isVisisble ? <UpgradeAction /> : null}
            {cancelSubscriptionAction.isVisisble ? (
              <CancelSubscriptionAction onClick={cancelSubscriptionAction.onCancel} />
            ) : null}
            <Fragment>
              {keepPlanAction.isVisisble ? (
                <KeepPlanAction
                  onClick={keepPlanAction.onKeepPlan}
                  isLoading={keepPlanAction.isProcessing}
                />
              ) : null}
            </Fragment>
          </Fragment>
        }
        footer={
          isFooterVisible ? (
            <CreditCardDetails
              expDate={paymentMethod.expDate}
              last4={paymentMethod.last4}
              brand={paymentMethod.brand}
              isExpired={paymentMethod.isExpired}
            />
          ) : null
        }
        footerActions={
          changeCardAction.isVisisble ? (
            <ChangeCardAction
              isLoading={changeCardAction.isProcessing}
              onClick={changeCardAction.onChangeCard}
            />
          ) : null
        }
      >
        {cancelSubscriptionWarning.isOpen ? (
          <CancelSubscriptionCard
            onSubmit={cancelSubscriptionDialog.onOpen}
            onCancel={cancelSubscriptionWarning.onClose}
          />
        ) : null}
      </PlanCardLayout>
      <CancelSubscriptionDialog
        isOpened={cancelSubscriptionDialog.isOpen}
        onClose={(): void => {
          cancelSubscriptionDialog.onClose();
          cancelSubscriptionWarning.onClose();
        }}
      />
    </Fragment>
  );
};
