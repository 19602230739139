import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { firstValueFrom } from 'rxjs';

import { ROUTES } from '@/router/routes';

import {
  getBillingCycleFromSubscription,
  getPlanTypeFromSubscription,
  isPlanTypeLegacy,
  PlanType,
  useBillingUseCase,
} from '@/features/common/billing';
import { useWorkspaceSubscription } from '@/features/common/workspace';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { ModalControler, useModalController } from '@/hooks';

import { useObservableResult } from '@/utils/rx';

import { usePaymentInfo, usePaymentMethod } from './hooks';
import { PaymentInfoType, PlanCardType } from './types';

type UsePaidPlanCardViewModel = (params: {
  type?: Nullable<PlanCardType>;
  paidPlanType: PlanType;
}) => {
  paymentInfo: ReturnType<typeof usePaymentInfo>;
  paymentMethod: ReturnType<typeof usePaymentMethod>;
  canCancel: boolean;
  isLoading: boolean;
  cancelSubscriptionDialog: ModalControler;
  cancelSubscriptionWarning: ModalControler;
  teamMembersCount: number;
  showSubtitle: boolean;
  resubscribeAction: {
    isVissible: boolean;
    onResubscribe: () => Promise<void>;
    isProcessing: boolean;
  };
  upgradeAction: {
    isVisisble: boolean;
  };
  cancelSubscriptionAction: {
    isVisisble: boolean;
    onCancel: () => void;
  };
  keepPlanAction: {
    isVisisble: boolean;
    onKeepPlan: () => void;
    isProcessing: boolean;
  };
  changeCardAction: {
    isVisisble: boolean;
    onChangeCard: () => Promise<void>;
    isProcessing: boolean;
  };
};

const CARD_UPDATED_QUERY_KEY = 'cardUpdated';

export const usePaidPlanCardViewModel: UsePaidPlanCardViewModel = ({
  type,
  paidPlanType,
}) => {
  const { t } = useTranslation('settings', { keyPrefix: 'subscriptions' });

  const navigate = useNavigate();

  const snackbar = useSnackbar();
  const { trackEvent } = useAnalytics();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const cardUpdated = searchParams.get(CARD_UPDATED_QUERY_KEY);

    if (cardUpdated) {
      snackbar.enqueueSnackbar(t('paymentMethodUpdated.title'), {
        variant: 'success',
        description: t('paymentMethodUpdated.description'),
        onClose: () => {
          searchParams.delete(CARD_UPDATED_QUERY_KEY);
          setSearchParams(searchParams);
        },
      });
    }
  }, []);

  const cancelSubscriptionDialog = useModalController();
  const cancelSubscriptionWarning = useModalController();

  const [isResubscribing, setIsResubscribing] = useState(false);
  const [isKeepPlanLoading, setIsKeepPlanLoading] = useState(false);
  const [isEditCardLoading, setIsEditCardLoading] = useState(false);
  const billingUseCase = useBillingUseCase();
  const subscriptionResult = useWorkspaceSubscription();

  const paymentMethodResult = useObservableResult(
    () => billingUseCase.getPaymentMethod(),
    { deps: [], cacheKey: 'payment-method' },
  );

  const paymentInfo = usePaymentInfo({ subscription: subscriptionResult.data });
  const paymentMethod = usePaymentMethod({ paymentMethod: paymentMethodResult.data });

  const logger = useAppLogger();

  const isCanceled =
    (subscriptionResult.data?.isCanceled ?? false) ||
    !(subscriptionResult.data?.isActive ?? false);

  const isLoading = subscriptionResult.isLoading || paymentMethodResult.isLoading;

  const { data: subscription } = useWorkspaceSubscription();
  const teamMembersCount = subscription?.paidMembersCount || 1;

  return {
    paymentMethod,
    paymentInfo,
    isLoading,
    canCancel: paymentInfo?.type === PaymentInfoType.Expires,
    cancelSubscriptionDialog,
    cancelSubscriptionWarning,
    teamMembersCount,
    showSubtitle: isPlanTypeLegacy(paidPlanType),
    resubscribeAction: {
      isVissible: !type && isCanceled && !!paymentMethod,
      isProcessing: isResubscribing,
      onResubscribe: async (): Promise<void> => {
        if (paymentInfo?.type === PaymentInfoType.Suspended) {
          navigate(ROUTES.BILLING.PAYMENT_DETAILS);
          return;
        }

        if (
          paymentInfo?.type === PaymentInfoType.LastPaymentFailed &&
          !subscription?.isActive &&
          !subscription?.isGracePeriod &&
          !subscription?.paid &&
          subscription
        ) {
          const planType = getPlanTypeFromSubscription(subscription);
          const billingCycle = getBillingCycleFromSubscription(subscription);

          const searchParams = new URLSearchParams();

          searchParams.set('new_plan', planType);
          searchParams.set('new_cycle', billingCycle);

          navigate(`${ROUTES.BILLING.PAYMENT_DETAILS}?${searchParams.toString()}`);
        }

        if (paymentInfo?.type === PaymentInfoType.Expires) {
          try {
            setIsResubscribing(true);
            await firstValueFrom(billingUseCase.renewSubscription());
            snackbar.enqueueSnackbar(t('subscriptionRenewed.title'), {
              variant: 'success',
              description: t('subscriptionRenewed.description'),
            });
          } catch (e) {
            logger.error(e);

            snackbar.enqueueSnackbar(t('subscriptionRenewFailed.title'), {
              variant: 'error',
              description: t('subscriptionRenewFailed.description'),
            });
          } finally {
            setIsResubscribing(false);
          }
        }
      },
    },
    upgradeAction: {
      isVisisble: !type && !isCanceled,
    },
    cancelSubscriptionAction: {
      isVisisble: type !== PlanCardType.Current && !isCanceled,
      onCancel: (): void => {
        cancelSubscriptionWarning.onOpen();
      },
    },
    keepPlanAction: {
      isVisisble: type === PlanCardType.Current && !isCanceled,
      onKeepPlan: async (): Promise<void> => {
        if (subscription?.plan) {
          try {
            setIsKeepPlanLoading(true);
            await firstValueFrom(
              billingUseCase.updateSubscription({ plan: subscription.plan }),
            );
          } catch (e) {
            logger.error(e);

            snackbar.enqueueSnackbar(t('subscriptionRenewFailed.title'), {
              variant: 'error',
              description: t('subscriptionRenewFailed.description'),
            });
          } finally {
            setIsKeepPlanLoading(false);
          }
        }
      },
      isProcessing: isKeepPlanLoading,
    },
    changeCardAction: {
      isVisisble: type !== PlanCardType.Current && !!paymentMethod,
      onChangeCard: async (): Promise<void> => {
        trackEvent(ANALYTICS_EVENTS.CLICK_CHANGE_CARD);
        try {
          setIsEditCardLoading(true);
          const { url } = await firstValueFrom(
            billingUseCase.updatePaymentMethod({
              successUrl: `${window.location.href}?${CARD_UPDATED_QUERY_KEY}=true`,
              cancelUrl: window.location.href,
            }),
          );
          window.open(url, '_self');
        } catch (e) {
          logger.error(e);

          snackbar.enqueueSnackbar(t('paymentMethodUpdateFailed.title'), {
            variant: 'error',
            description: t('paymentMethodUpdateFailed.description'),
          });
        } finally {
          setIsEditCardLoading(false);
        }
      },
      isProcessing: isEditCardLoading,
    },
  };
};
