import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import styles from './styles.module.scss';

export const CreditsUsed: FC<{ used: number; total: number }> = ({ used, total }) => {
  const { t } = useTranslation('settings', { keyPrefix: 'subscription.planCard' });

  return (
    <Box className={styles.credits}>
      <Typography className={styles.creditsTitle}>Credits used</Typography>
      <Typography className={styles.creditsDescription}>
        <Trans
          t={t}
          i18nKey="credits"
          defaults="You've used <span>{{used}}</span> of {{total}} available credits"
          values={{ used, total }}
          components={{ span: <span /> }}
        />
      </Typography>
    </Box>
  );
};
