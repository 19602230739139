import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  IContactListEntity,
  useContactListById,
  useContactListEntities,
  useDefaultContactList,
} from '@/features/common/contactList';

export const useContactsViewModel = () => {
  const currentContactListSnapshot = useRef<Nullable<IContactListEntity>>(null);
  const { id: contactListId = '' } = useParams();
  const defaultContactList = useDefaultContactList();
  const contactListById = useContactListById(contactListId);
  const contactListEntities = useContactListEntities();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('contacts');

  const isLoading = contactListById.isLoading || defaultContactList.isLoading;
  const hasError = contactListById.hasError;
  const title = `${contactListById.data?.name} (${contactListById?.data?.contactsAmount})`;

  useEffect(() => {
    if (!hasError || !currentContactListSnapshot.current) return;

    enqueueSnackbar(
      t('errors.listNotFound.title', { name: currentContactListSnapshot.current?.name }),
      {
        variant: 'error',
        persist: true,
      },
    );
  }, [hasError]);

  useEffect(() => {
    if (!contactListById.data) return;

    currentContactListSnapshot.current = contactListById.data;
  }, [contactListById.data]);

  return {
    isLoading,
    hasError,
    title,
    contactListEntities,
    currentContactList: contactListById.data,
    defaultContactList: defaultContactList.data,
  };
};
