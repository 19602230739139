import React from 'react';

import { AuthState } from '@/features/common/auth';

import { SplashScreen } from '@/components/SplashScreen';

import { useReferralLandingViewModel } from './useReferralLandingViewModel';

export const ReferralLanding: React.FC<{ authState: AuthState }> = ({ authState }) => {
  useReferralLandingViewModel(authState);

  return <SplashScreen />;
};
