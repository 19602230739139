import { FC, PropsWithChildren, ReactNode } from 'react';

import { ACCESS_PERMISSIONS, usePermissions } from '@/features/common/account';

export type Props = {
  permission: ACCESS_PERMISSIONS;
  fallback?: ReactNode;
};

export const HasPermission: FC<PropsWithChildren<Props>> = ({
  children,
  permission,
  fallback = null,
}) => {
  const { hasPermission } = usePermissions();
  const canAccessChildren = hasPermission(permission);
  return canAccessChildren ? <>{children}</> : <>{fallback}</>;
};
