import { inject, injectable } from 'inversify';
import { map, Observable, of, switchMap } from 'rxjs';
import { filter } from 'rxjs';

import { ACCOUNT_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import {
  IWorkspaceApiService,
  IWorkspaceDao,
  IWorkspaceDC,
  IWorkspaceEntity,
  IWorkspaceRepository,
  IWorkspaceSubscriptionEntity,
  mapWorkspaceDcToEntity,
  mapWorkspaceEntityToDc,
} from '@/features/common/workspace';
import { ITeamMemberEntity } from '@/features/settings';

import { IAccountDao, IAccountDC } from '../../account';
import { ISwitchWorkspaceReq } from '../data/dataContracts';

@injectable()
export default class WorkspaceRepository implements IWorkspaceRepository {
  @inject(WORKSPACE_TYPES.WorkspaceDao)
  private workspaceDao: IWorkspaceDao;

  @inject(ACCOUNT_TYPES.AccountDao)
  private accountDao: IAccountDao;

  @inject(WORKSPACE_TYPES.WorkspaceApiService)
  private apiService: IWorkspaceApiService;

  updateWorkspace(workspaceEntity: IWorkspaceEntity): Observable<IWorkspaceDC> {
    return this.workspaceDao.upsert(mapWorkspaceEntityToDc(workspaceEntity));
  }

  getWorkspaces(): Observable<IWorkspaceEntity[]> {
    return this.workspaceDao.findAll().pipe(
      map((workspaces) => {
        return workspaces.map(mapWorkspaceDcToEntity);
      }),
    );
  }

  switchWorkspace(req: ISwitchWorkspaceReq): Observable<boolean> {
    return this.apiService.switchWorkspace(req);
  }

  getCurrentWorkspace(): Observable<IWorkspaceEntity | null> {
    return this.accountDao.getCurrent().pipe(
      map((account: IAccountDC | null) => account?.personal_workspace_id),
      switchMap((id: string) => {
        if (!id) {
          return of(null);
        }

        return this.workspaceDao
          .findById(id)
          .pipe(
            map((workspace) => (workspace ? mapWorkspaceDcToEntity(workspace) : null)),
          );
      }),
    );
  }

  getCurrentWorkspaceOwner(): Observable<ITeamMemberEntity> {
    return this.getCurrentWorkspace().pipe(
      filter((workspace): workspace is IWorkspaceEntity => !!workspace),
      map((workspace) => {
        return workspace.members.find(
          (member) => member.role === 'owner',
        ) as ITeamMemberEntity;
      }),
    );
  }

  getCurrentWorkspaceSubscription(): Observable<IWorkspaceSubscriptionEntity> {
    return this.getCurrentWorkspace().pipe(
      filter((workspace) => !!workspace?.subscription),
      map((workspace: IWorkspaceEntity) => workspace.subscription),
    );
  }
}
