import { createContext } from 'react';

import { BillingCycle, PlanType } from '@/features/common/billing';

import { PlanAction } from '../types';

export type PlanActionResolverContextType = {
  resolve: (params: { planType: PlanType; billingCycle: BillingCycle }) => PlanAction;
  onAction(params: {
    action: PlanAction;
    planType: PlanType;
    billingCycle: BillingCycle;
  }): void;
};

/**
 * Use this context to pass plan action resolver logic to the children.
 * Motivation:
 *  - We have several places where we need to resolve plan action
 *  - It might be used also in marketing pages, where resolver logic is different
 */
export const PlanActionResolverContext = createContext<PlanActionResolverContextType>({
  resolve: () => PlanAction.None,
  onAction: () => {
    return;
  },
});
