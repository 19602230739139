import { BaseSyntheticEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { firstValueFrom } from 'rxjs';
import * as yup from 'yup';

import { useLoginUseCase } from '@/features/signin/ui/hooks';

import { useFormWithSchema } from '@/utils/validation';

const CustomTokenLoginFormSchema = yup.object({
  token: yup.string().required('fieldRequired'),
});

type CustomTokenLoginFormType = yup.InferType<typeof CustomTokenLoginFormSchema>;

type UseCustomTokenLoginFormResult = {
  loginWithCustomToken(e: BaseSyntheticEvent): void;
  form: UseFormReturn<CustomTokenLoginFormType>;
};

enum CustomTokenFormErrorType {
  ServiceError = 'serviceError',
}

export const useCustomTokenLoginForm = (): UseCustomTokenLoginFormResult => {
  const form = useFormWithSchema(CustomTokenLoginFormSchema, {
    defaultValues: {
      token: '',
    },
  });
  const { t } = useTranslation('auth');
  const loginUseCase = useLoginUseCase();

  const loginWithCustomToken = (e: BaseSyntheticEvent): void => {
    void form.handleSubmit(async ({ token }: CustomTokenLoginFormType) => {
      try {
        await firstValueFrom(loginUseCase.loginWithCustomToken(token));
      } catch (error) {
        form.setError('root', {
          type: CustomTokenFormErrorType.ServiceError,
          message: t('signInWithCustomToken.errors.invalidToken'),
        });
      }
    })(e);
  };

  return {
    loginWithCustomToken,
    form,
  };
};
