import { createContext } from 'react';

import { BillingCycle, NotLegacyPlanType, PlanType } from '@/features/common/billing';

import { PlanMetadata } from '../types';

const defaultMetadata: PlanMetadata = {
  name: '',
  description: '',
  prices: {
    [BillingCycle.Monthly]: 0,
    [BillingCycle.Yearly]: 0,
  },
  creditCardRequired: false,
  isCurrent: false,
  isMostPopular: false,
  features: {
    title: '',
    items: [],
  },
  actions: [],
  customBadgeText: null,
  crossedOutPrice: null,
  credits: {
    [BillingCycle.Monthly]: 0,
    [BillingCycle.Yearly]: 0,
  },
};

export const PlansMetadataContext = createContext<
  Record<NotLegacyPlanType, PlanMetadata>
>({
  [PlanType.Free]: defaultMetadata,
  [PlanType.Pro]: defaultMetadata,
  [PlanType.Unlimited]: defaultMetadata,
  [PlanType.Expand]: defaultMetadata,
});
