import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const ContentBox: React.FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => <Box className={clsx(styles.box, className)}>{children}</Box>;
