import { combineLatest } from 'rxjs';

import { useObservableResult } from '@/utils/rx';

import { useAccountCreditsUseCase } from './useAccountCreditsUseCase';

export const useAccountMonthlyCredits = (): {
  used: number;
  limit: number;
  expirationDate: number;
} => {
  const accountCreditsUseCase = useAccountCreditsUseCase();

  return useObservableResult(
    () => {
      return combineLatest({
        used: accountCreditsUseCase.getMonthlyUsedCredits(),
        limit: accountCreditsUseCase.getMonthlyCreditsLimit(),
        expirationDate: accountCreditsUseCase.getMonthlyCreditsExpirationDate(),
      });
    },
    {
      defaultData: {
        used: 0,
        limit: 0,
        expirationDate: 0,
      },
    },
  ).data;
};
