import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

import { IIntegrationApiService } from '../../domain';
import { IIntegrationDC, IIntegrationMapDC } from '../dataContracts';

const integrationEndpoints = {
  getMapping: (integrationName: string, objectType: string): string =>
    `integrations/get-mapping/${integrationName}/${objectType}`,
  saveMapping: (integrationName: string, objectType: string): string =>
    `integrations/save-mapping/${integrationName}/${objectType}`,
  updateEnabledObjects: (integrationName: string): string =>
    `integrations/update-enabled-objects/${integrationName}`,
  disconnectUser: (integrationName: string): string =>
    `integrations/disconnect-user/${integrationName}`,
  getIntegration: (integrationName: string): string =>
    `integrations/get-integration/${integrationName}`,
  exportItemsByIds: (integrationName: string, objectType: string): string =>
    `integrations/export-items/async/${integrationName}/${objectType}`,
};

@injectable()
export default class IntegrationApiService implements IIntegrationApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  exportByIds(integrationName: string, ids: string[]): Observable<{ task_id: string }> {
    return this.httpClient
      .post<{
        task_id: string;
      }>(integrationEndpoints.exportItemsByIds(integrationName, 'contact'), { ids })
      .pipe(map((response) => response.data));
  }

  getMapping(integrationName: string, objectType: string): Observable<IIntegrationMapDC> {
    return this.httpClient
      .get<IIntegrationMapDC>(
        integrationEndpoints.getMapping(integrationName, objectType),
      )
      .pipe(map((response) => response.data));
  }

  saveMapping(
    integrationName: string,
    objectType: string,
    data: IIntegrationMapDC['mapping'],
  ): Observable<unknown> {
    return this.httpClient.post(
      integrationEndpoints.saveMapping(integrationName, objectType),
      { mapping: data },
    );
  }

  updateSettings(
    integrationName: string,
    enabled_objects: string[],
  ): Observable<IIntegrationDC> {
    return this.httpClient
      .post(integrationEndpoints.updateEnabledObjects(integrationName), {
        enabled_objects,
      })
      .pipe(map((response: AxiosResponse<IIntegrationDC>) => response.data));
  }

  disconnectUser(integrationName: string): Observable<unknown> {
    return this.httpClient.post(integrationEndpoints.disconnectUser(integrationName), {});
  }

  getIntegration(integrationName: string): Observable<IIntegrationDC> {
    return this.httpClient
      .get<IIntegrationDC>(integrationEndpoints.getIntegration(integrationName))
      .pipe(map((response) => response.data));
  }
}
