import { IInvoiceDataEntity } from '@/features/paymentDetails/domain';

import { IInvoiceDataDC } from '../dataContracts';

export const mapInvoiceDataDcToEntity = (
  invoiceData: IInvoiceDataDC,
): IInvoiceDataEntity => ({
  created: invoiceData.created,
  total: invoiceData.total / 100,
  lines: invoiceData.lines.data.map((d) => ({
    amount: d.amount / 100,
    description: d.description,
    plan: {
      amount: d.plan.amount / 100,
    },
    quantity: d.quantity,
  })),
});
