import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { GetFreeCreditsDialogSubmitHandlers } from '@/features/getFreeCredits/ui/GetFreeCreditsDialog.types';

import styles from './styles.module.scss';

type Props = GetFreeCreditsDialogSubmitHandlers & {
  isFederated: boolean;
};

export const GetFreeCreditsFooter: FC<Props> = ({
  isFederated,
  onFederatedSubmit,
  onMicrosoftSubmit,
  onGoogleSubmit,
}) => {
  const { t } = useTranslation('dashboard');

  if (isFederated) {
    return (
      <Button
        variant="contained"
        color="secondary"
        className={styles.button}
        onClick={onFederatedSubmit}
      >
        <Typography variant="textAsButton">{t('getFreeCreditsDialog.button')}</Typography>
      </Button>
    );
  }

  return (
    <div className={styles.container}>
      <Button
        variant="contained"
        color="secondary"
        className={styles.button}
        onClick={onMicrosoftSubmit}
      >
        <Typography variant="textAsButton">
          {t('getFreeCreditsDialog.buttonMicrosoft')}
        </Typography>
      </Button>
      <Button
        variant="contained"
        color="secondary"
        className={styles.button}
        onClick={onGoogleSubmit}
      >
        <Typography variant="textAsButton">
          {t('getFreeCreditsDialog.buttonGoogle')}
        </Typography>
      </Button>
    </div>
  );
};
