import React from 'react';
import { useNavigate } from 'react-router-dom';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { useAuthUseCase } from '@/features/common/auth';

import { useUserMenuHandlers } from '@/components/header/hooks';

import { openInNewTab } from '@/utils/openInNewTab';

import { Helpers } from '../../utils/Helpers';

type Props = {
  fullName: string;
};

interface UseUserMenuViewModelResult {
  isUserMenuOpen: boolean;
  anchorEl: HTMLElement | null;
  initials: string;
  onOpen: (e: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  handleInviteTeamMember: () => void;
  navigateToHelpCenter: () => void;
  onLogout: () => void;
}

export const useUserMenuViewModel = ({ fullName }: Props): UseUserMenuViewModelResult => {
  const navigate = useNavigate();
  const initials = Helpers.getInitials(fullName);
  const { isUserMenuOpen, anchorEl, onOpen, onClose } = useUserMenuHandlers();
  const authUseCase = useAuthUseCase();

  const onLogout = (): void => {
    authUseCase.signOut().subscribe();
  };

  const handleInviteTeamMember = (): void => {
    onClose();
    navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT, {
      state: { isInviteTeamMemberModalOpen: true },
    });
  };

  const navigateToHelpCenter = (): void => {
    openInNewTab(EXTERNAL_ROUTES.HELP_CENTER);
  };

  return {
    onLogout,
    isUserMenuOpen,
    anchorEl,
    onOpen,
    onClose,
    initials,
    handleInviteTeamMember,
    navigateToHelpCenter,
  };
};
