import { inject, injectable } from 'inversify';
import { firstValueFrom, map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

import type IIntegrationsApiService from '../../domain/abstractions/IIntegrationsApiService';
import type { IIntegrationProviderDC, IIntegrationStatusDC } from '../dataContracts';

const integrationEndpoints = {
  requestIntegration: 'account/request-integration',
  getIntegrations: 'integrations/get-integrations',
  getAuthUrl: (provider: string): string =>
    `integrations/get-authorization-url/${provider}`,
  connectUser: (provider: string): string => `integrations/connect-user/${provider}`,
  getMapping: (provider: string, objectType: string): string =>
    `integrations/get-mapping/${provider}/${objectType}`,
  saveMapping: (provider: string, objectType: string): string =>
    `integrations/save-mapping/${provider}/${objectType}`,
  exportItems: (provider: string, objectType: string): string =>
    `integrations/export-items/async/${provider}/${objectType}`,
  sendRequestToAdminForIntegration: (provider: string): string =>
    `integrations/email-connection-request/${provider}`,
};

@injectable()
export default class IntegrationsApiService implements IIntegrationsApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  sendRequest(params: { title: string; message: string }): Promise<void> {
    return firstValueFrom(
      this.httpClient
        .post<void>(integrationEndpoints.requestIntegration, params)
        .pipe(map(({ data }) => data)),
    );
  }

  connectUser(provider: string, code: string): Promise<{ user_email: string }> {
    return firstValueFrom(
      this.httpClient
        .post<{ user_email: string }>(integrationEndpoints.connectUser(provider), {
          code,
        })
        .pipe(map(({ data }) => data)),
    );
  }

  sendRequestToAdminForIntegration(provider: string): Promise<string> {
    return firstValueFrom(
      this.httpClient
        .post<string>(integrationEndpoints.sendRequestToAdminForIntegration(provider), {})
        .pipe(map(({ data }) => data)),
    );
  }
  getIntegrations(): Observable<{
    integrations: IIntegrationStatusDC[];
    providers: IIntegrationProviderDC[];
  }> {
    return this.httpClient
      .get<{
        integrations: IIntegrationStatusDC[];
        providers: IIntegrationProviderDC[];
      }>(integrationEndpoints.getIntegrations)
      .pipe(map(({ data }) => data));
  }

  getAuthUrl(provider: string): Observable<{ url: string }> {
    return this.httpClient
      .get<{ url: string }>(integrationEndpoints.getAuthUrl(provider))
      .pipe(map(({ data }) => data));
  }
}
