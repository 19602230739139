import { FC, Fragment, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { Modal, Preloader } from '@/components';

import { useCancelSubscriptionViewModel } from './useCancelSubscriptionViewModel';

import styles from './styles.module.scss';

type CancelSubscriptionDialogProps = {
  isOpened: boolean;
  onClose: () => void;
};

export const CancelSubscriptionDialog: FC<CancelSubscriptionDialogProps> = ({
  isOpened,
  onClose,
}) => {
  const { isProcessing, submit } = useCancelSubscriptionViewModel({ onClose });
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    isOpened && trackEvent(ANALYTICS_EVENTS.CANCEL_SUBSCRIPTION_CLICK);
  }, [isOpened]);

  return (
    <Modal
      open={isOpened}
      onClose={isProcessing ? undefined : onClose}
      containerClass={styles.modal}
    >
      <Fragment>
        <Typography className={styles.modalHeader}>Cancel subscription</Typography>
        <Typography className={styles.modalBody}>
          Are you sure you want to cancel your subscription?
        </Typography>
        <Box className={styles.modalFooter}>
          <Button
            variant="text"
            color="info"
            className={styles.buttonCancel}
            onClick={onClose}
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            className={styles.buttonSubmit}
            onClick={submit}
            disabled={isProcessing}
          >
            {isProcessing ? <Preloader /> : 'Cancel Subscription'}
          </Button>
        </Box>
      </Fragment>
    </Modal>
  );
};
