import { FC } from 'react';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';

export const SubscriptionType: FC<{ row: { type: string } }> = ({ row: { type } }) => {
  return (
    <Typography
      variant="inherit"
      color="info"
      className={styles.subscriptionType}
      title={type}
    >
      {type}
    </Typography>
  );
};
