import { debounce, memoize } from 'lodash';
import { firstValueFrom } from 'rxjs';

import { container } from '@/ioc/container';
import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

import { EmailValidationSchema } from './EmailValidationSchema';

export const validateWorkEmail = async (email: string): Promise<boolean> => {
  const httpClient = container.get<IHttpClient>(NETWORK_TYPES.HttpClient);
  const response$ = httpClient.get<boolean>(`account/validate-email?email=${email}`);
  const { data: isValid } = await firstValueFrom(response$);
  return isValid;
};

const debouncedValidateWorkEmail = debounce(memoize(validateWorkEmail), 300, {
  leading: true,
});

export const WorkEmailValidationSchema = EmailValidationSchema.test(
  'bannedEmail',
  'workEmailInvalid',
  (email) => {
    return debouncedValidateWorkEmail(email);
  },
);
