import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BillingCycle, PlanType } from '@/features/common/billing';

import {
  AnalyticsIcon,
  HelpCenterIcon,
  HubIcon,
  KeyIcon,
  PageInfoIcon,
  PersonIcon,
  VerifiedUserIcon,
} from '@/components/Icons';

import { PlanFeatureCategory, PlanFeaturesMetadata } from '../types';
import {
  billingCycleDependent,
  comingSoon,
  fullySupported,
  limittedByQuantity,
  unlimitted,
  unsupported,
} from '../utils';

function getFeatureCategoryTitle(category: PlanFeatureCategory): string {
  return `features.categories.${category}`;
}

function getFeatureTitle(feature: string): string {
  return `features.${feature}.title`;
}

function getFeatureHint(feature: string): string {
  return `features.${feature}.hint`;
}

const PLAN_FEATURES_METADATA: PlanFeaturesMetadata = {
  [PlanFeatureCategory.General]: {
    title: getFeatureCategoryTitle(PlanFeatureCategory.General),
    icon: <PageInfoIcon />,
    features: [
      {
        title: getFeatureTitle('amountOfCredits'),
        hint: getFeatureHint('amountOfCredits'),
        supportByPlan: {
          [PlanType.Free]: billingCycleDependent({
            [BillingCycle.Monthly]: limittedByQuantity({ limit: 10 }),
            [BillingCycle.Yearly]: limittedByQuantity({ limit: 10 }),
          }),
          [PlanType.Pro]: billingCycleDependent({
            [BillingCycle.Monthly]: limittedByQuantity({ limit: 90 }),
            [BillingCycle.Yearly]: limittedByQuantity({ limit: 1080 }),
          }),
          [PlanType.Unlimited]: billingCycleDependent({
            [BillingCycle.Monthly]: unlimitted(),
            [BillingCycle.Yearly]: unlimitted(),
          }),
          [PlanType.Expand]: billingCycleDependent({
            [BillingCycle.Monthly]: unlimitted(),
            [BillingCycle.Yearly]: unlimitted(),
          }),
        },
      },
      {
        title: getFeatureTitle('exportCredits'),
        hint: getFeatureHint('exportCredits'),
        supportByPlan: {
          [PlanType.Free]: unlimitted(),
          [PlanType.Pro]: unlimitted(),
          [PlanType.Unlimited]: unlimitted(),
          [PlanType.Expand]: unlimitted(),
        },
      },
      {
        title: getFeatureTitle('amountOfUsers'),
        hint: getFeatureHint('amountOfUsers'),
        supportByPlan: {
          [PlanType.Free]: limittedByQuantity({
            limit: 1,
          }),
          [PlanType.Pro]: unlimitted(),
          [PlanType.Unlimited]: unlimitted(),
          [PlanType.Expand]: unlimitted(),
        },
      },
      {
        title: getFeatureTitle('linkedinExt'),
        hint: getFeatureHint('linkedinExt'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('universalExt'),
        hint: getFeatureHint('universalExt'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('dashboard'),
        hint: getFeatureHint('dashboard'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('topUpCredits'),
        hint: getFeatureHint('topUpCredits'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: unlimitted(),
          [PlanType.Expand]: unlimitted(),
        },
      },
      {
        title: getFeatureTitle('bulkSave'),
        hint: getFeatureHint('bulkSave'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('enrichCsv'),
        hint: getFeatureHint('enrichCsv'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
    ],
  },
  [PlanFeatureCategory.ContactManagement]: {
    title: getFeatureCategoryTitle(PlanFeatureCategory.ContactManagement),
    icon: <PersonIcon />,
    features: [
      {
        title: getFeatureTitle('listManagement'),
        hint: getFeatureHint('listManagement'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('tagManagement'),
        hint: getFeatureHint('tagManagement'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('exportToCsv'),
        hint: getFeatureHint('exportToCsv'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('saveToCrm'),
        hint: getFeatureHint('saveToCrm'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('saveToSalesEngagementTool'),
        hint: getFeatureHint('saveToSalesEngagementTool'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
    ],
  },
  [PlanFeatureCategory.CrmIntegrations]: {
    title: getFeatureCategoryTitle(PlanFeatureCategory.CrmIntegrations),
    icon: <HubIcon />,
    features: [
      {
        title: getFeatureTitle('salesforce'),
        hint: getFeatureHint('salesforce'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('hubspot'),
        hint: getFeatureHint('hubspot'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('zohoCrm'),
        hint: getFeatureHint('zohoCrm'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: comingSoon(),
          [PlanType.Expand]: comingSoon(),
        },
      },
      {
        title: getFeatureTitle('pipedrive'),
        hint: getFeatureHint('pipedrive'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: comingSoon(),
          [PlanType.Expand]: comingSoon(),
        },
      },
      {
        title: getFeatureTitle('zapier'),
        hint: getFeatureHint('zapier'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: comingSoon(),
          [PlanType.Expand]: comingSoon(),
        },
      },
      {
        title: getFeatureTitle('outreach'),
        hint: getFeatureHint('outreach'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: comingSoon(),
          [PlanType.Expand]: comingSoon(),
        },
      },
      {
        title: getFeatureTitle('salesloft'),
        hint: getFeatureHint('salesloft'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: comingSoon(),
          [PlanType.Expand]: comingSoon(),
        },
      },
      {
        title: getFeatureTitle('customIntegration'),
        hint: getFeatureHint('customIntegration'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
    ],
  },
  [PlanFeatureCategory.Analytics]: {
    title: getFeatureCategoryTitle(PlanFeatureCategory.Analytics),
    icon: <AnalyticsIcon />,
    features: [
      {
        title: getFeatureTitle('usageAnalytics'),
        hint: getFeatureHint('usageAnalytics'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('teamUsageAnalytics'),
        hint: getFeatureHint('teamUsageAnalytics'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
    ],
  },
  [PlanFeatureCategory.Compliance]: {
    title: getFeatureCategoryTitle(PlanFeatureCategory.Compliance),
    icon: <VerifiedUserIcon />,
    features: [
      {
        title: getFeatureTitle('gdprCompliant'),
        hint: getFeatureHint('gdprCompliant'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('ccpaCompliant'),
        hint: getFeatureHint('ccpaCompliant'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
    ],
  },
  [PlanFeatureCategory.SSO]: {
    title: getFeatureCategoryTitle(PlanFeatureCategory.SSO),
    icon: <KeyIcon />,
    features: [
      {
        title: getFeatureTitle('google'),
        hint: getFeatureHint('google'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('microsoft365'),
        hint: getFeatureHint('microsoft365'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('okta'),
        hint: getFeatureHint('okta'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: unsupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('saml'),
        hint: getFeatureHint('saml'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: unsupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
    ],
  },
  [PlanFeatureCategory.Support]: {
    title: getFeatureCategoryTitle(PlanFeatureCategory.Support),
    icon: <HelpCenterIcon />,
    features: [
      {
        title: getFeatureTitle('helpCenter'),
        hint: getFeatureHint('helpCenter'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('emailSupport'),
        hint: getFeatureHint('emailSupport'),
        supportByPlan: {
          [PlanType.Free]: fullySupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('liveChat'),
        hint: getFeatureHint('liveChat'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: fullySupported(),
          [PlanType.Unlimited]: fullySupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('onboarding'),
        hint: getFeatureHint('onboarding'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: unsupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
      {
        title: getFeatureTitle('dedicatedSupport'),
        hint: getFeatureHint('dedicatedSupport'),
        supportByPlan: {
          [PlanType.Free]: unsupported(),
          [PlanType.Pro]: unsupported(),
          [PlanType.Unlimited]: unsupported(),
          [PlanType.Expand]: fullySupported(),
        },
      },
    ],
  },
};

export function usePlanFeaturesMetadata(): PlanFeaturesMetadata {
  const {
    t,
    i18n: { language },
  } = useTranslation('plans');

  return useMemo(() => {
    return Object.entries(PLAN_FEATURES_METADATA).reduce((acc, [category, metadata]) => {
      // icon can not be structured cloned
      const { icon, ...restOfMetadata } = metadata;
      const featureCategoryMetadata: PlanFeaturesMetadata[PlanFeatureCategory] = {
        ...structuredClone(restOfMetadata),
        icon,
      };
      featureCategoryMetadata.title = t(featureCategoryMetadata.title);
      featureCategoryMetadata.features = featureCategoryMetadata.features.map(
        (feature) => ({
          ...feature,
          title: t(feature.title),
          hint: t(feature.hint),
        }),
      );

      acc[category] = featureCategoryMetadata;

      return acc;
    }, {} as PlanFeaturesMetadata);
  }, [t, language]);
}
