import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { CONTACT_BY_FILTERS_TYPES } from '@/ioc/types';

import { IContactByFiltersEntity, IContactByFiltersRepository } from '../domain';

import { ISyncEventDC } from './dataContracts';
import { IContactByFiltersDao } from './db';
import { mapContactByFiltersDcToEntity, mapContactByFiltersEntityToDc } from './mappers';

@injectable()
export class ContactByFiltersRepository implements IContactByFiltersRepository {
  @inject(CONTACT_BY_FILTERS_TYPES.ContactByFiltersDao)
  private readonly contactByFiltersDao: IContactByFiltersDao;

  public syncUpdate({
    autoUpdate,
    isUpdate,
    event,
    queryParams,
  }: {
    autoUpdate: boolean;
    isUpdate: boolean;
    queryParams: string;
    event: ISyncEventDC;
  }): Observable<IContactByFiltersEntity> {
    const pingAt = Math.floor(Date.now() / 1000);

    if (!isUpdate || autoUpdate) {
      return this.contactByFiltersDao
        .upsert({
          contact_by_filters_ids_latest: event.contacts.map((contact) => contact.uuid),
          contact_by_filters_ids_current: event.contacts.map((contact) => contact.uuid),
          company_by_filters_ids_latest: Object.keys(event.companies),
          company_by_filters_ids_current: Object.keys(event.companies),
          contact_info_count_current: event.contact_info_count,
          contact_info_count_latest: event.contact_info_count,
          count_current: event.count,
          count_latest: event.count,
          uuid: queryParams,
          ping_at: pingAt,
        })
        .pipe(map((response) => mapContactByFiltersDcToEntity(response)));
    } else {
      return this.contactByFiltersDao
        .updateOne(queryParams, {
          contact_by_filters_ids_latest: event.contacts.map((contact) => contact.uuid),
          company_by_filters_ids_latest: Object.keys(event.companies),
          contact_info_count_latest: event.contact_info_count,
          count_latest: event.count,
          ping_at: pingAt,
        })
        .pipe(map((response) => mapContactByFiltersDcToEntity(response)));
    }
  }

  public update(payload: IContactByFiltersEntity): Observable<IContactByFiltersEntity> {
    return this.contactByFiltersDao
      .updateOne(payload.uuid, mapContactByFiltersEntityToDc(payload))
      .pipe(map((response) => mapContactByFiltersDcToEntity(response)));
  }

  public getById(uuid: string): Observable<IContactByFiltersEntity | null> {
    return this.contactByFiltersDao.findById(uuid).pipe(
      map((response) => {
        if (!response) return null;
        return mapContactByFiltersDcToEntity(response);
      }),
    );
  }
}
