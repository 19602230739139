import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import { ROUTES } from '@/router/routes';

import { GetFreeCreditsDialog } from '@/features/getFreeCredits';
import { FeatureEnabled } from '@/features/system/featureFlag';

import { useModalController } from '@/hooks';

import Logo from '@/assets/icons/headerLogo.svg?react';
import LogoCropped from '@/assets/icons/headerLogoCropped.svg?react';

import { HeaderCreditsScore } from './components/Credits/HeaderCreditsScore';
import { UserMenuContainer } from './components/UserMenu/UserMenuContainer';
import { HeaderGuideContainer } from './components';
import { useHeaderViewModel } from './HeaderViewModel';

import styles from './styles.module.scss';

export const EmptyHeader: FC = () => {
  return (
    <header className={styles.wrapper}>
      <div className={styles.leftSide}>
        <NavLink to={ROUTES.DASHBOARD}>
          <div className={styles.logo}>
            <Logo />
          </div>
        </NavLink>
      </div>
    </header>
  );
};

export const Header: FC = () => {
  const { t } = useTranslation(['common', 'plans']);

  const {
    userData: {
      planType,
      userLogo,
      fullName,
      email,
      creditsUsed,
      creditsLimit,
      hasUserReceivedFreeCredits,
      role,
    },
    navigateToSettings,
    navigateToTeamManagement,
    navigateToSubscription,
    navigateToTagManagement,
    navigateToReferAFriend,
  } = useHeaderViewModel();
  const getFreeCreditsModal = useModalController('GetFreeCreditsModal');

  return (
    <header className={styles.wrapper}>
      <div className={styles.leftSide}>
        <NavLink to={ROUTES.DASHBOARD}>
          <div className={styles.logo}>
            <Logo />
            <LogoCropped />
          </div>
        </NavLink>

        <Box mr={1} />
        <Box className={styles.badgeFree}>
          <Typography variant="h3" fontWeight={500} lineHeight={1}>
            {t(`plans.${planType}.name`, { ns: 'plans' })}
          </Typography>
        </Box>

        <Box className={styles.leftDivider} />

        <Box className={styles.headerVerticalDivider} mr={3.75} />

        <nav className={styles.navigation}>
          <NavLink to={ROUTES.DASHBOARD}>
            <Typography variant="subtitle1" fontWeight={500}>
              {t('header.nav.dashboard')}
            </Typography>
          </NavLink>
          <NavLink to={ROUTES.CONTACTS_ENTRY}>
            <Typography variant="subtitle1" fontWeight={500}>
              {t('header.nav.savedContacts')}
            </Typography>
          </NavLink>
          <FeatureEnabled featureName="FEATURE_ENRICHMENT">
            <NavLink to={ROUTES.ENRICHMENT_ENTRY}>
              <Typography variant="subtitle1" fontWeight={500}>
                {t('header.nav.enrichment')}
              </Typography>
            </NavLink>
          </FeatureEnabled>
          <NavLink to={ROUTES.INTEGRATIONS}>
            <Typography variant="subtitle1" fontWeight={500}>
              {t('header.nav.integrations')}
            </Typography>
          </NavLink>
        </nav>
      </div>

      <div className={styles.rightSide}>
        <HeaderGuideContainer />
        <HeaderCreditsScore
          planType={planType}
          creditsUsed={creditsUsed}
          creditsLimit={creditsLimit}
        />
        <Box className={clsx(styles.headerVerticalDivider, styles.dividerUserMenu)} />
        <UserMenuContainer
          role={role}
          fullName={fullName}
          email={email}
          planType={planType}
          userLogo={userLogo}
          creditsUsed={creditsUsed}
          creditsLimit={creditsLimit}
          hasUserReceivedFreeCredits={hasUserReceivedFreeCredits}
          navigateToSettings={navigateToSettings}
          navigateToTeamManagement={navigateToTeamManagement}
          navigateToSubscription={navigateToSubscription}
          navigateToTagManagement={navigateToTagManagement}
          onGetFreeCreditsClick={getFreeCreditsModal.onGetFreeCreditsModalOpen}
          onReferFriendClick={navigateToReferAFriend}
        />
      </div>
      <GetFreeCreditsDialog
        open={getFreeCreditsModal.isGetFreeCreditsModalOpen}
        onClose={getFreeCreditsModal.onGetFreeCreditsModalClose}
      />
    </header>
  );
};
