import { BaseSyntheticEvent, useContext } from 'react';
import { type RegisterOptions, useForm, type UseFormReturn } from 'react-hook-form';
import { StripeCardElement } from '@stripe/stripe-js';

import {
  type IBillingDetailsEntity,
  type IPaymentMethodEntity,
  useBillingDetailsValidations,
  usePaymentCard,
} from '@/features/common/billing';

import { PaymentDetailsContext } from '../../contexts/PaymentDetailsContext';
import { PaymentFailedReason, usePaymentDetailsAnalytic } from '../../hooks';

export type PaymentFormValues = IBillingDetailsEntity & Partial<IPaymentMethodEntity>;

type PaymentDetailsValidations = {
  [Key in keyof IBillingDetailsEntity]: RegisterOptions<IBillingDetailsEntity, Key>;
};

type UsePaymentFormViewModel = (
  onSubmit: (data: PaymentFormValues, card?: StripeCardElement | null) => Promise<void>,
) => {
  form: UseFormReturn<PaymentFormValues>;
  validations: PaymentDetailsValidations;
  cardError?: string;
  handleSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
};

export const usePaymentFormViewModel: UsePaymentFormViewModel = (onSubmit) => {
  const { billingDetails, paymentMethod, account } = useContext(PaymentDetailsContext);

  const analytic = usePaymentDetailsAnalytic();
  const shouldValidateCard = !paymentMethod?.card;

  const form = useForm<PaymentFormValues>({
    defaultValues: {
      name: billingDetails?.name || '',
      email: billingDetails?.email || '',
      address: billingDetails?.address || '',
      city: billingDetails?.city || '',
      state: billingDetails?.state || '',
      postalCode: billingDetails?.postalCode || '',
      country: billingDetails?.country || account.settings.country || '',
      company: billingDetails?.company || '',
      vatId: billingDetails?.vatId || '',
      id: paymentMethod?.id,
      card: paymentMethod?.card,
    },
  });

  const {
    card,
    error: cardError,
    validate: validateCard,
  } = usePaymentCard({
    canShowError: form.formState.isSubmitted && shouldValidateCard,
    onError: () => {
      analytic.paymentFailed(PaymentFailedReason.CardDetails);
    },
  });

  const validations = useBillingDetailsValidations();

  const handleSubmit = form.handleSubmit(
    async (values) => {
      if (shouldValidateCard) {
        try {
          await validateCard();
        } catch {
          return;
        }
      }

      await onSubmit(values, card);
    },
    async () => {
      if (shouldValidateCard) {
        await validateCard();
      }
      if (!form.formState.isValid) {
        analytic.paymentFailed(PaymentFailedReason.EmptyFields);
      }
    },
  );

  return {
    form,
    validations,
    cardError,
    handleSubmit,
  };
};
