import { RegisterOptions, ValidationRule } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IBillingDetailsEntityUI } from '../entities';

export function useBillingDetailsValidations(): {
  [Key in keyof IBillingDetailsEntityUI]: RegisterOptions<IBillingDetailsEntityUI, Key>;
} {
  const { t } = useTranslation('billing', { keyPrefix: 'validations' });

  const getMinLengthValidation = (value: number): ValidationRule<number> => ({
    message: t('common.minLength', { value }),
    value,
  });
  const getMaxLengthValidation = (value: number): ValidationRule<number> => ({
    message: t('common.maxLength', { value }),
    value,
  });

  return {
    name: {
      required: t('name.required'),
      pattern: {
        value: /^([\p{L}]{2,})+\s+([\p{L}\s]{2,})+$/iu,
        message: t('name.incorrect'),
      },
      maxLength: getMaxLengthValidation(200),
    },
    email: {
      required: t('email.required'),
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: t('email.incorrect'),
      },
    },
    phone: {
      required: t('phone.required'),
      pattern: {
        value: /^[\w.\-+*#\\/()]+$/,
        message: t('phone.incorrect'),
      },
      minLength: getMinLengthValidation(2),
      maxLength: getMaxLengthValidation(36),
    },
    country: {
      required: t('country.required'),
    },
    state: {
      shouldUnregister: true,
    },
    city: {
      required: t('city.required'),
      minLength: getMinLengthValidation(2),
      maxLength: getMaxLengthValidation(42),
    },
    address: {
      required: t('address.required'),
      maxLength: getMaxLengthValidation(100),
    },
    company: {},
    postalCode: {
      required: t('postalCode.required'),
      maxLength: getMaxLengthValidation(20),
    },
    vatId: {
      shouldUnregister: true,
    },
  };
}
