import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { firstValueFrom } from 'rxjs';

import { useOnboardingUseCase } from '@/features/common/onboarding';
import { IReferralResponseDataEntity } from '@/features/referral/domain';
import { useReferralUseCase } from '@/features/referral/ui/hooks/useReferralUseCase';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { useObservableResult } from '@/utils/rx';
import { EmailValidationSchema, useFormWithSchemaBuilder } from '@/utils/validation';

type UseInviteByEmailViewModel = () => {
  emails: string[];
  handleEmailsChange: (emails: string[]) => void;
  handleSubmit: () => void;
  hasEmailError: (email: string) => boolean;
  disabled: boolean;
  processing: boolean;
  lastResponse: IReferralResponseDataEntity[];
};

type FormValues = {
  emails: string[];
};

const useLastResponse = (): [
  IReferralResponseDataEntity[],
  (response: IReferralResponseDataEntity[]) => void,
] => {
  const [lastResponse, setLastResponse] = useState<IReferralResponseDataEntity[]>([]);
  const timeoutRef = useRef<number | null>(null);

  const handleSetLastResponse = (response: IReferralResponseDataEntity[]): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setLastResponse(response);

    timeoutRef.current = window.setTimeout(() => {
      setLastResponse([]);
    }, 10_000);
  };

  return [lastResponse, handleSetLastResponse];
};

export const useInviteByEmailViewModel: UseInviteByEmailViewModel = () => {
  const { t } = useTranslation('referral');
  const { enqueueSnackbar } = useSnackbar();
  const logger = useAppLogger();
  const analytics = useAnalytics();
  const referralUseCase = useReferralUseCase();
  const onboardingUseCase = useOnboardingUseCase();
  const [lastResponse, setLastResponse] = useLastResponse();
  const { data: invitations } = useObservableResult(
    () => referralUseCase.getInvitations(),
    {
      deps: [],
      defaultData: [],
    },
  );

  const form = useFormWithSchemaBuilder(
    (builder) => {
      return builder.object().shape({
        emails: builder
          .array()
          .of(
            EmailValidationSchema.test({
              name: 'invitations',
              test: (email) => {
                return !invitations.some(({ inviteeEmail }) => inviteeEmail === email);
              },
            }),
          )
          .required(),
      });
    },
    {
      defaultValues: {
        emails: [],
      } satisfies FormValues,
      mode: 'all',
      reValidateMode: 'onChange',
    },
  );

  const handleEmailsChange = (emails: string[]): void => {
    form.setValue('emails', emails, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const emails = form.watch('emails');

  const emailsIndexMap = new Map<string, number>(
    emails.map((email, index) => [email, index]),
  );

  const hasEmailError = (email: string): boolean => {
    const formIndex = emailsIndexMap.get(email);
    // eslint-disable-next-line
    return !!form.formState.errors?.emails?.[formIndex!];
  };

  const handleSubmit = form.handleSubmit(async ({ emails }) => {
    try {
      setLastResponse([]);

      const result = await firstValueFrom(
        referralUseCase.sendInvitationEmail(emails.map((email) => ({ email }))),
      );

      setLastResponse(result);

      result.forEach(({ error }) => {
        if (!error) {
          analytics.trackEvent(ANALYTICS_EVENTS.SEND_REFERRAL_EMAIL);
          onboardingUseCase.completeInviteTeamMemberStep();
        }
      });

      form.reset({ emails: [] });
    } catch (error) {
      logger.error(error);
      enqueueSnackbar({
        variant: 'error',
        message: t('inviteSection.emailInput.networkError'),
      });
    }
  });

  return {
    emails,
    handleEmailsChange,
    handleSubmit,
    hasEmailError,
    processing: form.formState.isSubmitting,
    disabled:
      form.formState.isSubmitting || !form.formState.isValid || emails.length === 0,
    lastResponse,
  };
};
