import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IWorkspaceSubscriptionDC } from '@/features/common/workspace';
import { IHttpClient } from '@/features/system/network';

import { IBillingApiService } from '../abstractions/IBillingApiService';
import {
  IBillingDetailsDC,
  IBillingInvoiceDC,
  IPaymentMethodDC,
  IPaymentMethodUpdateSessionDC,
  IStripePromotionCodeDC,
  IStripeSubscriptionDC,
} from '../dataContracts';

@injectable()
export class BillingApiService implements IBillingApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private readonly httpClient: IHttpClient;

  private buildUrl(path: string): string {
    return `/billing-service${path}`;
  }

  getBillingInfo(): Observable<IBillingDetailsDC> {
    return this.httpClient
      .get<IBillingDetailsDC>(this.buildUrl('/accounts/my'))
      .pipe(map(({ data }) => data));
  }

  updateBillingInfo(billingInfo: IBillingDetailsDC): Observable<IBillingDetailsDC> {
    return this.httpClient
      .post<IBillingDetailsDC>(this.buildUrl('/stripe/billing-info'), billingInfo)
      .pipe(map(({ data }) => data));
  }

  initSubscription(params: {
    label: string;
    quantity: number;
    promo_code?: string;
    is_canceled?: boolean;
  }): Observable<IStripeSubscriptionDC> {
    return this.httpClient
      .post<IStripeSubscriptionDC>(this.buildUrl('/stripe/init-subscription'), params)
      .pipe(map(({ data }) => data));
  }

  getInvoices(): Observable<IBillingInvoiceDC[]> {
    return this.httpClient
      .get<IBillingInvoiceDC[]>(this.buildUrl('/stripe/invoices'))
      .pipe(map(({ data }) => data));
  }

  updateWorkspaceSubscription(params: {
    workspace: { uuid: string };
    subscription: Pick<
      Partial<IWorkspaceSubscriptionDC>,
      'plan' | 'is_canceled' | 'billing_details_filled' | 'promo_code_id'
    >;
  }): Observable<boolean> {
    return this.httpClient
      .patch(`/workspaces/${params.workspace.uuid}/subscription`, params.subscription)
      .pipe(map(() => true));
  }

  getPaymentMethod(): Observable<IPaymentMethodDC> {
    return this.httpClient
      .get<IPaymentMethodDC>(this.buildUrl('/stripe/payment-method'))
      .pipe(map(({ data }) => data));
  }

  updatePaymentMethod(params: {
    successUrl: string;
    cancelUrl: string;
  }): Observable<IPaymentMethodUpdateSessionDC> {
    return this.httpClient
      .post<IPaymentMethodUpdateSessionDC>(
        this.buildUrl('/stripe/payment-method-setup-session'),
        {
          success_url: params.successUrl,
          cancel_url: params.cancelUrl,
        },
      )
      .pipe(map(({ data }) => data));
  }

  getPromoCodeInfo(params: {
    code: string;
    label: string;
  }): Observable<IStripePromotionCodeDC> {
    const searchParams = new URLSearchParams();
    searchParams.set('code', params.code);
    searchParams.set('label', params.label);

    return this.httpClient
      .get<IStripePromotionCodeDC>(
        `${this.buildUrl('/stripe/promo-code-info')}?${searchParams.toString()}`,
      )
      .pipe(map(({ data }) => data));
  }
}
