import { injectable } from 'inversify';

import { DbState, IDbState } from '@/features/system/db/data/DbState';

import { IBillingDetailsDC, IBillingInvoiceDC } from '../dataContracts';

export interface IBillingStateDC {
  billingDetails: IBillingDetailsDC;
  invoices: IBillingInvoiceDC[];
}

export type IBillingState = IDbState<IBillingStateDC>;

@injectable()
export class BillingState extends DbState<IBillingStateDC> implements IBillingState {
  constructor() {
    super({ stateName: 'billing' });
  }
}
