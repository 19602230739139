export enum PlanType {
  Free = 'Free',
  ProLegacyV0 = 'Pro', // Legacy
  ProLegacyV1 = 'ProFixed_legacy', // Legacy
  Pro = 'ProV2',
  AdvancedLegacy = 'Advanced', // Legacy
  Growth = 'Growth', // Legacy
  Ultimate = 'Ultimate', // Legacy
  Scale = 'Scale', // Legacy
  Unlimited = 'Unlimited',
  Expand = 'Expand',
}

export type NotLegacyPlanType =
  | PlanType.Free
  | PlanType.Pro
  | PlanType.Unlimited
  | PlanType.Expand;

const PLAN_TYPE_ORDER_RATE = {
  [PlanType.Free]: 0,
  [PlanType.ProLegacyV0]: 1,
  [PlanType.Pro]: 1,
  [PlanType.AdvancedLegacy]: 2,
  [PlanType.Growth]: 2,
  [PlanType.Unlimited]: 2,
  [PlanType.Ultimate]: 3,
  [PlanType.Expand]: 3,
};

export const isPlanTypeLowerThen = (
  planType: PlanType,
  anotherPlan: PlanType,
): boolean => {
  return PLAN_TYPE_ORDER_RATE[planType] < PLAN_TYPE_ORDER_RATE[anotherPlan];
};

export const isPlanTypeHigherThen = (
  planType: PlanType,
  anotherPlan: PlanType,
): boolean => {
  return PLAN_TYPE_ORDER_RATE[planType] > PLAN_TYPE_ORDER_RATE[anotherPlan];
};

export const isPlanTypeHigherOrEqualThen = (
  planType: PlanType,
  anotherPlan: PlanType,
): boolean => {
  return PLAN_TYPE_ORDER_RATE[planType] >= PLAN_TYPE_ORDER_RATE[anotherPlan];
};
