import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IBillingDetailsEntity } from '../../domain';

import { useBillingUseCase } from './useBillingUseCase';

export function useGetBillingDetails(): ObservableResult<IBillingDetailsEntity> {
  const billingUseCase = useBillingUseCase();

  return useObservableResult(() => billingUseCase.getBillingDetails(), {
    deps: [],
    cacheKey: 'billingDetails',
  });
}
