import { FC } from 'react';

import PaymentLoader from './components/PaymentLoader';
import {
  PaymentDetailsLayout,
  PaymentForm,
  PaymentGuarantee,
  PaymentSummary,
} from './components';
import { usePaymentDetailsViewModel } from './PaymentDetailsViewModel';

export const PaymentDetails: FC = () => {
  const { summary, isProcessing, onSubmit } = usePaymentDetailsViewModel();

  return (
    <PaymentDetailsLayout>
      {isProcessing ? <PaymentLoader /> : null}
      <PaymentForm isProcessing={isProcessing} onSubmit={onSubmit} />
      <PaymentSummary summary={summary} isProcessing={isProcessing} />
      <PaymentGuarantee />
    </PaymentDetailsLayout>
  );
};
