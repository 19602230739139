import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import { SubscriptionPlan } from '@/features/common/workspace';

import { IInvoiceDataEntity } from '../domain';

import { IPaymentDetailsApiService, IPaymentDetailsRepository } from './abstractions';
import { mapInvoiceDataDcToEntity } from './mappers';

@injectable()
export class PaymentDetailsRepository implements IPaymentDetailsRepository {
  @inject(PAYMENT_DETAILS_TYPES.PaymentDetailsApiService)
  private api: IPaymentDetailsApiService;

  getInvoiceData(plan: SubscriptionPlan): Observable<IInvoiceDataEntity> {
    return this.api.getInvoiceData(plan).pipe(map((dc) => mapInvoiceDataDcToEntity(dc)));
  }
}
