import { FC } from 'react';

import { PlanType } from '@/features/common/billing';

import { LimitedCredits } from './LimitedCredits';

type HeaderCreditsScoreProps = {
  planType: PlanType;
  creditsUsed: number;
  creditsLimit: number;
};

export const HeaderCreditsScore: FC<HeaderCreditsScoreProps> = ({
  planType,
  creditsUsed,
  creditsLimit,
}) => {
  return planType === PlanType.Free ? (
    <LimitedCredits creditsUsed={creditsUsed} creditsLimit={creditsLimit} />
  ) : null;
};
