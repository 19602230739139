import { IStripeCouponEntity, StripeCouponDuration } from '../../domain';
import { IStripeCouponDC } from '../dataContracts';

export function mapStripeCouponDcToEnity(dc: IStripeCouponDC): IStripeCouponEntity {
  return {
    id: dc.id,
    amountOff: dc.amount_off,
    currency: dc.currency,
    duration: dc.duration as StripeCouponDuration,
    durationInMonths: dc.duration_in_months,
    name: dc.name,
    percentOff: dc.percent_off,
  };
}
