import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { UnauthenticatedLayout } from '@/router/layouts';
import { EXTERNAL_ROUTES } from '@/router/routes';

import styles from './styles.module.scss';

const toMinutesAndSeconds = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds % 60;
  return `${minutes}:${String(secondsLeft).padStart(2, '0')}`;
};

type Props = {
  lockedUntil: number | null;
  onComplete: () => void;
};

export const LockTimer: FC<Props> = ({ lockedUntil, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const now = dayjs().unix();

    if (typeof lockedUntil !== 'number') return;

    if (typeof lockedUntil === 'number' && lockedUntil <= now) {
      onComplete();
      return;
    }

    const updateTimer = (): void => {
      const currentTime = dayjs().unix();
      const timeDiff = lockedUntil - currentTime;

      if (timeDiff <= 0 && typeof lockedUntil === 'number') {
        clearInterval(timerId);
        onComplete();
      } else {
        setTimeLeft(toMinutesAndSeconds(timeDiff));
      }
    };

    updateTimer(); // Initial update
    const timerId = setInterval(updateTimer, 1000);

    return () => clearInterval(timerId);
  }, [lockedUntil, onComplete]);

  const { t } = useTranslation('auth');
  const now = dayjs().unix();

  if (typeof lockedUntil !== 'number' || now > lockedUntil) return null;

  return (
    <UnauthenticatedLayout.ErrorAlert className={styles.error}>
      <Box className={styles.errorContent}>
        <Trans
          t={t}
          i18nKey={'verification.tooManyAttempts'}
          values={{
            time: timeLeft,
          }}
          components={{
            a: <a href={EXTERNAL_ROUTES.HELP_CENTER} />,
            b: <b className={styles.highlightText} />,
          }}
        >
          You’ve tried this too many times. Try again in a few minutes.
          {/*@ts-ignore*/}
          <b>{{ resendLockTime: timeLeft }}</b>
        </Trans>
      </Box>
    </UnauthenticatedLayout.ErrorAlert>
  );
};
