import { injectable } from 'inversify';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  IGlobalErrorEntity,
  IGlobalErrorRepository,
} from '@/features/system/globalError';

@injectable()
export default class GlobalErrorRepository implements IGlobalErrorRepository {
  private executionErrorSubject = new BehaviorSubject<IGlobalErrorEntity | null>(null);
  private procedureErrorsSubject = new BehaviorSubject<IGlobalErrorEntity[]>([]);

  public setExecutionError(error: IGlobalErrorEntity): void {
    this.executionErrorSubject.next(error);
  }

  public restoreExecutionError(): void {
    this.executionErrorSubject.next(null);
  }

  public getExecutionError(): Observable<IGlobalErrorEntity | null> {
    return this.executionErrorSubject.asObservable();
  }

  public getProcedureErrors(): Observable<IGlobalErrorEntity[]> {
    return this.procedureErrorsSubject;
  }

  public pushProcedureError(error: IGlobalErrorEntity): void {
    this.procedureErrorsSubject.next([...this.procedureErrorsSubject.getValue(), error]);
  }

  public shiftProcedureError(): void {
    const arr = [...this.procedureErrorsSubject.getValue()];
    arr.splice(0, 1);
    this.procedureErrorsSubject.next(arr);
  }
}
