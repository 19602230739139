import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useInjection } from '@/ioc/ioc.react';
import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import {
  BillingCycle,
  IStripePromotionCodeEntity,
  PlanType,
} from '@/features/common/billing';
import { useCurrentWorkspace } from '@/features/common/workspace';
import type { IPaymentDetailsUseCase } from '@/features/paymentDetails';

import { formatCurrency } from '@/utils/formatCurrency';
import { useObservableResult } from '@/utils/rx';

import { IRecieptUI } from '../types';

import { useUpcomingInvoice } from './useUpcomingInvoice';

type UseReciept = (params: {
  planType: PlanType;
  billingCycle: BillingCycle;
  promotionCode?: IStripePromotionCodeEntity;
}) => IRecieptUI;

export const useReciept: UseReciept = ({ planType, billingCycle, promotionCode }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('paymentDetails', { keyPrefix: 'summary' });
  const { t: tPlan } = useTranslation('plans', { keyPrefix: 'plans' });

  const paymentDetailsUseCase = useInjection<IPaymentDetailsUseCase>(
    PAYMENT_DETAILS_TYPES.PaymentDetailsUseCase,
  );

  const { data: workspace } = useCurrentWorkspace();
  const membersCount = workspace?.billableMembersCount;

  const pricePerUnitTranslationKey =
    billingCycle === BillingCycle.Monthly ? 'baseAmountMonth' : 'baseAmountYear';

  const upcomingInvoice = useUpcomingInvoice();

  const reciept$ = useMemo(
    () =>
      paymentDetailsUseCase.getReciept({
        planType,
        billingCycle,
        promotionCode,
      }),
    [planType, billingCycle, promotionCode],
  );

  const { data: reciept } = useObservableResult(reciept$);

  const format = (amount: number): string => {
    return formatCurrency({
      currency: reciept?.currency ?? 'USD',
      amount,
      locale: language,
    });
  };

  const discounts = [
    ...(reciept?.discounts ?? []).map((d) => ({
      name: t(`savings.${d.name}`, {
        discount: d.displayPercent ? d.displayPercent : d.percent,
      }),
      amountFormatted: format(d.amount),
      amount: d.amount,
    })),
    ...(upcomingInvoice?.discounts ?? []).map((d) => ({
      name: t(d?.name),
      amount: d?.amount,
      amountFormatted: format(d?.amount),
    })),
  ];

  return {
    product: reciept?.product
      ? {
          name: `${tPlan(`${planType}.longName`)} (${t(billingCycle)})`,
          priceFormatted: format(reciept.product.price),
          pricePerUnit: t(pricePerUnitTranslationKey, {
            amount: format(reciept.product.pricePerUnit),
            count: membersCount,
          }),
        }
      : undefined,
    discounts: discounts.length > 0 ? discounts : undefined,
    promotion: reciept?.promotion
      ? {
          name: t('promotion', {
            name: reciept.promotion.name,
            amount: format(reciept.promotion.amount),
            percent: Number.isInteger(reciept.promotion.percent)
              ? reciept.promotion.percent
              : reciept.promotion.percent.toFixed(1),
          }),
        }
      : undefined,
    tax:
      reciept?.tax && reciept.tax.amount > 0
        ? {
            name: t('tax', { tax: reciept.tax.percent }),
            amountFormatted: format(reciept.tax.amount),
            amount: reciept.tax.amount,
          }
        : undefined,
    totalFormatted: upcomingInvoice.total
      ? format(upcomingInvoice.total)
      : reciept?.total
        ? format(reciept.total)
        : '0',
    total: upcomingInvoice.total || reciept?.total || 0,
    nextCharge: {
      amountFormatted: format(reciept?.nextCharge.amount ?? 0),
      amount: reciept?.nextCharge.amount ?? 0,
    },
  } satisfies IRecieptUI;
};
