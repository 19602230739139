import { ChangeEventHandler, FC, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Preview, SearchInput } from '@/components';

import { CommonActionProps } from '../types';

import styles from './styles.module.scss';

export type SearchActionProps = CommonActionProps & {
  onSearch: (value: string) => void;
  onClear: () => void;
};

export const SearchAction: FC<SearchActionProps> = ({
  isLoading = false,
  onSearch,
  onClear,
}) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation('lists');

  const handleSearchClear = (): void => {
    setSearch('');
    onClear();
  };
  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit: FormEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    onSearch(search);
  };

  return (
    <Box className={styles.search} component="form" onSubmit={handleSubmit}>
      {isLoading ? (
        <Preview />
      ) : (
        <SearchInput
          placeholder={t('subhead.searchField.placeholderLists')}
          value={search}
          onChange={handleSearchChange}
          onClear={handleSearchClear}
        />
      )}
    </Box>
  );
};
