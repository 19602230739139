import { useInjection } from '@/ioc/ioc.react';
import { WORKSPACE_TYPES } from '@/ioc/types';

import { IWorkspaceUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

import { InvitationEntity } from '../../domain';

type UseWorkspaceInvitationResult = {
  invitation: InvitationEntity | null;
  useCase: IWorkspaceUseCase;
};

export const useWorkspaceInvitation = (): UseWorkspaceInvitationResult => {
  const useCase = useInjection<IWorkspaceUseCase>(WORKSPACE_TYPES.WorkspaceUseCase);

  const { data } = useObservableResult(() => useCase.getInvitationToAnotherWorkspace(), {
    deps: [],
    defaultData: null,
  });

  return {
    invitation: data,
    useCase,
  };
};
