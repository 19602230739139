import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { AUTH_TYPES } from '@/ioc/types';

import { IAuthRepository } from '@/features/common/auth';
import { ILoginUseCases } from '@/features/signin/domain';

@injectable()
export class LoginUseCases implements ILoginUseCases {
  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  loginGoogle(): Observable<unknown> {
    return this.authRepository.signInWithGoogle();
  }

  loginMicrosoft(): Observable<unknown> {
    return this.authRepository.signInWithMicrosoft();
  }

  loginWithEmailAndPass(email: string, password: string): Observable<unknown> {
    return this.authRepository.signInWithEmailAndPassword(email, password);
  }

  sendVerificationLinkForRestorePassword(email: string): Observable<unknown> {
    return this.authRepository.sendPasswordResetEmail(email);
  }

  loginWithCustomToken(customToken: string): Observable<unknown> {
    return this.authRepository.signInWithCustomToken(customToken);
  }
}
