import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useAccount,
  useAccountMonthlyCredits,
  UserRole,
} from '@/features/common/account';
import { useCurrentPlanType } from '@/features/common/billing';
import { PlanCardContentBase } from '@/features/dashboard/ui/PlanCard/components/PlanCardContentBase';

import { PlanCardContentManager } from './PlanCardContentManager';

export const PlanCardProfessionalPlan: FC = () => {
  const { t } = useTranslation('plans', { keyPrefix: 'plans' });

  const { account } = useAccount();

  const { used: creditsUsed } = useAccountMonthlyCredits();
  const { data: planType } = useCurrentPlanType();

  const isManagerRole = account?.role === UserRole.Manager;

  return (
    <PlanCardContentBase
      title={t(`${planType}.longName`)}
      creditsUsed={creditsUsed}
      availableCredits={null}
      sliderProps={{
        defaultValue: creditsUsed,
        value: creditsUsed,
        min: 0,
        max: creditsUsed,
        disabled: true,
      }}
      showCreditsCount={false}
      hideCreditsData={isManagerRole}
    >
      {isManagerRole && <PlanCardContentManager />}
    </PlanCardContentBase>
  );
};
