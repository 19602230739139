import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';

import { DATE_SELECT_OPTION_VALUES, isDateSelectOptionValue } from '../enums';

const FORMAT = 'MM/DD/YYYY';

type DateSelectOption = {
  label: string;
  value: string;
  custom?: boolean;
};

export type UseFilterByTimeFacadeReturn = {
  handleOptionClick: (option: DateSelectOption) => void;
  handleCustomDateChange: ([startDate, endDate]: [Dayjs, Dayjs]) => void;
  handleClose: () => void;
  options: DateSelectOption[];
  optionsMapByValue: Record<string, DateSelectOption>;
  currentValueIsDefault: boolean;
  rangePickerOpen: boolean;
};

export const useDateSelectViewModel = (
  value: string,
  onChange: (value: string) => void,
): UseFilterByTimeFacadeReturn => {
  const [rangePickerOpen, setRangePickerOpen] = useState(false);

  const { t } = useTranslation('contacts');

  const currentValueIsDefault = isDateSelectOptionValue(value);

  const optionsMapByValue = useMemo(() => {
    const customValue = currentValueIsDefault ? '' : value;

    return {
      [DATE_SELECT_OPTION_VALUES.allTime]: {
        label: t('subhead.timeFilter.options.allTime'),
        value: DATE_SELECT_OPTION_VALUES.allTime,
      },
      [DATE_SELECT_OPTION_VALUES.today]: {
        label: t('subhead.timeFilter.options.today'),
        value: DATE_SELECT_OPTION_VALUES.today,
      },
      [DATE_SELECT_OPTION_VALUES.yesterday]: {
        label: t('subhead.timeFilter.options.yesterday'),
        value: DATE_SELECT_OPTION_VALUES.yesterday,
      },
      [DATE_SELECT_OPTION_VALUES.lastWeek]: {
        label: t('subhead.timeFilter.options.lastWeek'),
        value: DATE_SELECT_OPTION_VALUES.lastWeek,
      },
      [DATE_SELECT_OPTION_VALUES.lastMonth]: {
        label: t('subhead.timeFilter.options.lastMonth'),
        value: DATE_SELECT_OPTION_VALUES.lastMonth,
      },
      [DATE_SELECT_OPTION_VALUES.currentYear]: {
        label: t('subhead.timeFilter.options.currentYear'),
        value: DATE_SELECT_OPTION_VALUES.currentYear,
      },
      [customValue]: {
        label: t('subhead.timeFilter.options.custom'),
        value: customValue,
        custom: true,
      },
    };
  }, [t, value]);

  const options = useMemo(() => Object.values(optionsMapByValue), [optionsMapByValue]);

  const handleOptionClick = (option: DateSelectOption): void => {
    if (option.custom) {
      setRangePickerOpen(true);
    } else {
      setRangePickerOpen(false);
    }
  };

  const handleCustomDateChange = ([startDate, endDate]: [Dayjs, Dayjs]): void => {
    const customOptionValue = `${startDate.format(FORMAT)}-${endDate.format(FORMAT)}`;
    onChange(customOptionValue);
  };

  const handleClose = (): void => {
    if (currentValueIsDefault) setRangePickerOpen(false);
  };

  return {
    handleOptionClick,
    handleCustomDateChange,
    handleClose,
    options,
    optionsMapByValue,
    currentValueIsDefault,
    rangePickerOpen,
  };
};
