import { IInvitationDC } from '@/features/referral';
import { IInvitationEntity } from '@/features/referral/domain/entities';

export const mapInvitationDCtoEntity = (dc: IInvitationDC): IInvitationEntity => ({
  uuid: dc.uuid,
  status: dc.status,
  inviteeEmail: dc.invitee_email,
  generatedAt: dc.generated_at,
  referrerToken: dc.referrer_token,
  lastActivity: dc.status_updated_at,
});
