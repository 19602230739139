import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';

export const PaymentFormFieldset: FC<{
  title: string;
  subtitle?: string;
  children: ReactNode;
}> = ({ title, subtitle, children }) => {
  return (
    <fieldset className={styles.formGroup}>
      <legend className={styles.formGroupTitle}>{title}</legend>
      {subtitle ? (
        <Typography variant="subtitle1" color="info">
          {subtitle}
        </Typography>
      ) : null}
      <div className={styles.formGroupBody}>{children}</div>
    </fieldset>
  );
};
