import { useSearchParams } from 'react-router-dom';

import { isPlanType, PlanType } from '@/features/common/billing';

type UsePlanFromUrlReturn = [PlanType, (plan: PlanType) => void];

export const usePlanFromUrl = (
  defaultValue: PlanType = PlanType.Pro,
): UsePlanFromUrlReturn => {
  const [searchParams, setSearchParams] = useSearchParams();
  const planAsString = searchParams.get('new_plan');
  const plan = isPlanType(planAsString) ? planAsString : defaultValue;

  const handlePlanChange = (plan: PlanType): void => {
    searchParams.set('new_plan', plan);
    setSearchParams(searchParams);
  };

  return [plan, handlePlanChange];
};
