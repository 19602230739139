import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CustomInfoIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="6 6 24 24">
    <path
      data-name="Path 10635"
      d="M14 26a12 12 0 1 1 12-12 12.035 12.035 0 0 1-12 12zm0-21.6a9.6 9.6 0 1 0 9.6 9.6A9.628 9.628 0 0 0 14 4.4z"
      transform="translate(4.001 4.001)"
    />
    <path
      data-name="Path 10636"
      d="M12.2 9.4A1.134 1.134 0 0 1 11 8.2 1.134 1.134 0 0 1 12.2 7a1.134 1.134 0 0 1 1.2 1.2 1.134 1.134 0 0 1-1.2 1.2z"
      transform="translate(5.801 5.001)"
    />
    <path
      data-name="Path 10637"
      d="M13.6 18.2h-1.2a1.134 1.134 0 0 1-1.2-1.2v-3.6a1.134 1.134 0 0 1-1.2-1.2 1.134 1.134 0 0 1 1.2-1.2h1.2a1.134 1.134 0 0 1 1.2 1.2v3.6a1.2 1.2 0 1 1 0 2.4z"
      transform="translate(5.601 5.801)"
    />
  </SvgIcon>
);
