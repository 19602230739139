import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { Modal } from '@/components';
import { CancelOutlinedIcon } from '@/components/Icons';

import { useJustSubscribedDialogViewModel } from './JustSubscribedViewModel';

import styles from './styles.module.scss';

export const JustSubscribedDialog: FC = () => {
  const { isOpened, onClose, onStartProspecting, onMembersInvite } =
    useJustSubscribedDialogViewModel();

  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.justSubscribedDialog',
  });

  return (
    <Modal open={isOpened} onClose={onClose} containerClass={styles.modal}>
      <div className={styles.modalBody}>
        <CancelOutlinedIcon className={styles.closeIcon} onClick={onClose} />
        <Typography variant="h1" className={styles.emoji}>
          {'🥳'}
        </Typography>
        <Typography variant="h1" className={styles.modalTitle}>
          {t('title')}
        </Typography>
        <div className={styles.modalButtons}>
          <Button
            onClick={onStartProspecting}
            variant="contained"
            color="secondary"
            fullWidth
          >
            {t('startProspecting')}
          </Button>
          <Button onClick={onMembersInvite} variant="text" className={styles.invite}>
            {t('inviteMembers')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
