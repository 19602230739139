import { ChangeEvent, FC, useEffect, useRef } from 'react';
import { Box, OutlinedInput } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';

import { GroupsIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const NameCellEdit: FC<GridRenderEditCellParams> = ({
  id,
  value,
  field,
  error,
}) => {
  const apiRef = useGridApiContext();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    void apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  useEffect(() => {
    inputRef.current?.focus();
    inputRef.current?.select();
  }, []);

  useEffect(() => {
    if (error) {
      inputRef.current?.setAttribute('aria-label', error);
    }

    return () => {
      inputRef.current?.removeAttribute('aria-label');
    };
  }, [error]);

  return (
    <Box className={styles.nameCellEdit}>
      <Box className={styles.nameCellIcon}>
        <GroupsIcon />
      </Box>
      <Box className={styles.nameCellEditField}>
        <OutlinedInput
          type="text"
          name={`editableBaseInput-${id}`}
          defaultValue={value}
          onChange={handleValueChange}
          inputRef={inputRef}
          error={!!error}
        />
      </Box>
    </Box>
  );
};
