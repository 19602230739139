import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { PlanType } from '@/features/common/billing';

import Icon from './assets/talkToExpert.svg?react';

import styles from './styles.module.scss';

export const TalkToExpert: React.FC<{ planType: PlanType }> = ({ planType }) => {
  const { t } = useTranslation('plans');

  if (planType !== PlanType.Ultimate) return null;

  return (
    <a className={styles.wrapper} href={EXTERNAL_ROUTES.REQUEST_DEMO} target="_blank">
      <Icon />
      <Typography>{t('talkToExpertHint')}</Typography>
    </a>
  );
};
