import { inject, injectable } from 'inversify';
import { firstValueFrom, Observable } from 'rxjs';

import { REFERRAL_TYPES } from '@/ioc/types';

import { IReferralEmailRequestData, IReferralRepository } from '@/features/referral/data';
import { ReferralTokenInvalidError } from '@/features/referral/domain/error';

import { IReferralUseCase } from './abstractions';
import { IInvitationEntity, IReferralResponseDataEntity } from './entities';

@injectable()
export class ReferralUseCase implements IReferralUseCase {
  @inject(REFERRAL_TYPES.ReferralRepository)
  private repository: IReferralRepository;

  sendInvitationEmail(
    data: IReferralEmailRequestData[],
  ): Observable<IReferralResponseDataEntity[]> {
    return this.repository.sendInvitationEmail(data);
  }

  removeInvitation(uuid: string): Observable<boolean> {
    return this.repository.removeInvitation(uuid);
  }

  getInvitations(): Observable<IInvitationEntity[]> {
    return this.repository.getInvitations();
  }

  async validateToken(token: string): Promise<boolean> {
    const response = await firstValueFrom(this.repository.validateToken(token));

    if (!response) throw new ReferralTokenInvalidError();

    return response;
  }
}
