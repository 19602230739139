import { useMemo } from 'react';
import { filter, map } from 'rxjs';

import {
  IWorkspaceSubscriptionEntity,
  useSubscriptionUseCase,
} from '@/features/common/workspace';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { getPlanTypeFromSubscription, PlanType } from '../../domain';

export function useCurrentPlanType(): ObservableResult<PlanType> {
  const subscriptionUseCase = useSubscriptionUseCase();

  const currentPlanType$ = useMemo(
    () =>
      subscriptionUseCase.getSubscription().pipe(
        filter(
          (subscription): subscription is IWorkspaceSubscriptionEntity => !!subscription,
        ),
        map((subscription) => getPlanTypeFromSubscription(subscription)),
      ),
    [],
  );

  return useObservableResult(currentPlanType$);
}
