import { createContext } from 'react';

import { type IAccountEntity } from '@/features/common/account';
import { IBillingDetailsEntity, IPaymentMethodEntity } from '@/features/common/billing';
import { type IWorkspaceSubscriptionEntity } from '@/features/common/workspace';

import { IInvoiceDataEntity } from '../../../domain';

export type PaymentDetailsContextType = {
  account: IAccountEntity;
  currentSubscription: IWorkspaceSubscriptionEntity;
  paymentMethod: IPaymentMethodEntity | null;
  billingDetails: IBillingDetailsEntity | null;
  invoiceData: IInvoiceDataEntity | null;
};

export const PaymentDetailsContext = createContext<PaymentDetailsContextType>({
  get account(): IAccountEntity {
    throw new Error('PaymentDetailsContext is not yet initialized');
  },
  get currentSubscription(): IWorkspaceSubscriptionEntity {
    throw new Error('PaymentDetailsContext is not yet initialized');
  },
  paymentMethod: null,
  billingDetails: null,
  invoiceData: null,
});
