import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { equals } from 'ramda';

import { ContactInfoFilterType } from '@/features/common/contact';
import { useContactByFilters } from '@/features/common/contact';

import { useOptimisticState } from '@/hooks';

import { ContactsContext } from '../../context';

type Option = { label: string; value: string };

type UseFilterByContactInfoViewModelResult = {
  value: ContactInfoFilterType[];
  options: Option[];
  valuesIsMatched: boolean;
  appliedValuesCount: number;
  setValue(value: ContactInfoFilterType[]): void;
  handleApply(): void;
  handleCloseAnimationEnd(): void;
};

export const useFilterByContactInfoViewModel =
  (): UseFilterByContactInfoViewModelResult => {
    const { t } = useTranslation('contacts');
    const { filterByContactInfo, setFilterByContactInfo, contactURLSearchParams } =
      useContext(ContactsContext);
    const [value, setValue] = useOptimisticState(filterByContactInfo);
    const { data } = useContactByFilters(contactURLSearchParams.toString());

    const options: Option[] = [
      {
        value: 'phones',
        label: t('subhead.contactInfoFilter.options.phoneNumbers', {
          total: data?.contactInfoCountCurrent.phones || 0,
        }),
      },
      {
        value: 'verified_emails',
        label: t('subhead.contactInfoFilter.options.verifiedEmails', {
          total: data?.contactInfoCountCurrent.verifiedEmails || 0,
        }),
      },
      {
        value: 'emails',
        label: t('subhead.contactInfoFilter.options.emails', {
          total: data?.contactInfoCountCurrent.emails || 0,
        }),
      },
    ];
    const valuesIsMatched = equals(value.sort(), filterByContactInfo.sort());

    const handleApply = (): void => {
      setFilterByContactInfo(value);
    };

    const handleCloseAnimationEnd = (): void => {
      if (!valuesIsMatched) {
        setValue(filterByContactInfo);
      }
    };

    return {
      value,
      setValue,
      options,
      handleApply,
      handleCloseAnimationEnd,
      valuesIsMatched,
      appliedValuesCount: filterByContactInfo.length,
    };
  };
