import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/account';

import { CustomSelectV2 } from '@/components';

import { AddToListButton } from './components';
import { useListMoveActionViewModel } from './useListMoveActionViewModel';

import styles from './styles.module.scss';

export const ListMoveAction: FC = () => {
  const {
    value,
    options,
    search,
    disabled,
    applyDisabled,
    selectList,
    createList,
    moveList,
    searchIsFullMatched,
    exitMenu,
  } = useListMoveActionViewModel();
  const { t } = useTranslation('contacts');

  return (
    <CustomSelectV2
      value={value}
      options={options}
      onChange={selectList}
      InputProps={{
        render({ setAnchorElement }): ReactElement {
          return (
            <ActionDisableGuard>
              <Button
                variant="outlined"
                color="info"
                onClick={(e): void => {
                  setAnchorElement(e.currentTarget);
                }}
                disabled={disabled}
              >
                {t('subhead.moveToList.button')}
              </Button>
            </ActionDisableGuard>
          );
        },
      }}
      MenuProps={{
        SearchProps: {
          value: search.value,
          onChange: search.onChange,
          placeholder: t('subhead.moveToList.searchField.placeholder'),
        },
        PopoverProps: {
          transformOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
          TransitionProps: {
            onExited: exitMenu,
          },
        },
        ListProps: {
          classes: {
            root: styles.list,
          },
        },
        renderFooter({ closeMenu }): ReactElement {
          return (
            <Box className={styles.actions}>
              {search.value && !searchIsFullMatched && (
                <AddToListButton
                  onClick={(): void => {
                    createList();
                    closeMenu();
                  }}
                  inputValue={search.value}
                />
              )}
              <Button
                disabled={applyDisabled}
                onClick={(): void => {
                  moveList();
                  closeMenu();
                }}
              >
                {t('subhead.moveToList.apply')}
              </Button>
            </Box>
          );
        },
      }}
      searchable
      closeAfterSelect={false}
    />
  );
};
