import { inject, injectable, unmanaged } from 'inversify';
import { MangoQuery, MangoQuerySelector, RxCollection } from 'rxdb';
import { first, map, Observable, switchMap, throwError } from 'rxjs';
import { v4 } from 'uuid';

import { DB_TYPES } from '@/ioc/types';

import { IDbManager } from '../data/DbManagerNew';
import { Database } from '../types';

export interface IDbCollection<T extends object, IdKey extends keyof T> {
  findAll(query?: MangoQuery<T>): Observable<T[]>;
  findOne(query?: MangoQuery<T>): Observable<T | null>;
  findById(id: T[IdKey]): Observable<T | null>;
  findByIds(ids: Array<T[IdKey]>): Observable<T[]>;
  updateOne(id: T[IdKey], patch: Partial<T>): Observable<T>;
  upsert(patch: Partial<T>): Observable<T>;
  bulkUpsert(patches: Partial<T>[]): Observable<T[]>;
  insertOne(entity: WithOptionalId<T, IdKey>): Observable<T>;
  bulkInsert(entities: WithOptionalId<T, IdKey>[]): Observable<T[]>;
  bulkRemove(ids: T[IdKey][]): Observable<T[]>;
  removeOne(id: T[IdKey]): Observable<T>;
}

type CollectionName = keyof Database['collections'];

@injectable()
export class DbCollection<Entity extends object, IdKey extends keyof Entity>
  implements IDbCollection<Entity, IdKey>
{
  protected readonly collectionName: CollectionName;
  protected readonly idKey: IdKey;

  @inject(DB_TYPES.DbManager)
  private dbManager: IDbManager;

  private withId(entity: Partial<Entity>): Partial<Entity> {
    if (!Reflect.has(entity, this.idKey)) {
      return { ...entity, [this.idKey]: v4() };
    }

    return entity;
  }

  private assertIsString(value: unknown): asserts value is string {
    if (typeof value !== 'string') {
      throw new Error(`Expected string, got ${typeof value}`);
    }
  }

  constructor(@unmanaged() params: { collectionName: CollectionName; idKey: IdKey }) {
    this.collectionName = params.collectionName;
    this.idKey = params.idKey;
  }

  protected get collection(): Observable<RxCollection<Entity>> {
    return this.dbManager.getDb().pipe(
      map((db) => {
        if (!Reflect.has(db.collections, this.collectionName)) {
          throw new Error(`Collection ${this.collectionName} not found`);
        }

        return db.collections[this.collectionName] as unknown as RxCollection<Entity>;
      }),
    );
  }

  findAll(query: MangoQuery<Entity> = {}): Observable<Entity[]> {
    return this.collection.pipe(
      switchMap((collection) => {
        return collection.find({ ...query }).$;
      }),
      map((docs) => docs.map((doc) => doc.toMutableJSON())),
    );
  }

  findOne(query: MangoQuery<Entity> = {}): Observable<Entity | null> {
    return this.collection.pipe(
      switchMap((collection) => collection.findOne({ ...query }).$),
      map((doc) => (doc ? doc.toMutableJSON() : null)),
    );
  }

  findById(id: Entity[IdKey]): Observable<Entity | null> {
    return this.collection.pipe(
      switchMap(
        (collection) =>
          collection.findOne({
            selector: { [this.idKey]: id } as MangoQuerySelector<Entity>,
          }).$,
      ),
      map((doc) => (doc ? doc.toMutableJSON() : null)),
    );
  }

  findByIds(ids: Entity[IdKey][]): Observable<Entity[]> {
    return this.collection.pipe(
      switchMap(
        (collection) =>
          collection.find({
            selector: { [this.idKey]: { $in: ids } } as MangoQuerySelector<Entity>,
          }).$,
      ),
      map((docs) => docs.map((doc) => doc.toMutableJSON())),
    );
  }

  updateOne(id: Entity[IdKey], patch: Partial<Entity>): Observable<Entity> {
    return this.collection.pipe(
      switchMap((collection) => {
        return collection.findOne({
          selector: { [this.idKey]: id } as MangoQuerySelector<Entity>,
        }).$;
      }),
      first(),
      switchMap((doc) => {
        if (!doc) {
          return throwError(
            () => new Error(`Entity["${this.collectionName}] with id "${id}" not found`),
          );
        }

        return doc.incrementalPatch(patch);
      }),
      map((doc) => doc.toMutableJSON()),
    );
  }

  upsert(entity: Partial<Entity>): Observable<Entity> {
    return this.collection.pipe(
      first(),
      switchMap((collection) => {
        return collection.upsert(this.withId(entity));
      }),
      map((doc) => doc.toMutableJSON()),
    );
  }

  bulkUpsert(entities: Partial<Entity>[]): Observable<Entity[]> {
    return this.collection.pipe(
      first(),
      switchMap((collection) => {
        return collection.bulkUpsert(entities.map((entity) => this.withId(entity)));
      }),
      map((result) => {
        return result.success.map((doc) => doc.toMutableJSON());
      }),
    );
  }

  insertOne(entity: WithOptionalId<Entity, IdKey>): Observable<Entity> {
    return this.collection.pipe(
      first(),
      switchMap((collection) => {
        // @ts-ignore
        return collection.insert(this.withId(entity));
      }),
      map((doc) => doc.toMutableJSON()),
    );
  }

  bulkInsert(entities: WithOptionalId<Entity, IdKey>[]): Observable<Entity[]> {
    return this.collection.pipe(
      first(),
      switchMap((collection) => {
        // @ts-ignore
        return collection.bulkInsert(entities.map((entity) => this.withId(entity)));
      }),
      map(({ success }) => {
        return success.map((doc) => doc.toMutableJSON());
      }),
    );
  }

  bulkRemove(ids: Entity[IdKey][]): Observable<Entity[]> {
    return this.collection.pipe(
      first(),
      switchMap((collection) => {
        return collection.bulkRemove(
          ids.map((id) => {
            this.assertIsString(id);
            return id;
          }),
        );
      }),
      map(({ success }) => {
        return success.map((doc) => doc.toMutableJSON());
      }),
    );
  }

  removeOne(id: Entity[IdKey]): Observable<Entity> {
    return this.collection.pipe(
      first(),
      switchMap((collection) => {
        this.assertIsString(id);
        return collection.bulkRemove([id]);
      }),
      map((entities) => entities[0]),
    );
  }
}
