import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from '@mui/material';
import clsx from 'clsx';

import { useInjection } from '@/ioc/ioc.react';
import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import { UserRole } from '@/features/common/account';
import { ITeamMemberUseCase } from '@/features/settings';

import { ArrowDownIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type Props = {
  role: UserRole;
  email: string;
  roleTitle: string;
};

const RoleManagementButton: React.FC<Props> = ({ email, roleTitle }) => {
  const { t } = useTranslation('settings');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const teamMemberUseCase = useInjection<ITeamMemberUseCase>(
    TEAM_MEMBER_TYPES.TeamMemberUseCase,
  );

  const onButtonClick: React.MouseEventHandler<HTMLButtonElement> = ({
    currentTarget,
  }) => {
    setAnchorEl(currentTarget);
  };

  const onCloseDialog = (): void => {
    setAnchorEl(null);
  };

  const updateMemberRole = (role: UserRole): void => {
    const updateSubscription = teamMemberUseCase
      .updateTeamMember({ email, role })
      .subscribe(() => {
        setAnchorEl(null);
        updateSubscription.unsubscribe();
      });
  };

  const setAdminRole = (): void => updateMemberRole(UserRole.Admin);
  const setUserRole = (): void => updateMemberRole(UserRole.Member);
  const setManagerRole = (): void => updateMemberRole(UserRole.Manager);

  return (
    <>
      <Button
        variant="outlined"
        color="info"
        size={'small'}
        endIcon={<ArrowDownIcon />}
        className={clsx(styles.ChangePermissionButton, { [styles.Expanded]: !!anchorEl })}
        onClick={onButtonClick}
      >
        {roleTitle}
      </Button>
      <Popover
        classes={{ paper: styles.DialogPaper }}
        anchorEl={anchorEl}
        onClose={onCloseDialog}
        open={!!anchorEl}
      >
        <div className={styles.RolesWrapper}>
          <div onClick={setAdminRole} className={styles.Role} role="button" tabIndex={0}>
            <span>{t('teamManagement.inviteDialog.roleAdminTitle')}</span>
            <span>{t('teamManagement.inviteDialog.roleAdminDescription')}</span>
          </div>
          <div onClick={setUserRole} className={styles.Role} role="button" tabIndex={0}>
            <span>{t('teamManagement.inviteDialog.roleUserTitle')}</span>
            <span>{t('teamManagement.inviteDialog.roleUserDescription')}</span>
          </div>
          <div
            onClick={setManagerRole}
            className={styles.Role}
            role="button"
            tabIndex={0}
          >
            <span>{t('teamManagement.inviteDialog.roleManagerTitle')}</span>
            <span>{t('teamManagement.inviteDialog.roleManagerDescription')}</span>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default RoleManagementButton;
