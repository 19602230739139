import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

import { useAppLogger } from '@/features/system/logger';

import { IIntegrationEntityUI } from '../../entities';
import { useIntegrationsUseCase } from '../../hooks';

type Props = {
  currentIntegration: Nullable<IIntegrationEntityUI>;
};

type Return = {
  handleSubmit: () => Promise<void>;
};

export const useContactAdminDialogViewModel = ({ currentIntegration }: Props): Return => {
  const { t } = useTranslation('common');
  const integrationsUseCases = useIntegrationsUseCase();
  const appLogger = useAppLogger();

  const handleSubmit = async (): Promise<void> => {
    if (currentIntegration) {
      try {
        await integrationsUseCases.sendRequestToAdminForIntegration(
          currentIntegration.id,
        );
      } catch (err) {
        appLogger.error(err);
        enqueueSnackbar({
          variant: `error`,
          message: t('errors.errorOccurred'),
        });
      }
    }
  };

  return {
    handleSubmit,
  };
};
