import { inject, injectable } from 'inversify';
import { first, Observable, switchMap } from 'rxjs';

import { ACCOUNT_TYPES, TAG_TYPES } from '@/ioc/types';

import { IAccountUseCase } from '@/features/common/account';

import { ITagRepository } from './abstractions/repositories/ITagRepository';
import { ITagUseCase } from './abstractions/useCases/ITagUseCase';
import { ITagEntity } from './entities/TagEntity';

@injectable()
export default class TagUseCase implements ITagUseCase {
  @inject(TAG_TYPES.TagRepository)
  private tagRepository: ITagRepository;

  @inject(ACCOUNT_TYPES.AccountUseCase)
  private accountUseCase: IAccountUseCase;

  getTags = (): Observable<ITagEntity[]> => {
    return this.tagRepository.getTags();
  };

  addTag(tag: Pick<ITagEntity, 'name' | 'color'>): Observable<ITagEntity> {
    return this.accountUseCase.getAccount().pipe(
      first(),
      switchMap((account) => {
        return this.tagRepository.insertTag({
          ...tag,
          created_by: account?.uuid ?? '',
        });
      }),
    );
  }

  updateTag(payload: Pick<ITagEntity, 'uuid' | 'name' | 'color'>): Observable<void> {
    return this.tagRepository.updateTag(payload);
  }

  deleteTag(uuid: string): Observable<boolean> {
    return this.tagRepository.deleteTag(uuid);
  }
}
