import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { CustomSelect } from '@/components';

import { DateSelectPicker } from './DateSelectPicker';
import { useDateSelectViewModel } from './hooks';

import styles from './styles.module.scss';

dayjs.extend(isBetween);

export type DateSelectProps = {
  onChange: (value: string) => void;
  value: string;
  minDate?: number;
};

export const DateSelect: FC<DateSelectProps> = ({ onChange, value, minDate }) => {
  const {
    handleOptionClick,
    handleCustomDateChange,
    handleClose,
    options,
    optionsMapByValue,
    currentValueIsDefault,
    rangePickerOpen,
  } = useDateSelectViewModel(value, onChange);

  return (
    <Box className={styles.timeFilter}>
      <CustomSelect
        options={options}
        listProps={{
          className: styles.timeFilterList,
        }}
        value={value}
        onChange={onChange}
        onClose={handleClose}
        defaultAnchorProps={{
          anchorValue: optionsMapByValue[value]?.label || '',
        }}
        renderOption={({ option }): ReactElement => {
          return (
            <CustomSelect.DefaultOption
              key={option.value}
              onClick={(e): void => {
                option.custom && e.stopPropagation();
                handleOptionClick(option);
              }}
              option={option}
              selected={option.value === value}
            />
          );
        }}
        {...(rangePickerOpen && {
          renderActions: (): ReactElement => {
            return (
              <DateSelectPicker
                onChange={handleCustomDateChange}
                value={currentValueIsDefault ? '' : value}
                minDate={minDate}
              />
            );
          },
        })}
        popoverProps={{
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
        }}
      />
    </Box>
  );
};
