import { useContext } from 'react';

import { EnrichmentFileHeaderField } from '@/features/enrichment/domain/types';

import { EnrichmentUploadingContext } from '../../../../../../EnrichmentUploadingContext';

type TableRow = {
  id: string;
  ownField: string;
};

type UseConfigViewFieldsMapperViewModelResult = {
  rows: TableRow[];
  toNext(): void;
  close(): void;
};

export const useConfigViewFieldsMapperViewModel =
  (): UseConfigViewFieldsMapperViewModelResult => {
    const { toView, onUploadingModalClose } = useContext(EnrichmentUploadingContext);

    const rows = Object.values(EnrichmentFileHeaderField).map((field) => {
      return {
        id: field,
        ownField: field,
      };
    });

    const toNext = () => {
      toView('ConfigSettingsView');
    };

    const close = () => {
      onUploadingModalClose();
    };

    return {
      rows,
      toNext,
      close,
    };
  };
