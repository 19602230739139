import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { firstValueFrom } from 'rxjs';

import { ROUTES } from '@/router/routes';

import {
  BillingError,
  getSubscriptionPlanFrom,
  IBillingDetailsEntity,
  PaymentError,
  PromotionExpiredError,
  useBillingUseCase,
} from '@/features/common/billing';
import { FbPixelEvent, fbPixelTrackEvent } from '@/features/system/FbPixel';
import { useAppLogger } from '@/features/system/logger';

import { useDocumentMeta } from '@/hooks';

import { PaymentDetailsContext } from './contexts';
import {
  PaymentFailedReason,
  usePaymentDetailsAnalytic,
  usePaymentErrorSnackbar,
  usePaymentSummary,
  useRedirect,
} from './hooks';

export interface IPaymentDetailsViewModel {
  summary: ReturnType<typeof usePaymentSummary>;
  isProcessing: boolean;
  onSubmit: (
    data: IBillingDetailsEntity,
    card?: StripeCardElement | null,
  ) => Promise<void>;
}

export const usePaymentDetailsViewModel = (): IPaymentDetailsViewModel => {
  const { t } = useTranslation('paymentDetails');

  const [isProcessing, setIsProcessing] = useState(false);
  const { currentSubscription } = useContext(PaymentDetailsContext);
  useRedirect();

  const navigate = useNavigate();

  const stripe = useStripe();

  const analytic = usePaymentDetailsAnalytic();

  useEffect(() => {
    analytic.pageViewed();
  }, []);

  const billingUseCase = useBillingUseCase();

  const logger = useAppLogger();

  const isCurrentPlanPaid = currentSubscription.paid;

  const summary = usePaymentSummary({ currentSubscription });

  const plan = getSubscriptionPlanFrom({
    planType: summary.planType,
    billingCycle: summary.billingCycle,
  });

  const errorSnackbar = usePaymentErrorSnackbar();

  useDocumentMeta({
    title: 'payment.title',
    description: 'payment.description',
  });

  const updateSubscription = async (formValues: IBillingDetailsEntity): Promise<void> => {
    try {
      setIsProcessing(true);
      await firstValueFrom(billingUseCase.updateBillingDetails(formValues));

      await firstValueFrom(
        billingUseCase.updateSubscription({
          plan,
        }),
      );

      analytic.paymentSuccess({
        planType: summary.planType,
        billingCycle: summary.billingCycle,
      });

      navigate(ROUTES.SETTINGS.SUBSCRIPTION);
    } catch (error) {
      handlePaymentError(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const createSubscription = async (
    formValues: IBillingDetailsEntity,
    card?: StripeCardElement,
  ): Promise<void> => {
    try {
      if (!stripe) {
        throw new Error('Stripe is not initialized');
      }

      if (summary.reciept.total < 0.5) {
        throw new PaymentError('Payment amount is too low. Monimum amount is $0.50');
      }

      setIsProcessing(true);

      await firstValueFrom(
        billingUseCase.createSubscription({
          billingDetails: formValues,
          plan: plan,
          processPayment: async (secretKey: string): Promise<void> => {
            const { error } = await stripe.confirmCardPayment(secretKey, {
              // @ts-ignore
              payment_method: formValues.id ?? {
                card: card,
                billing_details: {
                  name: formValues.name,
                },
              },
              setup_future_usage: 'off_session',
              save_payment_method: true,
            });

            if (error) {
              throw PaymentError.fromStripeError(error);
            }
          },
          promotionCode: summary.promotionCode,
        }),
      );

      analytic.paymentSuccess({
        planType: summary.planType,
        billingCycle: summary.billingCycle,
      });

      fbPixelTrackEvent({
        type: FbPixelEvent.Purchase,
        options: { value: summary.reciept.total.toString(), currency: 'USD' },
      });

      navigate(ROUTES.SETTINGS.SUBSCRIPTION, { state: { justSubscribed: true } });
    } catch (error) {
      handlePaymentError(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePaymentError = (error: unknown): void => {
    logger.error(error);

    if (error instanceof PromotionExpiredError) {
      summary.onPromotionCodeRemove();
      errorSnackbar.show(t('promotionCode.expired'));
    } else if (error instanceof PaymentError || error instanceof BillingError) {
      errorSnackbar.show(error.message);
    } else {
      errorSnackbar.show();
    }

    analytic.paymentFailed(PaymentFailedReason.ServerError);
  };

  return {
    summary,
    isProcessing,
    onSubmit: isCurrentPlanPaid ? updateSubscription : createSubscription,
  };
};
