import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';

import { ReferralStatus } from '@/features/referral/domain/entities';

import { RubbishBinIcon } from '@/components/Icons';

import ResendEmailIcon from '@/assets/icons/settings/resendEmailIcon.svg?react';

import { useTableCellActionsViewModel } from './useTableCellActionsViewModel';

import styles from './styles.module.scss';

export type TableCellActionsProps = {
  uuid: string;
  email: string;
  status: ReferralStatus;
};

export const TableCellActions: React.FC<TableCellActionsProps> = (props) => {
  const { isResendButtonVisible, onResendEmail, onDelete } =
    useTableCellActionsViewModel(props);
  const { t } = useTranslation('referral', { keyPrefix: 'inviteesSection' });

  return (
    <Box className={styles.wrapper}>
      {isResendButtonVisible && (
        <Tooltip title={t('resend')}>
          <ResendEmailIcon onClick={onResendEmail} />
        </Tooltip>
      )}
      <Tooltip title={t('delete')}>
        <RubbishBinIcon onClick={onDelete} />
      </Tooltip>
    </Box>
  );
};
