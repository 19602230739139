import { inject, injectable } from 'inversify';
import { firstValueFrom } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

export interface IGeoSyncRepository {
  sync(): Promise<void>;
  getCountry(): string;
}

@injectable()
export class GeoSyncRepository implements IGeoSyncRepository {
  @inject(NETWORK_TYPES.HttpClient)
  private readonly httpClient: IHttpClient;

  public async sync(): Promise<void> {
    try {
      const { data } = await firstValueFrom(
        this.httpClient.get<{ country_code: string }>('account/ip-info'),
      );

      localStorage.setItem('deviceCountryCode', data.country_code);
    } catch (error) {
      localStorage.setItem('deviceCountryCode', 'US');
    }
  }

  public getCountry(): string {
    return localStorage.getItem('deviceCountryCode') || 'US';
  }
}
