import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { openInNewTab } from '@/utils/openInNewTab';

type JustSubscribedDialogViewModel = {
  isOpened: boolean;
  onClose: () => void;
  onMembersInvite: () => void;
  onStartProspecting: () => void;
};

export function useJustSubscribedDialogViewModel(): JustSubscribedDialogViewModel {
  const localtion = useLocation();
  const navigate = useNavigate();

  const [isOpened, setIsOpened] = useState(() =>
    Boolean(localtion.state?.justSubscribed),
  );

  function onClose(): void {
    setIsOpened(false);
    navigate(location.pathname, { state: { justSubscribed: false } });
  }

  function onMembersInvite(): void {
    onClose();
    navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT, {
      state: { isInviteTeamMemberModalOpen: true },
    });
  }

  function onStartProspecting(): void {
    onClose();
    openInNewTab(EXTERNAL_ROUTES.LINKEDIN_URL);
  }

  return {
    isOpened,
    onClose,
    onMembersInvite,
    onStartProspecting,
  };
}
