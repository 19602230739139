import { FC } from 'react';

import { PlanType } from '@/features/common/billing';

import PlanCardPreview from '../../../../assets/icons/planCardPreview.svg?react';

import { PlanCardFreePlan } from './components/PlanCardFreePlan';
import { PlanCardProfessionalPlan } from './components/PlanCardProfessionalPlan';
import { usePlanCardViewModel } from './PlanCardViewModel';

export const PlanCard: FC = () => {
  const { planType, isLoading } = usePlanCardViewModel();

  if (isLoading) {
    return <PlanCardPreview width="402px" preserveAspectRatio="none" />;
  }

  if (planType === PlanType.Free) {
    return <PlanCardFreePlan />;
  }

  return <PlanCardProfessionalPlan />;
};
