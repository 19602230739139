import React from 'react';
import { Box } from '@mui/material';

import { InviteeTableSection, InviteSection } from './components';

import styles from './styles.module.scss';

export const Referral: React.FC = () => {
  return (
    <Box className={styles.wrapper}>
      <InviteSection />
      <InviteeTableSection />
    </Box>
  );
};
