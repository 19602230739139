import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { useSearch } from '@/hooks';

import { Option, PrimitiveValue, SelectValue } from './types';

export type CustomSelectV2ContextType<
  OptionValue extends PrimitiveValue,
  Multiple extends boolean,
> = {
  value: Nullable<SelectValue<OptionValue, Multiple>>;
  inputData: { value: string; label: string };
  options: Option<OptionValue>[];
  multiple?: Multiple;
  isOpen: boolean;
  searchable?: boolean;
  searchValue: string;
  anchorElement: Nullable<HTMLElement>;
  setAnchorElement: Dispatch<SetStateAction<Nullable<HTMLElement>>>;
  closeMenu(): void;
  selectItem(option: Option<OptionValue>): void;
  changeSearchValue(e: React.ChangeEvent<HTMLInputElement>): void;
  clearSearchValue(): void;
  isSelected(option: Option<OptionValue>): boolean;
};

export const CustomSelectV2Context = createContext<
  CustomSelectV2ContextType<any, boolean>
>({
  value: null,
  inputData: { value: '', label: '' },
  options: [],
  multiple: false,
  isOpen: false,
  searchValue: '',
  anchorElement: null,
  setAnchorElement(): void {
    return;
  },
  closeMenu(): void {
    return;
  },
  selectItem(): void {
    return;
  },
  changeSearchValue(): void {
    return;
  },
  clearSearchValue(): void {
    return;
  },
  isSelected(): boolean {
    return false;
  },
});

export function CustomSelectV2ContextProvider<
  OptionValue extends PrimitiveValue,
  Multiple extends boolean,
>({
  children,
  value,
  multiple = false as Multiple,
  closeAfterSelect = true,
  searchable,
  searchValue,
  onSearchChange,
  options,
  onChange,
  onClose,
}: PropsWithChildren<{
  value: Nullable<SelectValue<OptionValue, Multiple>>;
  options: Option<OptionValue>[];
  multiple?: Multiple;
  closeAfterSelect?: boolean;
  searchable?: boolean;
  searchValue?: string;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (value: SelectValue<OptionValue, Multiple>) => void;
  onClose?: () => void;
}>): ReactElement {
  const {
    value: searchValueInternal,
    handleSearchChange,
    handleSearchClear,
  } = useSearch();

  const changeSearchValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onSearchChange) {
      onSearchChange(e);
    } else {
      handleSearchChange(e);
    }
  };
  const clearSearchValue = (): void => {
    if (onSearchChange) {
      onSearchChange({
        target: { value: '' },
        currentTarget: { value: '' },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      handleSearchClear();
    }
  };

  const valueIsArray = Array.isArray(value);
  const [anchorElement, setAnchorElement] = useState<Nullable<HTMLElement>>(null);

  const selectedOptionsMap = useMemo(() => {
    const result = new Map<OptionValue, Option<OptionValue>>();

    if (multiple && valueIsArray) {
      options.forEach((option) => {
        if (value.some((item) => item === option.value)) result.set(option.value, option);
      });
      return result;
    }

    if (value === undefined || value === null) return result;

    const selectedOption = options.find((option) => option.value === value);

    if (selectedOption) {
      result.set(selectedOption.value, selectedOption);
    }

    return result;
  }, [value, options]);

  const inputData = useMemo(() => {
    if (value === null || value === undefined) return { value: '', label: '' };

    if (valueIsArray) {
      return {
        value: value.join(','),
        label: value.map((v) => selectedOptionsMap.get(v)?.label ?? '').join(', ') || '',
      };
    }

    return {
      value: String(value),
      label: selectedOptionsMap.get(value as OptionValue)?.label ?? '',
    };
  }, [value, selectedOptionsMap]);

  const closeMenu = (): void => {
    setAnchorElement(null);
    clearSearchValue();
    onClose && onClose();
  };

  const isSelected = (option: Option<OptionValue>): boolean => {
    return selectedOptionsMap.has(option.value);
  };

  const selectItem = (newOption: Option<OptionValue>): void => {
    if (!value) {
      const newValue = (
        multiple === true ? [newOption.value] : newOption.value
      ) as SelectValue<OptionValue, Multiple>;

      onChange(newValue);

      !multiple && closeAfterSelect && closeMenu();

      return;
    }

    if (multiple && valueIsArray) {
      const isExist = value.some((item) => item === newOption.value);
      onChange(
        (isExist
          ? value.filter((item) => item !== newOption.value)
          : [...value, newOption.value]) as SelectValue<OptionValue, Multiple>,
      );

      return;
    }

    closeAfterSelect && closeMenu();
    onChange(newOption.value as SelectValue<OptionValue, Multiple>);
  };

  if (multiple && !valueIsArray) {
    throw Error(`Prop 'value' must be an array`);
  }

  return (
    <CustomSelectV2Context.Provider
      value={{
        value,
        inputData,
        options,
        multiple,
        searchable,
        searchValue: (searchValue as string) || searchValueInternal,
        anchorElement,
        setAnchorElement,
        closeMenu,
        isOpen: Boolean(anchorElement),
        selectItem,
        changeSearchValue,
        clearSearchValue,
        isSelected,
      }}
    >
      {children}
    </CustomSelectV2Context.Provider>
  );
}
