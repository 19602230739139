import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { ContentBox } from '../../components/ContentBox';

import { CopyInviteLink, InviteByEmail } from './components';

import styles from './styles.module.scss';

export const InviteSection: React.FC = () => {
  const { t } = useTranslation('referral', { keyPrefix: 'inviteSection' });

  return (
    <ContentBox className={styles.section}>
      <Typography variant="h1">{t('title')}</Typography>
      <Typography variant="h3">{t('subtitle')}</Typography>
      <CopyInviteLink />
      <InviteByEmail />
    </ContentBox>
  );
};
