import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import { PlanType } from '@/features/common/billing';

import {
  CreditsPerUser,
  CurrentPlanBadge,
  LimitedCredits,
  PlanActions,
  PlanFeatures,
  PlanLimits,
  TalkToExpert,
  UnlimitedEmailCredits,
  UnlimitedMobileCredits,
} from './components';
import { usePlanCardViewModel } from './PlanCardViewModel';

import styles from './styles.module.scss';

type PlanCardType = {
  variant: PlanType;
};

export const PlanCard: FC<PlanCardType> = ({ variant }) => {
  const {
    isPopular,
    isCurrent,
    name,
    description,
    price,
    action,
    onAction,
    features,
    featuresDetailed,
    crossedOutPrice,
    customBadge,
    credits,
    creditCardRequired,
  } = usePlanCardViewModel({
    planType: variant,
  });
  const { t } = useTranslation('plans');

  return (
    <article className={clsx(styles.planCard, { [styles.mostPopular]: isPopular })}>
      <Box className={styles.planCardTop}>
        {isPopular ? (
          <span className={clsx(styles.badge, styles.mostPopularBadge)}>
            {t('badges.mostPopular')}
          </span>
        ) : null}
        {customBadge ? (
          <span className={clsx(styles.badge, styles.customBadge)}>{t(customBadge)}</span>
        ) : null}
        {isCurrent ? <CurrentPlanBadge /> : null}

        <Typography className={styles.planCardTitle}>{name}</Typography>
        <Typography className={styles.planCardSubtitle}>{description}</Typography>

        <Typography className={styles.planCardPriceAmount}>
          {price.title}
          {crossedOutPrice &&
            (crossedOutPrice.title !== price.title ? (
              <Box className={styles.planCardPriceAmountCrossed}>
                {crossedOutPrice.title}
              </Box>
            ) : null)}
        </Typography>
        <Typography className={styles.planCardPriceDescription}>
          {price.subtitle1}
          <br />
          {price.subtitle2}
        </Typography>
      </Box>

      <PlanLimits>
        {variant === PlanType.Free && (
          <Fragment>
            <LimitedCredits variant="free" credits={credits} />
          </Fragment>
        )}

        {variant === PlanType.Pro && (
          <Fragment>
            <LimitedCredits variant="paid" credits={credits} />
            <CreditsPerUser />
          </Fragment>
        )}

        {(variant === PlanType.Unlimited || variant === PlanType.Expand) && (
          <Fragment>
            <UnlimitedMobileCredits />
            <UnlimitedEmailCredits />
          </Fragment>
        )}
      </PlanLimits>

      <PlanActions
        action={{ type: action, onClick: onAction }}
        creditCardRequired={creditCardRequired}
      />
      <PlanFeatures features={features} featuresDetailed={featuresDetailed} />
      <TalkToExpert planType={variant} />
    </article>
  );
};
