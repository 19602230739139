import { useEffect } from 'react';
import { firstValueFrom } from 'rxjs';

import {
  AuthIdentityProvider,
  CredentialsToOldError,
  useProvider,
  UserAlreadyExistsError,
} from '@/features/common/auth';
import { PlanType, useCurrentPlanType } from '@/features/common/billing';
import { useAnalytics } from '@/features/system/analytics';
import { ANALYTICS_EVENTS } from '@/features/system/analytics';

import { ModalControler, useDocumentMeta, useModalController } from '@/hooks';

import { useAccountSettingsSnackbar, useAccountSettingsUseCase } from '../../hooks';

type UseAccountSettingsViewModel = () => {
  showFreePlanCard: boolean;
  emailChangedDialog: ModalControler;
  passwordChangeDialog: ModalControler;
  onNameChange: (name: string) => Promise<void>;
  onEmailAndPasswordChange: (email: string, password: string) => Promise<void>;
  onEmailChange: (email: string) => Promise<void>;
  onPasswordChange: (password: string, currentPassword?: string) => Promise<void>;
  provider: AuthIdentityProvider | null;
};

export const useAccountSettingsViewModel: UseAccountSettingsViewModel = () => {
  const accountSettingsSnackbar = useAccountSettingsSnackbar();

  const emailChangedDialog = useModalController();
  const passwordChangeDialog = useModalController();

  const provider = useProvider();

  const { data: plantType } = useCurrentPlanType();

  const { changePasswordAndEmail, changeName, changeEmail, changePassword } =
    useAccountSettingsUseCase();

  const analytics = useAnalytics();

  useEffect(() => {
    analytics.trackEvent(ANALYTICS_EVENTS.VIEW_MY_ACCOUNT_PAGE);
  }, []);

  useDocumentMeta({
    title: 'account.title',
    description: 'account.description',
  });

  const handleEmailAndPasswordChange = async (
    email: string,
    password: string,
  ): Promise<void> => {
    try {
      const changed = await firstValueFrom(changePasswordAndEmail(email, password));

      if (changed.email) {
        analytics.trackEvent(ANALYTICS_EVENTS.CHANGE_EMAIL);
        emailChangedDialog.onOpen();
      }

      if (changed.password) {
        accountSettingsSnackbar.notifyPasswordUpdated();
        analytics.trackEvent(ANALYTICS_EVENTS.CHANGE_PASSWORD);
      }
    } catch (error) {
      if (error instanceof CredentialsToOldError) {
        accountSettingsSnackbar.notifyCredentialsOutdated();
      }

      if (error instanceof UserAlreadyExistsError) {
        accountSettingsSnackbar.notifyEmailAlreadyExists();
      }

      throw error;
    }
  };

  const handlePasswordChange = async (
    newPassword: string,
    currentPassword: string,
  ): Promise<void> => {
    try {
      await firstValueFrom(changePassword(newPassword, currentPassword));

      analytics.trackEvent(ANALYTICS_EVENTS.CHANGE_PASSWORD);
      accountSettingsSnackbar.notifyPasswordUpdated();
    } catch (error) {
      if (error instanceof CredentialsToOldError) {
        accountSettingsSnackbar.notifyCredentialsOutdated();
      }

      throw error;
    }
  };

  const handleNameChange = async (name: string): Promise<void> => {
    const changed = await firstValueFrom(changeName(name));

    if (changed) {
      accountSettingsSnackbar.notifyNameUpdated();
    }
  };

  const handleEmailChange = async (email: string): Promise<void> => {
    try {
      const changed = await firstValueFrom(changeEmail(email));

      if (changed) {
        analytics.trackEvent(ANALYTICS_EVENTS.CHANGE_EMAIL);
        emailChangedDialog.onOpen();
      }
    } catch (error) {
      if (error instanceof UserAlreadyExistsError) {
        accountSettingsSnackbar.notifyEmailAlreadyExists();
      }

      throw error;
    }
  };

  return {
    emailChangedDialog,
    passwordChangeDialog,
    onNameChange: handleNameChange,
    onEmailChange: handleEmailChange,
    onPasswordChange: handlePasswordChange,
    onEmailAndPasswordChange: handleEmailAndPasswordChange,
    provider: provider,
    showFreePlanCard: plantType === PlanType.Free,
  };
};
