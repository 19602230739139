import { useContext } from 'react';

import { useAppLogger } from '@/features/system/logger';

import { useAsyncExecutor, UseAsyncExecutorResult } from '@/hooks';

import { EnrichmentContext } from '../../../..//EnrichmentContext';
import { useEnrichmentUseCase } from '../../../../hooks';

export const useUserAccessDeniedModalViewModel = (): UseAsyncExecutorResult<boolean> => {
  const enrichmentUseCase = useEnrichmentUseCase();
  const { onAccessDeniedModalClose } = useContext(EnrichmentContext);
  const logger = useAppLogger();

  const contactAdmin = async (): Promise<boolean> => {
    try {
      const result = await enrichmentUseCase.contactAdmin();
      onAccessDeniedModalClose();
      return result;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  return useAsyncExecutor(contactAdmin);
};
