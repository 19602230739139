import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Slider, Stack, styled } from '@mui/material';
import { Box, Typography } from '@mui/material';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { ActionDisableGuard } from '@/features/common/account';
import { useExtensionState } from '@/features/common/extension';
import { useOnboardingUseCase } from '@/features/common/onboarding';

import { FlashIcon } from '@/components/Icons';

import { openInNewTab } from '@/utils/openInNewTab';

import { useUserMenuHandlers } from '../../hooks';

import styles from '../../styles.module.scss';

type LimitedCreditsProps = {
  creditsUsed: number;
  creditsLimit: number;
};

export const HeaderCreditsVBar = styled(Slider)`
  width: 7px;
  height: 30px;
  margin-right: ${({ theme }): string => theme.spacing(1)};
  padding: 0;

  .MuiSlider-rail {
    opacity: 1;
    color: ${({ theme }): string => theme.palette.info.disabled};
  }

  .MuiSlider-track {
    color: ${({ theme }): string => theme.palette.secondary.main};
  }

  .MuiSlider-thumb {
    display: none;
  }
`;

export const LimitedCredits: FC<LimitedCreditsProps> = ({
  creditsUsed,
  creditsLimit,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onClose } = useUserMenuHandlers();
  const { isInstalled: isExtensionInstalled } = useExtensionState();
  const onboardingUseCase = useOnboardingUseCase();

  const handleClickUpgradeNow = (): void => {
    navigate(`${ROUTES.BILLING.PLANS}?source=free-topbar-CTA`);
    onClose();
  };

  const handleClickInstallExtension = (): void => {
    openInNewTab(EXTERNAL_ROUTES.EXTENSION_URL);
    onboardingUseCase.completeClickDownloadExtensionStep();
    onClose();
  };

  return (
    <Box className={styles.limitedCreditsContainer}>
      <HeaderCreditsVBar
        defaultValue={creditsUsed}
        value={creditsUsed}
        min={0}
        max={creditsLimit}
        disabled
        orientation="vertical"
      />
      <Stack justifyContent="center" width="fit-content">
        <Typography variant="textInButton">{creditsUsed}</Typography>
        <Typography variant="subtitle1" lineHeight={1} whiteSpace="nowrap">
          {t('header.creditsUsed')}
        </Typography>
      </Stack>
      <Box mr={3} />
      {isExtensionInstalled ? (
        <ActionDisableGuard>
          <Button
            startIcon={<FlashIcon />}
            variant="contained"
            fullWidth
            className={styles.unlimitedCreditsButton}
            onClick={handleClickUpgradeNow}
          >
            <Typography variant="textInButton">{t('header.upgradeNow')}</Typography>
          </Button>
        </ActionDisableGuard>
      ) : (
        <Button
          variant="contained"
          size="small"
          className={styles.unlimitedCreditsButton}
          onClick={handleClickInstallExtension}
        >
          <Typography variant="subtitle2" fontWeight={600}>
            {t('header.getExtension', { ns: 'common' })}
          </Typography>
        </Button>
      )}
    </Box>
  );
};
