import * as amplitude from '@amplitude/analytics-browser';
import { Identify as IIdentify } from '@amplitude/analytics-types/lib/esm/event';
import { injectable } from 'inversify';

import { IAccountEntity } from '@/features/common/account';
import { IWorkspaceEntity, SubscriptionPlan } from '@/features/common/workspace';

import { AmplitudeUserProperties, IAnalyticsRepository } from '../domain';

declare let process: {
  env: {
    REACT_APP_AMPLITUDE_API_KEY: string;
  };
};

@injectable()
export class AnalyticsRepository implements IAnalyticsRepository {
  private amplitude: typeof amplitude;

  identifyEvent: IIdentify;

  constructor() {
    this.amplitude = amplitude;
  }

  init(): void {
    this.amplitude.init(import.meta.env.REACT_APP_AMPLITUDE_API_KEY, undefined, {
      defaultTracking: {
        sessions: true,
        pageViews: false,
        formInteractions: false,
        fileDownloads: false,
      },
    });

    this.identifyEvent = new amplitude.Identify();
  }

  trackEvent(eventType: string, eventOptions?: Record<string, unknown>): void {
    this.amplitude.track(eventType, eventOptions);
  }

  identify(account: IAccountEntity, workspace: IWorkspaceEntity): void {
    this.amplitude.setUserId(account.uuid);
    // this.amplitude.setDeviceId(account)
    const userProperties = {
      plan_type: workspace.subscription.plan,
      account_id: workspace?.uuid,
      account_number_of_paid_users: workspace.subscription.paidMembersCount,
      account_number_of_users: workspace.members.length,
      account_role: account.role,
      powerlead_community:
        workspace.subscription.plan === SubscriptionPlan.FreePlusMonthly,
      sign_up_date: new Date(account.createdAt).getTime(),
      credits_used: account.currentCreditsUsed,
      complete_onboarding: account.isUserSignUpFinished,
      sign_up_provider: account.providers[0],
    } as AmplitudeUserProperties;
    Object.entries(userProperties).forEach(([key, value]) => {
      this.identifyEvent.set(key, value);
    });
    this.amplitude.identify(this.identifyEvent);
  }
}
