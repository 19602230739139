import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip, Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { CustomInfoIcon } from '@/components/Icons';

import { openInNewTab } from '@/utils/openInNewTab';

import styles from './styles.module.scss';

export const HeaderGuideContainer: FC = () => {
  const { t } = useTranslation();

  const openHelpCenter = (): void => {
    openInNewTab(EXTERNAL_ROUTES.HELP_CENTER);
  };

  return (
    <Tooltip
      title={
        <Box width="120px" textAlign="center">
          {t('header.learnHowToGetTheMost')}
        </Box>
      }
    >
      <Grid
        gap="5px"
        alignItems="center"
        flexWrap="nowrap"
        width="fit-content"
        className={styles.powerleadGuideContainer}
        onClick={openHelpCenter}
      >
        <CustomInfoIcon />
        <Typography
          variant="subtitle1"
          color="#151417"
          lineHeight={1}
          fontWeight={500}
          whiteSpace="nowrap"
          className={styles.description}
        >
          {t('header.powerleadGuide')}
        </Typography>
      </Grid>
    </Tooltip>
  );
};
