import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { Modal } from '@/components';
import { CancelOutlinedIcon } from '@/components/Icons';

import { GetFreeCreditsFooter } from './components';
import { useGetFreeCreditsDialogViewModel } from './GetFreeCreditsDialogViewModel';

import styles from './styles.module.scss';

type GetFreeCreditsDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const GetFreeCreditsDialog: FC<GetFreeCreditsDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation('dashboard');
  const { credits, isFederated, onFederatedSubmit, onMicrosoftSubmit, onGoogleSubmit } =
    useGetFreeCreditsDialogViewModel();

  return (
    <Modal onClose={onClose} open={open} containerClass={styles.paperRoot}>
      <>
        <CancelOutlinedIcon className={styles.closeIcon} onClick={onClose} />
        <div className={styles.container}>
          <div className={styles.joinCommunity}>
            <Typography variant="textInButton">
              {t('getFreeCreditsDialog.joinCommunity')}
            </Typography>
          </div>
          <div className={styles.title}>
            <Typography variant="h1">{t('getFreeCreditsDialog.title')}</Typography>
          </div>
          <div className={styles.description}>
            <Typography variant="subtitle1">
              <Trans t={t} i18nKey="getFreeCreditsDialog.description" credits={credits}>
                Join the Powerlead community and get an extra
                {/*@ts-ignore*/}
                <span className={styles.creditsAmount}>{{ credits }}</span> credits every
                month, for free!
              </Trans>
            </Typography>
          </div>
          <div className={styles.hint}>
            <Typography variant="subtitle3">{t('getFreeCreditsDialog.hint')}</Typography>
          </div>
          <GetFreeCreditsFooter
            isFederated={isFederated}
            onFederatedSubmit={async (): Promise<void> => {
              try {
                await onFederatedSubmit();
              } finally {
                onClose();
              }
            }}
            onGoogleSubmit={async (): Promise<void> => {
              try {
                await onGoogleSubmit();
              } finally {
                onClose();
              }
            }}
            onMicrosoftSubmit={async (): Promise<void> => {
              try {
                await onMicrosoftSubmit();
              } finally {
                onClose();
              }
            }}
          />
        </div>
      </>
    </Modal>
  );
};
