import { FC, PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Slider, SliderProps, styled, Typography } from '@mui/material';

import dashboardStyles from '../../styles.module.scss';
import styles from '../styles.module.scss';

type PlanCardContentBaseProps = {
  title: string;
  creditsUsed: number;
  availableCredits: number | null;
  sliderProps: SliderProps;
  showCreditsCount: boolean;
  hideCreditsData?: boolean;
};

const TeamMembersCardBar = styled(Slider)`
  width: 360px;
  height: 6px;
  border-radius: 2px;
  padding: 0;

  .MuiSlider-rail {
    opacity: 1;
    color: ${({ theme }): string => theme.palette.info.disabled};
  }

  .MuiSlider-track {
    color: ${({ theme }): string => theme.palette.secondary.main};
  }

  .MuiSlider-thumb {
    display: none;
  }
`;

export const PlanCardContentBase: FC<PropsWithChildren<PlanCardContentBaseProps>> = ({
  children,
  title,
  creditsUsed,
  availableCredits,
  sliderProps,
  showCreditsCount = false,
  hideCreditsData = false,
}) => {
  const { t } = useTranslation('dashboard');

  return (
    <Box className={`${dashboardStyles.card} ${styles.cardRoot}`}>
      <Box className={styles.cardTitle}>
        <Typography variant="h1">{title}</Typography>
      </Box>

      {!hideCreditsData && (
        <>
          <Box className={styles.cardMonthlyCredits}>
            <Typography variant="h3">{t('monthlyCredits')}</Typography>
            {showCreditsCount && (
              <Typography variant="h3">
                {creditsUsed}/{availableCredits}
              </Typography>
            )}
          </Box>

          <Box className={styles.cardDescription}>
            <Typography variant="subtitle2" color="info">
              {availableCredits ? (
                <Trans
                  t={t}
                  i18nKey="freePlanDescription"
                  values={{ creditsUsed, availableCredits }}
                  defaults="You’ve used <1> {{creditsUsed}} </1> of {{availableCredits}} available credits"
                  components={{
                    // @ts-ignore
                    1: <Typography variant="subtitle2" color="secondary" as="span" />,
                  }}
                />
              ) : (
                <Trans
                  t={t}
                  i18nKey="proPlanDescription"
                  values={{ creditsUsed }}
                  defaults="You’ve used <1> {{creditsUsed}} </1> of unlimited credits"
                  components={{
                    // @ts-ignore
                    1: <Typography variant="subtitle2" color="secondary" as="span" />,
                  }}
                />
              )}
            </Typography>
          </Box>

          <Box className={styles.cardHBar}>
            <TeamMembersCardBar {...sliderProps} />
          </Box>
        </>
      )}

      {children}
    </Box>
  );
};
