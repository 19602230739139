import { injectable } from 'inversify';
import { BehaviorSubject, Observable } from 'rxjs';

import { IOnlineTrackerRepository } from './abstraction';

@injectable()
export class OnlineTrackerRepository implements IOnlineTrackerRepository {
  private isOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    navigator.onLine,
  );

  constructor() {
    window.addEventListener('offline', () => this.isOnline$.next(false));
    window.addEventListener('online', () => this.isOnline$.next(true));
  }

  get isOnline(): boolean {
    return this.isOnline$.getValue();
  }

  getIsOnline(): Observable<boolean> {
    return this.isOnline$.asObservable();
  }
}
