import { inject, injectable } from 'inversify';
import { RxReplicationWriteToMasterRow } from 'rxdb/dist/types/types';
import { firstValueFrom, map } from 'rxjs';

import { SYNC_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { CollectionName, WorkspaceDocument } from '@/features/system/db';
import { ReplicationService } from '@/features/system/replication';
import { mapBaseSyncEntity } from '@/features/system/replication/data/mappers';
import { IBaseSyncRepository } from '@/features/system/sync';

import { IWorkspaceApiService } from '../../domain/abstractions/IWorkspaceApiService';

@injectable()
export class WorkspaceReplicationService extends ReplicationService<WorkspaceDocument> {
  @inject(WORKSPACE_TYPES.WorkspaceApiService)
  private apiService: IWorkspaceApiService;

  @inject(SYNC_TYPES.BaseSyncRepository)
  private syncRepository: IBaseSyncRepository;

  constructor() {
    super({
      collectionName: CollectionName.Workspace,
      pullStreamFactory: () => {
        return this.syncRepository
          .getWorkspaceEvents()
          .pipe(map(mapBaseSyncEntity<WorkspaceDocument>));
      },
      pushHandlers: {
        update: (docs) => this.syncUpdate(docs),
      },
    });
  }

  private syncUpdate(
    docsToUpdate: RxReplicationWriteToMasterRow<WorkspaceDocument>[],
  ): Promise<WorkspaceDocument> {
    return firstValueFrom(
      this.apiService
        .updateWorkspace(docsToUpdate[0].newDocumentState)
        .pipe(map((workspace) => ({ ...workspace, _deleted: false }))),
    );
  }
}
