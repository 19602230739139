export enum SubscriptionPlan {
  FreeMonthly = 'free_monthly',
  FreePlusMonthly = 'free_plus_monthly',

  ProV0DailyLegacy = 'pro_daily',
  ProV0MonthlyLegacy = 'pro_monthly',
  ProV0AnnuallyLegacy = 'pro_annually',

  ProV1DailyLegacy = 'pro_fixed_daily',
  ProV1MonthlyLegacy = 'pro_fixed_monthly',
  ProV1AnnuallyLegacy = 'pro_fixed_annually',

  ProV2Daily = 'pro_daily_v2',
  ProV2Monthly = 'pro_monthly_v2',
  ProV2Annually = 'pro_annually_v2',

  AdvancedDayly = 'advanced_daily',
  AdvancedMonthly = 'advanced_monthly',
  AdvancedAnnually = 'advanced_annually',

  GrowthDayly = 'growth_daily',
  GrowthMonthly = 'growth_monthly',
  GrowthAnnually = 'growth_annually',

  UltimateDayly = 'ultimate_daily',
  UltimateMonthly = 'ultimate_monthly',
  UltimateAnnually = 'ultimate_annually',

  UnlimitedDaily = 'unlimited_daily_v2',
  UnlimitedMonthly = 'unlimited_monthly_v2',
  UnlimitedAnnually = 'unlimited_annually_v2',

  ExpandMock = 'expand_mock',

  Scale = 'scale',
}
