import { useContext } from 'react';

import { ACCESS_PERMISSIONS } from '../../domain';
import { AccountPermissionsContext } from '../contexts';

export const usePermissions = (): {
  hasPermission: (permission: ACCESS_PERMISSIONS) => boolean;
  isOwnerOrAdmin: boolean;
  freezedToUpdate: boolean;
} => {
  const data = useContext(AccountPermissionsContext);

  const hasPermission = (permission: ACCESS_PERMISSIONS): boolean => {
    if (!data) return false;

    return data.permissions[permission];
  };

  return {
    hasPermission,
    isOwnerOrAdmin: data?.isOwnerOrAdmin || false,
    freezedToUpdate: data.freezedToUpdate,
  };
};
