import { Components, Theme } from '@mui/material';

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  defaultProps: {
    size: 'medium',
    fullWidth: true,
  },
  styleOverrides: {
    root({ theme }) {
      return {
        '.MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px !important',
          borderColor: theme.palette.grey300,
        },
        '&:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
          {
            borderColor: theme.palette.grey300,
          },
      };
    },
  },
};
