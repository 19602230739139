import { useInjection } from '@/ioc/ioc.react';
import { CUSTOMER_MESSAGING_TYPES } from '@/ioc/types';

import { BillingCycle, PlanType } from '@/features/common/billing';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import {
  CustomerMessagingEventKeys,
  ICustomerMessagingUseCase,
} from '@/features/system/CustomerIO';

export enum PaymentFailedReason {
  ServerError = 'Server error',
  CardDetails = 'Card details',
  EmptyFields = 'Empty fields',
}

type UsePaymentDetailsAnalytic = () => {
  paymentSuccess: (params: { planType: PlanType; billingCycle: BillingCycle }) => void;
  paymentFailed: (reason: PaymentFailedReason) => void;
  clickSubmitPurchase: () => void;
  pageViewed: () => void;
};

export const usePaymentDetailsAnalytic: UsePaymentDetailsAnalytic = () => {
  const { trackEvent } = useAnalytics();

  const cmUseCase = useInjection<ICustomerMessagingUseCase>(
    CUSTOMER_MESSAGING_TYPES.CustomerMessagingUseCase,
  );

  return {
    pageViewed: (): void => {
      cmUseCase.page(CustomerMessagingEventKeys.VIEW_PAYMENT_DETAILS_PAGE);
      trackEvent(ANALYTICS_EVENTS.VIEW_PAYMENT_DETAILS_PAGE);
    },
    paymentSuccess: (params: {
      planType: PlanType;
      billingCycle: BillingCycle;
    }): void => {
      cmUseCase.track(CustomerMessagingEventKeys.PURCHASE_SUCCESSFUL);
      trackEvent(ANALYTICS_EVENTS.PAYMENT_SUCCESS, {
        package: `${params.planType} ${params.billingCycle}`,
      });
    },
    paymentFailed: (reason: PaymentFailedReason): void => {
      trackEvent(ANALYTICS_EVENTS.PAYMENT_FAILED, { type: reason });
    },
    clickSubmitPurchase: (): void => {
      trackEvent(ANALYTICS_EVENTS.CLICKS_SUBMIT_PURCHASE);
    },
  };
};
