import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { ActionDisableGuard } from '@/features/common/account';

import { Modal } from '@/components';

import IconClose from '@/assets/icons/iconClose.svg?react';
import IconArrows from '@/assets/icons/integrations/arrows.svg?react';
import IconConnectPowerlead from '@/assets/icons/integrations/connectPowerleadIcon.svg?react';

import { IIntegrationEntityUI } from '../../entities';

import styles from './styles.module.scss';

type Props = {
  integration: Nullable<IIntegrationEntityUI>;
  isOpen: boolean;
  onClose(): void;
};

export const IntegrationUpgradeDialog: FC<Props> = ({ integration, isOpen, onClose }) => {
  const { t } = useTranslation('integrations');
  const navigate = useNavigate();
  const currentIntegrationTitle = integration?.name || '';
  const currentIcon = integration?.icon || '';
  const isWiderIcon = integration?.id === 'salesforce';

  const handleSubmit: MouseEventHandler = (e) => {
    if (e.metaKey) return;

    e.preventDefault();
    onClose();
    navigate(ROUTES.BILLING.PLANS);
  };

  return (
    <Modal containerClass={styles.dialogPaper} open={isOpen} onClose={onClose}>
      <>
        <IconClose onClick={onClose} className={styles.closeIcon} />
        <div className={styles.dialogIcons}>
          <div className={styles.withBorder}>
            <IconConnectPowerlead />
          </div>
          <IconArrows />
          <div className={styles.integrationIconWrapper}>
            <img
              src={currentIcon}
              alt={t(currentIntegrationTitle)}
              style={{ width: isWiderIcon ? 65 : 52 }}
            />
          </div>
        </div>
        <Typography className={styles.dialogTitle}>{t('upgradeDialog.title')}</Typography>
        <Typography className={styles.dialogSubtitle}>
          {t('upgradeDialog.description')}
        </Typography>
        <ActionDisableGuard>
          <Button
            onClick={handleSubmit}
            className={styles.dialogButton}
            color="primary"
            variant="contained"
            href={ROUTES.BILLING.PLANS}
          >
            {t('upgradeDialog.submit')}
          </Button>
        </ActionDisableGuard>
      </>
    </Modal>
  );
};
