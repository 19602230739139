import { IPaymentMethodUpdateSessionEntity } from '../../domain';
import { IPaymentMethodUpdateSessionDC } from '../dataContracts';

export function mapPaymentMethodUpdateSessionDcToEntity(
  dc: IPaymentMethodUpdateSessionDC,
): IPaymentMethodUpdateSessionEntity {
  return {
    id: dc.id,
    url: dc.url,
  };
}
