import { FC, useEffect } from 'react';

import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { useDocumentMeta } from '@/hooks';

import {
  BillingDetailsCard,
  BillingHistoryCard,
  JustSubscribedDialog,
  PlanCard,
  SubscriptionsLayout,
} from './components';

export const Subscriptions: FC = () => {
  useDocumentMeta({
    title: 'subscription.title',
    description: 'subscription.description',
  });

  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(ANALYTICS_EVENTS.VIEW_SUBSCRIPTION_PAGE);
  }, []);

  return (
    <SubscriptionsLayout>
      <PlanCard />
      <BillingDetailsCard />
      <BillingHistoryCard />
      <JustSubscribedDialog />
    </SubscriptionsLayout>
  );
};
