import { Container } from 'inversify';

import { ACCOUNT_TYPES } from '@/ioc/types';

import type {
  IAccountDao,
  IAccountRepository,
  IAccountServicesApi,
} from '@/features/common/account';
import {
  AccountApiService,
  AccountCollectionCreator,
  AccountDao,
  AccountRepository,
} from '@/features/common/account';
import { IMigrationService } from '@/features/system/db';
import { IReplicationService } from '@/features/system/replication';

import { AccountReplicationService } from '../data/db/AccountReplicationService';
import { AccountMigrationService } from '../data/db/migration/migration';
import {
  AccountCreditsUseCase,
  IAccountCreditsUseCase,
  IAccountPermissionsUseCase,
} from '../domain';
import { IAccountUseCase } from '../domain/abstractions/useCases/IAccountUseCase';
import { AccountPermissionsUseCase } from '../domain/AccountPermissionsUseCase';
import AccountUseCase from '../domain/AccountUseCase';

export const bindAccountModule = (container: Container): void => {
  container
    .bind<IReplicationService>(ACCOUNT_TYPES.AccountReplicationService)
    .to(AccountReplicationService)
    .inSingletonScope();

  container
    .bind<IReplicationService>('ReplicationService')
    .toService(ACCOUNT_TYPES.AccountReplicationService);

  container
    .bind<AccountCollectionCreator>('DbCollectionCreator')
    .to(AccountCollectionCreator);

  container.bind<IAccountDao>(ACCOUNT_TYPES.AccountDao).to(AccountDao).inSingletonScope();

  container
    .bind<IAccountServicesApi>(ACCOUNT_TYPES.AccountApiService)
    .to(AccountApiService);

  container
    .bind<IAccountRepository>(ACCOUNT_TYPES.AccountRepository)
    .to(AccountRepository)
    .inSingletonScope();

  container
    .bind<IAccountUseCase>(ACCOUNT_TYPES.AccountUseCase)
    .to(AccountUseCase)
    .inSingletonScope();

  container
    .bind<IAccountCreditsUseCase>(ACCOUNT_TYPES.AccountCreditsUseCase)
    .to(AccountCreditsUseCase);

  container
    .bind<IAccountPermissionsUseCase>(ACCOUNT_TYPES.AccountPermissionsUseCase)
    .to(AccountPermissionsUseCase);

  container
    .bind<IMigrationService>(ACCOUNT_TYPES.AccountMigrationService)
    .to(AccountMigrationService);
};
