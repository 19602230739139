import { IBillingConfig } from './domain/types/BillingConfig';
import {
  BillingCycle,
  PlanType,
  ProAnnualDiscount,
  UnlimitedAnnualDiscount,
} from './domain';

export const billingConfig: IBillingConfig = {
  basePlanPrices: {
    [PlanType.Free]: 0,
    [PlanType.ProLegacyV0]: 25,
    [PlanType.ProLegacyV1]: 25,
    [PlanType.Pro]: 34,
    [PlanType.AdvancedLegacy]: 34,
    [PlanType.Growth]: 34,
    [PlanType.Ultimate]: 59,
    [PlanType.Scale]: NaN,
    [PlanType.Unlimited]: 99,
    [PlanType.Expand]: NaN,
  },
  taxRates: {
    default: 0,
  },
  discounts: {
    [PlanType.Pro]: {
      [BillingCycle.Yearly]: [ProAnnualDiscount],
    },
    [PlanType.Unlimited]: {
      [BillingCycle.Yearly]: [UnlimitedAnnualDiscount],
    },
  },
};
