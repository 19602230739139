import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, OutlinedInput, Typography } from '@mui/material';
import { StripeCardElement } from '@stripe/stripe-js';

import { CardField, PaymentFormField } from '@/features/common/billing';

import { CountrySelect } from '@/components';
import { LockIcon } from '@/components/Icons';

import { PaymentFormFieldset } from './PaymentFormFieldset';
import { PaymentFormValues, usePaymentFormViewModel } from './PaymentFormViewModel';

import styles from './styles.module.scss';

type PaymentFormProps = {
  isProcessing: boolean;
  onSubmit: (data: PaymentFormValues, card: StripeCardElement) => Promise<void>;
};

export const PaymentForm: FC<PaymentFormProps> = ({ isProcessing, onSubmit }) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'form' });
  const { t: tField } = useTranslation('billing', { keyPrefix: 'fields' });

  const { form, validations, cardError, handleSubmit } =
    usePaymentFormViewModel(onSubmit);

  const field = (key: string): { label: string; placeholder: string } => {
    return {
      label: tField(`${key}.label`),
      placeholder: tField(`${key}.placeholder`),
    };
  };

  return (
    <form className={styles.form} id="payment-details" noValidate onSubmit={handleSubmit}>
      <PaymentFormFieldset title={t('cardDetailsTitle')}>
        <Box className={styles.cardDetails}>
          <PaymentFormField
            name="name"
            labelText={field('name').label}
            required
            disabled={isProcessing}
            error={!!form.formState.errors.name}
            errorText={form.formState.errors.name?.message}
          >
            <OutlinedInput
              placeholder={field('name').placeholder}
              {...form.register('name', validations.name)}
            />
          </PaymentFormField>
          <Box className={styles.cardRow}>
            <PaymentFormField
              name="card"
              labelText={field('card').label}
              required
              error={!!cardError}
              errorText={cardError}
              errorPosition="bottom"
              disabled={isProcessing}
            >
              <CardField card={form.watch('card')} />
            </PaymentFormField>
            <Box className={styles.secure}>
              <LockIcon />
              <Typography variant="inherit" className={styles.secureText}>
                {t('secure')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </PaymentFormFieldset>
      <PaymentFormFieldset
        title={t('billingDetailsTitle')}
        subtitle={t('billingDetailsSubtitle')}
      >
        <Box className={styles.billingDetails}>
          <PaymentFormField
            name="address"
            labelText={field('address').label}
            required
            disabled={isProcessing}
            error={!!form.formState.errors.address}
            errorText={form.formState.errors.address?.message}
          >
            <OutlinedInput
              placeholder={field('address').placeholder}
              {...form.register('address', validations.address)}
            />
          </PaymentFormField>
          <PaymentFormField
            labelText={field('country').label}
            required
            disabled={isProcessing}
            error={!!form.formState.errors.country}
            errorText={form.formState.errors.country?.message}
            {...form.register('country', validations.country)}
          >
            <CountrySelect
              placeholder={field('country').placeholder}
              value={form.watch('country')}
              onChange={(country): void => form.setValue('country', country)}
            />
          </PaymentFormField>
          <PaymentFormField
            name="city"
            labelText={field('city').label}
            required
            disabled={isProcessing}
            error={!!form.formState.errors.city}
            errorText={form.formState.errors.city?.message}
          >
            <OutlinedInput
              placeholder={field('city').placeholder}
              {...form.register('city', validations.city)}
            />
          </PaymentFormField>
          <PaymentFormField
            name="state"
            labelText={field('state').label}
            disabled={isProcessing}
            error={!!form.formState.errors.state}
            errorText={form.formState.errors.state?.message}
          >
            <OutlinedInput
              placeholder={field('state').placeholder}
              {...form.register('state', validations.state)}
            />
          </PaymentFormField>
          <PaymentFormField
            name="postalCode"
            required
            labelText={field('postalCode').label}
            disabled={isProcessing}
            error={!!form.formState.errors.postalCode}
            errorText={form.formState.errors.postalCode?.message}
          >
            <OutlinedInput
              placeholder={field('postalCode').placeholder}
              {...form.register('postalCode', validations.postalCode)}
            />
          </PaymentFormField>
          <PaymentFormField
            name="company"
            labelText={field('company').label}
            disabled={isProcessing}
            error={!!form.formState.errors.company}
            errorText={form.formState.errors.company?.message}
          >
            <OutlinedInput
              placeholder={field('company').placeholder}
              {...form.register('company', validations.company)}
            />
          </PaymentFormField>
          <PaymentFormField
            name="vatId"
            labelText={field('vatId').label}
            disabled={isProcessing}
            error={!!form.formState.errors.vatId}
            errorText={form.formState.errors.vatId?.message}
          >
            <OutlinedInput
              placeholder={field('vatId').placeholder}
              {...form.register('vatId', validations.vatId)}
            />
          </PaymentFormField>
        </Box>
      </PaymentFormFieldset>
    </form>
  );
};
