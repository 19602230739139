import dayjs from 'dayjs';
import { inject, injectable } from 'inversify';
import { catchError, from, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

import {
  ACCOUNT_TYPES,
  AUTH_TYPES,
  ONBOARDING_TYPES,
  STORAGE_TYPES,
  SYNC_TYPES,
} from '@/ioc/types';

import { COUNTRIES_DC, IAccountUseCase } from '@/features/common/account';
import {
  IAuthRepository,
  InvalidWorkEmailError,
  UserAlreadyExistsError,
} from '@/features/common/auth';
import type { IOnboardingUseCase } from '@/features/common/onboarding';
import type { IStorageRepository } from '@/features/system/storage/index';
import type { IBaseSyncUseCase } from '@/features/system/sync';

import { WorkEmailValidationSchema } from '@/utils/validation';

type FullFillUserDataParams = {
  displayName: string;
  phoneNumber: string;
  unconfirmedEmail?: string;
  country: COUNTRIES_DC;
};

type SetupAccountParams = {
  info: FullFillUserDataParams;
  credentials?: {
    email: string;
    password: string;
  };
};

export interface ISignUpUseCases {
  signUpGoogle(): Observable<void>;
  signUpMicrosoft(): Observable<void>;
  signUpWithEmail(email: string): Observable<void>;
  sendEmailVerification(email: string): Observable<void>;
  verificateEmail(params: { code: string; email: string }): Observable<void>;
  setupAccount(params: SetupAccountParams): Observable<void>;
  checkUserExists(email: string): Observable<boolean>;
  setLockTime(minutes: number): Observable<void>;
  getLockTime(): Observable<number>;
  getUserCountry(): Observable<string>;
  clearLockTime(): Observable<void>;
}

@injectable()
export class SignUpUseCases implements ISignUpUseCases {
  handleSuccessRedirects(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  @inject(ACCOUNT_TYPES.AccountUseCase)
  private accountUseCase: IAccountUseCase;

  @inject(STORAGE_TYPES.StorageRepository)
  private storage: IStorageRepository;

  @inject(SYNC_TYPES.BaseSyncUseCase)
  private baseSyncUseCase: IBaseSyncUseCase;

  @inject(ONBOARDING_TYPES.OnboardingUseCase)
  private onboardingUseCase: IOnboardingUseCase;

  private readonly VERIFICATION_LOCK_TIME_KEY = 'VERIFICATION_LOCK_TIME';

  getUserCountry(): Observable<string> {
    return this.authRepository.getUserCountry();
  }

  setupAccount({ credentials, info }: SetupAccountParams): Observable<void> {
    if (credentials) {
      //HACK: do not sync data until we finish manual account creation
      return this.baseSyncUseCase.withStoppedSync(
        this.authRepository.checkUserExists(credentials.email).pipe(
          switchMap((isExisted: boolean) => {
            if (isExisted) return throwError(() => new UserAlreadyExistsError());

            return this.authRepository.signUpWithEmailAndPassword(
              credentials.email,
              credentials.password,
            );
          }),
          switchMap(() =>
            this.accountUseCase.setupAccount({
              fullName: info.displayName,
              phone: info.phoneNumber,
              country: info.country,
            }),
          ),
          switchMap(() => this.accountUseCase.deleteEmailForAccountCreation()),
          tap(() => this.storage.save({ hasSignedUp: 'true' })),
        ),
      );
    }

    return this.baseSyncUseCase.withStoppedSync(
      this.accountUseCase
        .setupAccount({
          fullName: info.displayName,
          phone: info.phoneNumber,
          country: info.country,
        })
        .pipe(tap(() => this.onboardingUseCase.completeSignupStep())),
    );
  }

  signUpGoogle(): Observable<void> {
    return this.authRepository.signUpWithGoogle().pipe(
      switchMap(() => {
        this.storage.save({ hasSignedUp: 'true' });
        return of(void 0);
      }),
    );
  }

  signUpWithEmail(email: string): Observable<void> {
    return this.authRepository.checkUserExists(email).pipe(
      switchMap((isExists) => {
        if (isExists) {
          return throwError(() => new UserAlreadyExistsError());
        }

        return from(WorkEmailValidationSchema.validate(email)).pipe(
          catchError(() => throwError(() => new InvalidWorkEmailError())),
        );
      }),
      switchMap(() => this.accountUseCase.saveEmailForAccountCreation(email)),
    );
  }

  verificateEmail(params: { code: string; email: string }): Observable<void> {
    return this.authRepository.applyActionCode(params.code).pipe(
      switchMap(() => {
        return this.accountUseCase.syncAccount(params.email);
      }),
      tap(() => {
        this.onboardingUseCase.completeSignupStep();
      }),
    );
  }

  signUpMicrosoft(): Observable<void> {
    return this.authRepository.signUpWithMicrosoft().pipe(
      tap(() => this.storage.save({ hasSignedUp: 'true' })),
      map(() => void 0),
    );
  }

  checkUserExists(email: string): Observable<boolean> {
    return this.authRepository.checkUserExists(email);
  }

  sendEmailVerification(email: string): Observable<void> {
    return this.authRepository.sendVerificationEmail(email);
  }

  setLockTime(minutes: number): Observable<void> {
    this.storage.save({
      [this.VERIFICATION_LOCK_TIME_KEY]: dayjs().add(minutes, 'minutes').unix(),
    });

    return of(void 0);
  }

  getLockTime(): Observable<number> {
    return this.storage.get$(this.VERIFICATION_LOCK_TIME_KEY).pipe(
      map((data) => Number(data)),
      map((data) => {
        if (Number.isFinite(data)) {
          return data;
        }

        return 0;
      }),
      catchError(() => of(0)),
    );
  }

  clearLockTime(): Observable<void> {
    this.storage.delete(this.VERIFICATION_LOCK_TIME_KEY);
    return of(void 0);
  }
}
