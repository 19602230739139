import { IBillingInvoiceEntity } from '../../domain';
import { IBillingInvoiceDC } from '../dataContracts';

export function mapBillingInvoiceDcToEntity(
  dc: IBillingInvoiceDC,
): IBillingInvoiceEntity {
  return {
    transactionId: dc.transaction_id,
    invoiceId: dc.invoice_id,
    amount: dc.amount,
    currency: dc.currency,
    description: dc.description,
    url: dc.url,
    date: dc.date,
  };
}
