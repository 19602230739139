import { useTeamMembersUseCase } from '@/features/settings/features/teamManagement/ui/hooks/useTeamMembersUseCase';

import { useObservableResult } from '@/utils/rx';

type UseUserCellViewModelResult = {
  value: string;
};

export const useUserCellViewModel = (params: {
  uuid: string;
  fullName: string;
}): UseUserCellViewModelResult => {
  const teamMembersUseCase = useTeamMembersUseCase();
  const { data } = useObservableResult(
    () => {
      return teamMembersUseCase.getById(params.uuid);
    },
    {
      deps: [params.uuid],
    },
  );

  return {
    value: data?.fullName ?? (params.fullName || ''),
  };
};
