import { FC, HTMLAttributes } from 'react';
import { Box } from '@mui/material';
import { clsx } from 'clsx';

import { CheckIcon } from '@/components/Icons';

import { SelectBaseOption } from './types';

import styles from './styles.module.scss';

export type CustomSelectDefaultOptionType = SelectBaseOption;

export type CustomSelectDefaultOptionProps = HTMLAttributes<HTMLDivElement> & {
  option: CustomSelectDefaultOptionType;
  optionDescription?: string;
  selected?: boolean;
};

export const CustomSelectDefaultOption: FC<CustomSelectDefaultOptionProps> = ({
  option,
  selected,
  className,
  optionDescription,
  ...props
}) => {
  return (
    <Box
      {...props}
      className={clsx(
        styles.defaultOption,
        {
          defaultOptionSelected: selected,
        },
        className,
      )}
    >
      <Box className={styles.optionWrapper}>
        <Box component="span">{option.label}</Box>
        {optionDescription && <Box component="span">{optionDescription}</Box>}
      </Box>
      {selected && (
        <Box component="span" className={styles.defaultOptionIcon}>
          <CheckIcon />
        </Box>
      )}
    </Box>
  );
};
