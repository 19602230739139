import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { useAcceptedTeamMembers } from '@/features/settings/features/teamManagement/ui/hooks';

type UseTeamMembersCardViewModelReturn = {
  teamMembers: number;
  inviteMembersButtonText: string;
  isLoading: boolean;
  handleInviteMembers: () => void;
};

export const useTeamMembersCardViewModel = (): UseTeamMembersCardViewModelReturn => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');

  const { teamMembers: teamMembersList } = useAcceptedTeamMembers();
  const teamMembers = teamMembersList.length;
  const inviteMembersButtonText =
    teamMembers < 2 ? t('inviteTeamMembers') : t('manageMembers');

  const handleInviteMembers = (): void => {
    const options =
      teamMembers < 2
        ? {
            state: { isInviteTeamMemberModalOpen: true },
          }
        : undefined;

    navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT, options);
  };

  return {
    teamMembers,
    inviteMembersButtonText,
    isLoading: teamMembers < 1,
    handleInviteMembers,
  };
};
