import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { AuthIdentityProvider } from '@/features/common/auth';
import { useGetFreeCreditsUseCase } from '@/features/getFreeCredits';

import { useUserMenuHandlers } from '@/components/header/hooks';

import { GetFreeCreditsDialogSubmitHandlers } from '../GetFreeCreditsDialog.types';

type ProvideContactsCurryResult = () => Promise<void>;

export const useSubmitHandlers = (): GetFreeCreditsDialogSubmitHandlers => {
  const { t } = useTranslation('dashboard');
  const { enqueueSnackbar } = useSnackbar();
  const useCase = useGetFreeCreditsUseCase();
  const { onClose: closeUserMenu } = useUserMenuHandlers();

  const submitCurry =
    (
      provider?: AuthIdentityProvider.google | AuthIdentityProvider.microsoft,
    ): ProvideContactsCurryResult =>
    async (): Promise<void> => {
      try {
        await useCase.provideContacts(provider);
        enqueueSnackbar(t('getFreeCreditsDialog.success'), {
          variant: 'success',
        });
      } catch {
        enqueueSnackbar(t('getFreeCreditsDialog.error'), {
          variant: 'error',
        });
      } finally {
        closeUserMenu();
      }
    };

  const onFederatedSubmit = submitCurry();
  const onMicrosoftSubmit = submitCurry(AuthIdentityProvider.microsoft);
  const onGoogleSubmit = submitCurry(AuthIdentityProvider.google);

  return {
    onFederatedSubmit,
    onGoogleSubmit,
    onMicrosoftSubmit,
  };
};
