import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { useAnalyticsCardViewModel } from '@/features/dashboard/features/chart/ui/AnalyticsCardViewModel';
import { ChartLegend } from '@/features/dashboard/features/chart/ui/components/ChartLegend';

import { DateSelect } from '@/components';

import AnalyticsCardPreview from '@/assets/icons/analyticsPreview.svg?react';

import { MemberSelect } from './components/MemberSelect';
import { AnalyticsChart } from './components';

import dashboardStyles from '../../../ui/styles.module.scss';
import styles from './styles.module.scss';

export const AnalyticsCard: FC = () => {
  const { t } = useTranslation('dashboard');

  const {
    labels,
    values,
    tooltipTitles,
    creditsUsed,
    emailAddresses,
    phoneNumbers,
    selectedUser,
    handleUsersChange,
    isLoading,
    handleDateChange,
    selectedDate,
    minDate,
  } = useAnalyticsCardViewModel();

  if (isLoading) {
    return <AnalyticsCardPreview preserveAspectRatio="none" height="316px" />;
  }

  return (
    <Box className={`${dashboardStyles.card} ${styles.analyticsCard}`}>
      <Box className={styles.titleWrapper}>
        <Typography variant="h1">{t('analytics.analytics')}</Typography>
      </Box>

      <Box className={styles.container}>
        <Box className={styles.leftContainer}>
          <Box className={styles.actionsBar}>
            <Box className={styles.descriptionWrapper}>
              <Typography variant="h3">{t('analytics.revealedProspects')}</Typography>
            </Box>
            <Box className={styles.selectsWrapper}>
              <MemberSelect member={selectedUser} onMemberChange={handleUsersChange} />
              <DateSelect
                onChange={handleDateChange}
                value={selectedDate}
                minDate={minDate}
              />
            </Box>
          </Box>
          <Box className={styles.chartWrapper}>
            <AnalyticsChart
              tooltipTitles={tooltipTitles}
              labels={labels}
              values={values}
              creditsUsed={creditsUsed}
              selectedDate={selectedDate}
              selectedUser={selectedUser}
            />
          </Box>
        </Box>

        <Box className={styles.rightContainer}>
          <ChartLegend
            creditsUsed={creditsUsed}
            emailAddresses={emailAddresses}
            phoneNumbers={phoneNumbers}
          />
        </Box>
      </Box>
    </Box>
  );
};
