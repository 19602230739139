import { useEffect, useState } from 'react';

import { useAccount } from '@/features/common/account';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

type UseCopyInviteLinkViewModel = () => {
  referralLink: string;
  handleCopy: () => void;
  isCopied: boolean;
};

export const useCopyInviteLinkViewModel: UseCopyInviteLinkViewModel = () => {
  const { account } = useAccount();
  const referralLink = `${location.origin}/referrals/${account?.referrerToken}`;
  const { trackEvent } = useAnalytics();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout((): void => {
        setIsCopied(false);
      }, 5_000);

      return (): void => {
        clearTimeout(timeout);
      };
    }
  }, [isCopied]);

  const handleCopy = (): void => {
    trackEvent(ANALYTICS_EVENTS.REFERRAL_COPY_REFERRAL_LINK);

    navigator.clipboard.writeText(referralLink).then(() => {
      setIsCopied(true);
    });
  };

  return {
    referralLink: `${location.origin}/referrals/${account?.referrerToken}`,
    handleCopy,
    isCopied,
  };
};
