import { useMemo } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { CONTACT_LIST_TYPES } from '@/ioc/types';

import { IContactListEntity, IContactListUseCase } from '@/features/common/contactList';

import { useObservableResult } from '@/utils/rx';

export const useContactListEntities = (): IContactListEntity[] => {
  const contactListUseCase = useInjection<IContactListUseCase>(
    CONTACT_LIST_TYPES.ContactListUseCase,
  );

  const contactListEntities$ = useMemo(() => {
    return contactListUseCase.getContactListByQuery({});
  }, []);

  const { data: contactListEntities } = useObservableResult(contactListEntities$, {
    defaultData: [],
  });

  return contactListEntities;
};
