import { inject, injectable } from 'inversify';
import { first, map, Observable, switchMap } from 'rxjs';

import { ACCOUNT_TYPES, CONTACT_LIST_TYPES } from '@/ioc/types';

import { IAccountUseCase } from '@/features/common/account';
import { IAccountPermissionsUseCase } from '@/features/common/account/domain';
import {
  IContactListEntity,
  IContactListRepository,
  IContactListUseCase,
} from '@/features/common/contactList';

import {
  IGetContactListByQueryUseCaseDto,
  IUpdateContactListUseCaseDto,
} from './abstractions/dto';
import { ContactListNotFoundError } from './errors';

@injectable()
export default class ContactListUseCase implements IContactListUseCase {
  @inject(CONTACT_LIST_TYPES.ContactListRepository)
  private readonly contactListRepository: IContactListRepository;

  @inject(ACCOUNT_TYPES.AccountUseCase)
  private readonly accountUseCase: IAccountUseCase;

  @inject(ACCOUNT_TYPES.AccountPermissionsUseCase)
  private readonly accountPermissionsUseCase: IAccountPermissionsUseCase;

  public getDefaultContactList(): Observable<IContactListEntity | null> {
    return this.contactListRepository.getDefaultContactList();
  }

  public getContactListByQuery(
    dto: IGetContactListByQueryUseCaseDto,
  ): Observable<IContactListEntity[]> {
    return this.accountPermissionsUseCase.getAccountPermissions().pipe(
      switchMap((accountPermissionsData) => {
        const query = { ...dto };
        if (!accountPermissionsData.permissions.CAN_ACCESS_ALL_CONTACTS) {
          Object.assign(query, { createdBy: accountPermissionsData.uuid });
        }

        return this.contactListRepository.getContactListByQuery(query);
      }),
    );
  }

  public getContactListById(id: string): Observable<IContactListEntity> {
    return this.contactListRepository.getContactListById(id).pipe(
      map((value) => {
        if (!value) throw new ContactListNotFoundError(id);

        return value;
      }),
    );
  }

  public createContactList(dto: { name: string }): Observable<IContactListEntity> {
    return this.accountUseCase.getAccount().pipe(
      first(),
      switchMap((account) => {
        return this.contactListRepository.createContactList({
          ...dto,
          createdBy: account?.uuid ?? '',
        });
      }),
    );
  }

  public updateContactList(
    dto: IUpdateContactListUseCaseDto,
  ): Observable<IContactListEntity> {
    return this.contactListRepository.updateContactList(dto);
  }

  public deleteContactList(uuid: string): Observable<boolean> {
    return this.contactListRepository.deleteContactList(uuid);
  }
}
