import { inject, injectable } from 'inversify';
import { Observable, of, switchMap, throwError } from 'rxjs';

import { BILLING_TYPES, PAYMENT_DETAILS_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import {
  BillingCycle,
  IBillingConfig,
  IStripePromotionCodeEntity,
  PlanType,
  StripeCouponDuration,
} from '@/features/common/billing';
import {
  getPlanTypeFromSubscription,
  IDiscountEntity,
  isPlanType,
} from '@/features/common/billing/domain';
import { IWorkspaceRepository, SubscriptionPlan } from '@/features/common/workspace';
import { isPlanUpgrade } from '@/features/plans/ui/utils';

import { IPaymentDetailsRepository } from '../data';

import { IPaymentDetailsUseCase } from './abstractions/useCases/IPaymentDetailsUseCase';
import { IInvoiceDataEntity } from './entities';
import { IReciept, IRecieptDiscountInfo, PromotionDuration } from './types';

type IProductInfo = NonNullable<IReciept['product']>;
type IDiscountInfo = NonNullable<IReciept['discounts']>;
type ITaxInfo = NonNullable<IReciept['tax']>;
type IPromotionInfo = NonNullable<IReciept['promotion']>;

@injectable()
export class PaymentDetailsUseCase implements IPaymentDetailsUseCase {
  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private readonly workspaceRepository: IWorkspaceRepository;

  @inject(BILLING_TYPES.BillingConfig)
  private readonly billingConfig: IBillingConfig;

  @inject(PAYMENT_DETAILS_TYPES.PaymentDetailsRepository)
  private paymentDetailsRepository: IPaymentDetailsRepository;

  private getProductInfo(params: {
    planType: PlanType;
    billingCycle: BillingCycle;
    numberOfUsers: number;
  }): IProductInfo {
    const pricePerUnit = this.billingConfig.basePlanPrices[params.planType];
    const cycleMultiplier = params.billingCycle === BillingCycle.Monthly ? 1 : 12;

    return {
      pricePerUnit,
      price: pricePerUnit * params.numberOfUsers * cycleMultiplier,
      name: params.planType,
    };
  }

  private getDiscountInfo(
    { name, rate, isOneTime, displayRate }: IDiscountEntity,
    total: number,
  ): IRecieptDiscountInfo {
    return {
      name,
      amount: total * rate,
      percent: Math.round(rate * 100),
      displayPercent: displayRate,
      isOneTime,
    };
  }

  private getTaxInfo(sum: number, taxRate: number): ITaxInfo {
    return {
      amount: sum * taxRate,
      percent: taxRate * 100,
    };
  }

  private getPromotionInfo(
    sum: number,
    promotion: IStripePromotionCodeEntity,
  ): IPromotionInfo {
    const name = promotion.code;

    const duration = {
      [StripeCouponDuration.Forever]: PromotionDuration.Forever,
      [StripeCouponDuration.Once]: PromotionDuration.Once,
      [StripeCouponDuration.Repeating]: PromotionDuration.Repeating,
    }[promotion.coupon.duration];

    if (promotion.coupon.percentOff) {
      return {
        name,
        amount: sum * (promotion.coupon.percentOff / 100),
        percent: promotion.coupon.percentOff,
        duration,
      };
    }

    if (promotion.coupon.amountOff) {
      const amountOff = promotion.coupon.amountOff / 100;

      return {
        name,
        amount: amountOff,
        percent: (amountOff / sum) * 100,
        duration,
      };
    }

    return {
      name,
      amount: 0,
      percent: 0,
      duration,
    };
  }

  getReciept(params: {
    planType: PlanType;
    billingCycle: BillingCycle;
    promotionCode?: IStripePromotionCodeEntity;
  }): Observable<IReciept> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      switchMap((workspace) => {
        if (!workspace) {
          return throwError(() => new Error('Workspace not found'));
        }

        const numberOfPayableUsers = workspace.billableMembersCount || 1;

        const product = this.getProductInfo({
          planType: params.planType,
          billingCycle: params.billingCycle,
          numberOfUsers: numberOfPayableUsers,
        });

        let total = product.price;
        let nextChargeAmount = product.price;
        const discounts: IDiscountInfo | undefined = [];
        let promotion: IPromotionInfo | undefined;

        const relatedDiscounts =
          this.billingConfig.discounts?.[params.planType]?.[params.billingCycle];

        if (relatedDiscounts) {
          let shouldCalculateNextPaymentTotal = false;
          let discountSum = 0;

          relatedDiscounts.forEach((d) => {
            if (d.isOneTime && !shouldCalculateNextPaymentTotal) {
              shouldCalculateNextPaymentTotal = true;
            }

            const discountInfo = this.getDiscountInfo(d, total - discountSum);

            discountSum += discountInfo.amount;
            discounts.push(discountInfo);
          });

          total = discounts.reduce((acc, current) => {
            return (acc = acc - current.amount);
          }, total);
          nextChargeAmount = total;

          if (shouldCalculateNextPaymentTotal) {
            const oneTimeDiscountAmount = discounts
              .filter((d) => !!d.isOneTime)
              .reduce((acc, curr) => {
                return (acc = acc + curr.amount);
              }, 0);

            nextChargeAmount += oneTimeDiscountAmount;
          }
        }

        const currentPlanType = getPlanTypeFromSubscription(workspace.subscription);
        const isCurrentPlanPaid =
          isPlanType(currentPlanType) && currentPlanType !== PlanType.Free;
        const isUpgrade =
          isPlanUpgrade(params.planType, currentPlanType) && isCurrentPlanPaid;

        if (isUpgrade) {
          //
        }

        if (params.promotionCode) {
          promotion = this.getPromotionInfo(total, params.promotionCode);
          total = total - promotion.amount;
        }

        const tax = this.getTaxInfo(total, this.billingConfig.taxRates.default);
        total = total + tax.amount;

        const reciept: IReciept = {
          currency: 'USD',
          cycle: params.billingCycle,
          product,
          discounts,
          promotion,
          tax,
          total,
          nextCharge: {
            amount: nextChargeAmount,
          },
        };

        return of(reciept);
      }),
    );
  }

  getInvoiceData(plan: SubscriptionPlan): Observable<IInvoiceDataEntity> {
    return this.paymentDetailsRepository.getInvoiceData(plan);
  }
}
