import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { SubscriptionPlan } from '@/features/common/workspace';
import { IHttpClient } from '@/features/system/network';

import { IPaymentDetailsApiService } from '../abstractions/IPaymentDetailsApiService';
import { IInvoiceDataDC } from '../dataContracts';

@injectable()
export class PaymentDetailsApiCervice implements IPaymentDetailsApiService {
  @inject(NETWORK_TYPES.HttpClient) private httpClient: IHttpClient;

  getInvoiceData(plan: SubscriptionPlan): Observable<IInvoiceDataDC> {
    return this.httpClient
      .post<IInvoiceDataDC>('/billing/upcoming-invoice', {
        plan,
      })
      .pipe(
        map(({ data }) => {
          return data;
        }),
      );
  }
}
