import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { useInjection } from '@/ioc/ioc.react';
import { SIGN_UP_TYPES } from '@/ioc/types';

import { ISignUpUseCases } from '@/features/signup/domain/SignUpUseCases';

import { useObservableResult } from '@/utils/rx';

type UseResendLockTimer = (minutes: number) => {
  lock(): void;
  isLocked: boolean;
  lockedUntil: number;
  onTimerComplete: () => void;
};

export const useLockTimer: UseResendLockTimer = (minutes: number) => {
  const signUpUseCase = useInjection<ISignUpUseCases>(SIGN_UP_TYPES.SignUpUseCases);
  const lockedUntil$ = useMemo(() => signUpUseCase.getLockTime(), []);
  const lockedUntil = useObservableResult(lockedUntil$, { defaultData: 0 });
  const isLocked = lockedUntil.data > dayjs().unix();

  const onTimerComplete = useCallback(() => {
    signUpUseCase.clearLockTime().subscribe({
      error: (err) => {
        console.error('Error clearing lock state:', err);
      },
    });
  }, []);

  return {
    lock: (): void => {
      if (isLocked) {
        return;
      }

      signUpUseCase.setLockTime(minutes).subscribe();
    },
    isLocked,
    lockedUntil: lockedUntil.data,
    onTimerComplete,
  };
};
