import React, { createContext, FC, PropsWithChildren, useMemo, useState } from 'react';

import { IUserMenuContext } from '@/components/header/contexts/UserMenu/UserMenuContext.types';

export const UserMenuContext = createContext<IUserMenuContext>({
  isUserMenuOpen: false,
  anchorEl: null,
  onOpen: () => void 0,
  onClose: () => void 0,
});

export const UserMenuContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const onOpen = (e: React.MouseEvent<HTMLElement>): void => {
    setIsUserMenuOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const onClose = (): void => {
    setIsUserMenuOpen(() => false);
    setAnchorEl(() => null);
  };

  const value = useMemo(
    () => ({
      isUserMenuOpen,
      anchorEl,
      onOpen,
      onClose,
    }),
    [isUserMenuOpen, anchorEl],
  );

  return <UserMenuContext.Provider value={value}>{children}</UserMenuContext.Provider>;
};
