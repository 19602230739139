import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { array, object, string } from 'yup';

import { IIntegrationEntity } from '@/features/integration/domain';
import { IMappingByObjectTypeEntityUI } from '@/features/integration/ui/entities';
import { useAppLogger } from '@/features/system/logger';

import { useFormWithSchema } from '@/utils/validation';

import { useIntegrationUseCase } from '../../../hooks';

import {
  FieldMappingFormValues,
  FieldMappingOptions,
  FieldOption,
  Mapping,
} from './types';

type UseFieldMappingForm = (params: {
  integration: IIntegrationEntity;
  integrationMapping: IMappingByObjectTypeEntityUI;
}) => {
  form: UseFormReturn<FieldMappingFormValues>;
  options: FieldMappingOptions;
  disabled: boolean;
  handleSubmit: () => void;
};

const POWERLEAD_PREDEFINED_FIELD = 'predefined_value';
const POWERLEAD_PREDEFINED_FIELD_DISPLAY = 'Predefined Value';

function mapMappingToUI(mapping: Mapping): Mapping {
  return mapping.map((m) => ({
    ...m,
    powerleadField: m.predefinedValue ? POWERLEAD_PREDEFINED_FIELD : m.powerleadField,
  }));
}

function mapUIToMapping(mapping: Mapping): Mapping {
  return mapping.map((m) => ({
    ...m,
    powerleadField:
      m.powerleadField === POWERLEAD_PREDEFINED_FIELD ? null : m.powerleadField,
  }));
}

export const useFieldMappingForm: UseFieldMappingForm = ({
  integration,
  integrationMapping,
}) => {
  const appLogger = useAppLogger();
  const { enqueueSnackbar } = useSnackbar();
  const integrationUseCase = useIntegrationUseCase();
  const { t: tSaving } = useTranslation('integrations', {
    keyPrefix: 'integration.tabs.fieldMapping',
  });

  const formValues: FieldMappingFormValues = useMemo(() => {
    return Object.entries(integrationMapping).reduce((acc, [objectType, { mapping }]) => {
      acc[objectType] = mapMappingToUI(mapping);
      return acc;
    }, {} satisfies FieldMappingFormValues);
  }, [integrationMapping]);

  const schema = useMemo(() => {
    const mappingSchema = object().shape({
      powerleadField: string().required(),
      providerField: string().required('Provider field is required'),
      predefinedValue: string().when('powerleadField', {
        is: POWERLEAD_PREDEFINED_FIELD,
        then: (b) => b.required('Predefined value is required'),
        otherwise: (b) => b.notRequired(),
      }),
    });

    return object().shape(
      Object.keys(integrationMapping).reduce((acc, objectType) => {
        acc[objectType] = array().of(mappingSchema);
        return acc;
      }, {}),
    );
  }, [integrationMapping]);

  const form: UseFormReturn<FieldMappingFormValues> = useFormWithSchema(schema, {
    defaultValues: formValues,
  });

  const formState = form.watch();

  const options = useMemo(() => {
    const predefinedFieldOption: FieldOption = {
      disabled: false,
      value: POWERLEAD_PREDEFINED_FIELD,
      label: POWERLEAD_PREDEFINED_FIELD_DISPLAY,
      example: null,
    };

    return Object.entries(integrationMapping).reduce(
      (acc, [objectType, { powerleadFields, providerFields }]) => {
        acc[objectType] = {
          powerleadFields: [predefinedFieldOption].concat(
            Object.entries(powerleadFields).map(
              ([value, { example, display: label }]) => ({
                value,
                label,
                disabled:
                  formState[objectType]?.some((m) => m.powerleadField === value) ?? false, // expensive calculation
                example,
              }),
            ),
          ),
          providerFields: Object.entries(providerFields).map(([value, label]) => ({
            value,
            label,
            disabled:
              formState[objectType]?.some((m) => m.providerField === value) ?? false, //expensive calculation
            example: null,
          })),
        };
        return acc;
      },
      {},
    );
  }, [formState, integrationMapping]);

  // reset form on persist data change
  useEffect(() => {
    form.reset(formValues);
  }, [formValues]);

  const submitValues = structuredClone(formState);

  const handleSubmit = form.handleSubmit(
    async () => {
      const { provider } = integration;
      try {
        await Promise.all(
          Object.entries(submitValues).map(([objectType, uiMapping]) =>
            integrationUseCase.saveMapping(
              provider,
              objectType,
              mapUIToMapping(uiMapping),
            ),
          ),
        );
        enqueueSnackbar({
          message: tSaving('savingSuccess'),
          variant: 'success',
        });
      } catch (e) {
        appLogger.error(e);
        enqueueSnackbar({
          variant: 'error',
          message: tSaving('savingError', { name: provider }),
        });
      }
    },
    () => {
      enqueueSnackbar({
        variant: 'error',
        message: tSaving('validationError'),
      });
    },
  );

  return {
    form,
    options,
    disabled: form.formState.isSubmitting || !form.formState.isDirty,
    handleSubmit,
  };
};
