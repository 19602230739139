import { Container } from 'inversify';

import { REQUEST_CACHE_TYPES } from '@/ioc/types';

import { InMemoryRequestCache } from '../data/InMemoryRequestCache';
import { IRequestCache } from '../data/RequestCache';

export const bindRequestCacheModule = (container: Container): void => {
  container
    .bind<IRequestCache>(REQUEST_CACHE_TYPES.InMemoryRequestCache)
    .to(InMemoryRequestCache)
    .inSingletonScope();
};
