import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ACCESS_PERMISSIONS,
  useAccount,
  usePermissions,
} from '@/features/common/account';
import { ITeamMemberEntity } from '@/features/settings';

import { useAcceptedTeamMembers } from './useAcceptedTeamMembers';

type Option = {
  label: string;
  value: string;
};

const mapTeamMemberEntityToSelectOption = (
  teamMember: ITeamMemberEntity,
  isCurrent: boolean,
): Option => ({
  value: teamMember.uuid,
  label: isCurrent ? `${teamMember.fullName} (Me)` : (teamMember.fullName ?? ''),
});

export const useTeamMemberSelectOptions = (excludeAllOption = false): Option[] => {
  const { t } = useTranslation('common');
  const { teamMembers } = useAcceptedTeamMembers();
  const { account } = useAccount();
  const { hasPermission } = usePermissions();
  const canAccessAllContacts = hasPermission(ACCESS_PERMISSIONS.CAN_ACCESS_ALL_CONTACTS);

  return useMemo<Option[]>(() => {
    const options: Option[] = [
      ...(excludeAllOption
        ? []
        : [
            {
              value: '',
              label: t('filters.byUser.defaultValueLabel'),
            },
          ]),
      ...teamMembers.map((memeber) =>
        mapTeamMemberEntityToSelectOption(memeber, memeber.uuid === account?.uuid),
      ),
    ];

    if (!canAccessAllContacts) {
      return options.filter((option) => option.value === account?.uuid);
    }

    return options;
  }, [excludeAllOption, teamMembers, account?.uuid, canAccessAllContacts, t]);
};
