import { MouseEvent, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useContactExportProgress, useExportContacts } from '@/features/common/contact';
import { useIntegrations } from '@/features/integrations/ui/hooks';
import { ANALYTICS_EVENTS, Platform, useAnalytics } from '@/features/system/analytics';

import { ContactsContext } from '../../context';

type UseExportActionViewModelResult = {
  anchorEl: Element | undefined;
  options: MenuItemOption[];
  selectedContactsCount: number;
  menuOpen: boolean;
  exportProgress: ReturnType<typeof useContactExportProgress>;
  onCloseMenu(): void;
  onOpenMenu(e: MouseEvent<HTMLButtonElement>): void;
  onRetry(): void;
  onExportToCsv(): Promise<void>;
  onExportToIntegration(option: MenuItemOption): Promise<void>;
};

type MenuItemOption = {
  label: string;
  icon: string;
  value: string;
};

export const useExportActionViewModel = (): UseExportActionViewModelResult => {
  const retryFunction = useRef<() => Promise<void>>();
  const [anchorEl, setAnchorEl] = useState<Element | undefined>();
  const { selectedContacts, contactURLSearchParams, contactEntitiesCount } =
    useContext(ContactsContext);
  const {
    onExportToCsvByIds,
    onExportToCsvByFilters,
    onExportToIntegration: exportToIntegration,
  } = useExportContacts();
  const analytics = useAnalytics();
  const { data: integrations } = useIntegrations();
  const { t } = useTranslation('contacts');
  const [menuOpen, setMenuOpen] = useState(false);

  const selectedContactsCount = selectedContacts.length || contactEntitiesCount;

  const exportProgress = useContactExportProgress();
  const options: MenuItemOption[] = integrations
    .filter((i) => i.status === 'connected')
    .map((integration) => ({
      label: integration.name,
      icon: integration.icon,
      value: integration.id,
    }));

  const onCloseMenu = (): void => {
    setMenuOpen(false);
  };

  const onOpenMenu = (e: MouseEvent<HTMLButtonElement>): void => {
    analytics.trackEvent(ANALYTICS_EVENTS.EXPORT_BUTTON_CLICK, {
      platform: Platform.Web,
    });
    setMenuOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const onExportToCsv = async (): Promise<void> => {
    try {
      setMenuOpen(false);
      retryFunction.current = (): Promise<void> => onExportToCsv();
      exportProgress.setConfig(() => ({
        open: true,
        status: 'pending',
        provider: { key: 'csv', label: t('subhead.export.menu.csv') },
      }));
      if (selectedContacts.length) {
        await onExportToCsvByIds(selectedContacts);
      } else {
        await onExportToCsvByFilters(contactURLSearchParams);
      }
      exportProgress.setConfig(() => ({ status: 'finished' }));
    } catch (error) {
      exportProgress.setConfig(() => ({ status: 'failed' }));
      throw error;
    }
  };

  const onExportToIntegration = async (option: MenuItemOption): Promise<void> => {
    try {
      setMenuOpen(false);
      retryFunction.current = (): Promise<void> => onExportToIntegration(option);
      exportProgress.setConfig(() => ({
        open: true,
        status: 'pending',
        provider: { key: 'integration', label: option.label },
      }));
      if (selectedContacts.length) {
        await exportToIntegration({
          provider: option.value,
          ids: selectedContacts,
        });
        exportProgress.setConfig(() => ({
          status: 'finished',
        }));
      } else {
        const searchParams = new URLSearchParams(contactURLSearchParams);
        searchParams.set('length', contactEntitiesCount.toString());
        await exportToIntegration({
          provider: option.value,
          searchParams,
        });
        exportProgress.setConfig(() => ({
          status: 'finished',
        }));
      }
    } catch (error) {
      exportProgress.setConfig(() => ({
        status: 'failed',
      }));
      throw error;
    }
  };

  const onRetry = (): void => {
    if (!retryFunction.current) return;

    retryFunction.current();
  };

  return {
    anchorEl,
    menuOpen,
    options,
    selectedContactsCount,
    exportProgress,
    onCloseMenu,
    onOpenMenu,
    onRetry,
    onExportToCsv,
    onExportToIntegration,
  };
};
