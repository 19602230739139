import { inject, injectable } from 'inversify';
import {
  catchError,
  filter,
  firstValueFrom,
  from,
  map,
  Observable,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

import { BILLING_TYPES, REQUEST_CACHE_TYPES } from '@/ioc/types';

import {
  IWorkspaceEntity,
  IWorkspaceSubscriptionEntity,
  SubscriptionPlan,
} from '@/features/common/workspace';
import { NetworkError } from '@/features/system/network';
import { IRequestCache } from '@/features/system/requestCache';

import {
  IBillingInvoiceEntity,
  IPaymentMethodEntity,
  IPaymentMethodUpdateSessionEntity,
  IStripePromotionCodeEntity,
  IStripeSubscriptionEntity,
  PromotionExpiredError,
  PromotionNotFoundError,
} from '../domain';
import { IBillingDetailsEntity } from '../domain/entities/BillingDetailsEntity';

import { IBillingApiService, IBillingRepository } from './abstractions';
import { IBillingDetailsDC, IBillingInvoiceDC } from './dataContracts';
import { IBillingState } from './db';
import {
  mapBillingDetailsDcToEntity,
  mapBillingDetailsEntityToDc,
  mapBillingInvoiceDcToEntity,
  mapPaymentMethodDCtoEntity,
  mapPaymentMethodUpdateSessionDcToEntity,
  mapStripePromotionCodeDcToEntity,
  mapStripeSubscriptionDcToEnity,
} from './mappers';

const PROMO_EXPIRED_RESPONSE = 'This promotion code has expired';

@injectable()
export class BillingRepository implements IBillingRepository {
  @inject(BILLING_TYPES.BillingApiService)
  private readonly billingApiService: IBillingApiService;

  @inject(BILLING_TYPES.BillingState)
  private readonly billingState: IBillingState;

  @inject(REQUEST_CACHE_TYPES.InMemoryRequestCache)
  private readonly requestCache: IRequestCache;

  private getLocalBillingDetails(): Observable<IBillingDetailsEntity> {
    return this.billingState.get$('billingDetails').pipe(
      filter((value) => !!value),
      map(mapBillingDetailsDcToEntity),
    );
  }

  private async getRemoteBillingDetails(): Promise<IBillingDetailsDC> {
    const response = await firstValueFrom(this.billingApiService.getBillingInfo());

    await this.billingState.set('billingDetails', () => {
      return response;
    });

    return response;
  }

  getBillingDetails(): Observable<IBillingDetailsEntity> {
    return from(
      this.requestCache.networkFirst({
        key: 'billingDetails',
        fetcher: () => this.getRemoteBillingDetails(),
      }),
    ).pipe(switchMap(() => this.getLocalBillingDetails()));
  }

  updateBillingDetails(
    details: IBillingDetailsEntity,
  ): Observable<IBillingDetailsEntity> {
    return this.billingApiService
      .updateBillingInfo(mapBillingDetailsEntityToDc(details))
      .pipe(
        map(mapBillingDetailsDcToEntity),
        tap((billingDetails) => {
          this.billingState.set('billingDetails', () => {
            return billingDetails;
          });
        }),
      );
  }

  initSubscription(params: {
    plan: SubscriptionPlan;
    promoCode?: string;
    isCanceled?: boolean;
  }): Observable<IStripeSubscriptionEntity> {
    return this.billingApiService
      .initSubscription({
        label: params.plan,
        promo_code: params.promoCode,
        is_canceled: params.isCanceled,
      })
      .pipe(
        catchError((e: NetworkError) => {
          if (e.response?.data?.includes(PROMO_EXPIRED_RESPONSE)) {
            return throwError(() => new PromotionExpiredError());
          } else {
            return throwError(() => new NetworkError());
          }
        }),
        map(mapStripeSubscriptionDcToEnity),
      );
  }

  updateWorkspaceSubscription(params: {
    workspace: Pick<IWorkspaceEntity, 'uuid'>;
    subscription: Pick<
      Partial<IWorkspaceSubscriptionEntity>,
      'plan' | 'billingDetailsFilled' | 'isCanceled' | 'promoCodeId'
    >;
  }): Observable<boolean> {
    return this.billingApiService.updateWorkspaceSubscription({
      workspace: params.workspace,
      subscription: {
        ...(params.subscription.plan && { plan: params.subscription.plan }),
        ...(params.subscription.billingDetailsFilled != null && {
          billing_details_filled: params.subscription.billingDetailsFilled,
        }),
        ...(params.subscription.isCanceled != null && {
          is_canceled: params.subscription.isCanceled,
        }),
        ...(params.subscription.promoCodeId && {
          promo_code_id: params.subscription.promoCodeId,
        }),
      },
    });
  }

  private getLocalInvoices(): Observable<IBillingInvoiceEntity[]> {
    return this.billingState.get$('invoices').pipe(
      filter((value) => !!value),
      map((invoices) => invoices.map(mapBillingInvoiceDcToEntity)),
    );
  }

  private async getRemoteInvoices(): Promise<IBillingInvoiceDC[]> {
    const invoices = await firstValueFrom(this.billingApiService.getInvoices());

    await this.billingState.set('invoices', () => {
      return invoices;
    });

    return invoices;
  }

  getInvoices(): Observable<IBillingInvoiceEntity[]> {
    return from(
      this.requestCache.networkFirst({
        key: 'invoices',
        fetcher: () => this.getRemoteInvoices(),
      }),
    ).pipe(switchMap(() => this.getLocalInvoices()));
  }

  getPaymentMethod(): Observable<IPaymentMethodEntity> {
    return this.billingApiService
      .getPaymentMethod()
      .pipe(map(mapPaymentMethodDCtoEntity));
  }

  updatePaymentMethod(params: {
    successUrl: string;
    cancelUrl: string;
  }): Observable<IPaymentMethodUpdateSessionEntity> {
    return this.billingApiService
      .updatePaymentMethod(params)
      .pipe(map(mapPaymentMethodUpdateSessionDcToEntity));
  }

  getPromoCodeInfo(params: {
    code: string;
    plan: SubscriptionPlan;
  }): Observable<IStripePromotionCodeEntity> {
    return this.billingApiService
      .getPromoCodeInfo({
        code: params.code,
        label: params.plan,
      })
      .pipe(
        catchError(() => throwError(() => new PromotionNotFoundError())),
        map(mapStripePromotionCodeDcToEntity),
      );
  }
}
