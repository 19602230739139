import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TagBadge } from '@/components';

import styles from './styles.module.scss';

export const CurrentPlanBadge: FC = () => {
  const { t } = useTranslation('plans');

  return <TagBadge className={styles.currentPlanBadge}>{t('badges.current')}</TagBadge>;
};
