import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Typography } from '@mui/material';

import IconClose from '../../../../../../../assets/icons/iconClose.svg?react';
import UpgradeDialogImage from '../../../../../../../assets/icons/settings/upgradeDialogImage.svg?react';

import styles from './styles.module.scss';

type Props = {
  isUpgradeDialogVisible: boolean;
  onUpgradeDialogClose(): void;
  onViewPlansClick(): void;
};

const UpgradeAccountDialog: React.FC<Props> = ({
  isUpgradeDialogVisible,
  onUpgradeDialogClose,
  onViewPlansClick,
}) => {
  const { t } = useTranslation('settings');

  return (
    <Popover
      classes={{
        paper: styles.DialogPaper,
        root: styles.DialogRoot,
      }}
      open={isUpgradeDialogVisible}
      anchorEl={document.body}
      onClose={onUpgradeDialogClose}
      anchorOrigin={{
        horizontal: 0,
        vertical: 0,
      }}
    >
      <IconClose onClick={onUpgradeDialogClose} className={styles.CloseIcon} />
      <UpgradeDialogImage />
      <Typography>{t('teamManagement.upgradeDialog.message')}</Typography>
      <Button variant="contained" color="primary" onClick={onViewPlansClick}>
        {t('teamManagement.upgradeDialog.buttonText')}
      </Button>
    </Popover>
  );
};

export default UpgradeAccountDialog;
