import { useMemo } from 'react';
import { filter, map } from 'rxjs';

import {
  IWorkspaceSubscriptionEntity,
  useSubscriptionUseCase,
} from '@/features/common/workspace';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { BillingCycle, getBillingCycleFromSubscription } from '../../domain';

export function useCurrentBillingCycle(): ObservableResult<BillingCycle> {
  const subscriptionUseCase = useSubscriptionUseCase();

  const currentBillingCycle$ = useMemo(
    () =>
      subscriptionUseCase.getSubscription().pipe(
        filter(
          (subscription): subscription is IWorkspaceSubscriptionEntity => !!subscription,
        ),
        map((subscription) => getBillingCycleFromSubscription(subscription)),
      ),
    [],
  );

  return useObservableResult(currentBillingCycle$);
}
