import { firstValueFrom } from 'rxjs';

import { ACCESS_PERMISSIONS, usePermissions } from '@/features/common/account';
import { useTeamMemberSelectOptions } from '@/features/settings';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { useContactUseCase } from '../../../../hooks';

export const useContactOwnerCellViewModel = ({
  assignedTo,
  contactId,
}: {
  assignedTo: string;
  contactId: string;
}): {
  options: ReturnType<typeof useTeamMemberSelectOptions>;
  handleApply: (newAssignedTo: string) => Promise<void>;
  canChangeContactsOwner: boolean;
} => {
  const logger = useAppLogger();
  const options = useTeamMemberSelectOptions(true);
  const contactUseCase = useContactUseCase();
  const { trackEvent } = useAnalytics();

  const { hasPermission } = usePermissions();
  const canChangeContactsOwner = hasPermission(
    ACCESS_PERMISSIONS.CAN_CHANGE_CONTACTS_OWNER,
  );

  const handleApply = async (newAssignedTo: string): Promise<void> => {
    if (newAssignedTo === assignedTo) {
      return;
    }

    try {
      await firstValueFrom(
        contactUseCase.updateById(String(contactId), {
          assigned_to: newAssignedTo,
        }),
      );
      trackEvent(ANALYTICS_EVENTS.CONTACT_OWNER_CHANGE);
    } catch (error) {
      logger.error(error);
    }
  };

  return {
    options,
    handleApply,
    canChangeContactsOwner,
  };
};
