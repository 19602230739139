import { useNavigate } from 'react-router-dom';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { useAccountMonthlyCredits } from '@/features/common/account';
import { useExtensionState } from '@/features/common/extension';
import {
  useOnboardingStepState,
  useOnboardingUseCase,
} from '@/features/common/onboarding/ui';

import { openInNewTab } from '@/utils/openInNewTab';

type ActionType = 'upgradePlan' | 'installExtension' | 'revealContact';

export const usePlanCardFreePlanViewModel = (): {
  monthlyCreditsUsed: number;
  monthlyCreditsLimit: number;
  actionType: ActionType;
  onUpgradeNow: () => void;
  onInstallExtneion: () => void;
  onRevalContact: () => void;
} => {
  const navigate = useNavigate();

  const { used: monthlyCreditsUsed, limit: monthlyCreditsLimit } =
    useAccountMonthlyCredits();
  const { isCompleted: isRevealedContact } = useOnboardingStepState('revealContact');
  const onboardingUseCase = useOnboardingUseCase();
  const { isInstalled: isExtensionInstalled } = useExtensionState();

  const onUpgradeNow = (): void => {
    navigate(ROUTES.BILLING.PLANS);
  };
  const onInstallExtneion = (): void => {
    openInNewTab(EXTERNAL_ROUTES.EXTENSION_URL);
    onboardingUseCase.completeClickDownloadExtensionStep();
  };
  const onRevalContact = (): void => {
    openInNewTab(EXTERNAL_ROUTES.LINKEDIN_PROFILE_TO_REVEAL);
  };

  const getActionType = (): ActionType => {
    if (!isExtensionInstalled) return 'installExtension';
    if (isExtensionInstalled && !isRevealedContact) return 'revealContact';
    return 'upgradePlan';
  };

  return {
    monthlyCreditsUsed,
    monthlyCreditsLimit,
    actionType: getActionType(),
    onUpgradeNow,
    onInstallExtneion,
    onRevalContact,
  };
};
