import { inject, injectable } from 'inversify';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { ACCOUNT_TYPES } from '@/ioc/types';

import { IAccountUseCase } from '@/features/common/account/domain/abstractions/useCases/IAccountUseCase';
import { AuthIdentityProvider } from '@/features/common/auth';

import { COUNTRIES_DC } from '../data/dataContracts/CountriesDC';

import { IAccountRepository } from './abstractions/repositories/IAccountRepository';
import { IAccountEntity, IAccountSettingsEntity } from './entities';

@injectable()
export default class AccountUseCase implements IAccountUseCase {
  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  saveEmailForAccountCreation(email: string): Observable<void> {
    return this.accountRepository.saveEmailForAccountCreation(email);
  }

  getEmailForAccountCreation(): BehaviorSubject<string> {
    return this.accountRepository.getEmailForAccountCreation();
  }

  deleteEmailForAccountCreation(): Observable<void> {
    return this.accountRepository.deleteEmailForAccountCreation();
  }

  getAccount(): Observable<IAccountEntity | null> {
    return this.accountRepository.getAccount();
  }

  syncAccount(email?: string): Observable<void> {
    return this.accountRepository.syncAccount(email);
  }

  updateSettings(settings: Partial<IAccountSettingsEntity>): Observable<void> {
    return this.accountRepository.updateSettings(settings);
  }

  setupAccount(account: {
    fullName: string;
    phone: string;
    country: COUNTRIES_DC;
  }): Observable<void> {
    return this.accountRepository.setupAccount(account);
  }

  updateAccount(account: Partial<IAccountEntity>): Observable<void> {
    return this.accountRepository.updateAccount(account);
  }

  getEmail(): Observable<string> {
    return this.getAccount().pipe(map((account) => account?.email ?? ''));
  }

  getIdentityProviders(): Observable<AuthIdentityProvider[]> {
    return this.getAccount().pipe(map((acc) => acc?.providers ?? []));
  }
}
