import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import styles from './styles.module.scss';

export const PlanCardContentManager: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();

  const onViewProspects = (): void => {
    navigate(ROUTES.CONTACTS_ENTRY);
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography className={styles.title}>{t('planManagerRoleTitle')}</Typography>
        <Typography className={styles.description}>
          {t('planManagerRoleDescription')}
        </Typography>
      </div>
      <Button
        onClick={onViewProspects}
        className={styles.button}
        variant="outlined"
        color="info"
        size="small"
      >
        {t('planManagerRoleButton')}
      </Button>
    </div>
  );
};
