export const DEFAULT_TAG_COLOR = '#f8c291';
export const TAG_COLORS = [
  '#60a3bc',
  '#4a69bd',
  DEFAULT_TAG_COLOR,
  '#b8e994',
  '#ffc048',
  '#f8a5c2',
  '#63cdda',
  '#303952',
  '#786fa6',
  '#f6e58d',
  '#ef5777',
  '#c7ecee',
];
