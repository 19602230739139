import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { UserRole } from '@/features/common/account';
import { useOnboardingUseCase } from '@/features/common/onboarding';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { useTeamMembersUseCase } from '../../hooks/useTeamMembersUseCase';

import { useInviteTeamMembersDialogFormResolver } from './useInviteTeamMembersDialogFormResolver';

type Return = {
  form: UseFormReturn<{ emails: string[]; role: UserRole }>;
  handleSubmitForm(onSuccess?: () => void): void;
  isProcessing: boolean;
};

export const useInviteTeamMembersDialogViewModel = (): Return => {
  const analytics = useAnalytics();
  const teamMemberUseCase = useTeamMembersUseCase();
  const { t } = useTranslation('settings');
  const { enqueueSnackbar } = useSnackbar();
  const resolver = useInviteTeamMembersDialogFormResolver();
  const form = useForm({
    resolver,
    defaultValues: {
      emails: [],
      role: UserRole.Member,
    },
    reValidateMode: 'onBlur',
  });
  const { emails = [], role } = form.watch();
  const [isProcessing, setIsProcessing] = useState(false);
  const logger = useAppLogger();
  const onboardingUseCase = useOnboardingUseCase();

  const showSuccessNotification = (): void => {
    enqueueSnackbar(t('teamManagement.inviteDialog.inviteSentToastTitle'), {
      variant: 'success',
      preventDuplicate: true,
      description: t('teamManagement.inviteDialog.inviteSentToastSubtitle'),
    });
  };

  const handleSubmitForm = (onSuccess?: () => void): void => {
    setIsProcessing(true);
    form.handleSubmit(async () => {
      try {
        await teamMemberUseCase.inviteTeamMembers(
          emails.map((email) => ({
            email,
            role,
          })),
        );
        setIsProcessing(false);
        emails.forEach(() => {
          analytics.trackEvent(ANALYTICS_EVENTS.ADD_TEAM_MEMBER, {
            role,
          });
        });
        await onboardingUseCase.completeInviteTeamMemberStep();

        showSuccessNotification();
        onSuccess && onSuccess();
      } catch (error) {
        setIsProcessing(false);
        logger.error(error);
      }
    })();
  };

  return {
    handleSubmitForm,
    form,
    isProcessing,
  };
};
