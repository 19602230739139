import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { PlanType, useIsCurrentPlanTypeLowerThen } from '@/features/common/billing';

import { IntegrationNotFoundError, SalesforceApiDisabledError } from '../domain';
import { IIntegrationEntity } from '../domain/entities';

import { useCurrentIntegration } from './hooks/useCurrentIntegration';
import { TabName } from './components';
import { IMappingByObjectTypeEntityUI } from './entities';
import { useCurrentIntegrationId, useCurrentIntegrationMap } from './hooks';

export type ErrorType = 'SalesforceApiDisabled' | 'Unknown' | 'NotFound';

type Return = {
  integration: Nullable<IIntegrationEntity>;
  integrationMapping: Nullable<IMappingByObjectTypeEntityUI>;
  isLoading: boolean;
  hasError: boolean;
  tab: TabName;
  handleTabChange: (value: TabName) => void;
  providerName: string;
  errorType?: Nullable<ErrorType>;
  navigateToIntegrations: () => void;
  isPlanLowerThenGrowth: boolean;
};

const useIntegrationViewModel = (): Return => {
  const { t } = useTranslation('integrations', { keyPrefix: 'providers' });
  const integrationId = useCurrentIntegrationId();

  const {
    data: integration,
    isLoading: isIntegrationLoading,
    hasError: integrationHasError,
    error: integrationError,
  } = useCurrentIntegration(integrationId);

  const {
    data: integrationMapping,
    isLoading: isIntegrationMappinLoading,
    hasError: integrationMappingHasError,
    error: integrationMappingError,
  } = useCurrentIntegrationMap(integration);
  const navigate = useNavigate();

  const isPlanLowerThenGrowth = useIsCurrentPlanTypeLowerThen(PlanType.Growth);

  const errorType = useMemo<Nullable<ErrorType>>(() => {
    if (!integrationError && !integrationMappingError) return null;

    const errors = [integrationError, integrationMappingError].filter(Boolean);

    if (errors.some((error) => error instanceof IntegrationNotFoundError)) {
      return 'NotFound' as const;
    }

    if (errors.some((error) => error instanceof SalesforceApiDisabledError)) {
      return 'SalesforceApiDisabled' as const;
    }

    return 'Unknown' as const;
  }, [integrationError, integrationMappingError]);

  const [tab, setTab] = useState<TabName>(TabName.Settings);

  const handleTabChange = (newTab: TabName): void => {
    setTab(newTab);
  };

  const providerName = t(integrationId);

  useEffect(() => {
    integration && !integration.connectionActive && navigate(ROUTES.INTEGRATIONS);
  }, [integration]);

  const navigateToIntegrations = (): void => {
    navigate(ROUTES.INTEGRATIONS);
  };

  return {
    tab,
    handleTabChange,
    integration,
    integrationMapping: integration ? integrationMapping : null,
    isLoading: isIntegrationLoading || isIntegrationMappinLoading,
    errorType,
    hasError: integrationHasError || integrationMappingHasError,
    providerName,
    navigateToIntegrations,
    isPlanLowerThenGrowth,
  };
};

export default useIntegrationViewModel;
