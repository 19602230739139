import { inject, injectable, postConstruct } from 'inversify';

import { AUTH_TYPES } from '@/ioc/types';

import { AuthStatus, IAuthRepository } from '@/features/common/auth';

import { IRequestCache, RequestCacheParams } from './RequestCache';

@injectable()
export class InMemoryRequestCache implements IRequestCache {
  private dedupCache: Map<string, Promise<unknown>>; // to avoid multiple requests for the same data
  private resultCache: Map<string, unknown>;
  private timersMap: Map<string, NodeJS.Timeout>;

  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  constructor() {
    this.dedupCache = new Map();
    this.resultCache = new Map();
    this.timersMap = new Map();
  }

  @postConstruct()
  autoClearCache(): void {
    this.authRepository.getAuthStatus().subscribe((authStatus) => {
      if (authStatus === AuthStatus.Unauthorized) {
        this.clear();
      }
    });
  }

  private dedupRequest<T>(key: string, fetcher: () => Promise<T>): Promise<T> {
    const dedupedPromise = this.dedupCache.get(key);

    if (dedupedPromise) {
      return dedupedPromise as Promise<T>;
    }

    const promise = fetcher();

    this.dedupCache.set(key, promise);

    promise.finally(() => {
      this.dedupCache.delete(key);
    });

    return promise;
  }

  private get<T>(key: string): Nullable<T> {
    return this.resultCache.get(key) as T;
  }

  private set<T>(key: string, value: T, maxExpirationTime?: number): void {
    if (maxExpirationTime) {
      // remove previous expiration timer
      clearTimeout(this.timersMap.get(key));
      this.timersMap.delete(key);

      // set new expiration timer
      this.timersMap.set(
        key,
        setTimeout(() => {
          this.resultCache.delete(key);
          this.timersMap.delete(key);
        }, maxExpirationTime),
      );
    }

    this.resultCache.set(key, value);
  }

  clear(): void {
    this.dedupCache.clear();
    this.resultCache.clear();
    this.timersMap.forEach((timer) => clearTimeout(timer));
    this.timersMap.clear();
  }

  cacheFirst = async <T>({
    key,
    fetcher,
    maxExpiration,
  }: RequestCacheParams<T>): Promise<T> => {
    const resultFromCache = this.get(key);

    if (resultFromCache) {
      return resultFromCache as T;
    }

    const request = this.dedupRequest(key, fetcher);

    const resultFromRequest = await request;

    this.set(key, resultFromRequest, maxExpiration);

    return resultFromRequest;
  };

  networkFirst = async <T>({
    key,
    fetcher,
    maxExpiration,
  }: RequestCacheParams<T>): Promise<T> => {
    try {
      const data = await this.dedupRequest(key, fetcher);

      this.set(key, data, maxExpiration);

      return data;
    } catch (e) {
      const resultFromCache = this.get<T>(key);

      if (resultFromCache) {
        return resultFromCache;
      }

      throw e;
    }
  };

  cacheFirstWithRefresh = async <T>({
    key,
    fetcher,
    maxExpiration,
  }: RequestCacheParams<T>): Promise<T> => {
    const resultFromCache = this.get<T>(key);

    if (resultFromCache) {
      this.dedupRequest(key, fetcher).then((data) => {
        this.set(key, data, maxExpiration);
      });

      return resultFromCache;
    }

    const data = await this.dedupRequest(key, fetcher);

    this.set(key, data, maxExpiration);

    return data;
  };
}
