import { useInjection } from '@/ioc/ioc.react';
import { LOGIN_TYPES } from '@/ioc/types';

import { ILoginUseCases } from '../../domain/abstractions/ILoginUseCases';

type UseLoginUseCase = () => ILoginUseCases;

export const useLoginUseCase: UseLoginUseCase = () => {
  return useInjection<ILoginUseCases>(LOGIN_TYPES.LoginUseCases);
};
