import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { Preloader } from '@/components';

import SendEmailIcon from '@/assets/icons/settings/resendEmailIcon.svg?react';

import { EmailField } from '../EmailField';
import { EmailFieldMessages } from '../EmailFieldMessages';

import { useInviteByEmailViewModel } from './useInviteByEmailViewModel';

import styles from './styles.module.scss';

export const InviteByEmail: FC = () => {
  const { t } = useTranslation('referral', { keyPrefix: 'inviteSection' });
  const {
    emails,
    handleEmailsChange,
    handleSubmit,
    hasEmailError,
    processing,
    disabled,
    lastResponse,
  } = useInviteByEmailViewModel();

  return (
    <Box className={styles.emailWrapper} component="form" onSubmit={handleSubmit}>
      <Typography variant="h3">{t('emailInput.title')}</Typography>
      <Box className={styles.emailInnerWrapper}>
        <EmailField
          value={emails}
          onChange={handleEmailsChange}
          getHasValueError={hasEmailError}
        />
        <Button
          type="submit"
          variant="contained"
          startIcon={<SendEmailIcon />}
          className={styles.sendButton}
          disabled={processing || disabled}
        >
          {processing ? <Preloader /> : t('actions.send')}
        </Button>
      </Box>
      <EmailFieldMessages response={lastResponse} />
    </Box>
  );
};
