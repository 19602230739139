import { useMemo } from 'react';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IPaymentMethodEntity } from '../../domain';

import { useBillingUseCase } from './useBillingUseCase';

export function useGetPaymentMethod(): ObservableResult<IPaymentMethodEntity | null> {
  const billingUseCase = useBillingUseCase();

  const paymentMethod$ = useMemo(() => billingUseCase.getPaymentMethod(), []);

  return useObservableResult(paymentMethod$);
}
