import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { GLOBAL_ERROR_TYPES } from '@/ioc/types';

import {
  IGlobalErrorEntity,
  IGlobalErrorRepository,
  IGlobalErrorUseCase,
} from '@/features/system/globalError';

@injectable()
export default class GlobalErrorUseCase implements IGlobalErrorUseCase {
  @inject(GLOBAL_ERROR_TYPES.GlobalErrorRepository)
  private globalErrorRepository: IGlobalErrorRepository;

  public getProcedureErrors(): Observable<IGlobalErrorEntity[]> {
    return this.globalErrorRepository.getProcedureErrors();
  }

  public getExecutionError(): Observable<IGlobalErrorEntity | null> {
    return this.globalErrorRepository.getExecutionError();
  }

  public setExecutionError(error: IGlobalErrorEntity): void {
    this.globalErrorRepository.setExecutionError(error);
  }

  public restoreExecutionError(): void {
    this.globalErrorRepository.restoreExecutionError();
  }

  public pushProcedureError(error: IGlobalErrorEntity): void {
    this.globalErrorRepository.pushProcedureError(error);
  }

  public shiftProcedureError(): void {
    this.globalErrorRepository.shiftProcedureError();
  }
}
