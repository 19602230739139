import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { firstValueFrom } from 'rxjs';

import { ROUTES } from '@/router/routes';

import {
  BillingCycle,
  getSubscriptionPlanFrom,
  PlanType,
  useBillingUseCase,
} from '@/features/common/billing';
import { useWorkspaceSubscription } from '@/features/common/workspace';
import { useAppLogger } from '@/features/system/logger';

export type PlanDowngradeDialogViewModel = {
  isOpen: boolean;
  text: string;
  changeDate: string;
  isProcess: boolean;
  open(planType: PlanType, billingCycle: BillingCycle): void;
  close(): void;
  onConfirm(): void;
};

export const usePlanDowngradeDialogViewModel = (): PlanDowngradeDialogViewModel => {
  const {
    t,
    i18n: { language },
  } = useTranslation('plans');
  const navigate = useNavigate();
  const billingUseCase = useBillingUseCase();
  const logger = useAppLogger();
  const snackbar = useSnackbar();

  const [isOpen, setIsOpen] = useState(false);
  const [planType, setPlanType] = useState<PlanType | null>(null);
  const [billingCycle, setBillingCycle] = useState<BillingCycle | null>(null);
  const [isProcess, setIsProcess] = useState(false);

  const { data: subscription } = useWorkspaceSubscription();

  const planNameText = isOpen ? t(`plans.${planType}.longName`) : '';
  const billingCycleText = isOpen ? t(`billingCycleDialog.${billingCycle}`) : '';
  const text = `${planNameText} (${billingCycleText})`;

  // @ts-ignore
  const changeDate = dayjs(dayjs.unix(subscription?.expirationDate), {
    locale: language,
  }).format('MMMM D, YYYY');

  const open = (planType: PlanType, billingCycle: BillingCycle): void => {
    setIsOpen(true);
    setPlanType(planType);
    setBillingCycle(billingCycle);
  };
  const close = (): void => {
    setIsOpen(false);
    setPlanType(null);
    setBillingCycle(null);
  };

  const onConfirm = async (): Promise<void> => {
    if (planType && billingCycle) {
      try {
        setIsProcess(true);

        await firstValueFrom(
          billingUseCase.updateSubscription({
            plan: getSubscriptionPlanFrom({ planType, billingCycle }),
          }),
        );

        close();
        navigate(ROUTES.SETTINGS.SUBSCRIPTION);
      } catch (e) {
        logger.log(e);

        snackbar.enqueueSnackbar(t('billingCycleDialog.error'), {
          variant: 'error',
          description: t('billingCycleDialog.errorDescription'),
          autoHideDuration: 5000,
        });
      } finally {
        setIsProcess(false);
      }
    }
  };

  return {
    isOpen,
    text,
    changeDate,
    isProcess,
    open,
    close,
    onConfirm,
  };
};
