import { inject, injectable } from 'inversify';
import { filter, first, map, Observable, switchMap } from 'rxjs';

import { TEAM_MEMBER_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import type {
  IWorkspaceDC,
  IWorkspaceEntity,
  IWorkspaceRepository,
} from '@/features/common/workspace';
import {
  IDeleteMemberDataEntity,
  ITeamMemberEntity,
  ITeamMemberEntityUI,
  ITeamMemberStateRepository,
  ITeamMemberUseCase,
  UpdateMemberData,
} from '@/features/settings';

@injectable()
export default class TeamMemberUseCase implements ITeamMemberUseCase {
  @inject(TEAM_MEMBER_TYPES.TeamMemberRepository)
  private repository: ITeamMemberStateRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  getById(id: string): Observable<ITeamMemberEntity | null> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      map((workspace) => {
        return workspace?.members.find((member) => member.uuid === id) ?? null;
      }),
    );
  }

  inviteTeamMembers(teamMembers: ITeamMemberEntityUI[]): Promise<IWorkspaceEntity> {
    return this.repository.inviteTeamMembers(teamMembers);
  }

  deleteTeamMembers(list: IDeleteMemberDataEntity[]): Observable<boolean> {
    return this.repository.deleteTeamMembers(list);
  }

  resendInvitation(data: UpdateMemberData): Observable<IWorkspaceDC> {
    return this.repository.resendInvitation(data);
  }

  getTeamMembers(): Observable<ITeamMemberEntity[]> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      map((workspace) => {
        return workspace?.members ? workspace.members : [];
      }),
    );
  }

  updateTeamMember(
    data: Pick<ITeamMemberEntity, 'email' | 'role'>,
  ): Observable<IWorkspaceDC> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      filter((workspace): workspace is IWorkspaceEntity => !!workspace),
      first(),
      map((workspace) => {
        return {
          ...workspace,
          members: workspace.members.map((member) => {
            if (member.email === data.email) {
              member.role = data.role;
            }
            return member;
          }),
        };
      }),
      switchMap((updatedWorkspace) => {
        return this.workspaceRepository.updateWorkspace(updatedWorkspace);
      }),
    );
  }

  setMemberAccessAllContacts(value: boolean): Observable<IWorkspaceDC> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      filter((workspace): workspace is IWorkspaceEntity => !!workspace),
      first(),
      switchMap((currentWorkspace) => {
        return this.workspaceRepository.updateWorkspace({
          ...currentWorkspace,
          memberAccessAllContacts: value,
        });
      }),
    );
  }

  getMemberAccessAllContacts(): Observable<boolean> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      filter((workspace): workspace is IWorkspaceEntity => !!workspace),
      map((workspace) => {
        return workspace.memberAccessAllContacts;
      }),
    );
  }
}
