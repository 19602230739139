import { forwardRef, ForwardRefExoticComponent, RefAttributes, useState } from 'react';
import { InputAdornment, OutlinedInput, OutlinedInputProps } from '@mui/material';

import { InvisibleIcon, VisibleIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const PasswordInput: ForwardRefExoticComponent<
  Omit<OutlinedInputProps, 'ref'> & RefAttributes<unknown>
> = forwardRef((props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const handleClick = (): void => {
    setVisible(!visible);
  };

  return (
    <OutlinedInput
      {...props}
      ref={ref}
      type={visible ? 'text' : 'password'}
      endAdornment={
        <InputAdornment
          classes={{ root: styles.passwordInputAdornmentEndRoot }}
          position="end"
          onClick={handleClick}
        >
          {visible ? <VisibleIcon /> : <InvisibleIcon />}
        </InputAdornment>
      }
    />
  );
});
PasswordInput.displayName = 'PasswordInput';
