import { inject, injectable } from 'inversify';
import { filter, first, from, map, Observable, of, switchMap, throwError } from 'rxjs';

import { BILLING_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import {
  IWorkspaceRepository,
  IWorkspaceSubscriptionEntity,
  SubscriptionPlan,
} from '@/features/common/workspace';
import { IWorkspaceEntity } from '@/features/common/workspace';

import { IBillingRepository } from '../data';

import { IBillingUseCase } from './abstractions';
import {
  IBillingDetailsEntity,
  IBillingInvoiceEntity,
  IPaymentMethodEntity,
  IPaymentMethodUpdateSessionEntity,
  IStripePromotionCodeEntity,
} from './entities';
import { PaymentError, PromotionExpiredError, WorkspaceNotFoundError } from './errors';
import { PlanType, StripeInvoiceStatus, StripeSubscriptionStatus } from './types';
import { getPlanTypeFromSubscription } from './utils';

@injectable()
export class BillingUseCase implements IBillingUseCase {
  @inject(BILLING_TYPES.BillingRepository)
  private billingRepository: IBillingRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  private getCurrentWorkspaceOrThrow(): Observable<IWorkspaceEntity> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      switchMap((workspace) => {
        if (!workspace) {
          return throwError(() => new WorkspaceNotFoundError());
        }

        return of(workspace);
      }),
    );
  }

  getPlanType(): Observable<PlanType> {
    return this.workspaceRepository.getCurrentWorkspaceSubscription().pipe(
      filter(
        (subscription): subscription is IWorkspaceSubscriptionEntity => !!subscription,
      ),
      map((subscription) => getPlanTypeFromSubscription(subscription)),
    );
  }

  getBillingDetails = (): Observable<IBillingDetailsEntity> => {
    return this.billingRepository.getBillingDetails();
  };

  updateBillingDetails = (
    billingDetails: IBillingDetailsEntity,
  ): Observable<IBillingDetailsEntity> => {
    return this.billingRepository.updateBillingDetails(billingDetails);
  };

  createSubscription(params: {
    plan: SubscriptionPlan;
    promotionCode?: IStripePromotionCodeEntity;
    billingDetails: IBillingDetailsEntity;
    processPayment: (secretKey: string) => Promise<void>;
    storePaymentMethod: (secretKey: string) => Promise<void>;
  }): Observable<boolean> {
    const { plan, promotionCode, billingDetails, processPayment } = params;

    return this.updateBillingDetails(billingDetails).pipe(
      switchMap(() => {
        return this.billingRepository.initSubscription({
          plan,
          promoCode: promotionCode?.id,
        });
      }),
      switchMap((subscription) => {
        if (
          (subscription.status === StripeSubscriptionStatus.Active ||
            subscription.status === StripeSubscriptionStatus.Trialing) &&
          subscription.latestInvoice.status === StripeInvoiceStatus.Paid
        ) {
          return of(true);
        }

        if (
          subscription.latestInvoice.paymentIntent?.clientSecret &&
          subscription.status === StripeSubscriptionStatus.Incomplete &&
          subscription.latestInvoice.status === StripeInvoiceStatus.Open
        ) {
          return from(
            processPayment(subscription.latestInvoice.paymentIntent?.clientSecret),
          );
        }

        return throwError(() => new PaymentError());
      }),
      switchMap(() => {
        return this.getCurrentWorkspaceOrThrow().pipe(first());
      }),
      switchMap((workspace) => {
        return this.billingRepository.updateWorkspaceSubscription({
          workspace: { uuid: workspace.uuid },
          subscription: { plan, billingDetailsFilled: true },
        });
      }),
      map(() => {
        return true;
      }),
    );
  }

  updateSubscription(params: { plan: SubscriptionPlan }): Observable<boolean> {
    const { plan } = params;

    return this.getCurrentWorkspaceOrThrow().pipe(
      first(),
      switchMap((workspace) => {
        return this.billingRepository.updateWorkspaceSubscription({
          workspace: { uuid: workspace.uuid },
          subscription: { plan, billingDetailsFilled: true },
        });
      }),
      map(() => {
        return true;
      }),
    );
  }

  cancelSubscription(): Observable<boolean> {
    return this.getCurrentWorkspaceOrThrow().pipe(
      switchMap((workspace) => {
        return this.billingRepository.updateWorkspaceSubscription({
          workspace: { uuid: workspace.uuid },
          subscription: { isCanceled: true },
        });
      }),
      map(() => {
        return true;
      }),
    );
  }

  renewSubscription(): Observable<boolean> {
    return this.getCurrentWorkspaceOrThrow().pipe(
      switchMap((workspace) => {
        return this.billingRepository.updateWorkspaceSubscription({
          workspace: { uuid: workspace.uuid },
          subscription: { isCanceled: false },
        });
      }),
    );
  }

  getInvoices(): Observable<IBillingInvoiceEntity[]> {
    return this.billingRepository.getInvoices().pipe(
      map((invoices) => {
        return invoices.sort((a, b) => {
          const dataA = new Date(a.date);
          const dataB = new Date(b.date);
          return dataB.getTime() - dataA.getTime();
        });
      }),
    );
  }

  getPaymentMethod(): Observable<IPaymentMethodEntity> {
    return this.billingRepository.getPaymentMethod();
  }

  updatePaymentMethod(params: {
    successUrl: string;
    cancelUrl: string;
  }): Observable<IPaymentMethodUpdateSessionEntity> {
    return this.billingRepository.updatePaymentMethod(params);
  }

  getPromoCodeInfo(params: {
    code: string;
    plan: SubscriptionPlan;
  }): Observable<IStripePromotionCodeEntity> {
    return this.billingRepository.getPromoCodeInfo(params).pipe(
      switchMap((promoCode) => {
        if (promoCode.expiresAt) {
          const expiresAt = new Date(promoCode.expiresAt);

          const now = new Date();
          if (expiresAt.getTime() < now.getTime()) {
            return throwError(() => new PromotionExpiredError());
          }
        }

        if (promoCode.active === false) {
          return throwError(() => new PromotionExpiredError());
        }

        return of(promoCode);
      }),
    );
  }
}
