import React, { ReactElement } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { UserRole } from '@/features/common/account';

import { CustomSelect, Modal } from '@/components';

import { EmailsAutocomplete } from './EmailsAutocomplete';
import { useInviteTeamMembersDialogViewModel } from './useInviteTeamMembersDialogViewModel';

import styles from './styles.module.scss';

type InviteTeamMembersDialogProps = {
  onInviteDialogClose(): void;
};

const withModalWrapper = (Component: React.FC<InviteTeamMembersDialogProps>) => {
  return (
    props: InviteTeamMembersDialogProps & {
      isInviteDialogVisible: boolean;
    },
  ): ReactElement => {
    const { isInviteDialogVisible, ...restProps } = props;
    return (
      <Modal
        containerClass={styles.Paper}
        onClose={props.onInviteDialogClose}
        open={isInviteDialogVisible}
      >
        <Component {...restProps} />
      </Modal>
    );
  };
};

export const InviteTeamMembersDialog: React.FC<InviteTeamMembersDialogProps> =
  withModalWrapper(({ onInviteDialogClose }) => {
    const { handleSubmitForm, form, isProcessing } =
      useInviteTeamMembersDialogViewModel();
    const { t } = useTranslation('settings');

    const options = [
      {
        value: UserRole.Admin,
        label: t('teamManagement.inviteDialog.roleAdminTitle'),
      },
      {
        value: UserRole.Member,
        label: t('teamManagement.inviteDialog.roleUserTitle'),
      },
      {
        value: UserRole.Manager,
        label: t('teamManagement.inviteDialog.roleManagerTitle'),
      },
    ];

    const getUserRoleOptionDescription = (role: UserRole): string => {
      if (role === UserRole.Admin) {
        return t('teamManagement.inviteDialog.roleAdminDescription');
      } else if (role === UserRole.Manager) {
        return t('teamManagement.inviteDialog.roleManagerDescription');
      } else {
        return t('teamManagement.inviteDialog.roleUserDescription');
      }
    };

    const { emails = [], role } = form.watch();

    const sendButtonDisabled = !form.formState.isValid || !emails.length || isProcessing;

    return (
      <>
        <Typography className={styles.Title}>
          {t('teamManagement.inviteDialog.title')}
        </Typography>
        <Typography className={styles.Subtitle}>
          {t('teamManagement.inviteDialog.subtitle')}
        </Typography>
        <Box className={styles.InputWrapper}>
          <EmailsAutocomplete
            values={emails}
            onChange={(values): void => {
              form.setValue('emails', values, { shouldValidate: true });
            }}
            error={
              form.formState.errors.emails as FieldError & {
                value: string;
              }
            }
          />
        </Box>
        <Box className={styles.InputWrapper}>
          <Typography>{t('teamManagement.inviteDialog.rolesInputTitle')}</Typography>
          <CustomSelect
            onChange={(value: UserRole): void => {
              form.setValue('role', value);
            }}
            value={role}
            listProps={{ classes: { root: styles.List } }}
            options={options}
            renderOption={({ option, selected }): React.ReactElement => (
              <CustomSelect.DefaultOption
                option={option}
                selected={selected}
                optionDescription={getUserRoleOptionDescription(option.value)}
              />
            )}
          />
        </Box>
        <Box className={styles.ButtonsWrapper}>
          <Button variant="text" color="info" onClick={onInviteDialogClose}>
            {t('teamManagement.inviteDialog.buttonCancel')}
          </Button>
          <Button
            variant="contained"
            color={'primary'}
            disabled={sendButtonDisabled}
            onClick={(): void => {
              handleSubmitForm(() => {
                onInviteDialogClose();
              });
            }}
          >
            {t('teamManagement.inviteDialog.buttonSendInvite')}
          </Button>
        </Box>
      </>
    );
  });
