import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

import { IAuthApiService } from '../abstractions';

const ApiUrl = {
  resetPassword: 'account/email',
  getInfoByIp: 'account/ip-info',
  exchangeAuthCode: 'account',
};

@injectable()
export class AuthApiService implements IAuthApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  public sendResetPasswordLink(email: string): Observable<void> {
    return this.httpClient
      .post<void>(ApiUrl.resetPassword, {
        email,
        email_type: 'reset_password',
      })
      .pipe(map(() => void 0));
  }

  public getUserInfoByIp(): Observable<{ country_code: string }> {
    return this.httpClient.get<{ country_code: string }>(ApiUrl.getInfoByIp).pipe(
      map(({ data }) => {
        return data;
      }),
    );
  }

  public sendEmailVerificationLink(email: string): Observable<void> {
    return this.httpClient
      .post<void>(ApiUrl.resetPassword, {
        email,
        email_type: 'confirm_email',
      })
      .pipe(map(() => void 0));
  }
}
