import { inject, injectable } from 'inversify';
import {
  combineLatest,
  filter,
  Observable,
  of,
  pairwise,
  startWith,
  Subject,
  switchMap,
  tap,
} from 'rxjs';

import {
  AUTH_TYPES,
  CONTACT_BY_FILTERS_TYPES,
  ONLINE_TRACKER_TYPES,
  SYNC_TYPES,
} from '@/ioc/types';

import { IAuthRepository } from '@/features/common/auth';
import { IOnlineTrackerRepository } from '@/features/system/OnlineTracker';
import { ISseClient } from '@/features/system/sync';

import { getApiUrl } from '@/utils/getApiUrl';

import { ISyncEventDC } from '../data';
import {
  ContactSyncByQueryResultStatus,
  IContactByFiltersRepository,
  IContactSyncRepository,
} from '../domain';

@injectable()
export class ContactSyncRepository implements IContactSyncRepository {
  @inject(SYNC_TYPES.SseClient)
  private readonly sseClient: ISseClient;

  @inject(ONLINE_TRACKER_TYPES.OnlineTrackerRepository)
  private readonly onlineTrackerRepository: IOnlineTrackerRepository;

  @inject(CONTACT_BY_FILTERS_TYPES.ContactByFiltersRepository)
  private readonly contactByFiltersRepository: IContactByFiltersRepository;

  private authRepository: IAuthRepository;
  private eventSybject = new Subject<{
    isUpdate: boolean;
    queryParams: string;
    event: ISyncEventDC;
  }>();

  constructor(@inject(AUTH_TYPES.AuthRepository) authRepository: IAuthRepository) {
    this.authRepository = authRepository;
  }

  private buildUrl(token: string, queryParams: string): string {
    const url = new URL(`/api/v1/contacts?${queryParams}`, getApiUrl());
    url.searchParams.append('token', token);

    return url.toString();
  }

  public syncByQuery({
    queryString,
    autoUpdate,
  }: {
    queryString: string;
    autoUpdate?: boolean;
  }): Observable<ContactSyncByQueryResultStatus> {
    return combineLatest([
      this.authRepository.getAccessToken(),
      this.onlineTrackerRepository.getIsOnline(),
    ]).pipe(
      filter(([token]) => !!token),
      switchMap(([token, isOnline]) => {
        if (!isOnline) return of('offline' as const);

        return this.sseClient
          .connect<ISyncEventDC>(this.buildUrl(token, queryString))
          .pipe(
            startWith(null),
            pairwise(),
            filter(([_, currentData]) => !!currentData),
            tap(([prevData, currentData]: [null | ISyncEventDC, ISyncEventDC]) => {
              this.eventSybject.next({
                isUpdate: !!prevData,
                queryParams: queryString,
                event: currentData,
              });
            }),
            switchMap(([prevData, currentData]) => {
              return this.contactByFiltersRepository.syncUpdate({
                autoUpdate: autoUpdate ?? false,
                isUpdate: !!prevData,
                queryParams: queryString,
                event: currentData,
              });
            }),
            switchMap(() => {
              return of('ready' as const);
            }),
          );
      }),
    );
  }

  public getEvent = (): Observable<{
    isUpdate: boolean;
    queryParams: string;
    event: ISyncEventDC;
  }> => {
    return this.eventSybject;
  };
}
