import { KeyboardEvent, useState } from 'react';

import { EmailFieldProps } from '@/features/referral/ui/components/InviteSection/components/EmailField/EmailField';

export const useEmailFieldViewModel = ({ onChange, value }: EmailFieldProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (_: unknown, value: string[]): void => {
    onChange(value);
  };

  const handleAutocompleteInputChange = (_: unknown, newInputValue: string): void => {
    const options: string[] = newInputValue.split(/[ ,]+/);
    const newValue = value
      .concat(options)
      .map((x) => x.trim())
      .filter((x) => x);

    if (options.length > 1) {
      onChange(newValue);
      setInputValue('');
    } else {
      setInputValue(newInputValue);
    }
  };

  const handleAutocompleteBlur = (): void => {
    if (inputValue.length > 0) {
      onChange(value.concat(inputValue.trim()));
      setInputValue('');
    }
  };

  const handleAutocompleteKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    switch (event.key) {
      case 'Tab': {
        if (inputValue.length > 0) {
          onChange(value.concat(inputValue));
          setInputValue('');
          event.preventDefault();
        }
        break;
      }
    }
  };

  return {
    inputValue,
    handleChange,
    handleAutocompleteInputChange,
    handleAutocompleteBlur,
    handleAutocompleteKeyDown,
  };
};
