import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { ITagDC } from '@/features/common/tag';
import { mapBulkResponse, NetworkBulkResponse } from '@/features/system/network';
import { IHttpClient } from '@/features/system/network/HttpClient';

export interface ITagServicesApi {
  createTags(tag: ITagDC[]): Observable<ITagDC[]>;
  updateTags(payload: Partial<ITagDC>[]): Observable<ITagDC[]>;
  deleteTags(items: string[]): Observable<boolean>;
}

const tagEndpoints = {
  tags: 'tags',
};

type BulkTagsResponse = NetworkBulkResponse<ITagDC>[];

@injectable()
export default class TagApiService implements ITagServicesApi {
  @inject(NETWORK_TYPES.HttpClient) private httpClient: IHttpClient;

  createTags(tags: ITagDC[]): Observable<ITagDC[]> {
    return this.httpClient
      .post<BulkTagsResponse>(tagEndpoints.tags, tags)
      .pipe(map((response) => mapBulkResponse(response.data)));
  }

  updateTags(tags: Partial<ITagDC>[]): Observable<ITagDC[]> {
    return this.httpClient
      .patch<BulkTagsResponse>(tagEndpoints.tags, tags)
      .pipe(map((response) => mapBulkResponse(response.data)));
  }

  deleteTags(items: string[]): Observable<boolean> {
    return this.httpClient
      .delete(tagEndpoints.tags, items)
      .pipe(map((response: AxiosResponse<boolean>) => response.data));
  }
}
