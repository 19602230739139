import { forwardRef, PropsWithChildren, ReactElement } from 'react';
import { MenuItem, MenuItemProps, styled } from '@mui/material';
import clsx from 'clsx';

import { Option, OptionType, PrimitiveValue } from '../../../../../../types';

import { DefaultItem, TagItem } from './components';

const Wrapper = styled(MenuItem)<
  MenuItemProps & {
    variant?: OptionType;
  }
>((props) => ({
  variants: [
    {
      props: { variant: 'default' },
      style: {
        padding: '9px 10px',
        borderRadius: '4px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        position: 'relative',
        paddingRight: '40px',
        backgroundColor: ((props): string => {
          if (props.selected) {
            return `#f5f5fa`;
          }
          return 'transparent';
        })(props),
        color: ((props): string => {
          if (props.selected) {
            return `#2173ff`;
          }
          return 'initial';
        })(props),
        '&:hover': {
          backgroundColor: '#f5f5fa',
          color: '#2173ff',
        },
        '&.Mui-focusVisible': {
          backgroundColor: '#f5f5fa',
        },
      },
    },
    {
      props: { variant: 'tag' },
      style: {
        borderRadius: '4px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        padding: '0',
        backgroundColor: 'transparent !important',
      },
    },
  ],
}));

type ItemProps<
  OptionValue extends PrimitiveValue,
  Type extends OptionType,
> = MenuItemProps & {
  variant: Type;
  option: Option<OptionValue, Type>;
  focused: boolean;
  index: number;
  renderItem?: (params: {
    option: Option<OptionValue, Type>;
    index: number;
    isSelected: boolean;
  }) => ReactElement;
};

export const Item = forwardRef(function <
  OptionValue extends PrimitiveValue,
  Type extends OptionType,
>(
  {
    children,
    variant,
    focused,
    index,
    option,
    renderItem,
    ...props
  }: PropsWithChildren<ItemProps<OptionValue, Type>>,
  ref,
) {
  return (
    <Wrapper
      {...props}
      ref={ref}
      className={clsx(props.className, {
        'Mui-focusVisible': focused,
      })}
      variant={variant}
      {...(variant === 'tag' && { disableRipple: true, disableTouchRipple: true })}
    >
      {renderItem ? (
        renderItem({ option, index, isSelected: !!props.selected })
      ) : variant === 'tag' ? (
        <TagItem
          selected={!!props.selected}
          color={(option as Option<OptionValue, 'tag'>)?.color || ''}
        >
          {option.label}
        </TagItem>
      ) : (
        <DefaultItem selected={!!props.selected}>{option.label}</DefaultItem>
      )}
    </Wrapper>
  );
});

Item.displayName = 'Item';
