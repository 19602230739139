import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGridPro } from '@mui/x-data-grid-pro';
import clsx from 'clsx';

import { ITeamMemberEntity } from '@/features/settings';

import { MemberActions } from './components/MemberActions';
import MemberLastActivity from './components/MemberLastActivity';
import MemberName from './components/MemberName';
import MemberRole from './components/MemberRole';
import MemberStatus from './components/MemberStatus';

import styles from './styles.module.scss';

type Props = {
  canManageTeamMembers: boolean;
  teamMembers: ITeamMemberEntity[];
  currentUserEmail: string;
};

const TeamMembersTable: React.FC<Props> = ({
  teamMembers,
  canManageTeamMembers,
  currentUserEmail,
}) => {
  const { t } = useTranslation('settings');
  const getRowId = (row: { email: string }): string => {
    return row.email;
  };

  return (
    <div className={clsx(styles.TableWrapper)}>
      <DataGridPro
        disableColumnReorder
        columns={[
          {
            headerName: t('teamManagement.table.nameTitle'),
            field: 'fullName',
            width: 150,
            renderCell: MemberName,
            disableColumnMenu: true,
          },
          {
            headerName: t('teamManagement.table.emailTitle'),
            field: 'email',
            width: 200,
            disableColumnMenu: true,
          },
          {
            headerName: t('teamManagement.table.roleTitle'),
            field: 'role',
            width: 150,
            renderCell: (props) => (
              <MemberRole
                {...props}
                canManageTeamMembers={canManageTeamMembers}
                currentUserEmail={currentUserEmail}
              />
            ),
            disableColumnMenu: true,
          },
          {
            headerName: t('teamManagement.table.lastActivityTitle'),
            field: 'lastLoginAt',
            width: 150,
            renderCell: MemberLastActivity,
            disableColumnMenu: true,
          },
          {
            headerName: t('teamManagement.table.statusTitle'),
            field: 'invitationStatus',
            renderCell: MemberStatus,
            disableColumnMenu: true,
          },
          {
            field: '',
            type: 'actions',
            flex: 1,
            renderCell: (props) => (
              <MemberActions {...props} canManageTeamMembers={canManageTeamMembers} />
            ),
            disableColumnMenu: true,
          },
        ]}
        rows={teamMembers}
        getRowId={getRowId}
        variant="basic"
        hideFooter
      />
    </div>
  );
};

export default TeamMembersTable;
