import { useMemo } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import { getSubscriptionPlanFrom } from '@/features/common/billing';
import { IPaymentDetailsUseCase } from '@/features/paymentDetails';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IInvoiceDataEntity } from '../../domain';

import { useBillingCycleFromUrl } from './useBillingCycleFromUrl';
import { usePlanFromUrl } from './usePlanFromUrl';

export const useGetInvoiceData = (): ObservableResult<IInvoiceDataEntity> => {
  const paymentDetailsUseCase = useInjection<IPaymentDetailsUseCase>(
    PAYMENT_DETAILS_TYPES.PaymentDetailsUseCase,
  );
  const [planType] = usePlanFromUrl();
  const [billingCycle] = useBillingCycleFromUrl();
  const plan = getSubscriptionPlanFrom({ planType, billingCycle });
  const invoiceData$ = useMemo(() => {
    return paymentDetailsUseCase.getInvoiceData(plan);
  }, [plan]);

  return useObservableResult(invoiceData$);
};
