import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import ModalIcon from '../../assets/ModalIcon.svg?react';

import styles from './styles.module.scss';

export const AdminAccessDeniedModal: FC = () => {
  const { t } = useTranslation('enrichment');
  const navigate = useNavigate();

  return (
    <>
      <Box className={styles.Icon}>
        <ModalIcon />
      </Box>
      <Typography variant="h3" className={styles.Title}>
        {t('adminAccessDeniedModal.title')}
      </Typography>
      <Typography className={styles.SubTitle}>
        {t('adminAccessDeniedModal.subTitle')}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={styles.Button}
        onClick={() => navigate(ROUTES.BILLING.PLANS)}
      >
        {t('adminAccessDeniedModal.button')}
      </Button>
    </>
  );
};
