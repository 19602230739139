import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { ContactInfoFilterType } from '@/features/common/contact';

import { Checkbox, CustomSelect } from '@/components';

import { useFilterByContactInfoViewModel } from './useFilterByContactInfoViewModel';

import styles from './styles.module.scss';

const Option: FC<{ label: string; checked: boolean; value: string }> = ({
  label,
  checked,
  value,
}) => {
  return (
    <Box className={styles.Option}>
      <Typography variant="subtitle1">{label}</Typography>
      <Checkbox checked={checked} value={value} id={value} />
    </Box>
  );
};

export const FilterByContactInfo: FC = () => {
  const {
    options,
    value,
    setValue,
    valuesIsMatched,
    appliedValuesCount,
    handleApply,
    handleCloseAnimationEnd,
  } = useFilterByContactInfoViewModel();
  const { t } = useTranslation('contacts');

  return (
    <Box className={styles.Wrapper}>
      <CustomSelect
        options={options}
        value={value}
        headerClassName={styles.SelectHeader}
        popoverProps={{
          classes: {
            paper: styles.Paper,
          },
          TransitionProps: {
            onExited: handleCloseAnimationEnd,
          },
        }}
        onChange={(value: ContactInfoFilterType[]): void => {
          setValue(value);
        }}
        defaultAnchorProps={{
          renderAnchorValue: (): ReactElement => {
            return (
              <Box className={styles.Anchor}>
                {Boolean(appliedValuesCount) && (
                  <Box component={'span'} className={styles.AnchorCount}>
                    {appliedValuesCount}
                  </Box>
                )}
                <Box component="span" className={styles.AnchorText}>
                  {t('subhead.contactInfoFilter.selectField.value')}
                </Box>
              </Box>
            );
          },
        }}
        renderHeader={(): ReactElement => {
          return (
            <Box className={styles.SelectHeaderText} component="span">
              {t('subhead.contactInfoFilter.title')}
            </Box>
          );
        }}
        renderOption={function ({ selected, option }): ReactElement {
          return <Option checked={selected} label={option.label} value={option.value} />;
        }}
        renderActions={({ onClose }): ReactElement => {
          return (
            <Box className={styles.Actions}>
              <Button
                disabled={valuesIsMatched}
                onClick={(): void => {
                  handleApply();
                  onClose();
                }}
                variant="text"
              >
                {t('subhead.contactInfoFilter.apply')}
              </Button>
            </Box>
          );
        }}
        multiple
      />
    </Box>
  );
};
