import { inject, injectable } from 'inversify';
import { firstValueFrom, from, map, Observable, switchMap } from 'rxjs';

import { INTEGRATIONS_TYPES, REQUEST_CACHE_TYPES } from '@/ioc/types';

import { mapError } from '@/features/integration/data/mappers/mapError'; // TODO: refactor it
import IIntegrationsApiService from '@/features/integrations/domain/abstractions/IIntegrationsApiService';
import IIntegrationsRepository from '@/features/integrations/domain/abstractions/IIntegrationsRepository';
import { IRequestCache } from '@/features/system/requestCache';

import iconCustom from '@/assets/icons/integrations/custom.png';
import iconHubspot from '@/assets/icons/integrations/hubspot.png';
import iconOutreach from '@/assets/icons/integrations/outreach.png';
import iconPipedrive from '@/assets/icons/integrations/pipedrive.png';
import iconSalesforce from '@/assets/icons/integrations/salesforce.png';
import iconZapier from '@/assets/icons/integrations/zapier.png';
import iconZoho from '@/assets/icons/integrations/zoho.png';

import type { IIntegrationEntity } from '../domain';

import type { IIntegrationsState } from './db';
import { mapIntegrationsDcToEntity } from './mappers';

@injectable()
export default class IntegrationsRepository implements IIntegrationsRepository {
  @inject(INTEGRATIONS_TYPES.IntegrationsApiService)
  private api: IIntegrationsApiService;

  @inject(INTEGRATIONS_TYPES.IntegrationsState)
  private state: IIntegrationsState;

  @inject(REQUEST_CACHE_TYPES.InMemoryRequestCache)
  private requestCache: IRequestCache;

  sendRequest(params: { title: string; message: string }): Promise<void> {
    return this.api.sendRequest(params);
  }

  sendRequestToAdminForIntegration(provider: string): Promise<string> {
    return this.api.sendRequestToAdminForIntegration(provider);
  }

  async connectUser(providerName: string, code: string): Promise<void> {
    try {
      await this.api.connectUser(providerName, code);
      await this.loadRemoteIntegrations(); // refresh integrations
    } catch (e) {
      throw mapError(e);
    }
  }

  getAuthUrl(provider: string): Promise<{ url: string }> {
    return firstValueFrom(this.api.getAuthUrl(provider));
  }

  private async loadRemoteIntegrations(): Promise<void> {
    const state = await firstValueFrom(this.api.getIntegrations());
    await this.state.setState(state);
  }

  private getLocalIntegrations(): Observable<IIntegrationEntity[]> {
    return this.state.getState().pipe(
      map((state) => {
        if (!state) throw new Error('Integrations is not synced with remote storage');

        return mapIntegrationsDcToEntity(state);
      }),
    );
  }

  getIntegrations(isStatic: true): Array<Omit<IIntegrationEntity, 'status'>>;
  getIntegrations(): Observable<IIntegrationEntity[]>;
  getIntegrations(
    isStatic?: true,
  ): Array<Omit<IIntegrationEntity, 'status'>> | Observable<IIntegrationEntity[]> {
    if (isStatic) return IntegrationsRepository.STATIC_INTEGRATIONS;

    return from(
      this.requestCache.networkFirst({
        key: 'integrations',
        fetcher: () => this.loadRemoteIntegrations(),
      }),
    ).pipe(switchMap(() => this.getLocalIntegrations()));
  }

  private static readonly STATIC_INTEGRATIONS: Array<Omit<IIntegrationEntity, 'status'>> =
    [
      {
        icon: iconSalesforce,
        id: 'salesforce',
        name: 'Salesforce',
      },
      {
        icon: iconHubspot,
        id: 'hubspot',
        name: 'HubSpot',
      },
      {
        icon: iconZoho,
        id: 'zoho',
        name: 'Zoho CRM',
      },
      {
        icon: iconPipedrive,
        id: 'pipedrive',
        name: 'Pipedrive',
      },
      {
        icon: iconZapier,
        id: 'zapier',
        name: 'Zapier',
      },
      {
        icon: iconOutreach,
        id: 'outreach',
        name: 'Outreach',
      },
      {
        id: 'custom',
        icon: iconCustom,
        name: 'Custom integration',
      },
    ];
}
