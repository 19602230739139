import { type FC, type ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, type StripeElementsOptions } from '@stripe/stripe-js';

// @ts-ignore
import LightFont from '@/assets/fonts/SofiaProLight.otf';
// @ts-ignore
import MediumFont from '@/assets/fonts/SofiaProRegular.otf';

const STRIPE_PUBLISHABLE_KEY = import.meta.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

if (!STRIPE_PUBLISHABLE_KEY) {
  console.warn('REACT_APP_STRIPE_PUBLISHABLE_KEY is not defined');
}

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const StripeOptions: StripeElementsOptions = {
  fonts: [
    {
      family: 'SofiaPro',
      src: `url('${window.location.origin}${LightFont}') format('opentype')`,
      weight: '400',
    },
    {
      family: 'SofiaPro',
      src: `url('${window.location.origin}${MediumFont}') format('opentype')`,
      weight: '500',
    },
  ],
};

type StripeProviderProps = {
  children: ReactNode;
};

export const StripeProvider: FC<StripeProviderProps> = ({ children }) => {
  return (
    <Elements stripe={stripePromise} options={StripeOptions}>
      {children}
    </Elements>
  );
};
