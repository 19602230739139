export interface IDiscountEntity {
  name: string;
  rate: number;
  isOneTime: boolean;
  displayRate: number;
}

class DiscountEntity implements IDiscountEntity {
  name: IDiscountEntity['name'];
  rate: IDiscountEntity['rate'];
  isOneTime: IDiscountEntity['isOneTime'];
  displayRate: IDiscountEntity['displayRate'];

  constructor(
    name: IDiscountEntity['name'],
    rate: IDiscountEntity['rate'],
    displayRate: number,
    isOneTime: IDiscountEntity['isOneTime'],
  ) {
    this.name = name;
    this.rate = rate;
    this.isOneTime = isOneTime;
    this.displayRate = displayRate;
  }
}

const CURRENT_DISCOUNT_RATE_TO_SHOW = 25;

export const DiscountAnnual = new DiscountEntity(
  'annualDiscount',
  25 / 100,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);

export const ProLegacyV1AnnualDiscount = new DiscountEntity(
  'annualDiscount',
  (25 - 19) / 25,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);
export const GrowthAnnualDiscount = new DiscountEntity(
  'annualDiscount',
  (34 - 25) / 34,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);

export const UltimateAnnualDiscount = new DiscountEntity(
  'annualDiscount',
  (59 - 44) / 59,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);

export const DiscountAdvancedAnnual25 = new DiscountEntity(
  'annualDiscount',
  25 / 100,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);

export const ProAnnualDiscount = new DiscountEntity(
  'annualDiscount',
  (34 - 25) / 34,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);

export const UnlimitedAnnualDiscount = new DiscountEntity(
  'annualDiscount',
  (99 - 69) / 99,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);
