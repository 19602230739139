import { FC } from 'react';
import { Box, Grid, IconButton, Popover, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { ArrowDownIcon } from '@/components/Icons';

import { UserData } from '../../types';

import { UserMenuPopover } from './UserMenuPopover';
import { useUserMenuViewModel } from './UserMenuViewModel';

import styles from './styles.module.scss';

type UserIconContainerProps = UserData & {
  navigateToSettings(): void;
  navigateToTeamManagement(): void;
  navigateToSubscription(): void;
  navigateToTagManagement(): void;
  onGetFreeCreditsClick: () => void;
  onReferFriendClick: () => void;
};

export const UserMenuContainer: FC<UserIconContainerProps> = ({
  fullName,
  email,
  planType,
  userLogo,
  creditsUsed,
  creditsLimit,
  hasUserReceivedFreeCredits,
  role,
  navigateToSettings,
  navigateToTeamManagement,
  navigateToSubscription,
  navigateToTagManagement,
  onGetFreeCreditsClick,
  onReferFriendClick,
}) => {
  const {
    onLogout,
    isUserMenuOpen,
    anchorEl,
    onOpen,
    onClose,
    initials,
    handleInviteTeamMember,
    navigateToHelpCenter,
  } = useUserMenuViewModel({ fullName });

  return (
    <>
      <Grid
        alignItems="center"
        flexWrap="nowrap"
        onClick={onOpen}
        className={styles.container}
      >
        <Avatar alt={fullName} src={userLogo} className={styles.avatar}>
          {initials}
        </Avatar>
        <div className={styles.nameContainer}>
          <Box mr={1.25} />
          <Typography variant="h3">
            {fullName.length > 15 ? fullName.substring(0, 15).concat('...') : fullName}
          </Typography>
          <Box mr={2} />
          <IconButton size="small">
            <ArrowDownIcon className={styles.arrowDown} />
          </IconButton>
        </div>
      </Grid>
      <Popover
        open={isUserMenuOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: -15,
          horizontal: 'left',
        }}
      >
        <UserMenuPopover
          role={role}
          fullName={fullName}
          initials={initials}
          email={email}
          planType={planType}
          userLogo={userLogo}
          creditsUsed={creditsUsed}
          creditsLimit={creditsLimit}
          navigateToSettings={navigateToSettings}
          navigateToTeamManagement={navigateToTeamManagement}
          navigateToSubscription={navigateToSubscription}
          navigateToTagManagement={navigateToTagManagement}
          navigateToHelpCenter={navigateToHelpCenter}
          onGetFreeCreditsClick={onGetFreeCreditsClick}
          onReferFriendClick={onReferFriendClick}
          onLogout={onLogout}
          hasUserReceivedFreeCredits={hasUserReceivedFreeCredits}
          handleInviteTeamMember={handleInviteTeamMember}
        />
      </Popover>
    </>
  );
};
