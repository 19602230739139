import { MouseEvent, ReactElement, useMemo, useState } from 'react';
import { Box, List, ListItem, ListProps, Popover, PopoverProps } from '@mui/material';
import { clsx } from 'clsx';
import { indexBy, prop } from 'ramda';

import { CustomSelectAnchor, CustomSelectAnchorProp } from './CustomSelectAnchor';
import { CustomSelectDefaultOption } from './CustomSelectDefaultOption';
import { CustomSelectEmptyList } from './CustomSelectEmptyList';
import { CustomSelectTagOption } from './CustomSelectTagOption';
import { SelectBaseOption } from './types';

import styles from './styles.module.scss';

export type {
  CustomSelectDefaultOptionProps,
  CustomSelectDefaultOptionType,
} from './CustomSelectDefaultOption';
export type {
  CustomSelectTagOptionProps,
  CustomSelectTagOptionType,
} from './CustomSelectTagOption';

type RenderCustomSelectOptionProp<T> = { option: T; selected: boolean };

export type CustomSelectProps<T> = {
  options: T[];
  value: string | string[] | null;
  multiple?: boolean;
  name?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  size?: 'small' | 'medium' | 'large';
  headerClassName?: string;
  emptyListText?: string;
  popoverProps?: Omit<PopoverProps, 'anchorEl' | 'open' | 'onClose'>;
  defaultAnchorProps?: Partial<
    Omit<
      CustomSelectAnchorProp,
      'isOpen' | 'disabled' | 'error' | 'required' | 'setAnchor' | 'size'
    >
  >;
  listProps?: ListProps;
  atLeastOneRequired?: boolean;
  closeAfterSelect?: boolean;
  onChange?: (value: string | string[]) => void;
  onClose?: () => void;
  renderOption: (props: RenderCustomSelectOptionProp<T>) => ReactElement;
  renderAnchor?: (
    props: Omit<CustomSelectAnchorProp, 'renderAnchorValue'>,
  ) => ReactElement;
  renderHeader?: () => ReactElement;
  renderActions?: (params: { onClose: () => void }) => ReactElement;
  renderCustomContent?: (params: { onClose: () => void }) => ReactElement;
};

export function CustomSelect<T extends SelectBaseOption>({
  value,
  onChange,
  onClose,
  multiple,
  name,
  options,
  disabled,
  error,
  required,
  atLeastOneRequired,
  closeAfterSelect = true,
  size = 'medium',
  headerClassName,
  emptyListText = 'List is empty',
  popoverProps,
  defaultAnchorProps,
  listProps,
  renderOption,
  renderHeader,
  renderAnchor,
  renderActions,
  renderCustomContent,
}: CustomSelectProps<T>): ReactElement {
  const valueIsArray = Array.isArray(value);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const optionsMapByValue: Record<string, T> = useMemo(() => {
    return indexBy(prop('value'), options);
  }, [options]);

  if (multiple && !valueIsArray) {
    throw Error(`Prop 'value' must be an array`);
  }

  const selectedOptions = useMemo(() => {
    if (valueIsArray) {
      return value
        .map((item: string) => optionsMapByValue[item])
        .filter((item) => Boolean(item));
    }
    const option = optionsMapByValue[value as string];
    return option ? [option] : [];
  }, [value, options]);

  const handleClose = (): void => {
    setAnchorElement(null);
    onClose && onClose();
  };

  const handleClick = (e: MouseEvent<HTMLLIElement>, option: T): void => {
    if (multiple && valueIsArray) {
      const needToRemoveValue = value.find((valueString) => option.value === valueString);
      if (atLeastOneRequired && value.length <= 1) return; // at least 1 value must be selected
      onChange &&
        onChange(
          needToRemoveValue
            ? value.filter((item) => item !== needToRemoveValue)
            : [...value, option.value],
        );
    } else {
      // Default value functionality
      onChange && onChange(option.value);
      closeAfterSelect && setAnchorElement(null);
    }
  };

  const inputValue =
    selectedOptions.length <= 1 ? selectedOptions.join('') : selectedOptions.join(',');

  const anchorValue =
    defaultAnchorProps?.anchorValue ||
    selectedOptions.map((item) => item.label).join(', ') ||
    '';

  return (
    <>
      {renderAnchor ? (
        renderAnchor({
          anchorValue,
          isOpen: Boolean(anchorElement),
          setAnchorElement,
          error,
          disabled,
          required,
        })
      ) : (
        <CustomSelectAnchor
          {...defaultAnchorProps}
          size={size}
          anchorValue={anchorValue}
          isOpen={Boolean(anchorElement)}
          disabled={disabled}
          error={error}
          required={required}
          setAnchorElement={setAnchorElement}
        />
      )}
      <input type="hidden" value={inputValue} name={name} aria-hidden />
      <Popover
        {...popoverProps}
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
      >
        {renderHeader && (
          <Box className={clsx(styles.header, headerClassName)}>{renderHeader()}</Box>
        )}
        {renderCustomContent ? (
          renderCustomContent({
            onClose: handleClose,
          })
        ) : options.length ? (
          <List {...listProps} className={clsx(styles.list, listProps?.className)}>
            {options.map((option) => {
              return (
                <ListItem
                  key={option.value}
                  data-value={option.value}
                  className={styles.listItem}
                  onClick={(e): void => {
                    handleClick(e, option);
                  }}
                >
                  {renderOption({
                    option,
                    selected: valueIsArray
                      ? value.includes(option.value)
                      : value === option.value,
                  })}
                </ListItem>
              );
            })}
          </List>
        ) : (
          <CustomSelectEmptyList>{emptyListText}</CustomSelectEmptyList>
        )}
        {renderActions && (
          <Box className={styles.actionsContainer}>
            {renderActions({
              onClose: handleClose,
            })}
          </Box>
        )}
      </Popover>
    </>
  );
}

CustomSelect.DefaultOption = CustomSelectDefaultOption;
CustomSelect.TagOption = CustomSelectTagOption;
