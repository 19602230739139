import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { AUTH_TYPES } from '@/ioc/types';

import { IAuthRepository } from '@/features/common/auth';

export interface IResetPasswordUseCases {
  resetPassword(oobCode: string, newPassword: string): Observable<void>;
  checkOobCode(oobCode: string): Observable<boolean>;
}

@injectable()
export class ResetPasswordUseCases implements IResetPasswordUseCases {
  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  checkOobCode(oobCode: string): Observable<boolean> {
    return this.authRepository.checkActionCode(oobCode).pipe(
      map(() => {
        return true;
      }),
    );
  }

  resetPassword(oobCode: string, newPassword: string): Observable<void> {
    return this.authRepository.resetPassword(oobCode, newPassword);
  }
}
