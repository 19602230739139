import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { getPlanTypeFromSubscription } from '@/features/common/billing/domain';
import { isPlanUpgrade } from '@/features/plans/ui/utils';

import { PaymentDetailsContext } from '../contexts';

import { usePlanFromUrl } from './usePlanFromUrl';

export function useRedirect(): void {
  const { currentSubscription } = useContext(PaymentDetailsContext);

  const navigate = useNavigate();

  const [nextPlanType] = usePlanFromUrl();

  // run it only once when subscription loaded
  useEffect(() => {
    const currentPlanType = getPlanTypeFromSubscription(currentSubscription);

    if (!isPlanUpgrade(nextPlanType, currentPlanType) && currentSubscription.isActive) {
      navigate(ROUTES.SETTINGS.SUBSCRIPTION);
    }
  }, []);
}
