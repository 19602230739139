import { useContext } from 'react';

import { useObservableResult } from '@/utils/rx';

import { EnrichmentContext } from '../EnrichmentContext';

import { useEnrichmentUseCase } from './useEnrichmentUseCase';

type UseEnrichmentAccessResult = {
  hasAccess: Nullable<boolean>;
  ensureEnrichmentAccess(): boolean;
};

export const useEnrichmentAccess = (): UseEnrichmentAccessResult => {
  const { onAccessDeniedModalOpen } = useContext(EnrichmentContext);
  const enrichmentUseCase = useEnrichmentUseCase();
  const { data } = useObservableResult(() => enrichmentUseCase.ensureAccess(), {
    deps: [],
  });

  const ensureEnrichmentAccess = (): boolean => {
    if (data === null) return false;

    if (!data.hasAccess) {
      onAccessDeniedModalOpen();
    }

    return data.hasAccess;
  };

  return {
    hasAccess: data?.hasAccess,
    ensureEnrichmentAccess,
  };
};
