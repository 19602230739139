import { SubscriptionPlan } from '@/features/common/workspace';

import { BillingCycle, PlanType } from '../types';

function match(billingCycle: BillingCycle): (map: {
  [key in
    | BillingCycle.Monthly
    | BillingCycle.Yearly
    | BillingCycle.Daily]: SubscriptionPlan;
}) => SubscriptionPlan {
  return (map) => map[billingCycle];
}

export function getSubscriptionPlanFrom(params: {
  planType: PlanType;
  billingCycle: BillingCycle;
}): SubscriptionPlan {
  const { planType, billingCycle } = params;

  switch (planType) {
    case PlanType.Free:
      return SubscriptionPlan.FreeMonthly;
    case PlanType.ProLegacyV0:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.ProV0DailyLegacy,
        [BillingCycle.Monthly]: SubscriptionPlan.ProV0MonthlyLegacy,
        [BillingCycle.Yearly]: SubscriptionPlan.ProV0AnnuallyLegacy,
      });
    case PlanType.ProLegacyV1:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.ProV1DailyLegacy,
        [BillingCycle.Monthly]: SubscriptionPlan.ProV1MonthlyLegacy,
        [BillingCycle.Yearly]: SubscriptionPlan.ProV1AnnuallyLegacy,
      });
    case PlanType.Pro:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.ProV2Daily,
        [BillingCycle.Monthly]: SubscriptionPlan.ProV2Monthly,
        [BillingCycle.Yearly]: SubscriptionPlan.ProV2Annually,
      });
    case PlanType.Unlimited:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.UnlimitedDaily,
        [BillingCycle.Monthly]: SubscriptionPlan.UnlimitedMonthly,
        [BillingCycle.Yearly]: SubscriptionPlan.UnlimitedAnnually,
      });
    case PlanType.Expand:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.ExpandMock,
        [BillingCycle.Monthly]: SubscriptionPlan.ExpandMock,
        [BillingCycle.Yearly]: SubscriptionPlan.ExpandMock,
      });
    case PlanType.AdvancedLegacy:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.AdvancedDayly,
        [BillingCycle.Monthly]: SubscriptionPlan.AdvancedMonthly,
        [BillingCycle.Yearly]: SubscriptionPlan.AdvancedAnnually,
      });
    case PlanType.Growth:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.GrowthDayly,
        [BillingCycle.Monthly]: SubscriptionPlan.GrowthMonthly,
        [BillingCycle.Yearly]: SubscriptionPlan.GrowthAnnually,
      });
    case PlanType.Ultimate:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.UltimateDayly,
        [BillingCycle.Monthly]: SubscriptionPlan.UltimateMonthly,
        [BillingCycle.Yearly]: SubscriptionPlan.UltimateAnnually,
      });
    case PlanType.Scale:
      return SubscriptionPlan.Scale;
  }
}
