import {
  mapTagDcToEntity,
  mapTagEntityToDc,
} from 'features/common/tag/data/mappers/mapper';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { TAG_TYPES } from '@/ioc/types';

import { ITagDao, ITagDC, ITagEntity, ITagRepository } from '@/features/common/tag';

@injectable()
export default class TagRepository implements ITagRepository {
  @inject(TAG_TYPES.TagDao)
  private tagDao: ITagDao;

  getTags = (): Observable<ITagEntity[]> => {
    return this.tagDao.findAll().pipe(map((tagArr) => tagArr.map(mapTagDcToEntity)));
  };

  addTag(tag: ITagEntity): Observable<ITagEntity> {
    return this.tagDao
      .upsert(mapTagEntityToDc(tag))
      .pipe(map((tag) => mapTagDcToEntity(tag)));
  }

  insertTag(payload: WithOptionalId<ITagDC, 'uuid'>): Observable<ITagEntity> {
    return this.tagDao.insertOne(payload).pipe(map((tag) => mapTagDcToEntity(tag)));
  }

  updateTag({
    uuid,
    ...patch
  }: Pick<ITagDC, 'uuid' | 'color' | 'name'>): Observable<void> {
    return this.tagDao.updateOne(uuid, patch).pipe(map(() => undefined));
  }

  deleteTag(uuid: string): Observable<boolean> {
    return this.tagDao.removeOne(uuid).pipe(map((res) => !!res));
  }
}
