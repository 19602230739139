import React, { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { ActionDisableGuard } from '@/features/common/account';
import { ITagEntity } from '@/features/common/tag';

import { Modal } from '@/components';
import { EditIcon, RubbishBinIcon } from '@/components/Icons';

import imgDeleteTagIcon from '@/assets/icons/settings/deleteTagIcon.png';

import { TagSettingsDialog } from '../../../TagSettingsDialog';

import useTagTableActionCellViewModel from './TagTableActionCellViewModel';

import styles from './styles.module.scss';

const EditButtonWithTagSettingsDialog: FC<{
  tag: Pick<ITagEntity, 'name' | 'color' | 'uuid'>;
}> = ({ tag }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { t } = useTranslation('tag');

  const onOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip placement="top" className={styles.Button} title={t('editTag.tooltip')}>
        <ActionDisableGuard>
          <Button variant="text" color="info" onClick={onOpen}>
            <EditIcon />
          </Button>
        </ActionDisableGuard>
      </Tooltip>
      <TagSettingsDialog anchorEl={anchorEl} onClose={onClose} tag={tag} />
    </>
  );
};

const TagTableActionsCell: React.FC<GridRenderCellParams> = ({
  row: { name, color, uuid, contacts, createdByUuid },
}) => {
  const {
    isVisible,
    isDeleteDialogVisible,
    onDelete,
    onCloseDeleteDialog,
    deleteDialog,
    isLastTag,
  } = useTagTableActionCellViewModel(uuid, createdByUuid, name);
  const { t } = useTranslation('tag');
  if (!isVisible) return null;

  return (
    <Box className={styles.Wrapper}>
      <EditButtonWithTagSettingsDialog
        tag={{
          name,
          color,
          uuid,
        }}
      />
      {!isLastTag && (
        <Tooltip title={t('deleteTag.tooltip')} placement="top" className={styles.Button}>
          <ActionDisableGuard>
            <Button variant="text" color="info" onClick={onDelete}>
              <RubbishBinIcon />
            </Button>
          </ActionDisableGuard>
        </Tooltip>
      )}
      <Modal
        open={isDeleteDialogVisible}
        onClose={onCloseDeleteDialog}
        containerClass={styles.DeleteDialog}
      >
        <>
          <img src={imgDeleteTagIcon} alt="Delete Tag" />
          <Typography className={styles.DeleteDialogTitle}>
            {t('deleteTag.dialogTitle')}
          </Typography>
          <Typography className={styles.DeleteDialogDescription}>
            {t('deleteTag.dialogDescription', { name, contacts })}
          </Typography>
          <Box className={styles.Buttons}>
            <Button color="info" onClick={onCloseDeleteDialog}>
              {t('deleteTag.dialogCancel')}
            </Button>
            <Button variant="contained" onClick={deleteDialog}>
              {t('deleteTag.dialogDelete')}
            </Button>
          </Box>
        </>
      </Modal>
    </Box>
  );
};

export default TagTableActionsCell;
