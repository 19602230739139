import { RxJsonSchema } from 'rxdb';

import { IInvitationDC } from '@/features/referral';

export const referralSchema: RxJsonSchema<IInvitationDC> = {
  title: 'referral schema',
  version: 0,
  primaryKey: 'uuid',
  type: 'object',
  required: ['uuid'],
  properties: {
    uuid: { type: 'string', maxLength: 100 },
    invitee_email: { type: 'string' },
    status: { type: 'string' },
    referrer_token: { type: 'string' },
    generated_at: { type: 'string' },
    status_updated_at: { type: 'string' },
  },
};
