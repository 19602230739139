import { IStripeSubscriptionEntity, StripeSubscriptionStatus } from '../../domain';
import { IStripeSubscriptionDC } from '../dataContracts';

export function mapStripeSubscriptionDcToEnity(
  dc: IStripeSubscriptionDC,
): IStripeSubscriptionEntity {
  return {
    id: dc.id,
    latestInvoice: {
      status: dc.latest_invoice.status,
      paymentIntent: dc.latest_invoice.payment_intent
        ? {
            clientSecret: dc.latest_invoice.payment_intent.client_secret,
          }
        : undefined,
    },
    pendingSetupIntent: dc.pending_setup_intent,
    status: dc.status as StripeSubscriptionStatus,
  };
}
