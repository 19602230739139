import { FirebaseError } from 'firebase/app';
import {
  ActionCodeInfo,
  AuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  User,
} from 'firebase/auth';
import { inject, injectable } from 'inversify';
import {
  catchError,
  distinctUntilChanged,
  filter,
  forkJoin,
  from,
  map,
  Observable,
  of,
  share,
  switchMap,
  throwError,
} from 'rxjs';

import { container } from '@/ioc/container';
import { AUTH_TYPES } from '@/ioc/types';

import { Gate } from '@/utils/gate';
import { WorkEmailValidationSchema } from '@/utils/validation';

import {
  EmailSendRateLimitError,
  InvalidWorkEmailError,
  UserAlreadyExistsError,
  UserNotFoundError,
} from '../domain';
import { AuthStatus } from '../domain/types';

import { IAuthApiService, IAuthRepository } from './abstractions';
import { mapFirebaseErrorToAuthError } from './mappers';
import { FirebaseService } from './network';

@injectable()
export class AuthRepository implements IAuthRepository {
  @inject(AUTH_TYPES.FirebaseService)
  private firebaseService: FirebaseService;
  private authReactivityLocker = new Gate();

  // cicrcular dependency
  private get authApiService(): IAuthApiService {
    return container.get<IAuthApiService>(AUTH_TYPES.AuthApiService);
  }

  getUser(): Observable<User | null | undefined> {
    return this.firebaseService.user.pipe(this.authReactivityLocker.pass, share());
  }

  getUserCountry(): Observable<string> {
    return this.authApiService.getUserInfoByIp().pipe(map((data) => data?.country_code));
  }

  getAuthStatus = (): Observable<AuthStatus> => {
    return this.getUser().pipe(
      map((user) => {
        if (user === undefined) {
          return AuthStatus.Initialisation;
        }

        if (user === null) {
          return AuthStatus.Unauthorized;
        }

        return AuthStatus.Authorized;
      }),
      distinctUntilChanged(),
    );
  };

  getAccessToken = (): Observable<string> => {
    return this.getAuthStatus().pipe(
      filter((status) => status !== AuthStatus.Initialisation),
      switchMap(() => this.getUser()),
      map((user) => (Reflect.get(user ?? {}, 'accessToken') as string) ?? ''),
      distinctUntilChanged(),
    );
  };

  sendPasswordResetEmail(email: string): Observable<void> {
    return this.authApiService.sendResetPasswordLink(email).pipe(
      catchError((error) => {
        if (error.statusCode === 429) {
          return throwError(() => new EmailSendRateLimitError());
        }
        if (error.statusCode === 400) {
          return throwError(() => new UserNotFoundError());
        }
        return throwError(() => error);
      }),
    );
  }

  applyActionCode(actionCode: string): Observable<void> {
    return from(this.firebaseService.applyActionCode(actionCode));
  }

  checkActionCode(actionCode: string): Observable<ActionCodeInfo> {
    return from(this.firebaseService.checkActionCode(actionCode));
  }

  checkUserExists(email: string): Observable<boolean> {
    return from(this.firebaseService.checkUserExists(email));
  }

  resetPassword(oobCode: string, newPassword: string): Observable<void> {
    return from(this.firebaseService.confirmPasswordReset(oobCode, newPassword));
  }

  updatePassword(newPassword: string, currentPassword?: string): Observable<void> {
    return of(currentPassword).pipe(
      switchMap((currentPassword) => {
        if (currentPassword) {
          return from(this.firebaseService.reauthenticateWithPassword(currentPassword));
        }

        return of(undefined);
      }),
      switchMap(() => {
        return from(this.firebaseService.updatePassword(newPassword));
      }),
      catchError((error) => {
        if (error instanceof FirebaseError) {
          return throwError(() => mapFirebaseErrorToAuthError(error));
        }

        return throwError(() => error);
      }),
    );
  }

  signInWithEmailAndPassword(email: string, password: string): Observable<string> {
    return from(this.firebaseService.signInWithEmailAndPassword(email, password)).pipe(
      switchMap((userCredential) => {
        return from(userCredential.user.getIdToken());
      }),
      catchError((error) => {
        if (error instanceof FirebaseError) {
          return throwError(() => mapFirebaseErrorToAuthError(error));
        }
        return throwError(() => error);
      }),
    );
  }

  signInWithGoogle(): Observable<string> {
    return this.signInWithProvider(this.firebaseService.googleAuthProvider);
  }

  signInWithMicrosoft(): Observable<string> {
    return this.signInWithProvider(this.firebaseService.microsoftAuthProvider);
  }

  signInWithCustomToken(customToken: string): Observable<unknown> {
    return from(this.firebaseService.signInWithCustomToken(customToken)).pipe(
      catchError((error) => {
        if (error instanceof FirebaseError) {
          return throwError(() => mapFirebaseErrorToAuthError(error));
        }
        return throwError(() => error);
      }),
    );
  }

  signUpWithEmailAndPassword(email: string, password: string): Observable<string> {
    return from(
      WorkEmailValidationSchema.validate(email).catch(() => {
        throw new InvalidWorkEmailError();
      }),
    ).pipe(
      switchMap(() =>
        from(this.firebaseService.createUserWithEmailAndPassword(email, password)),
      ),
      switchMap((userCredential) => from(userCredential.user.getIdToken())),
      catchError((error) => {
        if (error instanceof FirebaseError) {
          return throwError(() => mapFirebaseErrorToAuthError(error));
        }
        return throwError(() => error);
      }),
    );
  }

  signUpWithGoogle(): Observable<string> {
    return this.signUpWithProvider(this.firebaseService.googleAuthProvider);
  }

  signUpWithMicrosoft(): Observable<string> {
    return this.signUpWithProvider(this.firebaseService.microsoftAuthProvider);
  }

  signOut(): Observable<boolean> {
    return from(this.firebaseService.signOut()).pipe(map(() => true));
  }

  sendVerificationEmail(email: string): Observable<void> {
    return this.authApiService.sendEmailVerificationLink(email).pipe(
      catchError((error) => {
        if (error.statusCode === 429) {
          return throwError(() => new EmailSendRateLimitError());
        }
        if (error.statusCode === 400) {
          return throwError(() => new UserNotFoundError());
        }
        return throwError(() => error);
      }),
    );
  }

  reloadUser(): Observable<void> {
    return from(this.firebaseService.reloadUser());
  }

  getGoogleAuthProvider(): GoogleAuthProvider {
    return this.firebaseService.googleAuthProvider;
  }

  getMicrosoftAuthProvider(): OAuthProvider {
    return this.firebaseService.microsoftAuthProvider;
  }

  private signInWithProvider(provider: AuthProvider): Observable<string> {
    return this.authReactivityLocker.withLocked(
      from(this.firebaseService.signInWithPopup(provider)).pipe(
        switchMap((userCredential) => {
          return from(WorkEmailValidationSchema.validate(userCredential.user.email)).pipe(
            catchError(() => throwError(() => new InvalidWorkEmailError())),
            map(() => userCredential),
            catchError((error) =>
              from(this.firebaseService.signOut()).pipe(
                switchMap(() => throwError(() => error)),
              ),
            ),
          );
        }),
        map((userCredential) => {
          return {
            userCredential: userCredential,
            additionalUserInfo:
              this.firebaseService.getAdditionalUserInfo(userCredential),
          };
        }),
        switchMap(({ userCredential, additionalUserInfo }) => {
          if (additionalUserInfo?.isNewUser) {
            return forkJoin({
              deleteUser: this.firebaseService.deleteUser(),
              signOut: this.firebaseService.signOut(),
            }).pipe(switchMap(() => throwError(() => new UserNotFoundError())));
          }

          return from(userCredential.user.getIdToken());
        }),
        catchError((error) => {
          if (error instanceof FirebaseError) {
            return throwError(() => mapFirebaseErrorToAuthError(error));
          }
          return throwError(() => error);
        }),
      ),
    );
  }

  private signUpWithProvider(provider: AuthProvider): Observable<string> {
    return this.authReactivityLocker.withLocked(
      from(this.firebaseService.signInWithPopup(provider)).pipe(
        map((userCredential) => ({
          userCredential: userCredential,
          additionalUserInfo: this.firebaseService.getAdditionalUserInfo(userCredential),
        })),
        switchMap(({ userCredential, additionalUserInfo }) => {
          return from(WorkEmailValidationSchema.validate(userCredential.user.email)).pipe(
            catchError(() => {
              if (additionalUserInfo?.isNewUser) {
                return from(this.firebaseService.deleteUser()).pipe(
                  switchMap(() => throwError(() => new InvalidWorkEmailError())),
                );
              }

              return throwError(() => new InvalidWorkEmailError());
            }),
            switchMap(() => {
              if (additionalUserInfo?.isNewUser) {
                return from(userCredential.user.getIdToken());
              }

              return throwError(() => new UserAlreadyExistsError());
            }),
            catchError((error) => {
              return from(this.firebaseService.signOut()).pipe(
                switchMap(() => throwError(() => error)),
              );
            }),
          );
        }),
        catchError((error) => {
          if (error instanceof FirebaseError) {
            return throwError(() => mapFirebaseErrorToAuthError(error));
          }
          return throwError(() => error);
        }),
      ),
    );
  }
}
