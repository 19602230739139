import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { mergeDeepRight } from 'ramda';

import { ArrowRightIcon } from '@/components/Icons';

import styles from './styles.module.scss';

dayjs.extend(isBetween);

type DateSelectValue = [Dayjs | null, Dayjs | null];

type DateSelectPickerProps = {
  onChange: (value: DateSelectValue) => void;
  value: string;
  minDate?: number;
};

export const DateSelectPicker: FC<DateSelectPickerProps> = ({
  onChange,
  value: defaultValue,
  minDate: minDateTimestamp,
}) => {
  const [value, setValue] = useState<DateSelectValue>(() => {
    const parsedValue: DateSelectValue = [null, null];
    if (defaultValue) {
      const [startDate, endDate] = defaultValue.split('-');
      parsedValue[0] = dayjs(startDate);
      parsedValue[1] = dayjs(endDate);
    }
    return parsedValue;
  });
  const [open, setOpen] = useState(() => {
    if (value[0] && value[1]) {
      return false;
    }
    return true;
  });

  const minDate = dayjs.unix(minDateTimestamp ?? 0);

  const handleChange = (value: [Dayjs, Dayjs]): void => {
    setValue(value);
    const allValuesExist = value.every((item) => Boolean(item));
    const isFutureDate = (value: Dayjs): boolean => dayjs().isBefore(value);
    const isPastDate = (value: Dayjs): boolean => minDate.isAfter(value);

    const verifiedValue: [Dayjs, Dayjs] = value.map((value): Dayjs => {
      if (value && isFutureDate(value)) {
        return dayjs();
      }

      if (value && isPastDate(value)) {
        return minDate;
      }

      return value;
    }) as [Dayjs, Dayjs];

    if (allValuesExist) {
      onChange(verifiedValue);
    }
  };

  const handleInputClick = (): void => {
    setOpen(true);
  };

  const handelClose = (): void => {
    setOpen(false);
  };

  return (
    <Box className={styles.timeFilterRange}>
      <DateRangePicker
        disableFuture
        minDate={minDate}
        open={open}
        calendars={1}
        value={value}
        slotProps={{
          fieldSeparator: {
            className: styles.timeFilterRangeSeparator,
            children: (
              <Box component="span" className={styles.timeFilterRangeIcon}>
                <ArrowRightIcon />
              </Box>
            ),
          },
          textField: ({ position }) => {
            let props = {
              classes: {
                root: styles.timeFilterRangeField,
              },
              onClick: handleInputClick,
            };
            if (position === 'start') {
              props = mergeDeepRight(props, {
                InputProps: {
                  placeholder: 'Start date',
                },
              });
            } else {
              props = mergeDeepRight(props, {
                InputProps: {
                  placeholder: 'End date',
                },
              });
            }
            return props;
          },
        }}
        onChange={handleChange}
        onClose={handelClose}
      />
    </Box>
  );
};
