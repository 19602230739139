import { useCallback, useState } from 'react';

export type AsyncExecutorFunction<Payload> = (...args) => Promise<Payload>;

export type UseAsyncExecutorResult<Data> = {
  isLoading: boolean;
  error?: Nullable<Error>;
  status: AsyncExecutorStatus;
  asyncExecute: AsyncExecutorFunction<Data>;
};

export type AsyncExecutorStatus = 'idle' | 'loading' | 'success' | 'error';

export const useAsyncExecutor = <Data>(
  asyncFn: AsyncExecutorFunction<Data>,
): UseAsyncExecutorResult<Data> => {
  const [status, setStatus] = useState<AsyncExecutorStatus>('idle');
  const [error, setError] = useState<Nullable<Error>>();
  const isLoading = status === 'loading';

  const asyncExecute: AsyncExecutorFunction<Data> = useCallback(
    async (...args) => {
      setStatus('loading');
      try {
        const data = await asyncFn(...args);
        setStatus('success');
        return data;
      } catch (error) {
        setError(error);
        setStatus('error');
        throw error;
      }
    },
    [asyncFn],
  );

  return { isLoading, error, status, asyncExecute };
};
