import { FC, useEffect, useRef } from 'react';
import PhoneInputInner, {
  PhoneInputProps as PhoneInputInnerProps,
} from 'react-phone-input-2';
import clsx from 'clsx';

import { suggestCountries } from './countries';

import 'react-phone-input-2/lib/bootstrap.css';
import styles from './styles.module.scss';

type PhoneInputProps = {
  phone: string;
  onPhoneChange: (phone: string) => void;
  country?: string;
  onCountryChange?: (country: string) => void;
  placeholder?: string;
  hasError?: boolean;
};

export const PhoneInput: FC<PhoneInputProps> = ({
  placeholder,
  phone,
  onPhoneChange,
  country = 'US',
  onCountryChange,
  hasError,
}) => {
  const countryChangeHandlerRef = useRef(onCountryChange);

  const handleChange: PhoneInputInnerProps['onChange'] = (newPhone, metadata, e) => {
    if (phone !== newPhone) {
      onPhoneChange(newPhone);
    }

    // fix country change from country selection dropdown
    if ('countryCode' in metadata) {
      const newCountry = metadata.countryCode.toUpperCase();

      if (country !== newCountry) {
        onCountryChange?.(newCountry);
      }
    }

    // fix cursor position after text selection edit
    const input = e.target;

    if (!input.value) return;

    if (input.value.endsWith(')')) return;

    input.selectionStart = input.selectionEnd = input.value.length;
  };

  useEffect(() => {
    countryChangeHandlerRef.current = onCountryChange;
  });

  useEffect(() => {
    const oldCountry = country;

    const newCountries = suggestCountries(phone);

    if (newCountries.length === 0) return;

    if (newCountries.includes(oldCountry)) return;

    const newCountry = newCountries.toSorted((a, b) => b.length - a.length).at(0);

    if (newCountry && newCountry !== oldCountry) {
      countryChangeHandlerRef.current?.(newCountry);
    }
  }, [phone]);

  return (
    <PhoneInputInner
      searchPlaceholder={placeholder}
      containerClass={styles.phoneInputContainer}
      inputClass={clsx(styles.phoneInput, {
        [styles.error]: hasError,
      })}
      disableCountryGuess
      searchClass={styles.searchInput}
      buttonClass={styles.phoneDropdownButton}
      country={country?.toLowerCase()}
      value={phone}
      enableSearch={true}
      disableSearchIcon={true}
      onChange={handleChange}
    />
  );
};
