import { SubscriptionPlan } from '../../domain';

export const mapSubscriptionPlanDcToEntity = (dc: string): SubscriptionPlan => {
  if (
    Object.values(SubscriptionPlan).find((plan) => {
      return plan === dc;
    })
  ) {
    return dc as SubscriptionPlan;
  }

  return SubscriptionPlan.FreeMonthly;
};

export const mapSubscriptionPlanEntityToDc = (entity: SubscriptionPlan): string => {
  return entity;
};
