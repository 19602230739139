import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NotLegacyPlanType, PlanType } from '@/features/common/billing';
import {
  BillingCycleContext,
  PlanActionResolverContext,
  PlansMetadataContext,
} from '@/features/plans/ui/contexts';
import { PlanAction } from '@/features/plans/ui/types';

import { formatCurrency } from '@/utils/formatCurrency';

type PlanCardViewModel = {
  name: string;
  isCurrent: boolean;
  price: { value: string; clarification: string; underpriceText?: string };
  action: PlanAction;
  onAction?: () => void;
};

function usePrice(price: number, variant: NotLegacyPlanType): PlanCardViewModel['price'] {
  const {
    t,
    i18n: { language },
  } = useTranslation('plans');

  if (price === 0) {
    return {
      value: formatCurrency({
        amount: price,
        currency: 'USD',
        locale: language,
        maximumFractionDigits: 0,
      }),
      clarification: '',
    };
  }

  const underpriceText = useMemo(() => {
    if (variant === PlanType.Expand) {
      return t('prices.contactSubtitle');
    }

    return '';
  }, [t, variant]);

  if (Number.isNaN(price)) {
    return { value: t('prices.contactTitle'), clarification: '', underpriceText };
  }

  return {
    value: formatCurrency({
      amount: price,
      currency: 'USD',
      locale: language,
      maximumFractionDigits: 0,
    }),
    clarification: t('prices.rateSmall'),
    underpriceText,
  };
}

export function usePlanCardViewModel(params: {
  variant: NotLegacyPlanType;
}): PlanCardViewModel {
  const { [params.variant]: planMetadata } = useContext(PlansMetadataContext);
  const billingCycle = useContext(BillingCycleContext);
  const actionResolver = useContext(PlanActionResolverContext);

  const price = usePrice(planMetadata.prices[billingCycle], params.variant);
  const action = actionResolver.resolve({ planType: params.variant, billingCycle });

  return {
    name: planMetadata.name,
    price,
    isCurrent: planMetadata.isCurrent,
    action,
    onAction: (): void => {
      actionResolver.onAction({ planType: params.variant, billingCycle, action });
    },
  };
}
