import { FC } from 'react';
import { Box } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';

import { ActionDisableGuard } from '@/features/common/account';

import { CustomSelectV2, Preview } from '@/components';

import { useContactOwnerCellViewModel } from './useContactOwnerCellViewModel';

import styles from './styles.module.scss';

const ContactOwnerCellPreview = () => {
  return (
    <Box className={styles.cellPreview}>
      <Preview />
    </Box>
  );
};

export const ContactOwnerCell: FC<GridCellParams> & {
  Preview: FC;
} = ({ row, id }) => {
  const { options, handleApply, canChangeContactsOwner } = useContactOwnerCellViewModel({
    assignedTo: row.assignedTo,
    contactId: String(id),
  });

  return (
    <Box className={styles.cell}>
      <ActionDisableGuard>
        <CustomSelectV2
          size="small"
          value={row.assignedTo}
          options={options}
          onChange={handleApply}
          disabled={!canChangeContactsOwner}
          searchable
        />
      </ActionDisableGuard>
    </Box>
  );
};

ContactOwnerCell.Preview = ContactOwnerCellPreview;
