import { inject, injectable } from 'inversify';
import { first, forkJoin, map, Observable, of, switchMap, throwError } from 'rxjs';

import { ACCOUNT_TYPES, AUTH_TYPES } from '@/ioc/types';

import { IAccountRepository } from '@/features/common/account';
import { IAuthRepository, UserAlreadyExistsError } from '@/features/common/auth';
import { IAccountSettingsUseCase } from '@/features/settings/features/account';

@injectable()
export default class AccountSettingsUseCase implements IAccountSettingsUseCase {
  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  changeEmail = (email: string): Observable<boolean> => {
    return this.accountRepository.getAccount().pipe(
      first(),
      switchMap((account) => {
        if (account?.email === email) {
          return of(false);
        }

        return this.authRepository.checkUserExists(email).pipe(
          switchMap((isExisted) => {
            if (isExisted) {
              return throwError(() => new UserAlreadyExistsError());
            }

            return this.accountRepository.updateAccount({ email });
          }),
          map(() => true),
        );
      }),
    );
  };

  changePasswordAndEmail = (
    email: string,
    password: string,
  ): Observable<{
    email: boolean;
    password: boolean;
  }> => {
    return forkJoin({
      email: this.changeEmail(email),
      password: this.changePassword(password),
    });
  };

  changePassword = (password: string, currentPassword?: string): Observable<boolean> => {
    return this.authRepository
      .updatePassword(password, currentPassword)
      .pipe(map(() => true));
  };

  changeName = (name: string): Observable<boolean> => {
    return this.accountRepository.getAccount().pipe(
      first(),
      switchMap((account) => {
        if (account?.fullName === name) {
          return of(false);
        }

        return this.accountRepository
          .updateAccount({ fullName: name })
          .pipe(map(() => true));
      }),
    );
  };

  deleteAccount = (): Observable<unknown> => {
    this.accountRepository.deleteAccount().subscribe(); // HACK: we have problems with proper database removing and replication sropage that cause errors
    return this.authRepository.signOut();
  };
}
