import { useMemo } from 'react';

import { BillingCycle, PlanType } from '@/features/common/billing';
import {
  getBillingCycleFromSubscription,
  getFutureBillingCycleFromSubscription,
  getFuturePlanTypeFromSubscription,
  getPlanTypeFromSubscription,
} from '@/features/common/billing/domain';
import { useWorkspaceSubscription } from '@/features/common/workspace';

import { ModalControler, useModalController } from '@/hooks';

type Plan = {
  type: PlanType;
  billingCycle: BillingCycle;
};

type PlanCardViewModel = {
  currentPlan: Plan | null;
  futurePlan: Plan | null;
  cancelSubscriptionDialog: ModalControler;
  cancelSubscriptionWarning: ModalControler;
  editCardDialog: ModalControler;
  isPaidPlan: boolean;
  showNewPlanCard: boolean;
};

export function usePlanCardViewModel(): PlanCardViewModel {
  const editCardDialog = useModalController();
  const cancelSubscriptionDialog = useModalController();
  const cancelSubscriptionWarning = useModalController();
  const { data: subscription } = useWorkspaceSubscription();

  const { currentPlan, futurePlan } = useMemo(() => {
    if (!subscription) {
      return {
        currentPlan: null,
        futurePlan: null,
      };
    }

    const futurePlanType = getFuturePlanTypeFromSubscription(subscription);
    const futureBillingCycle = getFutureBillingCycleFromSubscription(subscription);

    return {
      currentPlan: {
        type: getPlanTypeFromSubscription(subscription),
        billingCycle: getBillingCycleFromSubscription(subscription),
      },
      futurePlan:
        futurePlanType && futureBillingCycle
          ? { type: futurePlanType, billingCycle: futureBillingCycle }
          : null,
    };
  }, [subscription]);

  const isPaidPlan = currentPlan?.type !== PlanType.Free;

  const isCurrentSameAsFuturePlan = subscription?.plan === subscription?.futurePlan;

  const showNewPlanCard =
    isPaidPlan &&
    !!currentPlan &&
    !!futurePlan &&
    !isCurrentSameAsFuturePlan &&
    !subscription?.isCanceled;

  return {
    currentPlan,
    futurePlan,
    editCardDialog,
    cancelSubscriptionDialog,
    cancelSubscriptionWarning,
    isPaidPlan,
    showNewPlanCard,
  };
}
