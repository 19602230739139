import { bindAnalyticsModule } from 'features/system/analytics';

import { bindAccountModule } from '@/features/common/account';
import { bindAuthModule } from '@/features/common/auth';
import { bindBillingModule } from '@/features/common/billing';
import { bindContactModule } from '@/features/common/contact';
import { bindContactListModule } from '@/features/common/contactList';
import { bindExtensionModule } from '@/features/common/extension';
import { bindFairUsageModule } from '@/features/common/fairUsage';
import { bindOnboardingModule } from '@/features/common/onboarding';
import { bindProspectTaskModule } from '@/features/common/prospectTask';
import { bindTagModule } from '@/features/common/tag';
import { bindWorkspaceModule } from '@/features/common/workspace';
import { bindDashboardChartModule } from '@/features/dashboard/features';
import { bindEnrichmentModule } from '@/features/enrichment';
import { bindExtensionAuthModule } from '@/features/extensionAuth';
import { bindGetFreeCreditsModule } from '@/features/getFreeCredits';
import { bindIntegrationModule } from '@/features/integration';
import { bindIntegrationsModule } from '@/features/integrations';
import { bindPaymentDetailsModule } from '@/features/paymentDetails';
import { bindReferralModule } from '@/features/referral';
import { bindResetPasswordModule } from '@/features/resetPassword';
import { bindRetentionModule } from '@/features/retention';
import { bindTeamMemberModule } from '@/features/settings';
import { bindAccountSettingsModule } from '@/features/settings/features/account';
import { bindLoginModule } from '@/features/signin';
import { bindSignUpModule } from '@/features/signup';
import { bindContextualOnboardingModule } from '@/features/system/contextualOnboarding';
import { bindCookiesModule } from '@/features/system/cookies';
import { bindCustomerMessagingModule } from '@/features/system/CustomerIO';
import { bindDatabaseManagerModule } from '@/features/system/db';
import { bindFeatureFlagModule } from '@/features/system/featureFlag';
import { bindGeoSyncModule } from '@/features/system/geoSync';
import { bindGlobalErrorModule } from '@/features/system/globalError';
import { bindHelpCenterModule } from '@/features/system/helpCenter';
import { bindLeaderElectionModule } from '@/features/system/leaderElection';
import { bindLeadsTrackingModule } from '@/features/system/leadsTracking';
import { bindLoggerModule } from '@/features/system/logger';
import { bindNetworkModule } from '@/features/system/network';
import { bindOnlineTrackerModule } from '@/features/system/OnlineTracker';
import { bindPhoneHelperModule } from '@/features/system/PhoneHelper';
import { bindReplicationModule } from '@/features/system/replication';
import { bindRequestCacheModule } from '@/features/system/requestCache';
import { bindServerTimeModule } from '@/features/system/serverTime';
import { bindStorageModule } from '@/features/system/storage';
import { bindSyncModule } from '@/features/system/sync';

import { container } from './container';

export const bindModules = (): void => {
  bindFeatureFlagModule(container);
  bindLoggerModule(container);
  bindServerTimeModule(container);

  // common modules
  bindAuthModule(container);
  bindRequestCacheModule(container);
  bindContactListModule(container);
  bindTagModule(container);
  bindWorkspaceModule(container);
  bindAccountModule(container);
  bindExtensionModule(container);
  bindExtensionAuthModule(container);
  bindContactModule(container);
  bindDashboardChartModule(container);
  bindProspectTaskModule(container);
  bindEnrichmentModule(container);
  bindFairUsageModule(container);
  bindOnboardingModule(container);

  // system modules
  bindGlobalErrorModule(container);
  bindNetworkModule(container);
  bindSyncModule(container);
  bindOnlineTrackerModule(container);
  bindStorageModule(container);
  bindLeadsTrackingModule(container);
  bindLeaderElectionModule(container);
  bindDatabaseManagerModule(container);
  bindReplicationModule(container);
  bindCustomerMessagingModule(container);
  bindContextualOnboardingModule(container);
  bindHelpCenterModule(container);
  bindCookiesModule(container);
  bindPhoneHelperModule(container);
  bindAnalyticsModule(container);
  bindGeoSyncModule(container);

  bindResetPasswordModule(container);
  bindLoginModule(container);
  bindSignUpModule(container);
  bindIntegrationModule(container);
  bindIntegrationsModule(container);
  bindTeamMemberModule(container);
  bindPaymentDetailsModule(container);
  bindBillingModule(container);
  bindAccountSettingsModule(container);
  bindGetFreeCreditsModule(container);
  bindRetentionModule(container);
  bindReferralModule(container);
};
