import { useMemo } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { CONTACT_LIST_TYPES } from '@/ioc/types';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IContactListEntity, IContactListUseCase } from '../..';

export const useDefaultContactList = (): ObservableResult<
  IContactListEntity | null,
  null,
  null
> => {
  const contactListUseCase = useInjection<IContactListUseCase>(
    CONTACT_LIST_TYPES.ContactListUseCase,
  );
  const currentContactList$ = useMemo(
    () => contactListUseCase.getDefaultContactList(),
    [],
  );

  return useObservableResult(currentContactList$);
};
