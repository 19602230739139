import { useMemo } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import { ITeamMemberEntity, ITeamMemberUseCase } from '@/features/settings';
import { InvitationStatus } from '@/features/settings/features/teamManagement/domain/entities/InvitationStatusEntity';

import { useObservableResult } from '@/utils/rx/hooks';

type UseTeamMembersReturn = {
  teamMembers: ITeamMemberEntity[];
};

export const useAcceptedTeamMembers = (): UseTeamMembersReturn => {
  const teamMemberUseCase = useInjection<ITeamMemberUseCase>(
    TEAM_MEMBER_TYPES.TeamMemberUseCase,
  );

  const teamMembers$ = useMemo(() => teamMemberUseCase.getTeamMembers(), []);

  const { data: allTeamMembers } = useObservableResult(teamMembers$, { defaultData: [] });

  const teamMembers = useMemo(
    () =>
      allTeamMembers.filter(
        (member) => member.invitationStatus === InvitationStatus.Accepted,
      ),
    [allTeamMembers],
  );

  return {
    teamMembers,
  };
};
