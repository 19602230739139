import { ICreditsEntity } from '@/features/common/workspace/domain';

import { ICreditsDC } from '../dataContracts';

export const mapCreditsDcToEntity = (dc: ICreditsDC): ICreditsEntity => {
  return {
    used: dc.used,
    limit: dc.limit,
    creditType: dc.credit_type,
    createdAt: dc.created_at,
    expiresAt: dc.expires_at,
  };
};

export const mapCreditsEntityToDc = (entity: ICreditsEntity): ICreditsDC => {
  return {
    used: entity.used,
    limit: entity.limit,
    credit_type: entity.creditType,
    created_at: entity.createdAt,
    expires_at: entity.expiresAt,
  };
};
