import { useMemo } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import { ITeamMemberUseCase } from '@/features/settings';

import { useObservableResult } from '@/utils/rx/hooks';

type PermissionsButtonViewModel = (onPermissionsPopoverClose: () => void) => {
  canAccessAllContacts: boolean;
  allowAccessAllProspects(): void;
  disallowAccessAllProspects(): void;
};

const usePermissionsButtonViewModel: PermissionsButtonViewModel = (
  onPermissionsPopoverClose,
) => {
  const teamMemberUseCase = useInjection<ITeamMemberUseCase>(
    TEAM_MEMBER_TYPES.TeamMemberUseCase,
  );
  const getMemberAccessAllContacts$ = useMemo(() => {
    return teamMemberUseCase.getMemberAccessAllContacts();
  }, []);
  const { data: canAccessAllContacts } = useObservableResult(
    getMemberAccessAllContacts$,
    {
      defaultData: false,
    },
  );

  const setPermission = (value: boolean): void => {
    teamMemberUseCase
      .setMemberAccessAllContacts(value)
      .subscribe(onPermissionsPopoverClose);
  };

  const allowAccessAllProspects = (): void => {
    setPermission(true);
  };

  const disallowAccessAllProspects = (): void => {
    setPermission(false);
  };

  return {
    canAccessAllContacts,
    allowAccessAllProspects,
    disallowAccessAllProspects,
  };
};

export default usePermissionsButtonViewModel;
