import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { SubHead } from '@/components';

import { useDocumentMeta } from '@/hooks';

import {
  FilterByUserAction,
  ManageTagsAction,
  NewListAction,
  SearchAction,
} from './Actions';
import { useContactListsViewModel } from './ContactListsViewModel';
import { ListsTable } from './ListsTable';

import styles from './styles.module.scss';

export const ContactLists: FC = () => {
  const { t } = useTranslation('lists');
  const {
    filterByUser,
    contactLists,
    setSearch,
    handleFilterByUserChange,
    handleChangeSortSchema,
  } = useContactListsViewModel();
  useDocumentMeta({
    title: 'savedContacts.title',
    description: 'savedContacts.description',
  });

  return (
    <Box className={styles.wrapper}>
      <SubHead>
        <SubHead.Title>{t('subhead.title')}</SubHead.Title>
        <SubHead.Action className={styles.actions}>
          <SearchAction
            onSearch={setSearch}
            onClear={(): void => {
              setSearch('');
            }}
          />
          <FilterByUserAction value={filterByUser} onChange={handleFilterByUserChange} />
          <ManageTagsAction />
          <NewListAction />
        </SubHead.Action>
      </SubHead>
      <Box className={styles.tableWrapper}>
        <ListsTable rows={contactLists} onSortSchemaChange={handleChangeSortSchema} />
      </Box>
    </Box>
  );
};
