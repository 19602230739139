import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { useInjection } from '@/ioc/ioc.react';
import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import {
  ACCESS_PERMISSIONS,
  useAccount,
  usePermissions,
} from '@/features/common/account';
import { PlanType, useCurrentPlanType } from '@/features/common/billing';
import { type ITeamMemberUseCase } from '@/features/settings';
import { ITeamMemberEntity } from '@/features/settings';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { useDocumentMeta } from '@/hooks';

import { useObservableResult } from '@/utils/rx/hooks';

export enum UserStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
}

export type TeamManagementViewModelReturn = {
  teamMembersList: ITeamMemberEntity[];
  permissionsButton: {
    permissionsPopoverAnchor: HTMLButtonElement | null;
    onPermissionsButtonClick: React.MouseEventHandler<HTMLButtonElement>;
    onPermissionsPopoverClose(): void;
  };
  upgradeAccountDialog: {
    isUpgradeDialogVisible: boolean;
    onInviteClick(): void;
    onUpgradeDialogClose(): void;
    onViewPlansClick(): void;
  };
  inviteDialog: {
    isInviteDialogVisible: boolean;
    onInviteDialogClose(): void;
  };
  canManageTeamMembers: boolean;
  currentUserEmail: string;
};

const useTeamManagementViewModel = (): TeamManagementViewModelReturn => {
  const navigate = useNavigate();
  const location = useLocation();
  const analytics = useAnalytics();
  const { data: planType } = useCurrentPlanType();
  const { account } = useAccount();

  const isInvitationDialogShouldBeShownAfterRedirect =
    location.state?.isInviteTeamMemberModalOpen;

  const [isUpgradeDialogVisible, setIsUpgradeDialogVisible] = useState(false);
  const [isInviteDialogVisible, setIsInviteDialogVisible] = useState(false);
  const [permissionsPopoverAnchor, setPermissionsPopoverAnchor] =
    useState<HTMLButtonElement | null>(null);

  const teamMemberUseCase = useInjection<ITeamMemberUseCase>(
    TEAM_MEMBER_TYPES.TeamMemberUseCase,
  );
  const getTeamMembers$ = useMemo(() => {
    return teamMemberUseCase.getTeamMembers();
  }, []);
  const { data: teamMembersList } = useObservableResult(getTeamMembers$, {
    defaultData: [],
  });

  // Permissions
  const { hasPermission } = usePermissions();
  const canManageTeamMembers = hasPermission(ACCESS_PERMISSIONS.CAN_MANAGE_TEAM_MEMBERS);

  useDocumentMeta({
    title: 'teamManagement.title',
    description: 'teamManagement.description',
  });

  useEffect(() => {
    if (isInvitationDialogShouldBeShownAfterRedirect && planType) {
      onInviteClick();
    }
  }, [isInvitationDialogShouldBeShownAfterRedirect, planType]);

  useEffect(() => {
    analytics.trackEvent(ANALYTICS_EVENTS.VIEW_TEAM_MANAGEMENT_PAGE);
  }, []);

  const onInviteClick = (): void => {
    analytics.trackEvent(ANALYTICS_EVENTS.ADD_TEAM_MEMBER_BUTTON_CLICK);

    if (planType === PlanType.Free) {
      setIsUpgradeDialogVisible(true);
    } else {
      setIsInviteDialogVisible(true);
    }
  };

  const onUpgradeDialogClose = (): void => {
    setIsUpgradeDialogVisible(false);
  };

  const onViewPlansClick = (): void => {
    navigate(ROUTES.BILLING.PLANS);
  };

  const onInviteDialogClose = (): void => {
    setIsInviteDialogVisible(false);
    window.history.replaceState({}, document.title);
  };

  const onPermissionsButtonClick: React.MouseEventHandler<HTMLButtonElement> = ({
    currentTarget,
  }) => {
    setPermissionsPopoverAnchor(currentTarget);
  };

  const onPermissionsPopoverClose = (): void => {
    setPermissionsPopoverAnchor(null);
  };

  return {
    teamMembersList,
    permissionsButton: {
      permissionsPopoverAnchor,
      onPermissionsButtonClick,
      onPermissionsPopoverClose,
    },
    upgradeAccountDialog: {
      isUpgradeDialogVisible,
      onInviteClick,
      onUpgradeDialogClose,
      onViewPlansClick,
    },
    inviteDialog: {
      isInviteDialogVisible,
      onInviteDialogClose,
    },
    canManageTeamMembers,
    currentUserEmail: account?.email ?? '',
  };
};

export default useTeamManagementViewModel;
