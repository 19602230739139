import { useContext } from 'react';

import { usePermissions } from '@/features/common/account';

import { EnrichmentContext } from '../../EnrichmentContext';

import { AdminAccessDeniedModal, UserAccessDeniedModal } from './components';

export const useEnrichmentAccessDeniedModalViewModel = () => {
  const { isOwnerOrAdmin } = usePermissions();
  const { isAccessDeniedModalOpen, onAccessDeniedModalClose } =
    useContext(EnrichmentContext);

  return {
    CurrentModal: !isOwnerOrAdmin ? UserAccessDeniedModal : AdminAccessDeniedModal,
    isOpen: isAccessDeniedModalOpen,
    close: onAccessDeniedModalClose,
  };
};
