import { injectable } from 'inversify';

import { IInvitationDC } from '@/features/referral';
import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

export type IReferralDao = IDbCollection<IInvitationDC, 'uuid'>;

@injectable()
export class ReferralDao
  extends DbCollection<IInvitationDC, 'uuid'>
  implements IReferralDao
{
  constructor() {
    super({ collectionName: CollectionName.Invitation, idKey: 'uuid' });
  }
}
