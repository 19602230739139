import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { firstValueFrom } from 'rxjs';

import { useInjection } from '@/ioc/ioc.react';
import { CONTACT_LIST_TYPES } from '@/ioc/types';

import { IContactListEntity, IContactListUseCase } from '@/features/common/contactList';
import { ANALYTICS_EVENTS, Platform, useAnalytics } from '@/features/system/analytics';

export const useCreateContactList = (): ((
  listName: string,
) => Promise<IContactListEntity>) => {
  const { t } = useTranslation('lists');
  const { enqueueSnackbar } = useSnackbar();
  const analytics = useAnalytics();
  const contactListUseCase = useInjection<IContactListUseCase>(
    CONTACT_LIST_TYPES.ContactListUseCase,
  );

  const createContactList = async (listName: string): Promise<IContactListEntity> => {
    if (/^(?=\s*$)/.test(listName)) throw new Error('Empty list name'); // is empty contact list name

    const contactList = await firstValueFrom(
      contactListUseCase.createContactList({ name: listName.trim() }),
    );

    enqueueSnackbar(contactList.name, {
      variant: 'success',
      autoHideDuration: 3000,
      preventDuplicate: true,
      description: t('createSuccessSnackbar.description'),
    });

    analytics.trackEvent(ANALYTICS_EVENTS.CREATE_NEW_LIST, {
      platform: Platform.Web,
    });

    return contactList;
  };

  return createContactList;
};
