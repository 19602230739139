import { injectable } from 'inversify';
import { MangoQuery } from 'rxdb';
import { first, Observable, of, switchMap, throwError } from 'rxjs';

import { ITagDC } from '@/features/common/tag';
import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

import { TagNameAlreadyExistError } from '../../../domain/errors';

export type ITagDao = Pick<
  IDbCollection<ITagDC, 'uuid'>,
  'upsert' | 'findAll' | 'removeOne' | 'insertOne' | 'updateOne'
>;

@injectable()
export default class TagDao extends DbCollection<ITagDC, 'uuid'> implements ITagDao {
  constructor() {
    super({ collectionName: CollectionName.Tag, idKey: 'uuid' });
  }

  private assertIfNameAlreadyExist(tag: {
    uuid?: string;
    name: string;
  }): Observable<void> {
    const uuidSelector = tag.uuid ? { uuid: { $ne: tag.uuid } } : {};
    const nameSelector = { name: tag.name };

    return this.findOne({ selector: { ...uuidSelector, ...nameSelector } }).pipe(
      first(),
      switchMap((tag) => {
        if (tag) {
          return throwError(() => new TagNameAlreadyExistError(tag.name));
        }

        return of(undefined);
      }),
    );
  }

  override insertOne(payload: WithOptionalId<ITagDC, 'uuid'>): Observable<ITagDC> {
    return this.assertIfNameAlreadyExist(payload).pipe(
      switchMap(() => {
        return super.insertOne(payload);
      }),
    );
  }

  override findAll(
    query: MangoQuery<ITagDC> = { sort: [{ created_at: 'asc' }] },
  ): Observable<ITagDC[]> {
    return super.findAll(query);
  }

  override updateOne(id: ITagDC['uuid'], payload: Partial<ITagDC>): Observable<ITagDC> {
    const assert$ = payload.name
      ? this.assertIfNameAlreadyExist({ uuid: id, name: payload.name })
      : of(undefined);

    return assert$.pipe(
      switchMap(() => {
        return super.updateOne(id, payload);
      }),
    );
  }
}
