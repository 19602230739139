import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Snackbar } from '@mui/material';

import { useOnlineTrackerViewModel } from './OnlineTrackerViewModel';

export const OnlineTracker: FC = () => {
  const { t } = useTranslation('common');
  const { isOpen, handleClose } = useOnlineTrackerViewModel();

  return (
    <Snackbar open={isOpen} onClose={handleClose} autoHideDuration={6000}>
      <Alert severity="error" onClose={handleClose}>
        <AlertTitle>{t('internetConnectionError')}</AlertTitle>
      </Alert>
    </Snackbar>
  );
};
