import dayjs, { Dayjs } from 'dayjs';
import { inject, injectable } from 'inversify';
import { map, Observable, switchMap } from 'rxjs';

import { ACCOUNT_TYPES, DASHBOARD_CHART_TYPES } from '@/ioc/types';

import { IAccountPermissionsUseCase } from '@/features/common/account/domain';

import { IDashboardChartRepository } from '../data';

import { Aggregator } from './abstractions/Aggregation';
import { IDashboardChartUseCase } from './abstractions/IDashboardChartUseCase';
import { AggregatedChartDataEntity } from './entities/AggregatedChartDataEntity';
import { ChartDataEntity } from './entities/ChartDataEntity';
import { AggregationFiltersDto } from './types/AggregationFiltersDto';
import { AggregationStrategy } from './types/AggregationStrategies';
import { ChartDataGroup } from './types/ChartDataGroup';
import { ChartDataTotals } from './types/ChartDataTotals';
import { GetChartDataReq } from './types/GetChartDataReq';
import { DailyAggregation, MonthlyAggregation, WeeklyAggregation } from './aggregations';

interface AggregationResult {
  result: AggregatedChartDataEntity[];
  strategy: AggregationStrategy;
}

@injectable()
export class DashboardChartUseCase implements IDashboardChartUseCase {
  @inject(DASHBOARD_CHART_TYPES.ChartRepository)
  private chartRepository: IDashboardChartRepository;

  @inject(ACCOUNT_TYPES.AccountPermissionsUseCase)
  private readonly accountPermissionsUseCase: IAccountPermissionsUseCase;

  private weekBreakpoint = 30;
  private monthBreakpoint = 360;

  getData(filters: GetChartDataReq): Observable<ChartDataGroup> {
    return this.accountPermissionsUseCase.getAccountPermissions().pipe(
      switchMap((accountPermissionsData) => {
        const query = { ...filters };
        if (!accountPermissionsData.permissions.CAN_ACCESS_ALL_CONTACTS) {
          Object.assign(query, { selectedUser: accountPermissionsData.uuid });
        }

        return this.chartRepository.getData(query).pipe(
          map((dataOriginal) => {
            const totals = new ChartDataTotals(dataOriginal);
            const { result, strategy } = this.aggregate(dataOriginal, query);

            return {
              data: result,
              totals,
              strategy,
            };
          }),
        );
      }),
    );
  }

  private aggregate(
    data: ChartDataEntity[],
    filters: GetChartDataReq,
  ): AggregationResult {
    const { fromDate, toDate } = filters;
    const from = dayjs.unix(fromDate);
    const to = dayjs.unix(toDate);

    const strategy = this.selectStrategy({ from, to });

    const { result } = this.getAggregatorImplementation(strategy, data, from, to);

    return { result, strategy };
  }

  private getAggregatorImplementation(
    strategy: AggregationStrategy,
    dataOriginal: ChartDataEntity[],
    from: Dayjs,
    to: Dayjs,
  ): Aggregator {
    switch (strategy) {
      case AggregationStrategy.Daily:
        return new DailyAggregation(dataOriginal, from, to);
      case AggregationStrategy.Weekly:
        return new WeeklyAggregation(dataOriginal, from, to);
      default:
        return new MonthlyAggregation(dataOriginal, from, to);
    }
  }

  private selectStrategy({ from, to }: AggregationFiltersDto): AggregationStrategy {
    const diff = to.diff(from, 'days');

    if (diff <= this.weekBreakpoint) {
      return AggregationStrategy.Daily;
    }

    if (diff > this.weekBreakpoint && diff <= this.monthBreakpoint) {
      return AggregationStrategy.Weekly;
    }

    return AggregationStrategy.Monthly;
  }
}
