import { inject, injectable } from 'inversify';
import {
  combineLatest,
  distinctUntilChanged,
  finalize,
  map,
  Observable,
  of,
  share,
  switchMap,
  tap,
} from 'rxjs';

import {
  AUTH_TYPES,
  LEADER_ELECTION_TYPES,
  ONLINE_TRACKER_TYPES,
  REPLICATION_TYPES,
  SYNC_TYPES,
} from '@/ioc/types';

import { IAuthRepository } from '@/features/common/auth';
import type { ILeaderElectionRepository } from '@/features/system/leaderElection';
import { IOnlineTrackerRepository } from '@/features/system/OnlineTracker';
import { IReplicationRepository, ReplicationStatus } from '@/features/system/replication';

import { distinctUntilKeysChanged } from '@/utils/rx';

import { IBaseSyncRepository, IBaseSyncUseCase } from './abstractions';
import { SyncStatus } from './types';

@injectable()
export class BaseSyncUseCase implements IBaseSyncUseCase {
  @inject(SYNC_TYPES.BaseSyncRepository)
  private syncRepository: IBaseSyncRepository;

  @inject(ONLINE_TRACKER_TYPES.OnlineTrackerRepository)
  private onlineTrackerRepository: IOnlineTrackerRepository;

  @inject(REPLICATION_TYPES.ReplicationRepository)
  private replicationRepository: IReplicationRepository;

  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  @inject(LEADER_ELECTION_TYPES.LeaderElectionRepository)
  private leaderElectionRepository: ILeaderElectionRepository;

  canSync(): Observable<boolean> {
    const isOnline$ = this.onlineTrackerRepository.getIsOnline();
    const isTabActive$ = this.leaderElectionRepository.getIsLeaderSubject();
    const isReplicationStarted$ = this.replicationRepository
      .getStatus()
      .pipe(map((status) => status === ReplicationStatus.Started));

    return combineLatest([isOnline$, isTabActive$, isReplicationStarted$]).pipe(
      map((dependencies) => dependencies.every(Boolean)),
    );
  }

  start(): Observable<SyncStatus> {
    return combineLatest({
      canSync: this.canSync(),
      token: this.authRepository.getAccessToken(),
    }).pipe(
      distinctUntilKeysChanged('canSync', 'token'),
      tap(({ canSync, token }) => {
        if (canSync && token) {
          this.syncRepository.start(token);
        } else {
          this.syncRepository.stop({ reset: !token });
        }
      }),
      switchMap(() => this.syncRepository.getStatus()),
      distinctUntilChanged(),
      share(),
    );
  }

  withStoppedSync = <T>(source: Observable<T>): Observable<T> => {
    return of(this.syncRepository.pause()).pipe(
      switchMap(() => source),
      finalize(() => this.syncRepository.unpause()),
    );
  };

  getStatus(): Observable<SyncStatus> {
    return this.syncRepository.getStatus();
  }
}
