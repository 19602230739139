import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import { AuthIdentityProvider } from '@/features/common/auth';
import { PlanCardFreePlan } from '@/features/dashboard/ui/PlanCard/components/PlanCardFreePlan';
import { ChangedEmailModal } from '@/features/settings/features/account/ui/components/ChangedEmailModal';

import { Paper } from '@/components';

import { AccountSettingsForm } from '../AccountSettingsForm';
import { PasswordDialog } from '../PasswordDialog';

import { useAccountSettingsViewModel } from './useAccountSettingsViewModel';

import styles from './styles.module.scss';

export const AccountSettings: FC = () => {
  const {
    provider,
    showFreePlanCard,
    emailChangedDialog,
    passwordChangeDialog,
    onNameChange,
    onPasswordChange,
  } = useAccountSettingsViewModel();

  if (!provider) return null;

  return (
    <Box className={clsx(styles.wrapper)}>
      <Paper className={styles.container}>
        <Typography variant={'h1'} className={styles.title}>
          Account Information
        </Typography>

        <AccountSettingsForm
          onNameChange={onNameChange}
          provider={provider}
          onPasswordChange={passwordChangeDialog.onOpen}
        />
      </Paper>

      {showFreePlanCard ? (
        <Box className={styles.freePlanWrapper}>
          <PlanCardFreePlan showCreditsCount title={'Credits'} />
        </Box>
      ) : null}

      <ChangedEmailModal isOpen={emailChangedDialog.isOpen} />

      <PasswordDialog
        isOpen={passwordChangeDialog.isOpen}
        onPasswordChange={onPasswordChange}
        onClose={passwordChangeDialog.onClose}
        askCurrentPassword={provider === AuthIdentityProvider.password}
      />
    </Box>
  );
};
