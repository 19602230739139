import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import styles from './styles.module.scss';

export const CustomSelectEmptyList: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box className={styles.emptyList}>
      <Box component="span">{children}</Box>
    </Box>
  );
};
