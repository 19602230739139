import { inject, injectable } from 'inversify';
import { firstValueFrom, map, Observable } from 'rxjs';

import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import { IWorkspaceDC, IWorkspaceEntity } from '@/features/common/workspace';
import { mapWorkspaceDcToEntity } from '@/features/common/workspace/data/mappers/mapWorkspace';
import {
  IDeleteMemberDataEntity,
  ITeamMemberApiService,
  ITeamMemberEntityUI,
  ITeamMemberStateRepository,
  mapDeleteMemberDataEntityToDc,
  UpdateMemberData,
} from '@/features/settings';

@injectable()
export default class TeamMemberStateRepository implements ITeamMemberStateRepository {
  @inject(TEAM_MEMBER_TYPES.TeamMemberApiService) private api: ITeamMemberApiService;

  inviteTeamMembers(teamMembers: ITeamMemberEntityUI[]): Promise<IWorkspaceEntity> {
    return firstValueFrom(
      this.api
        .inviteTeamMembers(teamMembers)
        .pipe(map((workspaceDc) => mapWorkspaceDcToEntity(workspaceDc))),
    );
  }

  deleteTeamMembers(list: IDeleteMemberDataEntity[]): Observable<boolean> {
    return this.api.deleteTeamMembers(list.map(mapDeleteMemberDataEntityToDc));
  }

  resendInvitation(data: UpdateMemberData): Observable<IWorkspaceDC> {
    return this.api.resendInvitation(data);
  }
}
