import React, { ReactElement } from 'react';
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Box,
  Chip,
  TextField,
} from '@mui/material';

import { useEmailFieldViewModel } from './useEmailFieldViewModel';

import styles from './styles.module.scss';

export type EmailFieldProps = {
  value: string[];
  onChange(value: string[]): void;
  getHasValueError?: (value: string) => boolean;
};

export const EmailField: React.FC<EmailFieldProps> = (props) => {
  const {
    inputValue,
    handleAutocompleteInputChange,
    handleAutocompleteBlur,
    handleChange,
    handleAutocompleteKeyDown,
  } = useEmailFieldViewModel(props);

  return (
    <Box>
      <Autocomplete
        classes={{ inputRoot: styles.input }}
        multiple
        options={[]}
        onBlur={handleAutocompleteBlur}
        onKeyDown={handleAutocompleteKeyDown}
        onInputChange={handleAutocompleteInputChange}
        inputValue={inputValue}
        value={props.value}
        onChange={handleChange}
        isOptionEqualToValue={(option, value): boolean => {
          return option === value;
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={'email@email.com'} />
        )}
        renderTags={(
          values: string[],
          getTagProps: AutocompleteRenderGetTagProps,
        ): ReactElement[] => {
          return values.map((option, index) => {
            const { onDelete } = getTagProps({ index });
            const chipVariant = props.getHasValueError?.(option) ? 'error' : 'default';

            return (
              <Chip
                key={option}
                label={option}
                variant={chipVariant}
                onDelete={onDelete}
                component="div"
              />
            );
          });
        }}
      />
    </Box>
  );
};
