import React from 'react';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { getIsFirefox } from '@/utils/userAgent';

import { TagTableRowData } from '../../TagManagementViewModel';

import useTagTableColumns from './hooks/useTagTableColumns';

import styles from './styles.module.scss';

interface Props {
  tagTableList: TagTableRowData[];
}

const TagTable: React.FC<Props> = ({ tagTableList }) => {
  const autoHeight = getIsFirefox() && tagTableList.length === 0; // specific case for Firefox. Need to set autoHeight to true to avoid bad UI when table is empty
  const columns = useTagTableColumns();

  return (
    <Box className={styles.TableWrapper}>
      <DataGridPro
        columns={columns}
        rows={tagTableList}
        variant="basic"
        hideFooter
        autoHeight={autoHeight}
      />
    </Box>
  );
};

export default TagTable;
