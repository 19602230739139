import { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

import { Card } from '../../../Card';

import styles from './styles.module.scss';

type PlanCardProps = {
  body: ReactNode;
  bodyActions?: ReactNode;
  footer: ReactNode;
  footerActions?: ReactNode;
  children?: ReactNode;
  title?: string;
};

export const PlanCardLayout: FC<PlanCardProps> = ({
  body,
  bodyActions,
  footer,
  footerActions,
  children,
  title,
}) => {
  return (
    <Card>
      {children ? (
        children
      ) : (
        <Box className={styles.card}>
          <Typography variant="subtitle3" className={styles.cardTitle}>
            {title}
          </Typography>
          <div className={styles.cardBody}>
            <div className={styles.left}>{body}</div>
            {bodyActions && <div className={styles.actions}>{bodyActions}</div>}
          </div>
          {footer || footerActions ? (
            <div className={styles.cardFooter}>
              <div className={styles.left}>{footer}</div>
              {footerActions && <div className={styles.actions}>{footerActions}</div>}
            </div>
          ) : null}
        </Box>
      )}
    </Card>
  );
};
