import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Tooltip } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { indexBy, prop } from 'ramda';

import { ROUTES } from '@/router/routes';

import { ActionDisableGuard } from '@/features/common/account';
import { useContactUseCase } from '@/features/common/contact';
import { useTagSelectOptions } from '@/features/common/tag';
import { ANALYTICS_EVENTS, Platform, useAnalytics } from '@/features/system/analytics';

import { CustomSelect, SearchInput, TagBadge } from '@/components';

import { useSearch } from '@/hooks';

import styles from './styles.module.scss';

type TagsCellProps = GridCellParams & {
  value?: string[];
};

export const TagsCell: FC<TagsCellProps> = ({ value = [], ...props }) => {
  const { t } = useTranslation('contactsTable');
  const [currentValue, setCurrentValue] = useState<string[]>(value);
  const { value: search, handleSearchChange, handleSearchClear } = useSearch();
  const { filteredOptions, options } = useTagSelectOptions(search);
  const contactUseCase = useContactUseCase();
  const optionsMapByValue = indexBy(prop('value'), options);
  const tagsIds = value.filter((v) => !!optionsMapByValue[v]); // to avoid runtime errors when tag was removed before contact update was received

  const navigate = useNavigate();
  const analytics = useAnalytics();

  const getTooltipTitle = (): ReactElement[] | string => {
    if (tagsIds.length <= 1) return '';
    const arr = tagsIds.slice(1);
    return arr.map((item) => {
      return <Box key={item}>{optionsMapByValue[item].label}</Box>;
    });
  };

  const handleChange = (val: string[]): void => {
    setCurrentValue(val);
  };

  const handleApply = (): void => {
    contactUseCase
      .updateById(String(props.id), {
        tags: currentValue,
      })
      .subscribe({
        next: (): void => {
          analytics.trackEvent(ANALYTICS_EVENTS.CONTACT_ADD_TAG, {
            platform: Platform.Web,
          });
        },
      });
  };

  const handleClickTagManager = (): void => {
    navigate(ROUTES.SETTINGS.TAG_MANAGEMENT);
  };

  const valuesIsMatched = useMemo(() => {
    if (value.length !== currentValue.length) {
      return false;
    }

    const valueSet = new Set(value);

    return currentValue.every((v) => valueSet.has(v));
  }, [value, currentValue]);

  const handlePopoverCloseAnimationEnd = (): void => {
    if (!valuesIsMatched) {
      setCurrentValue(value);
    }
  };

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(currentValue)) {
      setCurrentValue(value);
    }
  }, [JSON.stringify(value)]);

  return (
    <Box>
      <ActionDisableGuard>
        <CustomSelect
          multiple
          options={filteredOptions}
          value={currentValue}
          onChange={handleChange}
          popoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            TransitionProps: {
              onExited: handlePopoverCloseAnimationEnd,
            },
          }}
          listProps={{
            classes: {
              root: styles.cellMenu,
            },
          }}
          renderAnchor={({ setAnchorElement }): ReactElement => {
            if (!tagsIds.length) {
              return (
                <Button
                  variant="text"
                  className={styles.addTagButton}
                  onClick={(e): void => {
                    setAnchorElement(e.currentTarget);
                  }}
                >
                  {t('tagsCell.addTag')}
                </Button>
              );
            }

            if (!options.length) return <>-</>;

            const firstOption = optionsMapByValue[tagsIds[0]];

            if (tagsIds.length === 1) {
              return (
                <TagBadge
                  onClick={(e): void => {
                    setAnchorElement(e.currentTarget);
                  }}
                  bgColor={firstOption.color}
                  className={styles.cellTagAnchor}
                >
                  {firstOption.label}
                </TagBadge>
              );
            }

            return (
              <Box className={styles.cellMultipleAnchor}>
                <TagBadge bgColor={firstOption.color}>{firstOption.label}</TagBadge>
                <Tooltip title={getTooltipTitle()} placement="top">
                  <Box
                    className={styles.cellMultipleAnchorButton}
                    onClick={(e): void => {
                      setAnchorElement(e.currentTarget);
                    }}
                  >
                    +{tagsIds.length - 1}
                  </Box>
                </Tooltip>
              </Box>
            );
          }}
          renderHeader={(): ReactElement => {
            return (
              <SearchInput
                variant="filled"
                value={search}
                placeholder={t('tagsCell.searchField.placeholder')}
                onChange={handleSearchChange}
                onClear={handleSearchClear}
              />
            );
          }}
          renderOption={({ option, selected }): ReactElement => {
            return <CustomSelect.TagOption option={option} selected={selected} />;
          }}
          renderActions={({ onClose }): ReactElement => {
            return (
              <Box className={styles.cellMenuActionsRow}>
                <Button onClick={handleClickTagManager}>
                  {t('tagsCell.manageTags')}
                </Button>
                <ActionDisableGuard>
                  <Button
                    disabled={valuesIsMatched}
                    onClick={(): void => {
                      handleApply();
                      onClose();
                    }}
                  >
                    {t('tagsCell.apply')}
                  </Button>
                </ActionDisableGuard>
              </Box>
            );
          }}
        />
      </ActionDisableGuard>
    </Box>
  );
};
