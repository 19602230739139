import { useMemo } from 'react';
import { indexBy, prop } from 'ramda';

import { ITagEntity } from '@/features/common/tag';

import { useTagEntities } from './useTagEntities';

type Option = {
  label: string;
  value: string;
  color: string;
};

const mapTagEntityToCustomSelectTagOption = (tag: ITagEntity): Option => {
  return {
    value: tag.uuid,
    label: tag.name,
    color: tag.color,
  };
};

export const useTagSelectOptions = (
  searchByNameReg = '',
): {
  options: Option[];
  filteredOptions: Option[];
  optionsMapByValue: Record<string, Option>;
} => {
  const tags = useTagEntities();

  const options = useMemo(() => tags.map(mapTagEntityToCustomSelectTagOption), [tags]);

  const optionsMapByValue: Record<string, Option> = useMemo(() => {
    return indexBy(prop('value'), options);
  }, [options]);

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label?.toLowerCase().includes(searchByNameReg.toLowerCase()),
    );
  }, [options, searchByNameReg]);

  return {
    options,
    filteredOptions,
    optionsMapByValue,
  };
};
