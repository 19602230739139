import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/account';

import { FlashIcon } from '@/components/Icons';

import { PlanCardContentBase } from '../PlanCardContentBase';

import { usePlanCardFreePlanViewModel } from './usePlanCardFreePlanViewModel';

import styles from './styles.module.scss';

type PlanCardFreePlanProps = {
  showCreditsCount?: boolean;
  title?: string;
};

export const PlanCardFreePlan: FC<PlanCardFreePlanProps> = ({
  title,
  showCreditsCount = false,
}) => {
  const { t } = useTranslation('dashboard');
  const {
    monthlyCreditsLimit,
    monthlyCreditsUsed,
    actionType,
    onInstallExtneion,
    onRevalContact,
    onUpgradeNow,
  } = usePlanCardFreePlanViewModel();

  return (
    <PlanCardContentBase
      title={title || t('freePlan')}
      creditsUsed={monthlyCreditsUsed}
      availableCredits={monthlyCreditsLimit}
      showCreditsCount={showCreditsCount}
      sliderProps={{
        defaultValue: monthlyCreditsUsed,
        value: monthlyCreditsUsed,
        min: 0,
        max: monthlyCreditsLimit,
        disabled: true,
      }}
    >
      {actionType === 'installExtension' && (
        <Button
          variant="outlined"
          classes={{ root: styles.button }}
          size="small"
          onClick={onInstallExtneion}
        >
          <Typography className={styles.buttonText} variant="textInButton">
            {t('getExtension')}
          </Typography>
        </Button>
      )}
      {actionType === 'revealContact' && (
        <Button
          variant="outlined"
          classes={{ root: styles.button }}
          size="small"
          onClick={onRevalContact}
        >
          <Typography className={styles.buttonText} variant="textInButton">
            {t('getFirstContact')}
          </Typography>
        </Button>
      )}
      {actionType === 'upgradePlan' && (
        <ActionDisableGuard>
          <Button
            variant="outlined"
            classes={{ root: styles.button }}
            size="small"
            startIcon={<FlashIcon />}
            onClick={onUpgradeNow}
          >
            <Typography className={styles.buttonText} variant="textInButton">
              {t('upgradeNow')}
            </Typography>
          </Button>
        </ActionDisableGuard>
      )}
    </PlanCardContentBase>
  );
};
