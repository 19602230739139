import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useElements } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';

type UsePaymentCardReturn = {
  card?: StripeCardElement | null;
  error?: string;
  validate: () => Promise<void>;
};

export function usePaymentCard(params: {
  canShowError: boolean;
  onError?: () => void;
}): UsePaymentCardReturn {
  const onErrorRef = useRef<(() => void) | undefined>(params.onError);
  const [error, setError] = useState<string | undefined>('required');
  const { t } = useTranslation('billing', { keyPrefix: 'validations' });

  const elements = useElements();
  const card = elements?.getElement('card');

  onErrorRef.current = params.onError;

  useEffect(() => {
    if (!card) {
      return;
    }

    const handleCardChange = (event: StripeCardElementChangeEvent): void => {
      if (event.error) {
        setError(event.error.code);
        onErrorRef.current?.();
        return;
      }

      if (event.complete) {
        setError(undefined);
        return;
      }

      if (event.empty) {
        setError('required');
        onErrorRef.current?.();
      } else {
        setError(undefined);
      }
    };

    card.on('change', handleCardChange);

    return () => {
      card.off('change', handleCardChange);
    };
  }, [card]);

  return {
    error: params.canShowError && error ? t(`card.${error}`) : undefined,
    card,
    validate: async (): Promise<void> => {
      if (!elements || !card) {
        return;
      }

      const { error } = await elements.submit();

      if (error?.message) {
        setError(error.message);
        throw error;
      }
    },
  };
}
