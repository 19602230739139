import { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  IBillingDetailsEntity,
  IBillingDetailsEntityUI,
  useBillingDetailsValidations,
} from '@/features/common/billing';

export type EditBillingDetailsFormValues = Pick<
  IBillingDetailsEntityUI,
  | 'name'
  | 'country'
  | 'state'
  | 'city'
  | 'address'
  | 'postalCode'
  | 'phone'
  | 'company'
  | 'email'
  | 'vatId'
>;

type BillingDetailsViewModel = {
  form: UseFormReturn<EditBillingDetailsFormValues>;
  isProcessing: boolean;
  validations: ReturnType<typeof useBillingDetailsValidations>;
  onSubmit: (values: EditBillingDetailsFormValues) => Promise<void>;
};

export function useEditBillingDetailsViewModel(params: {
  isOpened: boolean;
  billingDetails: IBillingDetailsEntity;
  onSubmit: (values: EditBillingDetailsFormValues) => Promise<void>;
  onClose: () => void;
}): BillingDetailsViewModel {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.editBillingDetails',
  });
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setIsProcessing] = useState(false);
  const validations = useBillingDetailsValidations();

  const form = useForm({
    values: params.billingDetails,
  });

  useEffect(() => {
    form.reset(params.billingDetails);
  }, [params.billingDetails]);

  useEffect(() => {
    if (params.isOpened) {
      return () => {
        form.reset();
      };
    }
  }, [params.isOpened]);

  return {
    form,
    validations,
    isProcessing,
    onSubmit: async (values: EditBillingDetailsFormValues): Promise<void> => {
      try {
        setIsProcessing(true);

        await params.onSubmit(values);

        enqueueSnackbar(t('successSnackbar.title'), {
          variant: 'success',
          autoHideDuration: 3000,
          description: t('successSnackbar.description'),
        });

        params.onClose();
      } finally {
        setIsProcessing(false);
      }
    },
  };
}
