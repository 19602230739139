import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import {
  useAccount,
  useAccountMonthlyCredits,
  UserRole,
} from '@/features/common/account';
import { PlanType, useCurrentPlanType } from '@/features/common/billing';
import { useHasUserReceivedFreeCredits } from '@/features/getFreeCredits';

import { UserData } from './types';

type UseHeaderViewModel = () => {
  userData: UserData;
  navigateToSettings(): void;
  navigateToTeamManagement(): void;
  navigateToSubscription(): void;
  navigateToTagManagement(): void;
  navigateToReferAFriend(): void;
};

export const useHeaderViewModel: UseHeaderViewModel = () => {
  const { account } = useAccount();
  const { data: planType } = useCurrentPlanType();
  const { data: hasUserReceivedFreeCredits } = useHasUserReceivedFreeCredits();

  const accountMontlyCredits = useAccountMonthlyCredits();

  const userData: UserData = {
    planType: planType ?? PlanType.Free,
    userLogo: account?.thumbnail ?? '',
    fullName: account?.fullName ?? '',
    email: account?.email ?? '',
    creditsUsed: accountMontlyCredits.used,
    creditsLimit: accountMontlyCredits.limit,
    hasUserReceivedFreeCredits,
    role: account?.role || UserRole.Member,
  };
  const navigate = useNavigate();

  const navigateToSettings = (): void => {
    navigate(ROUTES.SETTINGS.INDEX);
  };
  const navigateToTeamManagement = (): void => {
    navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT);
  };
  const navigateToSubscription = (): void => {
    navigate(ROUTES.SETTINGS.SUBSCRIPTION);
  };
  const navigateToTagManagement = (): void => {
    navigate(ROUTES.SETTINGS.TAG_MANAGEMENT);
  };
  const navigateToReferAFriend = (): void => {
    navigate(ROUTES.SETTINGS.REFERRAL);
  };

  return {
    userData,
    navigateToSettings,
    navigateToTeamManagement,
    navigateToSubscription,
    navigateToTagManagement,
    navigateToReferAFriend,
  };
};
