import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from '@mui/material';
import clsx from 'clsx';

import { ActionDisableGuard } from '@/features/common/account';

import { CustomSelect } from '@/components';
import { ArrowDownIcon } from '@/components/Icons';

import usePermissionsButtonViewModel from './PermissionsButtonViewModel';

import styles from './styles.module.scss';

type Props = {
  permissionsPopoverAnchor: HTMLButtonElement | null;
  onPermissionsButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  onPermissionsPopoverClose(): void;
};

const PermissionsButton: React.FC<Props> = ({
  permissionsPopoverAnchor,
  onPermissionsPopoverClose,
  onPermissionsButtonClick,
}) => {
  const { t } = useTranslation('settings');
  const { canAccessAllContacts, allowAccessAllProspects, disallowAccessAllProspects } =
    usePermissionsButtonViewModel(onPermissionsPopoverClose);

  return (
    <>
      <ActionDisableGuard>
        <Button
          className={clsx(styles.PermissionsButton, {
            [styles.Opened]: !!permissionsPopoverAnchor,
          })}
          variant="outlined"
          color="info"
          size={'small'}
          endIcon={<ArrowDownIcon />}
          onClick={onPermissionsButtonClick}
        >
          {t('teamManagement.accessPermissionsButton')}
        </Button>
      </ActionDisableGuard>
      <Popover
        onClose={onPermissionsPopoverClose}
        anchorOrigin={{
          horizontal: -140,
          vertical: 'bottom',
        }}
        classes={{ paper: styles.PermissionsList }}
        open={!!permissionsPopoverAnchor}
        anchorEl={permissionsPopoverAnchor}
      >
        <div onClick={disallowAccessAllProspects}>
          <CustomSelect.DefaultOption
            option={{
              label: t('teamManagement.accessPermissionsButtonOwnText'),
              value: 'own',
            }}
            selected={!canAccessAllContacts}
          />
        </div>
        <div onClick={allowAccessAllProspects}>
          <CustomSelect.DefaultOption
            option={{
              label: t('teamManagement.accessPermissionsButtonAllText'),
              value: 'all',
            }}
            selected={canAccessAllContacts}
          />
        </div>
      </Popover>
    </>
  );
};

export default PermissionsButton;
