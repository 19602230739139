import {
  EmailStatus,
  EmailType,
  IContactEntity,
  PhoneType,
} from '@/features/common/contact';

type UseContactInfoViewModelResult = {
  emails: {
    email: string;
    status: EmailStatus;
    type: EmailType;
  }[];
  phoneNumbers: { phone: string; type: Nullable<PhoneType> }[];
  socialLinks: string[];
  exportedLinks: { provider: string; link: string }[];
};

const genSocialLinkByType = (type: string, id: string): string => {
  switch (type) {
    case 'twitter':
      return `https://twitter.com/${id}`;
    case 'linkedin':
      return `https://www.linkedin.com/in/${id}`;
    case 'facebook':
      return `https://www.facebook.com/${id}`;
    case 'instagram':
      return `https://www.instagram.com/${id}`;
    case 'vk':
      return `https://vk.com/${id}`;
    default:
      return '';
  }
};

export const useContactInfoViewModel = (
  entity: IContactEntity,
): UseContactInfoViewModelResult => {
  return {
    emails: (entity.emails || []).map((item) => ({
      email: item.email,
      status: item.status,
      type: item.type,
    })),
    phoneNumbers: entity.phones
      .map((item) => ({
        phone: item.normalizedPhone || item.phone,
        type: item.type,
      }))
      .filter((phone) => !!phone),
    socialLinks: entity.socialProfiles.map((item) =>
      genSocialLinkByType(item.type, item.userId),
    ),
    exportedLinks: entity.integrationUrls,
  };
};
