import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { filter, first, switchMap } from 'rxjs';

import { ROUTES } from '@/router/routes';

import { AuthState, useAuthUseCase } from '@/features/common/auth';
import {
  ANALYTICS_EVENTS,
  useAnalytics,
  WalkthroughStep,
} from '@/features/system/analytics';

import { useSignUpUseCase } from '../../hooks';

const useSuccessSignUpViewModel = (): void => {
  const effectCalled = useRef(false);

  const { t } = useTranslation('auth');
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const signUpUseCase = useSignUpUseCase();
  const authUseCase = useAuthUseCase();
  const snackbar = useSnackbar();
  const { trackEvent } = useAnalytics();

  const oobCode = queryParams.get('oobCode') ?? '';
  const email = queryParams.get('email') ?? '';

  useEffect(() => {
    // to prevent double effect call in strict mode
    if (effectCalled.current) {
      return;
    }

    effectCalled.current = true;

    const subscription = signUpUseCase
      .verificateEmail({ code: oobCode, email })
      .pipe(
        switchMap(() => authUseCase.getAuthState()),
        filter((authState) => authState !== AuthState.ShouldVerifyEmail), // wait until user get verified through sse
        first(),
      )
      .subscribe({
        next: (authState) => {
          if (authState === AuthState.CanAccessSite) {
            snackbar.enqueueSnackbar(t('verification.success.title'), {
              variant: 'success',
            });
            navigate(ROUTES.DASHBOARD, { replace: true });
            trackEvent(ANALYTICS_EVENTS.USER_PRODUCT_WALKTHROUGH, {
              step: WalkthroughStep.idle,
            });
          } else {
            snackbar.enqueueSnackbar(t('verification.success.titleLogin'), {
              variant: 'success',
            });
            navigate(ROUTES.LOGIN, { replace: true });
          }
        },
        error: () => {
          snackbar.enqueueSnackbar(t('verification.failed.title'), {
            variant: 'error',
            description: t('verification.failed.description'),
          });
          navigate(ROUTES.LOGIN, { replace: true });
        },
      });

    return (): void => subscription.unsubscribe();
  }, []);
};

export default useSuccessSignUpViewModel;
