import { useState } from 'react';
import { firstValueFrom } from 'rxjs';

import {
  BillingCycle,
  getBillingCycleFromSubscription,
  getPlanTypeFromSubscription,
  getSubscriptionPlanFrom,
  IStripePromotionCodeEntity,
  PlanType,
  useBillingUseCase,
} from '@/features/common/billing';
import { DiscountAnnual } from '@/features/common/billing/domain';
import { IWorkspaceSubscriptionEntity } from '@/features/common/workspace';

import { IRecieptUI } from '../types';

import { useBillingCycleFromUrl } from './useBillingCycleFromUrl';
import { usePlanFromUrl } from './usePlanFromUrl';
import { useReciept } from '.';

type UsePaymentSummary = (params: {
  currentSubscription: IWorkspaceSubscriptionEntity;
}) => {
  showBillingCycleSwitcher: boolean;
  annualDiscountPercent: string;
  billingCycle: BillingCycle;
  onBillingCycleChange: (billingCycle: BillingCycle) => void;
  planType: PlanType;
  reciept: IRecieptUI;
  onPromotionCodeApply: (code: string) => Promise<void>;
  onPromotionCodeRemove: () => void;
  promotionCode?: IStripePromotionCodeEntity;
  promotionsAvailable: boolean;
};

function getCurrentPaidPlanOrProFrom(
  subscription: IWorkspaceSubscriptionEntity,
): PlanType {
  const planType = getPlanTypeFromSubscription(subscription);
  if (planType === PlanType.Free) {
    return PlanType.Pro;
  }

  return planType;
}

export const usePaymentSummary: UsePaymentSummary = ({ currentSubscription }) => {
  const billingUseCase = useBillingUseCase();

  const [promotionCode, setPromotionCode] = useState<
    IStripePromotionCodeEntity | undefined
  >();

  const defaultBuyingPlan = getCurrentPaidPlanOrProFrom(currentSubscription);
  const defaultBuyingBillingCycle = getBillingCycleFromSubscription(currentSubscription);

  const [planType] = usePlanFromUrl(defaultBuyingPlan);
  const [billingCycle, setBillingCycle] = useBillingCycleFromUrl(
    defaultBuyingBillingCycle,
  );

  // const [initialBillingCycle] = useState(billingCycle);

  const promotionsAvailable =
    getPlanTypeFromSubscription(currentSubscription) === PlanType.Free;

  const reciept = useReciept({
    planType,
    billingCycle,
    promotionCode,
  });

  function onPromotionCodeRemove(): void {
    setPromotionCode(undefined);
  }

  async function onPromotionCodeApply(code: string): Promise<void> {
    const promotionCode = await firstValueFrom(
      billingUseCase.getPromoCodeInfo({
        code: code,
        plan: getSubscriptionPlanFrom({
          planType,
          billingCycle,
        }),
      }),
    );
    setPromotionCode(promotionCode);
  }

  function onBillingCycleChange(billingCycle: BillingCycle): void {
    setBillingCycle(billingCycle);
    setPromotionCode(undefined);
  }

  return {
    annualDiscountPercent: (DiscountAnnual.rate * 100).toFixed(0),
    showBillingCycleSwitcher: false,
    billingCycle,
    onBillingCycleChange,
    planType,
    reciept,
    onPromotionCodeRemove,
    onPromotionCodeApply,
    promotionCode,
    promotionsAvailable,
  };
};
