import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { combineLatest, tap } from 'rxjs';

import { useInjection } from '@/ioc/ioc.react';
import { GLOBAL_ERROR_TYPES } from '@/ioc/types';

import { useAuthUseCase } from '@/features/common/auth';
import { IGlobalErrorEntity, IGlobalErrorUseCase } from '@/features/system/globalError';
import { UnauthorizedError } from '@/features/system/network';

export const useGlobalErrorViewModel = (): void => {
  const authUseCase = useAuthUseCase();
  const globalErrorUseCase = useInjection<IGlobalErrorUseCase>(
    GLOBAL_ERROR_TYPES.GlobalErrorUseCase,
  );
  const [globalExecutionError, setGlobalExecutionError] =
    useState<IGlobalErrorEntity | null>(null);
  const [globalProcedureErrors, setGlobalProcedureErrors] = useState<
    IGlobalErrorEntity[]
  >([]);
  const snackbar = useSnackbar();

  const handleCloseProcedureErrorSnackbar = (): void => {
    globalErrorUseCase.shiftProcedureError();
  };

  useEffect(() => {
    const subscription = combineLatest([
      globalErrorUseCase.getExecutionError(),
      globalErrorUseCase.getProcedureErrors(),
    ])
      .pipe(
        tap(([executionError, procedureErrors]) => {
          setGlobalExecutionError(executionError);
          setGlobalProcedureErrors(procedureErrors);
        }),
      )
      .subscribe();
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (globalExecutionError instanceof UnauthorizedError) {
      authUseCase.signOut().subscribe();
    }
  }, [globalExecutionError]);

  useEffect(() => {
    if (!globalProcedureErrors.length) return;

    const firstGlobalProcedureErrorFromQueue = globalProcedureErrors[0];

    snackbar.enqueueSnackbar(firstGlobalProcedureErrorFromQueue.message, {
      variant: 'error',
      onClose: handleCloseProcedureErrorSnackbar,
    });
  }, [globalProcedureErrors.length]);
};
