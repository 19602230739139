import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardInfoHint } from '../CardHint';

import { usePlanCardNextPaymentViewModel } from './PlanCardNextPaymentViewModel';

export const PlanCardNextPayment: React.FC = () => {
  const { t } = useTranslation('settings', { keyPrefix: 'subscriptions.planCard' });
  const data = usePlanCardNextPaymentViewModel();

  if (!data) return null;

  return <CardInfoHint>{t('nextPayment', data)}</CardInfoHint>;
};
