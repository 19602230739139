import { BillingCycle } from '../';

export function isBillingCycle(billingCycle: unknown): billingCycle is BillingCycle {
  if (typeof billingCycle !== 'string') {
    return false;
  }

  switch (billingCycle) {
    case BillingCycle.Daily:
    case BillingCycle.Monthly:
    case BillingCycle.Yearly:
      return true;
    default:
      return false;
  }
}
