import { PlanType, useCurrentPlanType } from '@/features/common/billing';
import { useWorkspaceSubscription } from '@/features/common/workspace';

type UsePlanCardViewModelReturn = {
  planType: PlanType;
  isLoading: boolean;
};

export const usePlanCardViewModel = (): UsePlanCardViewModelReturn => {
  const { isLoading: isSubscriptionLoading } = useWorkspaceSubscription();
  const { data: planType, isLoading: isPlanTypeLoading } = useCurrentPlanType();

  const isLoading = isSubscriptionLoading || isPlanTypeLoading;

  return { planType: planType ?? PlanType.Free, isLoading };
};
