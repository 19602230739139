import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TablePagination } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import { Card } from '../Card';

import { InvoiceAction } from './components/InvoiceAction';
import { SubscriptionType } from './components/SubscriptionType';
import { useBillingHistoryViewModel } from './BillingHistoryViewModel';

import styles from './styles.module.scss';

export const BillingHistoryCard: FC = () => {
  const { data, isVisible, isLoading, pagination } = useBillingHistoryViewModel();

  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.billingHistory',
  });

  if (!isVisible) {
    return null;
  }

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: t('table.header.date'),
      sortable: false,
      width: 120,
    },
    {
      field: 'type',
      headerName: t('table.header.type'),
      renderCell: SubscriptionType,
      sortable: false,
      width: 210,
    },
    {
      field: 'amount',
      headerName: t('table.header.price'),
      sortable: false,
    },
    {
      field: 'transactionId',
      type: 'actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <InvoiceAction url={params.row.url} />;
      },
    },
  ];

  return (
    <Card>
      <Box className={styles.history}>
        <Typography variant="h1">{t('title')}</Typography>
        <DataGridPro
          rows={data}
          columns={columns}
          getRowId={(row) => row.transactionId}
          classes={{
            root: styles.historyTable,
            columnHeader: styles.historyTableHeader,
            columnHeaders: styles.historyTableHeader,
            columnHeadersInner: styles.historyTableHeader,
            columnHeaderTitle: styles.historyTableHeaderTitle,
            row: styles.historyTableRow,
            cell: styles.historyTableCell,
          }}
          disableColumnResize
          disableColumnReorder
          disableColumnMenu
          columnHeaderHeight={36}
          rowHeight={40}
          loading={isLoading}
          variant="basic"
          hideFooter
        />
        <TablePagination
          component="div"
          classes={{
            root: styles.historyTablePagination,
            toolbar: styles.historyTablePaginationToolbar,
            actions: styles.historyTablePaginationActions,
          }}
          page={pagination.page}
          count={pagination.total}
          rowsPerPage={pagination.pageSize}
          rowsPerPageOptions={[pagination.pageSize]}
          backIconButtonProps={{ color: 'info', size: 'small', disableRipple: true }}
          nextIconButtonProps={{ color: 'info', size: 'small', disableRipple: true }}
          onPageChange={(_e, page) => pagination.changePage(page)}
        />
      </Box>
    </Card>
  );
};
