import { FC, PropsWithChildren } from 'react';
import { styled } from '@mui/material';

import { Checkbox } from '@/components';
import { TagBadge } from '@/components/TagBadge';

type TagItemProps = {
  selected: boolean;
  color: string;
};

const Tag = styled(TagBadge)(() => ({
  marginRight: '20px',
}));

export const TagItem: FC<PropsWithChildren<TagItemProps>> = ({
  children,
  color,
  ...props
}) => {
  return (
    <>
      <Tag bgColor={color}>{children}</Tag>
      <Checkbox checked={props.selected} />
    </>
  );
};
