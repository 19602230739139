import dayjs from 'dayjs';
import { inject, injectable } from 'inversify';
import { filter, map, Observable, switchMap } from 'rxjs';

import { ACCOUNT_TYPES, DASHBOARD_CHART_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { ISubscriptionUseCase } from '@/features/common/workspace';
import { IDashboardChartRepository } from '@/features/dashboard/features/chart/data';

import { IAccountCreditsUseCase, IAccountUseCase } from './abstractions';
import { IAccountEntity } from './entities';

@injectable()
export class AccountCreditsUseCase implements IAccountCreditsUseCase {
  // TODO: find a way to get these values from the backend
  // private MAX_PAID_MONTHLY_CREDITS = 5000;
  private MAX_PAID_DAILY_CREDITS = 500;

  @inject(ACCOUNT_TYPES.AccountUseCase)
  private accountUseCase: IAccountUseCase;

  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private subscriptionUseCase: ISubscriptionUseCase;

  @inject(DASHBOARD_CHART_TYPES.ChartRepository)
  private chartRepository: IDashboardChartRepository;

  getMonthlyUsedCredits(): Observable<number> {
    return this.accountUseCase
      .getAccount()
      .pipe(map((account) => account?.currentCreditsUsed ?? 0));
  }

  getMonthlyCreditsLimit(): Observable<number> {
    return this.subscriptionUseCase
      .getSubscriptionCreditsInfo()
      .pipe(map((creditsInfo) => creditsInfo.limit));
  }

  getMonthlyCreditsExpirationDate(): Observable<number> {
    return this.subscriptionUseCase.getSubscriptionCreditsInfo().pipe(
      map((creditsInfo) => {
        return creditsInfo.expiresAt ?? dayjs().add(1, 'month').unix();
      }),
    );
  }

  getDailyUsedCredits(): Observable<number> {
    return this.accountUseCase.getAccount().pipe(
      filter((account): account is IAccountEntity => !!account),
      switchMap((account) => {
        const today = dayjs();

        return this.chartRepository.getData({
          fromDate: today.startOf('day').unix(),
          toDate: today.endOf('day').unix(),
          selectedUser: account.uuid,
        });
      }),
      map((data) => Math.max(...data.map((d) => d.creditsUsed))),
    );
  }

  getDailyCreditsLimit(): Observable<number> {
    return this.subscriptionUseCase.getIsFreePlan().pipe(
      map((isFreePlan) => {
        if (isFreePlan) {
          return NaN;
        }

        return this.MAX_PAID_DAILY_CREDITS;
      }),
    );
  }
}
