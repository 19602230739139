import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

export interface IProspectTaskApiService {
  retry(taskId: string): Observable<{
    task_id: 'string';
  }>;
}

type RetryResponse = {
  task_id: 'string';
};

const ApiEndpoints = {
  retry: '/enrichment/retry/:uuid',
};

@injectable()
export class ProspectTaskApiService implements IProspectTaskApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  public retry(taskId: string): Observable<RetryResponse> {
    return this.httpClient
      .post<RetryResponse>(ApiEndpoints.retry.replace(':uuid', taskId), {})
      .pipe(map((response) => response.data));
  }
}
