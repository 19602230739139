import { useIsFederatedIdentityProvider } from '@/features/getFreeCredits/ui/hooks/useIsFederatedIdentityProvider';
import { useSubmitHandlers } from '@/features/getFreeCredits/ui/hooks/useSubmitHandlers';

import { GetFreeCreditsDialogSubmitHandlers } from './GetFreeCreditsDialog.types';

type GetFreeCreditsDialogViewModel = GetFreeCreditsDialogSubmitHandlers & {
  isFederated: boolean;
  credits: number;
};

export const useGetFreeCreditsDialogViewModel = (): GetFreeCreditsDialogViewModel => {
  const credits = 20;
  const { onFederatedSubmit, onGoogleSubmit, onMicrosoftSubmit } = useSubmitHandlers();
  const { isFederatedIdentityProvider } = useIsFederatedIdentityProvider();

  return {
    credits,
    onFederatedSubmit,
    onGoogleSubmit,
    onMicrosoftSubmit,
    isFederated: isFederatedIdentityProvider,
  };
};
