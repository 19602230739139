import { FC } from 'react';

import { SplashScreen } from '@/components/SplashScreen';

import { PaymentDetailsProvider } from './contexts';
import { PaymentDetails as PaymentDetailsInner } from './PaymentDetails';

export const PaymentDetails: FC = () => {
  return (
    <PaymentDetailsProvider fallback={<SplashScreen />}>
      <PaymentDetailsInner />
    </PaymentDetailsProvider>
  );
};
