import { FC } from 'react';
import { Box, BoxProps } from '@mui/material';
import { clsx } from 'clsx';

import { TagBadge } from '@/components';

import { Checkbox, CheckboxProps } from '../Checkbox';

import { SelectBaseOption } from './types';

import styles from './styles.module.scss';

export type CustomSelectTagOptionType = SelectBaseOption & {
  color: string;
};

export type CustomSelectTagOptionProps = BoxProps & {
  option: CustomSelectTagOptionType;
  selected?: boolean;
  checkBoxProps?: CheckboxProps;
};

export const CustomSelectTagOption: FC<CustomSelectTagOptionProps> = ({
  option,
  selected,
  checkBoxProps,
  className,
  ...props
}) => {
  return (
    <Box {...props} className={clsx(styles.tagOption, className)}>
      <TagBadge bgColor={option.color} className={styles.tagOptionBox}>
        {option.label}
      </TagBadge>
      <Checkbox {...checkBoxProps} checked={selected} />
    </Box>
  );
};
