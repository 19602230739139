import { useInjection } from '@/ioc/ioc.react';
import { TAG_TYPES } from '@/ioc/types';

import { ITagEntity, ITagUseCase } from '@/features/common/tag';

import { useObservableResult } from '@/utils/rx';

export const useTagEntities = (): ITagEntity[] => {
  const tagUseCase = useInjection<ITagUseCase>(TAG_TYPES.TagUseCase);

  const { data: tagEntities } = useObservableResult(() => tagUseCase.getTags(), {
    defaultData: [],
  });

  return tagEntities;
};
