/**
 * PlanAction enum
 * @description
 * Enum for plan actions that user can do with plan
 */
export enum PlanAction {
  None = 'None',
  ChoosePlan = 'ChoosePlan',
  ChangeBillingCycle = 'ChangeBillingCycle',
  CommingSoon = 'CommingSoon',
  TalkToSales = 'TalkToSales',
  GetStarted = 'GetStarted',
  CurrentPlan = 'CurrentPlan',
}
