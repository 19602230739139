import { FC } from 'react';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';

export const CardInfoHint: FC<{ children: string }> = ({ children }) => {
  return (
    <Typography color="info" className={styles.hint}>
      {children}
    </Typography>
  );
};

export const CardErrorHint: FC<{ children: string }> = ({ children }) => {
  return (
    <Typography color="error" className={styles.hint}>
      {children}
    </Typography>
  );
};
