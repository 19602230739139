import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { SyncStatus } from '@/features/system/sync/domain';

import { useObservableResult } from '@/utils/rx';

import { useBaseSyncUseCase } from '../../hooks';

import styles from './styles.module.scss';

export const RealTimeUpdatesIssueTracker: FC<PropsWithChildren> = ({ children }) => {
  const syncUseCase = useBaseSyncUseCase();
  const { t } = useTranslation('common');

  const { data: canSync } = useObservableResult(() => syncUseCase.canSync(), {
    deps: [],
  });

  const { data: syncStatus } = useObservableResult(() => syncUseCase.getStatus(), {
    deps: [],
  });

  if (!canSync || !syncStatus) {
    return <>{children}</>;
  }

  switch (syncStatus) {
    case SyncStatus.Inactive:
    case SyncStatus.Offline:
      return (
        <>
          <Box className={styles.banner}>
            <Typography variant="subtitle2" color="white">
              {t('streamConnectivityIssue')}
            </Typography>
          </Box>
          {children}
        </>
      );
    case SyncStatus.Error:
    case SyncStatus.Initializing:
    case SyncStatus.Active:
    default:
      return <>{children}</>;
  }
};
