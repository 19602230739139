import { useMemo } from 'react';

import { isPlanTypeLowerThen, PlanType } from '../../domain';

import { useCurrentPlanType } from './useCurrentPlanType';

type UseIsCurrentPlanTypeLowerThen = (planType: PlanType) => boolean;

export const useIsCurrentPlanTypeLowerThen: UseIsCurrentPlanTypeLowerThen = (
  planType,
) => {
  const { data: currentPlan } = useCurrentPlanType();

  return useMemo(
    () => (currentPlan ? isPlanTypeLowerThen(currentPlan, planType) : false),
    [currentPlan],
  );
};
