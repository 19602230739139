import { FC } from 'react';
import { Radio as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material';
import clsx from 'clsx';

import { CheckIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type RadioProps = Omit<MuiRadioProps, 'disableRipple' | 'icon' | 'checkedIcon'>;

export const Radio: FC<RadioProps> = ({ ...props }) => {
  return (
    <MuiRadio
      disableRipple
      checkedIcon={
        <span className={clsx(styles.icon, styles.checked)}>
          <CheckIcon />
        </span>
      }
      icon={<span className={styles.icon} />}
      {...props}
      className={clsx(styles.base, props.className)}
    />
  );
};
