import {
  IWorkspaceSubscriptionEntity,
  SubscriptionPlan,
} from '@/features/common/workspace';

import { BillingCycle } from '../types';

function getBillingCycleFromPlan(plan: SubscriptionPlan): BillingCycle {
  switch (plan) {
    case SubscriptionPlan.ProV0DailyLegacy:
    case SubscriptionPlan.ProV1DailyLegacy:
    case SubscriptionPlan.ProV2Daily:
    case SubscriptionPlan.GrowthDayly:
    case SubscriptionPlan.UltimateDayly:
    case SubscriptionPlan.UnlimitedDaily:
      return BillingCycle.Daily;
    case SubscriptionPlan.FreeMonthly:
    case SubscriptionPlan.FreePlusMonthly:
    case SubscriptionPlan.ProV0MonthlyLegacy:
    case SubscriptionPlan.ProV1MonthlyLegacy:
    case SubscriptionPlan.ProV2Monthly:
    case SubscriptionPlan.GrowthMonthly:
    case SubscriptionPlan.UltimateMonthly:
    case SubscriptionPlan.UnlimitedMonthly:
      return BillingCycle.Monthly;
    case SubscriptionPlan.ProV0AnnuallyLegacy:
    case SubscriptionPlan.ProV1AnnuallyLegacy:
    case SubscriptionPlan.ProV2Annually:
    case SubscriptionPlan.GrowthAnnually:
    case SubscriptionPlan.UltimateAnnually:
    case SubscriptionPlan.UnlimitedAnnually:
      return BillingCycle.Yearly;
    default:
      return BillingCycle.Monthly;
  }
}

export function getBillingCycleFromSubscription({
  plan,
}: Pick<IWorkspaceSubscriptionEntity, 'plan'>): BillingCycle {
  return getBillingCycleFromPlan(plan);
}

export function getFutureBillingCycleFromSubscription({
  futurePlan,
}: Pick<IWorkspaceSubscriptionEntity, 'futurePlan'>): BillingCycle | null {
  if (!futurePlan) {
    return null;
  }

  return getBillingCycleFromPlan(futurePlan);
}
