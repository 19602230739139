import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';

import {
  ACCESS_PERMISSIONS,
  ActionDisableGuard,
  HasPermission,
} from '@/features/common/account';
import styles from '@/features/dashboard/ui/styles.module.scss';
import { useTeamMembersCardViewModel } from '@/features/dashboard/ui/TeamMembersCard/TeamMembersCardViewModel';

import TeamMembersPreview from '../../../../assets/icons/teamMembersPreview.svg?react';

export const TeamMembersCard: FC = () => {
  const { t } = useTranslation('dashboard');
  const { teamMembers, inviteMembersButtonText, isLoading, handleInviteMembers } =
    useTeamMembersCardViewModel();

  if (isLoading) {
    return <TeamMembersPreview preserveAspectRatio="none" width="402px" height="131px" />;
  }

  return (
    <Stack className={styles.card} width="402px" height="131px">
      <Typography variant="h1">{t('teamMembers')}</Typography>
      <Box mb={0.75} />
      <Typography variant="h3">
        {teamMembers} {t('seats')}
      </Typography>
      <Box mb={2} />
      <HasPermission permission={ACCESS_PERMISSIONS.CAN_MANAGE_TEAM_MEMBERS}>
        <ActionDisableGuard>
          <Button
            variant="outlined"
            classes={{ root: styles.greyBorderButton }}
            size="small"
            onClick={handleInviteMembers}
          >
            <Typography fontSize="12px" variant="textInButton">
              {inviteMembersButtonText}
            </Typography>
          </Button>
        </ActionDisableGuard>
      </HasPermission>
    </Stack>
  );
};
