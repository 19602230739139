import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import clsx from 'clsx';

import { CopyIcon } from '@/components/Icons';

import { useCopyInviteLinkViewModel } from './useCopyInviteLinkViewModel';

import styles from './styles.module.scss';

export const CopyInviteLink: FC = () => {
  const { t } = useTranslation('referral', { keyPrefix: 'inviteSection' });
  const { referralLink, isCopied, handleCopy } = useCopyInviteLinkViewModel();

  return (
    <Box>
      <Typography variant="h3">{t('copyInviteLink')}</Typography>
      <Box className={styles.copyWrapper}>
        <Box className={clsx(styles.linkWrapper, { [styles.active]: isCopied })}>
          {referralLink}
        </Box>
        <Button variant="contained" startIcon={<CopyIcon />} onClick={handleCopy}>
          {t('actions.copy')}
        </Button>
        <Typography
          className={clsx(styles.message, {
            [styles.active]: isCopied,
          })}
          variant="subtitle3"
        >
          {t('emailInput.message')}
        </Typography>
      </Box>
    </Box>
  );
};
