import { FC, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput, SearchInputProps } from '@/components';

import { CustomSelectV2Context } from '../../../../CustomSelectV2Context';

type SearchProps = Omit<SearchInputProps, 'value' | 'onChange' | 'onClear'> & {
  variant?: 'filled' | 'outlined';
};

export const Search: FC<SearchProps> = ({
  variant = 'filled',
  autoFocus = true,
  ...props
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation('common');
  const { searchValue, changeSearchValue, clearSearchValue } =
    useContext(CustomSelectV2Context);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') e.preventDefault();
  };

  useEffect(() => {
    autoFocus && ref.current?.focus();
  }, []);

  return (
    <SearchInput
      {...props}
      inputRef={ref}
      placeholder={props.placeholder || t('customSelect.search.placeholder')}
      value={searchValue}
      onChange={changeSearchValue}
      onClear={clearSearchValue}
      onKeyDown={handleKeyDown}
      variant={variant}
    />
  );
};
