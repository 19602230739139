import { inject, injectable } from 'inversify';

import { WORKSPACE_TYPES } from '@/ioc/types';

import { DbCollectionCreator, IMigrationService } from '@/features/system/db';
import { CollectionName } from '@/features/system/db';

import { IWorkspaceDC } from '../dataContracts';

import { workspaceSchema } from './schema/workspaceSchema';

@injectable()
export class WorkspaceCollectionCreator extends DbCollectionCreator<IWorkspaceDC> {
  constructor(
    @inject(WORKSPACE_TYPES.WorkspaceMigrationService)
    migrationService: IMigrationService,
  ) {
    super({
      collectionName: CollectionName.Workspace,
      schema: workspaceSchema,
      migrationStrategies: migrationService.getMigrationStrategies(),
    });
  }
}
