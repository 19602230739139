import { useState } from 'react';

import { BillingCycle, PlanType } from '@/features/common/billing';

type ChangeBillingCycleState =
  | { isOpened: false }
  | {
      isOpened: true;
      isPorcessing: boolean;
      subscription: { planType: PlanType; billingCycle: BillingCycle };
    };

const DEFAULT_STATE: ChangeBillingCycleState = { isOpened: false };

type UseChangeBillingCycleState = () => readonly [
  ChangeBillingCycleState,
  {
    open(subscription: { planType: PlanType; billingCycle: BillingCycle }): void;
    close(): void;
    startProcessing(): void;
    processedWithSuccess(): void;
    processedWithError(): void;
  },
];

export const useChangeBillingCycleState: UseChangeBillingCycleState = () => {
  const [state, setState] = useState<ChangeBillingCycleState>(DEFAULT_STATE);

  return [
    state,
    {
      open(subscription: { planType: PlanType; billingCycle: BillingCycle }): void {
        setState({ isOpened: true, isPorcessing: false, subscription });
      },
      close(): void {
        setState((prevState) => {
          if (prevState.isOpened && prevState.isPorcessing) {
            return prevState;
          }

          return { isOpened: false };
        });
      },
      startProcessing(): void {
        setState((prevState) => {
          if (!prevState.isOpened) {
            throw new Error('Cannot start processing when dialog is closed');
          }

          return { ...prevState, isPorcessing: true };
        });
      },
      processedWithSuccess(): void {
        setState({ isOpened: false });
      },
      processedWithError(): void {
        setState((prevState) => {
          if (!prevState.isOpened) {
            throw new Error('Cannot process with error when dialog is closed');
          }

          return { ...prevState, isPorcessing: false };
        });
      },
    },
  ] as const;
};
