import {
  IWorkspaceSubscriptionEntity,
  SubscriptionPlan,
} from '@/features/common/workspace';

import { PlanType } from '../types';

function _getPlanTypeFromSubscription(plan: SubscriptionPlan): PlanType {
  switch (plan) {
    case SubscriptionPlan.FreeMonthly:
    case SubscriptionPlan.FreePlusMonthly:
      return PlanType.Free;

    case SubscriptionPlan.ProV0DailyLegacy:
    case SubscriptionPlan.ProV0MonthlyLegacy:
    case SubscriptionPlan.ProV0AnnuallyLegacy:
      return PlanType.ProLegacyV0;

    case SubscriptionPlan.ProV1DailyLegacy:
    case SubscriptionPlan.ProV1MonthlyLegacy:
    case SubscriptionPlan.ProV1AnnuallyLegacy:
      return PlanType.ProLegacyV1;

    case SubscriptionPlan.ProV2Daily:
    case SubscriptionPlan.ProV2Monthly:
    case SubscriptionPlan.ProV2Annually:
      return PlanType.Pro;

    case SubscriptionPlan.AdvancedDayly:
    case SubscriptionPlan.AdvancedMonthly:
    case SubscriptionPlan.AdvancedAnnually:
      return PlanType.AdvancedLegacy;

    case SubscriptionPlan.GrowthDayly:
    case SubscriptionPlan.GrowthMonthly:
    case SubscriptionPlan.GrowthAnnually:
      return PlanType.Growth;

    case SubscriptionPlan.UltimateDayly:
    case SubscriptionPlan.UltimateMonthly:
    case SubscriptionPlan.UltimateAnnually:
      return PlanType.Ultimate;

    case SubscriptionPlan.UnlimitedDaily:
    case SubscriptionPlan.UnlimitedMonthly:
    case SubscriptionPlan.UnlimitedAnnually:
      return PlanType.Unlimited;

    case SubscriptionPlan.Scale:
      return PlanType.Scale;
    default:
      return PlanType.Free;
  }
}

export function getPlanTypeFromSubscription({
  plan,
}: Pick<IWorkspaceSubscriptionEntity, 'plan'>): PlanType {
  if (!plan) {
    return PlanType.Free;
  }

  return _getPlanTypeFromSubscription(plan);
}

export function getFuturePlanTypeFromSubscription({
  futurePlan,
}: Pick<IWorkspaceSubscriptionEntity, 'futurePlan'>): PlanType | null {
  if (!futurePlan) {
    return null;
  }

  return _getPlanTypeFromSubscription(futurePlan);
}
