import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import {
  ACCESS_PERMISSIONS,
  ActionDisableGuard,
  HasPermission,
} from '@/features/common/account';

import { InviteTeamMembersDialog } from './components/InviteTeamMembersDialog';
import PermissionsButton from './components/PermissionsButton';
import TeamMembersTable from './components/TeamMembersTable';
import UpgradeAccountDialog from './components/UpgradeAccountDialog';
import useTeamManagementViewModel from './TeamManagementViewModel';

import styles from './styles.module.scss';

const TeamManagement: React.FC = () => {
  const { t } = useTranslation('settings');
  const {
    upgradeAccountDialog,
    inviteDialog,
    permissionsButton,
    teamMembersList,
    canManageTeamMembers,
    currentUserEmail,
  } = useTeamManagementViewModel();

  return (
    <Box className={styles.TabWrapper}>
      <HasPermission permission={ACCESS_PERMISSIONS.CAN_MANAGE_TEAM_MEMBERS}>
        <Box className={styles.ButtonsWrapper}>
          <PermissionsButton {...permissionsButton} />
          <ActionDisableGuard>
            <Button
              onClick={upgradeAccountDialog.onInviteClick}
              variant="contained"
              color="secondary"
              size="small"
            >
              {t('teamManagement.inviteButton')}
            </Button>
          </ActionDisableGuard>
        </Box>
      </HasPermission>

      <UpgradeAccountDialog {...upgradeAccountDialog} />
      <InviteTeamMembersDialog {...inviteDialog} />
      <TeamMembersTable
        canManageTeamMembers={canManageTeamMembers}
        teamMembers={teamMembersList}
        currentUserEmail={currentUserEmail}
      />
    </Box>
  );
};

export default TeamManagement;
