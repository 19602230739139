import type { Components, Theme } from '@mui/material';

export const typography = {
  fontFamily: 'SofiaPro',
  h1: {
    fontSize: 19,
    fontWeight: 600,
  },
  h2: {
    fontSize: 16,
    fontWeight: 600,
  },
  h3: {
    fontSize: 14,
  },
  subtitle1: {
    fontSize: 13,
  },
  subtitle2: {
    fontSize: 12,
  },
  subtitle3: {
    fontFamily: 'SofiaPro',
    fontSize: 11,
  },
  textAsButton: {
    fontFamily: 'SofiaPro',
    fontSize: 13,
    fontWeight: 500,
  },
  textInButton: {
    fontFamily: 'SofiaPro',
    fontSize: 13,
    fontWeight: 600,
  },
  tooltip: {
    fontFamily: 'SofiaPro',
    fontSize: 11,
  },
};

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  styleOverrides: {
    root: ({ ownerState: { color }, theme }) => {
      if (typeof color === 'function') {
        return {
          color: color(theme),
        };
      }

      if (typeof color === 'string') {
        return {
          color: theme.palette[color]?.main || color || 'inherit',
        };
      }

      return {
        color: color || 'inherit',
      };
    },
  },
};
