import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalOwnProps } from '@mui/base/Modal/Modal.types';
import { firstValueFrom } from 'rxjs';

import { ROUTES } from '@/router/routes';

import { useWorkspaceInvitation } from '@/features/common/workspace/ui';
import { useAppLogger } from '@/features/system/logger';

import { getMailLinkHref } from '@/utils/cttLinks';

import { useUserMenuHandlers } from '../header/hooks';

type SwitchTeamDialogViewModel = {
  name: string;
  email: string;
  emailHref: string;
  onClose: ModalOwnProps['onClose'];
  isOpen: boolean;
  isProcessing: boolean;
  onSubmit: () => void;
};

export const useSwitchTeamDialogViewModel = (): SwitchTeamDialogViewModel => {
  const { invitation, useCase } = useWorkspaceInvitation();
  const [isOpen, setIsOpen] = useState(false);
  const { onClose: closeUserMenu } = useUserMenuHandlers();
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const logger = useAppLogger();

  const onSubmit = async (): Promise<void> => {
    if (!invitation) return;

    try {
      setIsProcessing(true);

      await firstValueFrom(
        useCase.acceptSwitchWorkspace({
          uuid: invitation.invitedTo,
        }),
      );

      closeUserMenu();
      navigate(ROUTES.DASHBOARD);
    } catch (error) {
      logger.error(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const onClose = (_, reason): void => {
    if (!invitation) return;

    if (reason !== 'backdropClick') {
      useCase
        .rejectSwitchWorkspace({
          uuid: invitation.invitedTo,
        })
        .subscribe();
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(Boolean(invitation?.invitedTo));
  }, [invitation?.invitedTo]);

  return {
    name: invitation?.invitedBy.name || '',
    email: invitation?.invitedBy.email || '',
    onClose,
    onSubmit,
    isOpen,
    isProcessing,
    emailHref: getMailLinkHref(invitation?.invitedBy.email || ''),
  };
};
